<div>
    <template v-for="item in (price?.reductionAdditions || [])" :key="item">
        <Tag
            v-if="resolveReductionAdditionType(item) === ReductionAdditionType.Regular || resolveReductionAdditionType(item) === ReductionAdditionType.Promotion"
            :style="{ background: item.type ===
                ReductionAdditionValueType.AdditionPercent || item.type === ReductionAdditionValueType.AdditionAbsolute ? 'brown' : 'gray' }"
            >{{formatPrice(item.value, item.type ===
            ReductionAdditionValueType.ReductionPercent || item.type ===
            ReductionAdditionValueType.AdditionPercent)}}</Tag
        >
        <!--<Tag
            v-if="resolveReductionAdditionType(item) === ReductionAdditionType.Promotion"
            severity="success"
        >
            <div>
                <font-awesome-icon icon="fa-solid fa-star" />
                <span class="ml-1">
                    {{formatPrice(item.value, item.type ===
                    ReductionAdditionValueType.ReductionPercent || item.type ===
                    ReductionAdditionValueType.AdditionPercent)}}
                </span>
            </div>
        </Tag>-->
        <!--<Tag
            v-if="resolveReductionAdditionType(item) === ReductionAdditionType.Transport"
            :style="{ background: 'brown' }"
            ><div>
                <font-awesome-icon
                    :icon="['fas', 'person-military-pointing']"
                />
                <span class="ml-1">
                    +{{formatPrice(item.value, item.type ===
                    ReductionAdditionValueType.AdditionPercent)}}
                </span>
            </div></Tag
        >
        <Tag
            v-if="resolveReductionAdditionType(item) === ReductionAdditionType.Customs"
            :style="{ background: 'brown' }"
        >
            <div>
                <font-awesome-icon :icon="['fas', 'fa-truck-plane']" />
                <span class="ml-1">
                    +{{formatPrice(item.value, item.type ===
                    ReductionAdditionValueType.AdditionPercent)}}
                </span>
            </div>
        </Tag>-->
    </template>
    <!--<Tag v-if="nsr" :style="{ background: (nsr > 0 ? 'navy' : 'brown') }"
        ><div>
            <font-awesome-icon v-if="nsr > 0" icon="fa-solid fa-medal" />
            <font-awesome-icon
                v-else
                :icon="['fas', 'person-military-pointing']"
            />
            <span class="ml-1"> {{nsr}} </span>
        </div></Tag
    >-->
</div>
