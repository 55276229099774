import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid"
}
const _hoisted_2 = { class: "col w-full" }
const _hoisted_3 = {
  key: 0,
  class: "flex align-items-center"
}
const _hoisted_4 = { class: "font-semibold text-sm mr-2" }

export function render(_ctx, _cache) {
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_AutoComplete = _resolveComponent("AutoComplete")
  const _component_p_tree = _resolveComponent("p-tree")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showSearchField || _ctx.enableChildTreeCheckbox)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex", {'justify-content-between' : _ctx.showSearchField && _ctx.enableChildTreeCheckbox, 'justify-content-end' : !(_ctx.showSearchField && _ctx.enableChildTreeCheckbox)}])
            }, [
              (_ctx.enableChildTreeCheckbox)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("labels.workflows.includeProductsFromChildCategories")), 1),
                    _createVNode(_component_p_checkbox, {
                      modelValue: _ctx.includeProductsFromChildCategories,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.includeProductsFromChildCategories) = $event)),
                      onChange: _ctx.triggerEventEmission,
                      binary: ""
                    }, null, 8, ["modelValue", "onChange"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.showSearchField)
                ? (_openBlock(), _createBlock(_component_AutoComplete, {
                    key: 1,
                    modelValue: _ctx.searchTerm,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.searchTerm) = $event)),
                    suggestions: _ctx.filteredResults,
                    placeholder: _ctx.searchTermPlaceHolder,
                    onComplete: _ctx.loadData,
                    onItemSelect: _ctx.onTermSelected,
                    optionLabel: "name",
                    optionValue: "id"
                  }, null, 8, ["modelValue", "suggestions", "placeholder", "onComplete", "onItemSelect"]))
                : _createCommentVNode("", true)
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_p_tree, {
      value: _ctx.categories,
      selectionMode: _ctx.selectionType,
      onNodeExpand: _ctx.onNodeExpand,
      onNodeSelect: _ctx.onNodeSelect,
      onNodeUnselect: _ctx.onNodeUnselect,
      selectionKeys: _ctx.selectedCategories,
      "onUpdate:selectionKeys": _cache[2] || (_cache[2] = $event => ((_ctx.selectedCategories) = $event)),
      metaKeySelection: false,
      class: "text-sm",
      expandedKeys: _ctx.expandedKeys
    }, null, 8, ["value", "selectionMode", "onNodeExpand", "onNodeSelect", "onNodeUnselect", "selectionKeys", "expandedKeys"])
  ], 64))
}