import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "formgrid grid p-fluid" }
const _hoisted_2 = { class: "col field" }
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}
const _hoisted_6 = { class: "mt-3 flex justify-content-end" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_AutoComplete = _resolveComponent("AutoComplete")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.sourceProduct')), 1),
        _createVNode(_component_AutoComplete, {
          modelValue: _ctx.v$.productSource.$model,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.productSource.$model) = $event)),
          suggestions: _ctx.filteredProducts,
          class: _normalizeClass({'p-invalid':_ctx.v$.productSource.$invalid && _ctx.submitted}),
          onComplete: _cache[2] || (_cache[2] = $event => (_ctx.searchProducts($event))),
          dropdown: "",
          optionLabel: "label",
          optionValue: "value"
        }, null, 8, ["modelValue", "suggestions", "class"]),
        ((_ctx.v$.productSource.$invalid && _ctx.submitted) || _ctx.v$.productSource.$pending.$response)
          ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.v$.productSource.required.$message), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.productType')), 1),
        _createVNode(_component_p_dropdown, {
          modelValue: _ctx.v$.productType.$model,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.productType.$model) = $event)),
          options: [{label: 'OpenBox', value: 'OpenBox'}, {label: 'Used (3 Sterne ★★★)', value: 'Used-3-Stars'}, {label: 'Used (2 Sterne ★★)', value: 'Used-2-Stars'}, {label: 'Used (1 Stern ★)', value: 'Used-1-Star'}, {label: 'ExDemo (Grade A)', value: 'ExDemo-Grade-A'}, {label: 'ExDemo (Grade B)', value: 'ExDemo-Grade-B'}, {label: 'DEMO-POOL', value: 'DEMO-POOL'}, {label: 'LOANER', value: 'KOMMISSIONSWARE'}],
          optionLabel: "label",
          optionValue: "value",
          class: _normalizeClass({'p-invalid':_ctx.v$.productType.$invalid && _ctx.submitted})
        }, null, 8, ["modelValue", "class"]),
        ((_ctx.v$.productType.$invalid && _ctx.submitted) || _ctx.v$.productType.$pending.$response)
          ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.v$.productType.required.$message), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_p_button, {
        severity: "success",
        textContent: _toDisplayString(_ctx.$t('buttons.proceedToAdding')),
        disabled: !_ctx.showNextButton,
        onClick: _ctx.handleProceedToAddingClick
      }, null, 8, ["textContent", "disabled", "onClick"])
    ])
  ]))
}