import {ReductionAdditionType, ReductionAdditionValueType} from '@/utils/enums';
import {isCurrentlyActivePrice} from '@/utils/helpers';
import {i18n} from '@/utils/i18n';
import Tag from 'primevue/tag';
import {computed, toRefs} from 'vue';

export default {
    name: 'reduction-addition',
    components: {
        Tag
    },
    props: {
        price: {
            type: Object
        },
        nsrValue: {
            type: Number
        }
    },
    setup(props: any) {
        const {price, nsrValue} = toRefs(props);

        const resolveReductionAdditionType = (reductionAddition: {
            name: string;
            type: string;
            value: string;
        }): ReductionAdditionType => {
            if (
                reductionAddition.type ===
                    ReductionAdditionValueType.ReductionPercent ||
                reductionAddition.type ===
                    ReductionAdditionValueType.ReductionAbsolute
            ) {
                const currentTimestamp = Date.now();

                return price.value?.startDate > currentTimestamp &&
                    price.value?.endDate
                    ? ReductionAdditionType.Promotion
                    : ReductionAdditionType.Regular;
            } else {
                /*return reductionAddition.name === 'Transport'
                    ? ReductionAdditionType.Transport
                    : ReductionAdditionType.Customs;*/
                return ReductionAdditionType.Regular;
            }
        };

        const formatPrice = (value: number, isPercentage: boolean = false) => {
            if (isPercentage) {
                return value + ' %';
            }

            const formatter = new Intl.NumberFormat(i18n.global.locale, {
                style: 'currency',
                currency: price.value?.currencyName || 'EUR'
            });

            return formatter.format(value);
        };

        const nsr = computed(() => {
            if (nsrValue.value && isCurrentlyActivePrice(price.value)) {
                return formatPrice(nsrValue.value);
            }

            return null;
        });

        return {
            price,
            nsr,
            resolveReductionAdditionType,
            formatPrice,
            isCurrentlyActivePrice: isCurrentlyActivePrice,
            ReductionAdditionType,
            ReductionAdditionValueType
        };
    }
};
