import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "font-bold" }
const _hoisted_2 = { class: "grid p-fluid" }
const _hoisted_3 = { class: "col field" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { style: {"text-align":"center"} }

export function render(_ctx, _cache) {
  const _component_Promotions = _resolveComponent("Promotions")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_Panel = _resolveComponent("Panel")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_BulkProductAction = _resolveComponent("BulkProductAction")

  return (_openBlock(), _createBlock(_component_BulkProductAction, {
    onCloseDialog: _ctx.closeResponsive,
    onConfirmClicked: _ctx.confirmClicked,
    products: _ctx.products,
    productTotalRecords: _ctx.productTotalRecords,
    displayDialog: _ctx.showDialog,
    isFilterRelated: _ctx.isFilterRelated,
    summaryTabVisible: _ctx.summaryTabVisible,
    loading: _ctx.loading,
    dialogHeader: _ctx.$t('labels.editPromotions'),
    canPostponeExecution: ""
  }, {
    "tab-panel-middle": _withCtx(() => [
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('labels.promotions')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Promotions, {
            "custom-attributes-definition": _ctx.customAttributesDefinition,
            onFormDirty: _ctx.onFormDirty,
            isBulkEdit: ""
          }, null, 8, ["custom-attributes-definition", "onFormDirty"])
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    "summary-panel-header": _withCtx((slotProps) => [
      _createVNode(_component_Panel, { class: "mb-3" }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('labels.adjustments')), 1)
        ]),
        default: _withCtx(() => [
          (_ctx.filteredPromotionsInput)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(Object.entries(_ctx.filteredPromotionsInput), (item) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    (['internalNote','internalNoteSupplySource'].indexOf(item[0]) !== -1)
                      ? (_openBlock(), _createElementBlock("label", _hoisted_4, _toDisplayString(_ctx.$t('labels.productDescription.' + item[0])), 1))
                      : (_openBlock(), _createElementBlock("label", _hoisted_5, _toDisplayString(_ctx.$t('labels.productPromotions.' + item[0])), 1)),
                    (item[1] === true || item[1] === false)
                      ? (_openBlock(), _createBlock(_component_TriStateCheckbox, {
                          key: 2,
                          modelValue: item[1],
                          "onUpdate:modelValue": $event => ((item[1]) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : (item[1] instanceof Date)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                            _createTextVNode(_toDisplayString(item[1].toLocaleDateString(_ctx.locale) ||
                            _ctx.$t('messages.valueWillBeSetToNull').toUpperCase()), 1)
                          ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                            _createTextVNode(_toDisplayString(item[1] ||
                            _ctx.$t('messages.valueWillBeSetToNull').toUpperCase()), 1)
                          ], 64))
                  ])
                ]))
              }), 256))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    "summary-panel-middle": _withCtx(() => [
      _createElementVNode("h1", _hoisted_6, [
        _createVNode(_component_font_awesome_icon, {
          style: {"margin-left":"30px"},
          icon: ['fas', 'angle-double-down']
        }),
        _createVNode(_component_font_awesome_icon, {
          style: {"margin-left":"30px"},
          icon: ['fas', 'angle-double-down']
        }),
        _createVNode(_component_font_awesome_icon, {
          style: {"margin-left":"30px"},
          icon: ['fas', 'angle-double-down']
        })
      ])
    ]),
    _: 1
  }, 8, ["onCloseDialog", "onConfirmClicked", "products", "productTotalRecords", "displayDialog", "isFilterRelated", "summaryTabVisible", "loading", "dialogHeader"]))
}