import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "form-label text-sm" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "form-label text-sm" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "form-label text-sm" }
const _hoisted_8 = { class: "font-weight-bold" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "form-label text-sm" }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "form-label text-sm" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "row mt-4 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_calendar = _resolveComponent("p-calendar")
  const _component_p_fieldset = _resolveComponent("p-fieldset")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_Panel = _resolveComponent("Panel")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('labels.productPrices.addEdit' + (_ctx.priceType || 'Cost') + 'Price'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[6] || (_cache[6] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw'},
    style: {width: '45vw'},
    closable: false,
    modal: true
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[5] || (_cache[5] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        _createVNode(_component_p_fieldset, {
          legend: _ctx.$t('labels.productPrices.priceInformation')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('labels.' + _ctx.priceType.toLowerCase() +
                        'PriceLong')), 1),
                _createElementVNode("div", null, [
                  _createVNode(_component_InputNumber, {
                    locale: _ctx.locale,
                    mode: "currency",
                    currency: _ctx.isCostPrice ? _ctx.currencyName : 'EUR',
                    maxFractionDigits: 2,
                    autocomplete: "off",
                    modelValue: _ctx.v$.price.$model,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$.price.$model) = $event)),
                    class: _normalizeClass({'p-invalid':_ctx.v$.price.$invalid && _ctx.submitted, 'mb-3':  !((_ctx.v$.price.$invalid && _ctx.submitted) || _ctx.v$.price.$pending.$response)})
                  }, null, 8, ["locale", "currency", "modelValue", "class"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.price.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('labels.startDate')), 1),
                _createElementVNode("div", null, [
                  _createVNode(_component_p_calendar, {
                    autocomplete: "off",
                    dateFormat: "dd.mm.yy",
                    minDate: new Date(),
                    showButtonBar: "",
                    modelValue: _ctx.v$.startDate.$model,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.startDate.$model) = $event)),
                    class: _normalizeClass({'tt-form-input': true, 'p-invalid':_ctx.v$.startDate.$invalid && _ctx.submitted, 'mb-3':  !((_ctx.v$.startDate.$invalid && _ctx.submitted) || _ctx.v$.startDate.$pending.$response)})
                  }, null, 8, ["minDate", "modelValue", "class"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.startDate.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('labels.endDate')), 1),
                _createElementVNode("div", null, [
                  _createVNode(_component_p_calendar, {
                    autocomplete: "off",
                    dateFormat: "dd.mm.yy",
                    minDate: new Date(),
                    showButtonBar: "",
                    modelValue: _ctx.v$.endDate.$model,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$.endDate.$model) = $event)),
                    class: _normalizeClass({'tt-form-input': true, 'p-invalid':_ctx.v$.endDate.$invalid && _ctx.submitted, 'mb-3':  !((_ctx.v$.endDate.$invalid && _ctx.submitted) || _ctx.v$.endDate.$pending.$response)})
                  }, null, 8, ["minDate", "modelValue", "class"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.endDate.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["legend"]),
        (_ctx.isCostPrice || _ctx.isSellPrice)
          ? (_openBlock(), _createBlock(_component_Panel, {
              key: 0,
              class: "mt-3"
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('labels.productPrices.reductionAdditions')), 1),
                  ((_ctx.state.reductionAdditions || []).length < 1)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: "pi pi-plus-circle ml-2",
                        style: {"color":"green","top":"1px","cursor":"pointer"},
                        onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.addReductionAddition && _ctx.addReductionAddition(...args)))
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.reductionAdditions, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "grid mt-3"
                  }, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('labels.productPrices.reductionAdditionType')), 1),
                      _createElementVNode("div", null, [
                        _createVNode(_component_p_dropdown, {
                          class: "w-full",
                          modelValue: item.type,
                          "onUpdate:modelValue": $event => ((item.type) = $event),
                          options: _ctx.getReductionAdditionValueTypeOptions(item),
                          disabled: _ctx.isEdit && !!item.id,
                          optionLabel: "label",
                          optionValue: "value"
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "disabled"]),
                        (_ctx.submitted)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.reductionAdditions.$each.$response.$errors[index].type, (error) => {
                              return (_openBlock(), _createElementBlock("small", {
                                style: {"display":"block"},
                                class: "p-error",
                                key: error
                              }, _toDisplayString(error.$message), 1))
                            }), 128))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('labels.productPrices.reductionAdditionValue')), 1),
                      _createElementVNode("div", null, [
                        _createVNode(_component_InputNumber, {
                          mode: "decimal",
                          class: _normalizeClass(["w-11", {'p-invalid':_ctx.submitted && _ctx.v$.reductionAdditions.$each.$response.$data[index].value.$invalid}]),
                          locale: _ctx.locale,
                          maxFractionDigits: 2,
                          modelValue: item.value,
                          "onUpdate:modelValue": $event => ((item.value) = $event)
                        }, null, 8, ["locale", "modelValue", "onUpdate:modelValue", "class"]),
                        _createElementVNode("span", {
                          class: "pi pi-minus-circle ml-2 mt-2 text-red-600",
                          style: {"cursor":"pointer"},
                          onClick: $event => (_ctx.removeReductionAddition(index))
                        }, null, 8, _hoisted_13),
                        (_ctx.submitted)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.reductionAdditions.$each.$response.$errors[index].value, (error) => {
                              return (_openBlock(), _createElementBlock("small", {
                                style: {"display":"block"},
                                class: "p-error",
                                key: error
                              }, _toDisplayString(error.$message), 1))
                            }), 128))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_p_button, {
            severity: "danger",
            textContent: _toDisplayString(_ctx.$t('buttons.cancel')),
            onClick: _cache[4] || (_cache[4] = $event => (_ctx.closeResponsive(null)))
          }, null, 8, ["textContent"]),
          _createVNode(_component_p_button, {
            severity: "success",
            type: "submit",
            textContent: _toDisplayString(_ctx.$t('buttons.confirm'))
          }, null, 8, ["textContent"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}