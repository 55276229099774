import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-9" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "font-semibold" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 0,
  class: "pi pi-check text-green-600"
}
const _hoisted_11 = {
  key: 1,
  class: "pi pi-times-circle text-red-600"
}
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-9" }
const _hoisted_14 = { class: "flex justify-content-end" }
const _hoisted_15 = { class: "mr-3 mt-1" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-8" }
const _hoisted_21 = { class: "flex justify-content-end" }
const _hoisted_22 = { class: "mr-3 mt-1" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = ["onClick"]
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { class: "row" }
const _hoisted_27 = { class: "col-5" }
const _hoisted_28 = { class: "flex justify-content-end" }
const _hoisted_29 = { class: "mr-3 mt-1" }
const _hoisted_30 = ["onClick"]
const _hoisted_31 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Column = _resolveComponent("Column")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_calendar = _resolveComponent("p-calendar")
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_InputSwitch = _resolveComponent("InputSwitch")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_Tag = _resolveComponent("Tag")
  const _component_ReductionAddition = _resolveComponent("ReductionAddition")
  const _component_ProductPriceDialog = _resolveComponent("ProductPriceDialog")
  const _component_ScaledProductPriceDialog = _resolveComponent("ScaledProductPriceDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm mb-5",
          stripedRows: "",
          paginator: _ctx.articleSupplySources && _ctx.articleSupplySources.length > 20,
          rows: 20,
          selection: _ctx.selectedSupplySource,
          "onUpdate:selection": _cache[1] || (_cache[1] = $event => ((_ctx.selectedSupplySource) = $event)),
          editingRows: _ctx.editingRows,
          "onUpdate:editingRows": _cache[2] || (_cache[2] = $event => ((_ctx.editingRows) = $event)),
          dataKey: "id",
          value: _ctx.articleSupplySources,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50,100,200],
          "current-page-report-template": "{first} to {last} of {totalRecords}",
          editMode: "row",
          onRowEditSave: _ctx.onRowEditComplete,
          rowClass: (data) => {return (data.id || null).toString() === _ctx.primarySupplySourceId
                ? 'bg-green-100'
                : '';}
        }, {
          empty: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('messages.noRecordsFound')) + ". ", 1)
          ]),
          default: _withCtx(() => [
            (_ctx.articleSupplySources.length > 1)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 0,
                  selectionMode: "single",
                  headerStyle: "width: 3rem"
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_Column, {
              field: "positionNumber",
              header: _ctx.$t('labels.position')
            }, {
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.positionNumber), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "articleNumber",
              header: _ctx.$t('labels.salesOrder.supplierArticleNumber')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "supplier",
              header: _ctx.$t('labels.productAdd.supplier')
            }, {
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.supplier?.name), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              style: {"min-width":"10rem"},
              field: "discountedPriceFormatted",
              header: _ctx.$t('labels.salesOrder.price')
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_ctx.formatPrice(data?.supplier?.currencyName || 'EUR',
                        data.finalPrice)) + " ", 1),
                  (data.finalPriceInDefaultCurrency)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                        _cache[9] || (_cache[9] = _createTextVNode(" [")),
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formatPrice('EUR',
                                data.finalPriceInDefaultCurrency)), 1),
                        _cache[10] || (_cache[10] = _createTextVNode("] "))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: 'Kalkulatorischer EK',
              style: {"min-width":"10rem"}
            }, {
              body: _withCtx(({data}) => [
                (data.finalPriceIncludingNsr)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createTextVNode(_toDisplayString(_ctx.formatPrice(data?.supplier?.currencyName || 'EUR',
                        data.finalPriceIncludingNsr)) + " ", 1),
                      (data.finalPriceIncludingNsrInDefaultCurrency)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                            _cache[11] || (_cache[11] = _createTextVNode(" [")),
                            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.formatPrice('EUR',
                                data.finalPriceIncludingNsrInDefaultCurrency)), 1),
                            _cache[12] || (_cache[12] = _createTextVNode("] "))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                _cache[13] || (_cache[13] = _createTextVNode(" >"))
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "nsrStandard",
              header: "NSR Standard"
            }, {
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputNumber, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  mode: "decimal",
                  locale: _ctx.locale,
                  maxFractionDigits: 2,
                  inputClass: "w-6",
                  class: "text-sm"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "locale"])
              ]),
              body: _withCtx(({data}) => [
                (data?.customAttributes?.supply_source_nsr_regular)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.formatPrice(data?.supplier?.currencyName || 'EUR',
                        data.customAttributes?.supply_source_nsr_regular)), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "nsrPromo",
              header: "NSR Promo"
            }, {
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputNumber, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  mode: "decimal",
                  locale: _ctx.locale,
                  maxFractionDigits: 2,
                  class: "text-sm",
                  inputClass: "w-6"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "locale"])
              ]),
              body: _withCtx(({data}) => [
                (data?.customAttributes?.supply_source_nsr_promo)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.formatPrice(data?.supplier?.currencyName || 'EUR',
                        data.customAttributes?.supply_source_nsr_promo)), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              header: "NSR Promo Startdatum",
              field: "nsrPromoStart"
            }, {
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_p_calendar, {
                  dateFormat: "dd.mm.yy",
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  minDate: new Date()
                }, null, 8, ["modelValue", "onUpdate:modelValue", "minDate"])
              ]),
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.promoStartDateFormatted), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              header: "NSR Promo Enddatum",
              field: "nsrPromoEnd"
            }, {
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_p_calendar, {
                  dateFormat: "dd.mm.yy",
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  minDate: new Date()
                }, null, 8, ["modelValue", "onUpdate:modelValue", "minDate"])
              ]),
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.promoEndDateFormatted), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "nsrPromoAppliesForStock",
              header: "Promo gilt für Lagerware"
            }, {
              body: _withCtx(({data}) => [
                (data.customAttributes?.supply_source_nsr_promo_applies_for_stock)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_10))
                  : (_openBlock(), _createElementBlock("i", _hoisted_11))
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_p_checkbox, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  binary: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            (_ctx.editPermissionAvailable)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 1,
                  rowEditor: "",
                  style: {"width":"10%","min-width":"5rem"},
                  bodyStyle: "text-align:center"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["paginator", "selection", "editingRows", "value", "onRowEditSave", "rowClass"])
      ]),
      _cache[14] || (_cache[14] = _createElementVNode("div", { class: "col-3" }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm mb-5",
          rowClass: _ctx.rowClass,
          stripedRows: "",
          paginator: _ctx.productCostPrices && _ctx.productCostPrices.length > 20,
          rows: 20,
          value: _ctx.productCostPrices,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50,100,200],
          "current-page-report-template": "{first} to {last} of {totalRecords}"
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('labels.costPriceLong')) + " ", 1),
            (_ctx.editPermissionAvailable)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "pi pi-plus-circle text-green-600 ml-2",
                  style: {"cursor":"pointer"},
                  onClick: _cache[3] || (_cache[3] = $event => {_ctx.originalPrice = null;_ctx.dialogPriceType = _ctx.PriceType.CostPrice;_ctx.showProductPriceDialog = true;})
                }))
              : _createCommentVNode("", true)
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("labels.inactivePrices")), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_InputSwitch, {
                  modelValue: _ctx.showCostPricesInThePast,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.showCostPricesInThePast) = $event))
                }, null, 8, ["modelValue"])
              ])
            ])
          ]),
          empty: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('messages.noRecordsFound')) + ". ", 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "startDateFormatted",
              header: _ctx.$t('labels.startDate')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "endDateFormatted",
              header: _ctx.$t('labels.endDate')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "priceFormatted",
              header: _ctx.$t('labels.ekBasePrice')
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("span", null, _toDisplayString(data.priceFormatted), 1),
                ((data?.description || '').toUpperCase() === 'PROMO')
                  ? (_openBlock(), _createBlock(_component_Tag, {
                      key: 0,
                      severity: "success",
                      class: "ml-2"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-star" }),
                          _cache[15] || (_cache[15] = _createTextVNode(" PROMO "))
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('labels.weclappPriceDiscounts')
            }, {
              body: _withCtx(({data}) => [
                _createVNode(_component_ReductionAddition, {
                  price: data,
                  nsrValue: _ctx.nsrValue
                }, null, 8, ["price", "nsrValue"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "discountedPriceFormatted",
              header: _ctx.$t('labels.ekDiscountPrice')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "priceScaleValue",
              header: _ctx.$t('labels.priceRangeFrom')
            }, {
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.priceScaleValue) + " ", 1),
                (!data.priceScaleValue && _ctx.editPermissionAvailable)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: "pi pi-plus-circle text-green-600 ml-1",
                      style: {"cursor":"pointer"},
                      onClick: $event => {_ctx.originalPrice = data;_ctx.showScaledPriceDialog = true;}
                    }, null, 8, _hoisted_16))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, null, {
              body: _withCtx(({data}) => [
                (_ctx.editPermissionAvailable && !data.priceScaleValue && (!data.endDate || data.endDate >= Date.now()))
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: "pi pi-pencil",
                      style: {"cursor":"pointer"},
                      onClick: $event => {_ctx.originalPrice = data;_ctx.dialogPriceType = _ctx.PriceType.CostPrice;_ctx.showProductPriceDialog = true;}
                    }, null, 8, _hoisted_17))
                  : _createCommentVNode("", true),
                (_ctx.editPermissionAvailable)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass(["pi pi-minus-circle text-red-600", {'ml-2': !data.priceScaleValue && (!data.endDate || data.endDate >= Date.now())}]),
                      style: {"cursor":"pointer"},
                      onClick: $event => (_ctx.removePrice(data, !!data.priceScaleValue))
                    }, null, 10, _hoisted_18))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["rowClass", "paginator", "value"])
      ]),
      _cache[16] || (_cache[16] = _createElementVNode("div", { class: "col-3" }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm mb-5",
          stripedRows: "",
          rowClass: _ctx.rowClass,
          paginator: _ctx.productSellPrices && _ctx.productSellPrices.length > 20,
          rows: 20,
          value: _ctx.productSellPrices,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50,100,200],
          "current-page-report-template": "{first} to {last} of {totalRecords}"
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('labels.sellPriceLong')), 1),
            (_ctx.editPermissionAvailable)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "pi pi-plus-circle text-green-600 ml-2",
                  style: {"cursor":"pointer"},
                  onClick: _cache[5] || (_cache[5] = $event => {_ctx.originalPrice = null;_ctx.dialogPriceType = _ctx.PriceType.SellPrice;_ctx.showProductPriceDialog = true;})
                }))
              : _createCommentVNode("", true)
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t("labels.inactivePrices")), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_InputSwitch, {
                  modelValue: _ctx.showSellPricesInThePast,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.showSellPricesInThePast) = $event))
                }, null, 8, ["modelValue"])
              ])
            ])
          ]),
          empty: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('messages.noRecordsFound')) + ". ", 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "startDateFormatted",
              header: _ctx.$t('labels.startDate')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "endDateFormatted",
              header: _ctx.$t('labels.endDate')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "salesChannel",
              header: _ctx.$t('labels.productHome.salesChannel')
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", {
                  class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-teltec': data?.salesChannel === 'NET1', 'sales-channel-videodata': data?.salesChannel === 'NET2'}])
                }, null, 2)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "priceFormatted",
              header: _ctx.$t('labels.vkPrice')
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("span", null, _toDisplayString(data.priceFormatted), 1),
                ((data?.description || '').toUpperCase() === 'PROMO')
                  ? (_openBlock(), _createBlock(_component_Tag, {
                      key: 0,
                      severity: "success",
                      class: "ml-2"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-star" }),
                          _cache[17] || (_cache[17] = _createTextVNode(" PROMO "))
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('labels.weclappPriceDiscounts')
            }, {
              body: _withCtx(({data}) => [
                _createVNode(_component_ReductionAddition, { price: data }, null, 8, ["price"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "discountedPriceFormatted",
              header: _ctx.$t('labels.vkDiscountPrice')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "priceScaleValue",
              header: _ctx.$t('labels.priceRangeFrom')
            }, {
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.priceScaleValue) + " ", 1),
                (_ctx.editPermissionAvailable && !data.priceScaleValue)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: "pi pi-plus-circle text-green-600 ml-1",
                      style: {"cursor":"pointer"},
                      onClick: $event => {_ctx.originalPrice = data;_ctx.showScaledPriceDialog = true;}
                    }, null, 8, _hoisted_23))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, null, {
              body: _withCtx(({data}) => [
                (_ctx.editPermissionAvailable && !data.endDate || data.endDate >= Date.now())
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: "pi pi-pencil",
                      style: {"cursor":"pointer"},
                      onClick: $event => {_ctx.originalPrice = data;_ctx.dialogPriceType = _ctx.PriceType.SellPrice;_ctx.showProductPriceDialog = true;}
                    }, null, 8, _hoisted_24))
                  : _createCommentVNode("", true),
                (_ctx.editPermissionAvailable)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass(["pi pi-minus-circle text-red-600", {'ml-2': !data.endDate || data.endDate >= Date.now()}]),
                      style: {"cursor":"pointer"},
                      onClick: $event => (_ctx.removePrice(data))
                    }, null, 10, _hoisted_25))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["rowClass", "paginator", "value"])
      ]),
      _cache[18] || (_cache[18] = _createElementVNode("div", { class: "col-4" }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_26, [
      _createElementVNode("div", _hoisted_27, [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          rowClass: _ctx.rowClass,
          paginator: _ctx.productListPrices && _ctx.productListPrices.length > 20,
          rows: 20,
          value: _ctx.productListPrices,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50,100,200],
          "current-page-report-template": "{first} to {last} of {totalRecords}"
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('labels.listPriceLong')), 1),
            (_ctx.editPermissionAvailable)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "pi pi-plus-circle text-green-600 ml-2",
                  style: {"cursor":"pointer"},
                  onClick: _cache[7] || (_cache[7] = $event => {_ctx.originalPrice = null;_ctx.dialogPriceType = _ctx.PriceType.ListPrice;_ctx.showProductPriceDialog = true;})
                }))
              : _createCommentVNode("", true)
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$t("labels.inactivePrices")), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_InputSwitch, {
                  modelValue: _ctx.showListPricesInThePast,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.showListPricesInThePast) = $event))
                }, null, 8, ["modelValue"])
              ])
            ])
          ]),
          empty: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('messages.noRecordsFound')) + ". ", 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "startDateFormatted",
              header: _ctx.$t('labels.startDate')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "endDateFormatted",
              header: _ctx.$t('labels.endDate')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "priceFormatted",
              header: _ctx.$t('labels.price')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, null, {
              body: _withCtx(({data}) => [
                (_ctx.editPermissionAvailable && !data.endDate || data.endDate >= Date.now())
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: "pi pi-pencil",
                      style: {"cursor":"pointer"},
                      onClick: $event => {_ctx.originalPrice = data;_ctx.dialogPriceType = _ctx.PriceType.ListPrice;_ctx.showProductPriceDialog = true;}
                    }, null, 8, _hoisted_30))
                  : _createCommentVNode("", true),
                (_ctx.editPermissionAvailable)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass(["pi pi-minus-circle text-red-600", {'ml-2': !data.endDate || data.endDate >= Date.now()}]),
                      style: {"cursor":"pointer"},
                      onClick: $event => (_ctx.removePrice(data))
                    }, null, 10, _hoisted_31))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["rowClass", "paginator", "value"])
      ]),
      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "col-7" }, null, -1))
    ]),
    _createVNode(_component_ProductPriceDialog, {
      "display-cost-price-dialog": _ctx.showProductPriceDialog,
      priceType: _ctx.dialogPriceType,
      originalPrice: _ctx.originalPrice,
      currencyName: _ctx.supplierCurrencyName,
      onCloseDialog: _ctx.onCloseProductPriceDialog
    }, null, 8, ["display-cost-price-dialog", "priceType", "originalPrice", "currencyName", "onCloseDialog"]),
    _createVNode(_component_ScaledProductPriceDialog, {
      "display-scaled-price-dialog": _ctx.showScaledPriceDialog,
      originalPrice: _ctx.originalPrice,
      onCloseDialog: _ctx.onCloseScaledPriceDialog
    }, null, 8, ["display-scaled-price-dialog", "originalPrice", "onCloseDialog"])
  ], 64))
}