import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createSlots as _createSlots, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-3" }
const _hoisted_3 = { class: "font-semibold" }
const _hoisted_4 = { class: "text-xs" }
const _hoisted_5 = { class: "text-xs" }
const _hoisted_6 = { class: "text-xs" }
const _hoisted_7 = { class: "text-xs" }
const _hoisted_8 = { class: "pi pi-pencil text-color" }
const _hoisted_9 = { class: "text-xs" }
const _hoisted_10 = { class: "text-xs" }
const _hoisted_11 = { class: "text-xs" }
const _hoisted_12 = { class: "text-xs" }
const _hoisted_13 = { class: "pi pi-pencil text-color" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_TreeSelect = _resolveComponent("TreeSelect")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Column = _resolveComponent("Column")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_router_link = _resolveComponent("router-link")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.productsWithIncompleteMeasures')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('labels.shipment.warehouseLevel')), 1),
            _createVNode(_component_TreeSelect, {
              modelValue: _ctx.singleStoragePlace,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.singleStoragePlace) = $event)),
              options: _ctx.storagePlaces,
              onNodeExpand: _ctx.onNodeExpand,
              onNodeSelect: _ctx.onNodeSelect,
              onChange: _ctx.onChangeStorageLocation,
              class: "p-column-filter text-sm mt-2 w-full"
            }, null, 8, ["modelValue", "options", "onNodeExpand", "onNodeSelect", "onChange"])
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col" }, null, -1))
        ]),
        (_ctx.singleStoragePlace)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.storagePlaceTotalRecords > 0 && _ctx.storagePlaceTotalRecords <= 300)
                ? (_openBlock(), _createBlock(_component_DataTable, {
                    key: 0,
                    class: "p-datatable-sm text-sm",
                    stripedRows: "",
                    scrollable: "",
                    scrollHeight: "calc(100vh - 23rem)",
                    paginator: "",
                    rows: 50,
                    ref: "dt",
                    dataKey: "id",
                    value: _ctx.products,
                    responsiveLayout: "scroll",
                    "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                    "rows-per-page-options": [50,100,200],
                    "current-page-report-template": "{first} to {last} of {totalRecords}",
                    filters: _ctx.nonLazyFilters,
                    "onUpdate:filters": _cache[2] || (_cache[2] = $event => ((_ctx.nonLazyFilters) = $event)),
                    filterDisplay: "row",
                    onRowClick: _cache[3] || (_cache[3] = $event => (_ctx.onRowClick($event)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        field: "article.articleNumber",
                        sortable: true,
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.articleNumber')
                      }, {
                        filter: _withCtx(({filterModel,filterCallback}) => [
                          _createVNode(_component_InputText, {
                            type: "text",
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                            onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                            class: "p-column-filter text-sm",
                            placeholder: _ctx.$t('labels.articleNumber')
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                        ]),
                        body: _withCtx(({data}) => [
                          _createTextVNode(_toDisplayString(data.article?.articleNumber), 1)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        field: "article.name",
                        sortable: true,
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.name')
                      }, {
                        filter: _withCtx(({filterModel,filterCallback}) => [
                          _createVNode(_component_InputText, {
                            type: "text",
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                            onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                            class: "p-column-filter text-sm",
                            placeholder: _ctx.$t('labels.name')
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                        ]),
                        body: _withCtx(({data}) => [
                          _createTextVNode(_toDisplayString(data.article?.name), 1)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        field: "article.ean",
                        sortable: true,
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.ean')
                      }, {
                        filter: _withCtx(({filterModel,filterCallback}) => [
                          _createVNode(_component_InputText, {
                            type: "text",
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                            onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                            class: "p-column-filter text-sm",
                            placeholder: _ctx.$t('labels.ean')
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                        ]),
                        body: _withCtx(({data}) => [
                          _createTextVNode(_toDisplayString(data.article?.ean), 1)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        field: "article.manufacturerPartNumber",
                        sortable: true,
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.mpn')
                      }, {
                        filter: _withCtx(({filterModel,filterCallback}) => [
                          _createVNode(_component_InputText, {
                            type: "text",
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                            onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                            class: "p-column-filter text-sm",
                            placeholder: _ctx.$t('labels.mpn')
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                        ]),
                        body: _withCtx(({data}) => [
                          _createTextVNode(_toDisplayString(data.article?.manufacturerPartNumber), 1)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        field: "serialNumber",
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.productHome.serialNumberExtern')
                      }, {
                        filter: _withCtx(({filterModel,filterCallback}) => [
                          _createVNode(_component_InputText, {
                            type: "text",
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                            onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                            class: "p-column-filter text-sm",
                            placeholder: _ctx.$t('labels.productHome.serialNumberExtern')
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.shipment.warehouse')
                      }, {
                        body: _withCtx(({data}) => [
                          _createTextVNode(_toDisplayString(data.warehouse?.name), 1)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.shipment.warehouseLevel')
                      }, {
                        body: _withCtx(({data}) => [
                          _createTextVNode(_toDisplayString(data.storagePlace?.name), 1)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        field: "article.measurementVerified",
                        dataType: "boolean",
                        bodyClass: "text-center",
                        style: {"max-width":"7rem"},
                        header: _ctx.$t('labels.productHome.measurementVerified')
                      }, {
                        filter: _withCtx(({filterModel, filterCallback}) => [
                          _createVNode(_component_TriStateCheckbox, {
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                            onChange: $event => (filterCallback())
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                        ]),
                        body: _withCtx(({data}) => [
                          _createElementVNode("i", {
                            class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.article?.measurementVerified, 'text-red-600 pi-times-circle':!data.article?.measurementVerified}])
                          }, null, 2)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      (_ctx.showDimensionColumn)
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 0,
                            style: {"min-width":"6rem"}
                          }, {
                            body: _withCtx(({data}) => [
                              _createElementVNode("div", _hoisted_4, _toDisplayString(data.article?.articleGrossWeight ? ('G: ' +
                        (data.article.articleGrossWeight).toFixed(1) + ' kg') :
                        ''), 1),
                              _createElementVNode("div", _hoisted_5, _toDisplayString(data.article?.articleHeight ? ('H: ' +
                        (data.article.articleHeight * 100).toFixed(1) + ' cm') :
                        ''), 1),
                              _createElementVNode("div", _hoisted_6, _toDisplayString(data.article?.articleWidth ? ('B: ' +
                        (data.article.articleWidth * 100).toFixed(1) + ' cm') :
                        ''), 1),
                              _createElementVNode("div", _hoisted_7, _toDisplayString(data.article?.articleLength ? ('L: ' +
                        (data.article.articleLength * 100).toFixed(1) + ' cm') :
                        ''), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Column, { style: {"min-width":"7rem"} }, _createSlots({ _: 2 }, [
                        (_ctx.editPermissionAvailable)
                          ? {
                              name: "body",
                              fn: _withCtx(({data}) => [
                                _createVNode(_component_router_link, {
                                  to: {
                                        name: 'ProductsWithIncompleteDimensionsDetails',
                                        params: {id: data.articleNumber},
                                        state: {originalData: JSON.stringify({singleStoragePlace: _ctx.singleStoragePlace, path: _ctx.selectedNode?.path})}
                                    }
                                }, {
                                  default: _withCtx(() => [
                                    _withDirectives(_createElementVNode("i", _hoisted_8, null, 512), [
                                      [
                                        _directive_tooltip,
                                        _ctx.$t('labels.articleDetails'),
                                        void 0,
                                        { left: true }
                                      ]
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["to"])
                              ]),
                              key: "0"
                            }
                          : undefined
                      ]), 1024)
                    ]),
                    _: 1
                  }, 8, ["value", "filters"]))
                : (_openBlock(), _createBlock(_component_DataTable, {
                    key: 1,
                    class: "p-datatable-sm text-sm",
                    stripedRows: "",
                    scrollable: "",
                    scrollHeight: "calc(100vh - 23rem)",
                    paginator: "",
                    lazy: "",
                    rows: 10,
                    ref: "dt",
                    dataKey: "id",
                    totalRecords: _ctx.totalRecords,
                    value: _ctx.products,
                    responsiveLayout: "scroll",
                    "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                    "rows-per-page-options": [10,20,50],
                    "current-page-report-template": "{first} to {last} of {totalRecords}",
                    filters: _ctx.filters,
                    "onUpdate:filters": _cache[4] || (_cache[4] = $event => ((_ctx.filters) = $event)),
                    filterDisplay: "row",
                    onRowClick: _cache[5] || (_cache[5] = $event => (_ctx.onRowClick($event))),
                    onPage: _ctx.onPage,
                    onFilter: _ctx.onFilter,
                    onSort: _ctx.onSort
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        field: "article.articleNumber",
                        sortable: true,
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.articleNumber')
                      }, {
                        filter: _withCtx(({filterModel,filterCallback}) => [
                          _createVNode(_component_InputText, {
                            type: "text",
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                            onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                            class: "p-column-filter text-sm",
                            placeholder: _ctx.$t('labels.articleNumber')
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                        ]),
                        body: _withCtx(({data}) => [
                          _createTextVNode(_toDisplayString(data.article?.articleNumber), 1)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        field: "article.name",
                        sortable: true,
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.name')
                      }, {
                        filter: _withCtx(({filterModel,filterCallback}) => [
                          _createVNode(_component_InputText, {
                            type: "text",
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                            onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                            class: "p-column-filter text-sm",
                            placeholder: _ctx.$t('labels.name')
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                        ]),
                        body: _withCtx(({data}) => [
                          _createTextVNode(_toDisplayString(data.article?.name), 1)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        field: "article.ean",
                        sortable: true,
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.ean')
                      }, {
                        filter: _withCtx(({filterModel,filterCallback}) => [
                          _createVNode(_component_InputText, {
                            type: "text",
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                            onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                            class: "p-column-filter text-sm",
                            placeholder: _ctx.$t('labels.ean')
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                        ]),
                        body: _withCtx(({data}) => [
                          _createTextVNode(_toDisplayString(data.article?.ean), 1)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        field: "article.manufacturerPartNumber",
                        sortable: true,
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.mpn')
                      }, {
                        filter: _withCtx(({filterModel,filterCallback}) => [
                          _createVNode(_component_InputText, {
                            type: "text",
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                            onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                            class: "p-column-filter text-sm",
                            placeholder: _ctx.$t('labels.mpn')
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                        ]),
                        body: _withCtx(({data}) => [
                          _createTextVNode(_toDisplayString(data.article?.manufacturerPartNumber), 1)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        field: "serialNumber",
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.productHome.serialNumberExtern')
                      }, {
                        filter: _withCtx(({filterModel,filterCallback}) => [
                          _createVNode(_component_InputText, {
                            type: "text",
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                            onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                            class: "p-column-filter text-sm",
                            placeholder: _ctx.$t('labels.productHome.serialNumberExtern')
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.shipment.warehouse')
                      }, {
                        body: _withCtx(({data}) => [
                          _createTextVNode(_toDisplayString(data.warehouse?.name), 1)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.shipment.warehouseLevel')
                      }, {
                        body: _withCtx(({data}) => [
                          _createTextVNode(_toDisplayString(data.storagePlace?.name), 1)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        dataType: "boolean",
                        bodyClass: "text-center",
                        style: {"max-width":"7rem"},
                        header: _ctx.$t('labels.productHome.measurementVerified')
                      }, {
                        body: _withCtx(({data}) => [
                          _createElementVNode("i", {
                            class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.article?.measurementVerified, 'text-red-600 pi-times-circle':!data.article?.measurementVerified}])
                          }, null, 2)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      (_ctx.showDimensionColumn)
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 0,
                            style: {"min-width":"6rem"}
                          }, {
                            body: _withCtx(({data}) => [
                              _createElementVNode("div", _hoisted_9, _toDisplayString(data.article?.articleGrossWeight ? ('G: ' +
                        (data.article.articleGrossWeight).toFixed(1) + ' kg') :
                        ''), 1),
                              _createElementVNode("div", _hoisted_10, _toDisplayString(data.article?.articleHeight ? ('H: ' +
                        (data.article.articleHeight * 100).toFixed(1) + ' cm') :
                        ''), 1),
                              _createElementVNode("div", _hoisted_11, _toDisplayString(data.article?.articleWidth ? ('B: ' +
                        (data.article.articleWidth * 100).toFixed(1) + ' cm') :
                        ''), 1),
                              _createElementVNode("div", _hoisted_12, _toDisplayString(data.article?.articleLength ? ('L: ' +
                        (data.article.articleLength * 100).toFixed(1) + ' cm') :
                        ''), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Column, { style: {"min-width":"7rem"} }, _createSlots({ _: 2 }, [
                        (_ctx.editPermissionAvailable)
                          ? {
                              name: "body",
                              fn: _withCtx(({data}) => [
                                _createVNode(_component_router_link, {
                                  to: {
                                        name: 'ProductsWithIncompleteDimensionsDetails',
                                        params: {id: data.articleNumber},
                                        state: {originalData: JSON.stringify({singleStoragePlace: _ctx.singleStoragePlace, path: _ctx.selectedNode?.path})}
                                    }
                                }, {
                                  default: _withCtx(() => [
                                    _withDirectives(_createElementVNode("i", _hoisted_13, null, 512), [
                                      [
                                        _directive_tooltip,
                                        _ctx.$t('labels.articleDetails'),
                                        void 0,
                                        { left: true }
                                      ]
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["to"])
                              ]),
                              key: "0"
                            }
                          : undefined
                      ]), 1024)
                    ]),
                    _: 1
                  }, 8, ["totalRecords", "value", "filters", "onPage", "onFilter", "onSort"]))
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["header"])
  ], 64))
}