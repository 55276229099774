import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createBlock as _createBlock, renderList as _renderList, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex flex-column" }
const _hoisted_2 = {
  key: 0,
  class: "text-orange-600"
}
const _hoisted_3 = {
  key: 1,
  class: "text-lg"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "text-green-500" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 0,
  class: "text-sm"
}
const _hoisted_9 = { class: "flex justify-contente-end align-items-baseline" }
const _hoisted_10 = { class: "grid grid-nogutter" }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { href: "/products" }
const _hoisted_14 = { class: "text-sm" }
const _hoisted_15 = { class: "w-full m-2 flex justify-content-between" }
const _hoisted_16 = { class: "mb-3" }
const _hoisted_17 = { class: "card-body" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "w-full mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_p_button = _resolveComponent("p-button")
  const _component_DataView = _resolveComponent("DataView")
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")
  const _component_p_multiselect = _resolveComponent("p-multiselect")
  const _component_ProductHome = _resolveComponent("ProductHome")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_GroupTab = _resolveComponent("GroupTab")
  const _component_WeclappDescription = _resolveComponent("WeclappDescription")
  const _component_ShopDescriptionContainer = _resolveComponent("ShopDescriptionContainer")
  const _component_AccessoriesContainer = _resolveComponent("AccessoriesContainer")
  const _component_CategoriesContainer = _resolveComponent("CategoriesContainer")
  const _component_BadgesContainer = _resolveComponent("BadgesContainer")
  const _component_ShopContentContainer = _resolveComponent("ShopContentContainer")
  const _component_Prices = _resolveComponent("Prices")
  const _component_Promotions = _resolveComponent("Promotions")
  const _component_Compliance = _resolveComponent("Compliance")
  const _component_GoogleAdwordsContainer = _resolveComponent("GoogleAdwordsContainer")
  const _component_BStock = _resolveComponent("BStock")
  const _component_TabView = _resolveComponent("TabView")
  const _component_Panel = _resolveComponent("Panel")
  const _component_p_dialog = _resolveComponent("p-dialog")
  const _directive_tooltip = _resolveDirective("tooltip")
  const _directive_badge = _resolveDirective("badge")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, null, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            (_ctx.collidingUsers.length > 0)
              ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_2, [
                  _createVNode(_component_font_awesome_icon, {
                    style: {"margin-right":"10px"},
                    icon: ['fas', 'exclamation-triangle']
                  })
                ])), [
                  [
                    _directive_tooltip,
                    _ctx.$t('messages.collidingUsersProductEditPlain', {item:
                            _ctx.collidingUsers.join(', ')}),
                    void 0,
                    { top: true }
                  ]
                ])
              : _createCommentVNode("", true),
            (_ctx.productDetails?.weclapp)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                  _createElementVNode("a", {
                    href: _ctx.productDetails.weclapp.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + _ctx.productDetails.weclapp.id,
                    target: "_blank"
                  }, _toDisplayString(_ctx.productNumber), 9, _hoisted_4),
                  _cache[11] || (_cache[11] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                  _createTextVNode(_toDisplayString(_ctx.productDetails?.weclapp?.name), 1),
                  _cache[12] || (_cache[12] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                  _createTextVNode(" UVP " + _toDisplayString(_ctx.listPrice) + " ", 1),
                  _cache[13] || (_cache[13] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('labels.vkPrice')) + " " + _toDisplayString(_ctx.formatter.format(_ctx.vkPrice)) + " ", 1),
                  (_ctx.ekPrice !== null)
                    ? _renderSlot(_ctx.$slots, "default", { key: 0 }, () => [
                        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('labels.ekPrice')) + " " + _toDisplayString(_ctx.formatter.format(_ctx.ekPrice)), 1)
                      ], true)
                    : _createCommentVNode("", true),
                  (_ctx.calcEkPrice !== null)
                    ? _renderSlot(_ctx.$slots, "default", { key: 1 }, () => [
                        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                        _cache[8] || (_cache[8] = _createTextVNode(" Kalkulatorischer EK ")),
                        _createElementVNode("span", {
                          class: _normalizeClass({'text-green-600': _ctx.calcEkPrice <= _ctx.ekPrice, 'text-red-600': _ctx.calcEkPrice > _ctx.ekPrice})
                        }, _toDisplayString(_ctx.formatter.format(_ctx.calcEkPrice)), 3)
                      ], true)
                    : _createCommentVNode("", true),
                  (_ctx.ekPrice !== null || _ctx.calcEkPrice !== null)
                    ? _renderSlot(_ctx.$slots, "default", { key: 2 }, () => [
                        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                        _cache[10] || (_cache[10] = _createTextVNode(" Marge ")),
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.formatter.format(_ctx.vkPrice - (_ctx.calcEkPrice ||
                            _ctx.ekPrice))) + " [" + _toDisplayString(Math.round((100 - (_ctx.calcEkPrice ||
                            _ctx.ekPrice) / _ctx.vkPrice * 100) * 100) / 100) + " %]", 1)
                      ], true)
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.productDetails?.shopware?.name && _ctx.productDetails?.shopware?.url)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _cache[14] || (_cache[14] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                  _createElementVNode("a", {
                    target: "_blank",
                    href: _ctx.resolvedShopwareUrl(_ctx.productDetails?.shopware, _ctx.resolvedPlatform?.threeLetterId)
                  }, [
                    _createElementVNode("i", {
                      class: _normalizeClass(["pi pi-shopping-cart", {'sales-channel-teltec-color':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && _ctx.productDetails?.shopware?.active, 'sales-channel-videodata-color':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && _ctx.productDetails?.shopware?.active}]),
                      style: {"font-size":"1.2rem"}
                    }, null, 2)
                  ], 8, _hoisted_6)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.productDetails?.secondShopware?.name && _ctx.productDetails?.secondShopware?.url)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                  _cache[15] || (_cache[15] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                  _createElementVNode("a", {
                    target: "_blank",
                    href: _ctx.resolvedShopwareUrl(_ctx.productDetails?.secondShopware, _ctx.secondPlatform?.threeLetterId)
                  }, [
                    _createElementVNode("i", {
                      class: _normalizeClass(["pi pi-shopping-cart", {'sales-channel-videodata-color':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && _ctx.productDetails?.secondShopware?.active, 'sales-channel-teltec-color':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && _ctx.productDetails?.secondShopware?.active}]),
                      style: {"font-size":"1.2rem"}
                    }, null, 2)
                  ], 8, _hoisted_7)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          (_ctx.productDetails?.weclapp?.extraInfo)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.stockQuantityWithoutOrder')) + ": ", 1),
                _createElementVNode("b", null, _toDisplayString(_ctx.productDetails?.weclapp?.extraInfo.stockQuantityWithoutOrder), 1),
                _cache[16] || (_cache[16] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('labels.stockTotal')) + ": ", 1),
                _createElementVNode("b", null, _toDisplayString(_ctx.productDetails?.weclapp?.extraInfo.stockQuantity), 1),
                _cache[17] || (_cache[17] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('labels.stockValuationPrice')) + ": ", 1),
                _createElementVNode("b", null, _toDisplayString(_ctx.formatter.format(_ctx.productDetails.weclapp.extraInfo.stockValuationPrice)), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      icons: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          (_ctx.showOverlayButton)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.previousHistoryItem)
                  ? _withDirectives((_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: "pi pi-chevron-left text-xs mr-3",
                      onClick: _cache[1] || (_cache[1] = $event => (_ctx.onHistoryItemSelected(_ctx.previousHistoryItem.articleNumber))),
                      style: {"cursor":"pointer"}
                    }, null, 512)), [
                      [
                        _directive_tooltip,
                        _ctx.$t('labels.previousHistoryItem'),
                        void 0,
                        { top: true }
                      ]
                    ])
                  : _createCommentVNode("", true),
                (_ctx.nextHistoryItem)
                  ? _withDirectives((_openBlock(), _createElementBlock("i", {
                      key: 1,
                      class: "pi pi-chevron-right text-xs mr-3",
                      onClick: _cache[2] || (_cache[2] = $event => (_ctx.onHistoryItemSelected(_ctx.nextHistoryItem.articleNumber))),
                      style: {"cursor":"pointer"}
                    }, null, 512)), [
                      [
                        _directive_tooltip,
                        _ctx.$t('labels.nextHistoryItem'),
                        void 0,
                        { top: true }
                      ]
                    ])
                  : _createCommentVNode("", true),
                (_ctx.historyProducts.length > 0)
                  ? _withDirectives((_openBlock(), _createBlock(_component_p_button, {
                      key: 2,
                      icon: "pi pi-chevron-down",
                      class: "mr-3 p-button-secondary p-button-rounded overlay-panel-toggle",
                      onClick: _ctx.toggleOverlayPanel
                    }, null, 8, ["onClick"])), [
                      [
                        _directive_tooltip,
                        _ctx.$t('labels.allHistoryItems'),
                        void 0,
                        { top: true }
                      ]
                    ])
                  : _createCommentVNode("", true),
                (_ctx.historyProducts.length > 0)
                  ? (_openBlock(), _createBlock(_component_OverlayPanel, {
                      key: 3,
                      ref: "overlayPanel"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DataView, {
                          value: _ctx.historyProducts,
                          class: "text-sm",
                          layout: "list"
                        }, {
                          list: _withCtx((slotProps) => [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("div", _hoisted_11, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.items, (item, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: index,
                                    class: "col",
                                    onClick: $event => (_ctx.onHistoryItemSelected(item.articleNumber)),
                                    style: {"cursor":"pointer"}
                                  }, _toDisplayString(item.name) + " (" + _toDisplayString(item.articleNumber) + ") ", 9, _hoisted_12))
                                }), 128))
                              ])
                            ])
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    }, 512))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          _createElementVNode("a", _hoisted_13, _toDisplayString(_ctx.$t("labels.article")), 1),
          _createTextVNode("  /  " + _toDisplayString(_ctx.productNumber) + " ", 1),
          _createElementVNode("i", {
            onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.toggleBookmark && _ctx.toggleBookmark(...args))),
            class: "fas fa-star ml-2 mr-2",
            style: _normalizeStyle(_ctx.isBookmarked ? 'color: rgb(255, 193, 7)' : '')
          }, null, 4),
          _createVNode(_component_p_multiselect, {
            modelValue: _ctx.selectedTabs,
            class: "text-xs",
            ref: "tabSelectorComponent",
            options: _ctx.tabOptions,
            optionLabel: "label",
            optionValue: "value",
            optionDisabled: "optionDisabled",
            onChange: _ctx.onToggleTabVisibility,
            placeholder: "Select Tabs",
            style: {"max-width":"12em"}
          }, {
            option: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_14, _toDisplayString(slotProps.option.label), 1)
            ]),
            footer: _withCtx(() => [
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_p_button, {
                  severity: "danger",
                  class: "mr-3",
                  size: "small",
                  onClick: _ctx.onCancelTabsSelection
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_p_button, {
                  severity: "success",
                  size: "small",
                  onClick: _ctx.onConfirmTabsSelection
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "options", "onChange"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_TabView, {
          class: "tabview-custom text-sm",
          activeIndex: _ctx.activeTabIndex,
          "onUpdate:activeIndex": _cache[4] || (_cache[4] = $event => ((_ctx.activeTabIndex) = $event)),
          scrollable: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TabPanel, null, {
              header: _withCtx(() => _cache[18] || (_cache[18] = [
                _createElementVNode("i", { class: "pi pi-align-justify" }, null, -1),
                _createElementVNode("span", null, "Home", -1)
              ])),
              default: _withCtx(() => [
                _createVNode(_component_ProductHome, {
                  "product-details": _ctx.productDetails,
                  "custom-attributes-definition": _ctx.customAttributesDefinition,
                  onReloadProduct: _ctx.loadProductDetails
                }, null, 8, ["product-details", "custom-attributes-definition", "onReloadProduct"])
              ]),
              _: 1
            }),
            _createVNode(_component_TabPanel, {
              disabled: ['ttl', 'vdl', 'vct'].indexOf(_ctx.resolvedPlatform?.threeLetterId) === -1
            }, {
              header: _withCtx(() => _cache[19] || (_cache[19] = [
                _createElementVNode("i", { class: "pi pi-link" }, null, -1),
                _createElementVNode("span", null, "Group ID", -1)
              ])),
              default: _withCtx(() => [
                _createVNode(_component_GroupTab, { "product-details": _ctx.productDetails }, null, 8, ["product-details"])
              ]),
              _: 1
            }, 8, ["disabled"]),
            _createVNode(_component_TabPanel, null, {
              header: _withCtx(() => [
                _cache[20] || (_cache[20] = _createElementVNode("i", { class: "pi pi-align-justify" }, null, -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.description")) + " [Weclapp]", 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_WeclappDescription, {
                  "weclapp-details": _ctx.productDetails?.weclapp,
                  onReloadProduct: _ctx.loadProductDetails,
                  onAutoSaveDescription: _ctx.onAutoSaveDescription
                }, null, 8, ["weclapp-details", "onReloadProduct", "onAutoSaveDescription"])
              ]),
              _: 1
            }),
            _createVNode(_component_TabPanel, {
              disabled: !_ctx.editDescriptionPermissionAvailable || (!_ctx.productDetails?.shopware && !_ctx.productDetails?.secondShopware)
            }, {
              header: _withCtx(() => [
                _cache[21] || (_cache[21] = _createElementVNode("i", { class: "pi pi-align-justify" }, null, -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.description")), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_ShopDescriptionContainer, {
                  "product-details": _ctx.productDetails,
                  onReloadProduct: _ctx.loadProductDetails,
                  onAutoSaveDescription: _ctx.onAutoSaveDescription
                }, null, 8, ["product-details", "onReloadProduct", "onAutoSaveDescription"])
              ]),
              _: 1
            }, 8, ["disabled"]),
            (_ctx.visibleTabs.indexOf('Accessories') !== -1)
              ? (_openBlock(), _createBlock(_component_TabPanel, {
                  key: 0,
                  disabled: !_ctx.productDetails?.shopware && !_ctx.productDetails?.secondShopware
                }, {
                  header: _withCtx(() => [
                    _cache[22] || (_cache[22] = _createElementVNode("i", { class: "pi pi-sitemap" }, null, -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.articleAccessories")), 1)
                  ]),
                  default: _withCtx(() => [
                    (_ctx.productDetails)
                      ? (_openBlock(), _createBlock(_component_AccessoriesContainer, {
                          key: 0,
                          "product-details": _ctx.productDetails,
                          onReloadProduct: _ctx.loadProductDetails
                        }, null, 8, ["product-details", "onReloadProduct"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["disabled"]))
              : _createCommentVNode("", true),
            (_ctx.visibleTabs.indexOf('Categories') !== -1)
              ? (_openBlock(), _createBlock(_component_TabPanel, {
                  key: 1,
                  disabled: !_ctx.productDetails?.shopware && !_ctx.productDetails?.secondShopware
                }, {
                  header: _withCtx(() => [
                    _cache[23] || (_cache[23] = _createElementVNode("i", { class: "pi pi-sitemap" }, null, -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.categories")), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_CategoriesContainer, {
                      "product-details": _ctx.productDetails,
                      onReloadProduct: _ctx.loadProductDetails
                    }, null, 8, ["product-details", "onReloadProduct"])
                  ]),
                  _: 1
                }, 8, ["disabled"]))
              : _createCommentVNode("", true),
            (_ctx.visibleTabs.indexOf('Badges') !== -1)
              ? (_openBlock(), _createBlock(_component_TabPanel, {
                  key: 2,
                  disabled: !_ctx.productDetails?.shopware && !_ctx.productDetails?.secondShopware
                }, {
                  header: _withCtx(() => [
                    _cache[24] || (_cache[24] = _createElementVNode("i", { class: "pi pi-tags" }, null, -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.badges")), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_BadgesContainer, {
                      "product-details": _ctx.productDetails,
                      onReloadProduct: _ctx.loadProductDetails
                    }, null, 8, ["product-details", "onReloadProduct"])
                  ]),
                  _: 1
                }, 8, ["disabled"]))
              : _createCommentVNode("", true),
            _createVNode(_component_TabPanel, {
              disabled: !_ctx.productDetails?.shopware && !_ctx.productDetails?.secondShopware
            }, {
              header: _withCtx(() => [
                _cache[25] || (_cache[25] = _createElementVNode("i", { class: "pi pi-images" }, null, -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.productHome.webshopMedia")), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_ShopContentContainer, {
                  "product-details": _ctx.productDetails,
                  onReloadProduct: _ctx.loadProductDetails
                }, null, 8, ["product-details", "onReloadProduct"])
              ]),
              _: 1
            }, 8, ["disabled"]),
            _createVNode(_component_TabPanel, null, {
              header: _withCtx(() => [
                _cache[26] || (_cache[26] = _createElementVNode("i", { class: "pi pi-euro text-xs" }, null, -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.prices")), 1),
                _withDirectives(_createElementVNode("span", _hoisted_16, null, 512), [
                  [
                    _directive_badge,
                    'New',
                    void 0,
                    { warning: true }
                  ]
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_Prices, {
                  "product-details": _ctx.productDetails,
                  "exchange-rates": _ctx.exchangeRates,
                  onReloadProduct: _ctx.loadProductDetails
                }, null, 8, ["product-details", "exchange-rates", "onReloadProduct"])
              ]),
              _: 1
            }),
            (_ctx.visibleTabs.indexOf('Promotions') !== -1)
              ? (_openBlock(), _createBlock(_component_TabPanel, { key: 3 }, {
                  header: _withCtx(() => [
                    _cache[27] || (_cache[27] = _createElementVNode("i", { class: "pi pi-percentage text-xs" }, null, -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.promotions")), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_Promotions, {
                      "product-details": _ctx.productDetails,
                      "custom-attributes-definition": _ctx.customAttributesDefinition,
                      onReloadProduct: _ctx.loadProductDetails
                    }, null, 8, ["product-details", "custom-attributes-definition", "onReloadProduct"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.visibleTabs.indexOf('Compliance') !== -1)
              ? (_openBlock(), _createBlock(_component_TabPanel, { key: 4 }, {
                  header: _withCtx(() => [
                    _cache[28] || (_cache[28] = _createElementVNode("i", { class: "pi pi-check-square" }, null, -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.compliance")), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_Compliance, {
                      "product-details": _ctx.productDetails,
                      "custom-attributes-definition": _ctx.customAttributesDefinition,
                      onReloadProduct: _ctx.loadProductDetails
                    }, null, 8, ["product-details", "custom-attributes-definition", "onReloadProduct"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.visibleTabs.indexOf('GoogleAds') !== -1)
              ? (_openBlock(), _createBlock(_component_TabPanel, {
                  key: 5,
                  disabled: !_ctx.productDetails?.shopware && !_ctx.productDetails?.secondShopware
                }, {
                  header: _withCtx(() => [
                    _cache[29] || (_cache[29] = _createElementVNode("i", { class: "pi pi-google" }, null, -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.productGoogleAds.label")), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_GoogleAdwordsContainer, {
                      "product-details": _ctx.productDetails,
                      onReloadProduct: _ctx.loadProductDetails
                    }, null, 8, ["product-details", "onReloadProduct"])
                  ]),
                  _: 1
                }, 8, ["disabled"]))
              : _createCommentVNode("", true),
            (_ctx.visibleTabs.indexOf('BStock') !== -1)
              ? (_openBlock(), _createBlock(_component_TabPanel, {
                  key: 6,
                  disabled: !_ctx.productDetails?.shopware || _ctx.resolvedPlatform?.threeLetterId !== 'ttl'
                }, {
                  header: _withCtx(() => _cache[30] || (_cache[30] = [
                    _createElementVNode("i", {
                      class: "fa fa-box-open",
                      style: {"font-size":"1rem"}
                    }, null, -1),
                    _createElementVNode("span", null, "B-Stock", -1)
                  ])),
                  default: _withCtx(() => [
                    _createVNode(_component_BStock, { "product-details": _ctx.productDetails }, null, 8, ["product-details"])
                  ]),
                  _: 1
                }, 8, ["disabled"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["activeIndex"])
      ]),
      _: 3
    }),
    _createVNode(_component_p_dialog, {
      header: _ctx.$t('messages.pleaseConfirm'),
      visible: _ctx.showCollidingUsersDialog,
      "onUpdate:visible": _cache[5] || (_cache[5] = $event => ((_ctx.showCollidingUsersDialog) = $event)),
      breakpoints: {'960px': '75vw'},
      style: {width: '50vw'},
      modal: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", null, [
            _createElementVNode("h6", {
              innerHTML: _ctx.$t('messages.collidingUsersProductEdit', {item:
                    _ctx.collidingUsers.join(', ')})
            }, null, 8, _hoisted_18)
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_p_button, {
              severity: "danger",
              size: "small",
              onClick: _ctx.cancelHandler
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_p_button, {
              severity: "success",
              size: "small",
              onClick: _ctx.continueAnywayHandler
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("buttons.continueAnyway")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["header", "visible"])
  ], 64))
}