import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache) {
  const _component_Tag = _resolveComponent("Tag")

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.price?.reductionAdditions || []), (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: item }, [
        (_ctx.resolveReductionAdditionType(item) === _ctx.ReductionAdditionType.Regular || _ctx.resolveReductionAdditionType(item) === _ctx.ReductionAdditionType.Promotion)
          ? (_openBlock(), _createBlock(_component_Tag, {
              key: 0,
              style: _normalizeStyle({ background: item.type ===
                _ctx.ReductionAdditionValueType.AdditionPercent || item.type === _ctx.ReductionAdditionValueType.AdditionAbsolute ? 'brown' : 'gray' })
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatPrice(item.value, item.type ===
            _ctx.ReductionAdditionValueType.ReductionPercent || item.type ===
            _ctx.ReductionAdditionValueType.AdditionPercent)), 1)
              ]),
              _: 2
            }, 1032, ["style"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}