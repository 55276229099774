import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx, _cache) {
  const _component_Badges = _resolveComponent("Badges")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_TabView = _resolveComponent("TabView")

  return (_openBlock(), _createBlock(_component_TabView, { class: "tabview-custom text-sm" }, {
    default: _withCtx(() => [
      _createVNode(_component_TabPanel, {
        disabled: !_ctx.productDetails?.shopware
      }, {
        header: _withCtx(() => [
          _cache[0] || (_cache[0] = _createElementVNode("i", { class: "pi pi-tags mr-2" }, null, -1)),
          _createElementVNode("span", null, [
            _createTextVNode(_toDisplayString(_ctx.$t("labels.badges")), 1),
            (_ctx.productDetails?.shopware?.badges)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, " (" + _toDisplayString(_ctx.productDetails.shopware.badges.length) + ")", 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("span", {
            class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
          }, null, 2)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Badges, {
            "shopware-details": _ctx.productDetails?.shopware,
            onReloadProduct: _ctx.loadProductDetails
          }, null, 8, ["shopware-details", "onReloadProduct"])
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_TabPanel, {
        disabled: !_ctx.productDetails?.secondShopware
      }, {
        header: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("i", { class: "pi pi-tags mr-2" }, null, -1)),
          _createElementVNode("span", null, [
            _createTextVNode(_toDisplayString(_ctx.$t("labels.badges")), 1),
            (_ctx.productDetails?.secondShopware?.badges)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, " (" + _toDisplayString(_ctx.productDetails.secondShopware.badges.length) + ")", 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("span", {
            class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
          }, null, 2)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Badges, {
            "shopware-details": _ctx.productDetails?.secondShopware,
            onReloadProduct: _ctx.loadProductDetails,
            platform: _ctx.secondPlatform
          }, null, 8, ["shopware-details", "onReloadProduct", "platform"])
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }))
}