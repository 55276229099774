import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_DescriptionTab = _resolveComponent("DescriptionTab")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_CommentsTab = _resolveComponent("CommentsTab")
  const _component_ImagesTab = _resolveComponent("ImagesTab")
  const _component_ArchivedEmailsTab = _resolveComponent("ArchivedEmailsTab")
  const _component_ServiceTab = _resolveComponent("ServiceTab")
  const _component_ReferenceDetailsTab = _resolveComponent("ReferenceDetailsTab")
  const _component_RepairDetailsTab = _resolveComponent("RepairDetailsTab")
  const _component_SendReturnLabelTab = _resolveComponent("SendReturnLabelTab")
  const _component_TabView = _resolveComponent("TabView")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, null, {
      header: _withCtx(() => [
        _createElementVNode("h4", null, [
          _createTextVNode(_toDisplayString(_ctx.$t('labels.ticketNumber')) + " ", 1),
          _createElementVNode("a", {
            href: _ctx.ticketDetails?.url + 'webapp/view/ticketing/ticketDetail.page?entityId=' + _ctx.ticketDetails?.id,
            target: "_blank"
          }, _toDisplayString(_ctx.ticketDetails?.ticketNumber), 9, _hoisted_1),
          (_ctx.ticketDetails?.customer)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                _createElementVNode("a", {
                  href: _ctx.ticketDetails.url + 'webapp/view/party/PartyDetail.page?entityId=' + _ctx.ticketDetails.partyId,
                  target: "_blank"
                }, _toDisplayString(_ctx.ticketDetails.customer.company ??
                    _ctx.ticketDetails.customer.firstName + ' ' +
                    _ctx.ticketDetails.customer.lastName), 9, _hoisted_2)
              ], 64))
            : _createCommentVNode("", true),
          _createElementVNode("span", {
            class: "ticket-status-badge ml-3",
            style: _normalizeStyle(_ctx.getStatusCellStyle(_ctx.ticketDetails?.ticketStatus))
          }, _toDisplayString(_ctx.ticketDetails?.ticketStatus), 5)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_TabView, {
          class: "tabview-custom text-sm",
          ref: "tabview4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TabPanel, null, {
              header: _withCtx(() => [
                (_ctx.ticketDetails)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      style: _normalizeStyle(_ctx.getPriorityCellStyle(_ctx.ticketDetails.ticketPriority)),
                      class: "pi pi-circle-fill mr-2"
                    }, null, 4))
                  : _createCommentVNode("", true),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('labels.description')), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_DescriptionTab, {
                  "ticket-details": _ctx.ticketDetails,
                  onReloadTicketDetails: _ctx.loadTicketDetails
                }, null, 8, ["ticket-details", "onReloadTicketDetails"])
              ]),
              _: 1
            }),
            _createVNode(_component_TabPanel, null, {
              header: _withCtx(() => [
                _cache[2] || (_cache[2] = _createElementVNode("i", { class: "pi pi-comments mr-2" }, null, -1)),
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t("labels.ticket.comments")) + " ", 1),
                  (_ctx.ticketDetails?.comments)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, "(" + _toDisplayString(_ctx.ticketDetails.comments.length) + ")", 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_CommentsTab, { "ticket-details": _ctx.ticketDetails }, null, 8, ["ticket-details"])
              ]),
              _: 1
            }),
            _createVNode(_component_TabPanel, null, {
              header: _withCtx(() => [
                _cache[3] || (_cache[3] = _createElementVNode("i", { class: "pi pi-images mr-2" }, null, -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.images")), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_ImagesTab, { "ticket-details": _ctx.ticketDetails }, null, 8, ["ticket-details"])
              ]),
              _: 1
            }),
            _createVNode(_component_TabPanel, null, {
              header: _withCtx(() => [
                _cache[4] || (_cache[4] = _createElementVNode("i", { class: "pi pi-envelope mr-2" }, null, -1)),
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t("labels.ticket.emailArchive")) + " ", 1),
                  (_ctx.ticketDetails?.archivedEmails)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, "(" + _toDisplayString(_ctx.ticketDetails.archivedEmails.length) + ")", 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_ArchivedEmailsTab, {
                  "ticket-details": _ctx.ticketDetails,
                  onReloadTicketDetails: _ctx.loadTicketDetails
                }, null, 8, ["ticket-details", "onReloadTicketDetails"])
              ]),
              _: 1
            }),
            (_ctx.showServiceTab)
              ? (_openBlock(), _createBlock(_component_TabPanel, { key: 0 }, {
                  header: _withCtx(() => [
                    _cache[5] || (_cache[5] = _createElementVNode("i", { class: "pi pi-wrench mr-2" }, null, -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.ticket.serviceDetails")), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_ServiceTab, { "ticket-details": _ctx.ticketDetails }, null, 8, ["ticket-details"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_TabPanel, null, {
              header: _withCtx(() => [
                _cache[6] || (_cache[6] = _createElementVNode("i", { class: "pi pi-sitemap mr-2" }, null, -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.ticket.referenceDetails")), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_ReferenceDetailsTab, {
                  "ticket-details": _ctx.ticketDetails,
                  onReloadTicketDetails: _ctx.loadTicketDetails
                }, null, 8, ["ticket-details", "onReloadTicketDetails"])
              ]),
              _: 1
            }),
            _createVNode(_component_TabPanel, null, {
              header: _withCtx(() => [
                _cache[7] || (_cache[7] = _createElementVNode("i", { class: "pi pi-wrench mr-2" }, null, -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.ticket.repairDetails")), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_RepairDetailsTab, {
                  "ticket-details": _ctx.ticketDetails,
                  onReloadTicketDetails: _ctx.loadTicketDetails
                }, null, 8, ["ticket-details", "onReloadTicketDetails"])
              ]),
              _: 1
            }),
            _createVNode(_component_TabPanel, {
              disabled: !_ctx.ticketDetails?.partyId || (_ctx.ticketDetails?.customAttributes && _ctx.ticketDetails.customAttributes.ticket_inbound_tracking !== null && _ctx.ticketDetails.customAttributes.ticket_inbound_tracking.trim() !== '')
            }, {
              header: _withCtx(() => _cache[8] || (_cache[8] = [
                _createElementVNode("i", { class: "fa-brands fa-ups mr-2 font-bold" }, null, -1),
                _createElementVNode("span", null, "Returns Label versenden", -1)
              ])),
              default: _withCtx(() => [
                _createVNode(_component_SendReturnLabelTab, {
                  "ticket-details": _ctx.ticketDetails,
                  onReloadTicketDetails: _ctx.loadTicketDetails
                }, null, 8, ["ticket-details", "onReloadTicketDetails"])
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    })
  ], 64))
}