import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_2 = {
  key: 1,
  class: "flex"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _component_AddUserPermissionDialog = _resolveComponent("AddUserPermissionDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.users')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          paginator: "",
          rows: 20,
          scrollable: "",
          scrollHeight: "calc(100vh - 23rem)",
          ref: "dt",
          dataKey: "username",
          totalRecords: _ctx.totalRecords,
          value: _ctx.users,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50],
          "current-page-report-template": "{first} to {last} of {totalRecords}",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[1] || (_cache[1] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          onStateRestore: _ctx.onStateRestore,
          stateStorage: "local",
          stateKey: "user-list-state-session"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "displayName",
              sortable: true,
              style: {"max-width":"fit-content"},
              header: _ctx.$t('labels.user.displayName')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: _ctx.$t('labels.user.displayName')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "username",
              sortable: true,
              style: {"max-width":"fit-content"},
              header: _ctx.$t('labels.email')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: _ctx.$t('labels.email')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "roles",
              style: {"max-width":"fit-content"},
              header: _ctx.$t('labels.roles')
            }, {
              body: _withCtx(({data}) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.roles.sort(), (element) => {
                  return (_openBlock(), _createElementBlock("div", null, [
                    (element === 'master-admin')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(element), 1))
                      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createTextVNode(_toDisplayString(element) + " ", 1),
                          (_ctx.editPermissionAvailable)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                style: {"cursor":"pointer"},
                                onClick: $event => (_ctx.onRemoveRole(element, data.username))
                              }, _cache[2] || (_cache[2] = [
                                _createElementVNode("i", {
                                  class: "pi pi-times-circle ml-1 text-red-600",
                                  style: {"margin-top":"2px"}
                                }, null, -1)
                              ]), 8, _hoisted_3))
                            : _createCommentVNode("", true)
                        ]))
                  ]))
                }), 256))
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "createdAt",
              sortable: true,
              style: {"max-width":"6rem"},
              header: _ctx.$t('labels.memberSince')
            }, {
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.createdAtFormatted), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "lastActivity",
              sortable: true,
              style: {"max-width":"6rem"},
              header: _ctx.$t('labels.user.lastActivity')
            }, {
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.lastActivityFormatted), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            (_ctx.editPermissionAvailable)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 0,
                  style: {"min-width":"6rem"},
                  bodyStyle: "text-align:center"
                }, {
                  body: _withCtx(({data}) => [
                    (data.roles.indexOf('master-admin') === -1)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          style: {"cursor":"pointer"},
                          onClick: $event => {_ctx.selectedUser = data;_ctx.displayAddPermissionDialog = true}
                        }, _cache[3] || (_cache[3] = [
                          _createElementVNode("i", {
                            class: "pi pi-plus-circle ml-1 text-green-600",
                            style: {"margin-top":"2px"}
                          }, null, -1)
                        ]), 8, _hoisted_4))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["totalRecords", "value", "filters", "onStateRestore"])
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_AddUserPermissionDialog, {
      "selected-user": _ctx.selectedUser,
      "display-dialog": _ctx.displayAddPermissionDialog,
      onCloseDialog: _ctx.closeDialogListener
    }, null, 8, ["selected-user", "display-dialog", "onCloseDialog"])
  ], 64))
}