import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = { class: "grid p-fluid mt-5 mb-5" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "grid" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "grid mt-3" }
const _hoisted_10 = { class: "col field" }
const _hoisted_11 = { class: "col-1 field" }
const _hoisted_12 = { class: "col-2 field" }
const _hoisted_13 = { class: "col field" }
const _hoisted_14 = { class: "col field" }
const _hoisted_15 = { class: "w-full flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_InputText = _resolveComponent("InputText")
  const _component_app_checkbox = _resolveComponent("app-checkbox")
  const _component_p_button = _resolveComponent("p-button")
  const _component_router_link = _resolveComponent("router-link")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.dimensionsAcquisition')
    }, {
      default: _withCtx(() => [
        (_ctx.productDetails?.weclapp)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_DataTable, {
                  class: "p-datatable-sm",
                  stripedRows: "",
                  lazy: false,
                  ref: "dt",
                  dataKey: "id",
                  responsiveLayout: "scroll",
                  value: [_ctx.productDetails]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      style: {"min-width":"9rem"},
                      header: _ctx.$t('labels.articleNumber')
                    }, {
                      body: _withCtx(({data}) => [
                        _createTextVNode(_toDisplayString(data.weclapp.articleNumber), 1)
                      ]),
                      _: 1
                    }, 8, ["header"]),
                    _createVNode(_component_Column, {
                      style: {"min-width":"9rem"},
                      header: _ctx.$t('labels.name')
                    }, {
                      body: _withCtx(({data}) => [
                        _createTextVNode(_toDisplayString(data.weclapp.name), 1)
                      ]),
                      _: 1
                    }, 8, ["header"]),
                    _createVNode(_component_Column, {
                      style: {"min-width":"9rem"},
                      header: _ctx.$t('labels.ean')
                    }, {
                      body: _withCtx(({data}) => [
                        _createTextVNode(_toDisplayString(data.weclapp.ean), 1)
                      ]),
                      _: 1
                    }, 8, ["header"])
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.grossWeight')), 1),
                      _createElementVNode("div", null, [
                        _createVNode(_component_InputNumber, {
                          locale: _ctx.locale,
                          class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.articleGrossWeight.$invalid}),
                          mode: "decimal",
                          min: 0,
                          showButtons: "",
                          suffix: ' kg',
                          maxFractionDigits: 4,
                          step: 0.1,
                          modelValue: _ctx.v$.articleGrossWeight.$model,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.articleGrossWeight.$model) = $event)),
                          onKeyup: _cache[2] || (_cache[2] = _withKeys($event => (_ctx.onPastedDimension('articleGrossWeight')), ["enter"])),
                          ref: "articleWeightInputField"
                        }, null, 8, ["locale", "class", "modelValue"]),
                        (_ctx.submitted)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.articleGrossWeight.$silentErrors, (error) => {
                              return (_openBlock(), _createElementBlock("small", {
                                style: {"display":"block"},
                                class: "p-error",
                                key: error
                              }, _toDisplayString(error.$message), 1))
                            }), 128))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.length')), 1),
                      _createElementVNode("div", null, [
                        _createVNode(_component_InputNumber, {
                          locale: _ctx.locale,
                          class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.articleLength.$invalid}),
                          mode: "decimal",
                          min: 0,
                          showButtons: "",
                          maxFractionDigits: 1,
                          suffix: ' cm',
                          step: 0.1,
                          modelValue: _ctx.v$.articleLength.$model,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.articleLength.$model) = $event)),
                          onKeyup: _cache[4] || (_cache[4] = _withKeys($event => (_ctx.onPastedDimension('articleLength')), ["enter"])),
                          ref: "articleLengthInputField"
                        }, null, 8, ["locale", "class", "modelValue"]),
                        (_ctx.submitted)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.articleLength.$silentErrors, (error) => {
                              return (_openBlock(), _createElementBlock("small", {
                                style: {"display":"block"},
                                class: "p-error",
                                key: error
                              }, _toDisplayString(error.$message), 1))
                            }), 128))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.width')), 1),
                      _createElementVNode("div", null, [
                        _createVNode(_component_InputNumber, {
                          locale: _ctx.locale,
                          class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.articleWidth.$invalid}),
                          mode: "decimal",
                          min: 0,
                          showButtons: "",
                          suffix: ' cm',
                          maxFractionDigits: 1,
                          step: 0.1,
                          modelValue: _ctx.v$.articleWidth.$model,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$.articleWidth.$model) = $event)),
                          onKeyup: _cache[6] || (_cache[6] = _withKeys($event => (_ctx.onPastedDimension('articleWidth')), ["enter"])),
                          ref: "articleWidthInputField"
                        }, null, 8, ["locale", "class", "modelValue"]),
                        (_ctx.submitted)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.articleWidth.$silentErrors, (error) => {
                              return (_openBlock(), _createElementBlock("small", {
                                style: {"display":"block"},
                                class: "p-error",
                                key: error
                              }, _toDisplayString(error.$message), 1))
                            }), 128))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.height')), 1),
                      _createElementVNode("div", null, [
                        _createVNode(_component_InputNumber, {
                          locale: _ctx.locale,
                          class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.articleHeight.$invalid}),
                          mode: "decimal",
                          min: 0,
                          showButtons: "",
                          suffix: ' cm',
                          maxFractionDigits: 1,
                          step: 0.1,
                          modelValue: _ctx.v$.articleHeight.$model,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.v$.articleHeight.$model) = $event)),
                          onKeyup: _cache[8] || (_cache[8] = _withKeys($event => (_ctx.onPastedDimension('articleHeight')), ["enter"])),
                          ref: "articleHeightInputField"
                        }, null, 8, ["locale", "class", "modelValue"]),
                        (_ctx.submitted)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.articleHeight.$silentErrors, (error) => {
                              return (_openBlock(), _createElementBlock("small", {
                                style: {"display":"block"},
                                class: "p-error",
                                key: error
                              }, _toDisplayString(error.$message), 1))
                            }), 128))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ean')), 1),
                      _createElementVNode("div", null, [
                        _createVNode(_component_InputText, {
                          name: "ean",
                          autocomplete: "off",
                          modelValue: _ctx.v$.ean.$model,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$.ean.$model) = $event)),
                          class: _normalizeClass({'p-invalid':_ctx.v$.ean.$invalid && _ctx.submitted}),
                          ref: "articleEanInputField"
                        }, null, 8, ["modelValue", "class"]),
                        (_ctx.submitted)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.ean.$silentErrors, (error) => {
                              return (_openBlock(), _createElementBlock("small", {
                                style: {"display":"block"},
                                class: "p-error",
                                key: error
                              }, _toDisplayString(error.$message), 1))
                            }), 128))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.singlePackage')), 1),
                      _createElementVNode("div", null, [
                        _createVNode(_component_app_checkbox, {
                          modelValue: _ctx.v$.article_single_package.$model,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.v$.article_single_package.$model) = $event)),
                          binary: "",
                          class: "mt-2"
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.containsAerosols')), 1),
                      _createElementVNode("div", null, [
                        _createVNode(_component_app_checkbox, {
                          modelValue: _ctx.v$.article_flag_aerosole.$model,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.v$.article_flag_aerosole.$model) = $event)),
                          binary: "",
                          class: "mt-2"
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.dangerousGoods')), 1),
                      _createElementVNode("div", null, [
                        _createVNode(_component_app_checkbox, {
                          modelValue: _ctx.v$.article_flag_akku_groesser_100wh.$model,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => ((_ctx.v$.article_flag_akku_groesser_100wh.$model) = $event)),
                          binary: "",
                          class: "mt-2"
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.dangerousGoodsSmall')), 1),
                      _createElementVNode("div", null, [
                        _createVNode(_component_app_checkbox, {
                          modelValue: _ctx.v$.article_flag_akku_kleiner_100wh.$model,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.v$.article_flag_akku_kleiner_100wh.$model) = $event)),
                          binary: "",
                          class: "mt-2"
                        }, null, 8, ["modelValue"])
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                (_ctx.originalStoragePlace && _ctx.originalPath)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: {
                                    name: 'ProductsWithIncompleteDimensions',
                                    query: {storagePlace: _ctx.originalStoragePlace, path: _ctx.originalPath.join('|')}
                                }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_p_button, {
                          severity: "danger",
                          disabled: _ctx.savingInProgress
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("buttons.dimensionsAcquisitionCancel")), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled"])
                      ]),
                      _: 1
                    }, 8, ["to"]))
                  : (_openBlock(), _createBlock(_component_router_link, {
                      key: 1,
                      to: {
                                    name: 'ProductsWithIncompleteDimensions'
                                }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_p_button, {
                          severity: "danger",
                          disabled: _ctx.savingInProgress
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("buttons.dimensionsAcquisitionCancel")), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled"])
                      ]),
                      _: 1
                    })),
                _createVNode(_component_p_button, {
                  disabled: _ctx.savingInProgress,
                  severity: "success",
                  textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.dimensionsAcquisitionConfirm')),
                  onClick: _cache[14] || (_cache[14] = $event => (_ctx.handleSubmit(!_ctx.v$.$invalid)))
                }, null, 8, ["disabled", "textContent"])
              ])
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["header"])
  ], 64))
}