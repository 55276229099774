import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "mt-3 flex justify-content-end" }

export function render(_ctx, _cache) {
  const _component_Column = _resolveComponent("Column")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h6", null, _toDisplayString(_ctx.$t("labels.columnMapping")), 1),
    _createElementVNode("form", {
      onSubmit: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
    }, [
      _createVNode(_component_DataTable, {
        class: "text-sm",
        stripedRows: "",
        rows: _ctx.items.length,
        dataKey: "id",
        value: _ctx.items,
        responsiveLayout: "scroll"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            header: _ctx.$t('labels.weclapp')
          }, {
            body: _withCtx(({data}) => [
              _createElementVNode("div", {
                class: _normalizeClass({'font-bold': data.required})
              }, [
                _createTextVNode(_toDisplayString(data.header.charAt(0).toUpperCase() +
                    data.header.slice(1)) + " ", 1),
                (data.required)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode("*")
                    ], 64))
                  : _createCommentVNode("", true)
              ], 2)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('labels.yourFile')
          }, {
            body: _withCtx(({data}) => [
              _createVNode(_component_p_dropdown, {
                modelValue: _ctx.state.itemsToSubmit.find((item) => item.header.trim() === data.header.trim()).mapped,
                "onUpdate:modelValue": $event => ((_ctx.state.itemsToSubmit.find((item) => item.header.trim() === data.header.trim()).mapped) = $event),
                class: _normalizeClass([{'p-invalid':_ctx.submitted && _ctx.v$.itemsToSubmit.$each.$response.$data[_ctx.state.itemsToSubmit.findIndex((item) => item.header === data.header)].mapped.$invalid}, "w-full"]),
                options: _ctx.options,
                filter: "",
                showClear: ""
              }, null, 8, ["modelValue", "onUpdate:modelValue", "class", "options"]),
              (_ctx.submitted)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.itemsToSubmit.$each.$response.$errors[_ctx.state.itemsToSubmit.findIndex((item) => item.header === data.header)].mapped, (error) => {
                    return (_openBlock(), _createElementBlock("small", {
                      style: {"display":"block"},
                      class: "p-error mt-1",
                      key: error
                    }, _toDisplayString(error.$message), 1))
                  }), 128))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('labels.preview')
          }, {
            body: _withCtx(({data}) => [
              _createTextVNode(_toDisplayString(_ctx.getRowExample(data.header)), 1)
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["rows", "value"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_p_button, {
          severity: "success",
          type: "submit"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("buttons.processFile")), 1)
          ]),
          _: 1
        })
      ])
    ], 32)
  ], 64))
}