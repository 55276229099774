import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "confirmation-content" }
const _hoisted_2 = { class: "w-full mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('messages.pleaseConfirm'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[2] || (_cache[2] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw', '640px': '90vw'},
    style: {width: '450px'},
    modal: true
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_p_button, {
          severity: "danger",
          onClick: _cache[0] || (_cache[0] = $event => (_ctx.handleAnswer(false, $event)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_p_button, {
          severity: "success",
          onClick: _cache[1] || (_cache[1] = $event => (_ctx.handleAnswer(true, $event)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('messages.confirmUnsavedChages')), 1)
      ])
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}