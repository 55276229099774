import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "confirmation-content" }
const _hoisted_2 = { class: "w-full mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('labels.softwareUpdate'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[1] || (_cache[1] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw', '640px': '90vw'},
    style: {width: '450px'},
    modal: true,
    closable: false
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_p_button, {
          severity: "danger",
          onClick: _cache[0] || (_cache[0] = $event => (_ctx.handleConfirmation(false)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_p_button, {
          severity: "success",
          onClick: _ctx.handleConfirmation
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _cache[2] || (_cache[2] = _createElementVNode("i", {
            class: "pi pi-exclamation-triangle",
            style: {"margin-left":"0px","margin-right":"0.5rem","margin-bottom":"0.3rem","font-size":"1.2rem"}
          }, null, -1)),
          _createTextVNode(_toDisplayString(_ctx.$t('messages.newSoftwareBrowserReload')), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}