import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "formgrid grid p-fluid" }
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = { class: "flex align-items-center" }
const _hoisted_6 = {
  key: 0,
  class: "font-bold text-red-400 ml-2"
}
const _hoisted_7 = { class: "formgrid grid p-fluid" }
const _hoisted_8 = { class: "col field" }
const _hoisted_9 = { class: "formgrid grid p-fluid" }
const _hoisted_10 = { class: "col field" }
const _hoisted_11 = { class: "formgrid grid p-fluid" }
const _hoisted_12 = { class: "col field" }
const _hoisted_13 = { class: "formgrid grid p-fluid" }
const _hoisted_14 = { class: "col field" }
const _hoisted_15 = { class: "formgrid grid p-fluid" }
const _hoisted_16 = { class: "col field" }
const _hoisted_17 = { class: "formgrid grid p-fluid" }
const _hoisted_18 = { class: "col field" }
const _hoisted_19 = { class: "formgrid grid p-fluid" }
const _hoisted_20 = { class: "col field" }
const _hoisted_21 = { class: "formgrid grid p-fluid" }
const _hoisted_22 = { class: "col field" }
const _hoisted_23 = { class: "formgrid grid p-fluid" }
const _hoisted_24 = { class: "col field" }
const _hoisted_25 = { class: "w-full flex justify-content-between mt-4" }

export function render(_ctx, _cache) {
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_InputText = _resolveComponent("InputText")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_calendar = _resolveComponent("p-calendar")
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[12] || (_cache[12] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.supplier')), 1),
            _createVNode(_component_p_dropdown, {
              modelValue: _ctx.v$['supplier'].$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$['supplier'].$model) = $event)),
              options: _ctx.suppliers['supplier'] || [],
              optionLabel: "label",
              optionValue: "value",
              filter: true,
              onChange: _cache[1] || (_cache[1] = $event => (_ctx.setDropdownValue(('supplier'), $event))),
              showClear: true,
              class: _normalizeClass({'p-invalid':_ctx.v$['supplier'].$invalid && _ctx.submitted})
            }, {
              option: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(slotProps?.option?.label) + " (" + _toDisplayString(slotProps?.option?.supplierNumber) + ") ", 1),
                    (slotProps?.option?.referenceNumber)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(slotProps.option.referenceNumber), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["modelValue", "options", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['supplier'].$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.sku')), 1),
            _createVNode(_component_InputText, {
              placeholder: _ctx.$t('labels.productAdd.sku'),
              autocomplete: "off",
              modelValue: _ctx.v$['sku'].$model,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$['sku'].$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$['sku'].$invalid && _ctx.submitted})
            }, null, 8, ["placeholder", "modelValue", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['sku'].$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ekPrice')), 1),
            _createVNode(_component_InputNumber, {
              mode: "decimal",
              locale: _ctx.locale,
              min: 0,
              maxFractionDigits: 2,
              autocomplete: "off",
              modelValue: _ctx.v$['ekPrice'].$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$['ekPrice'].$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$['ekPrice'].$invalid && _ctx.submitted})
            }, null, 8, ["locale", "modelValue", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['ekPrice'].$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.currency')), 1),
            _createVNode(_component_InputText, {
              disabled: "",
              placeholder: _ctx.$t('labels.productHome.currency'),
              autocomplete: "off",
              modelValue: _ctx.v$['supplierCurrency'].$model,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$['supplierCurrency'].$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$['supplierCurrency'].$invalid && _ctx.submitted})
            }, null, 8, ["placeholder", "modelValue", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['supplierCurrency'].$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.ekStartDate')), 1),
            _createVNode(_component_p_calendar, {
              autocomplete: "off",
              dateFormat: "dd.mm.yy",
              modelValue: _ctx.v$['ekStartDate'].$model,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$['ekStartDate'].$model) = $event)),
              class: _normalizeClass({'tt-form-input': true, 'p-invalid':_ctx.v$['ekStartDate'].$invalid && _ctx.submitted})
            }, null, 8, ["modelValue", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['ekStartDate'].$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.taxRateType')), 1),
            _createVNode(_component_p_dropdown, {
              modelValue: _ctx.v$['ekTaxRateType'].$model,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.v$['ekTaxRateType'].$model) = $event)),
              options: _ctx.taxRateTypeOptions,
              optionLabel: "label",
              optionValue: "value",
              onChange: _cache[7] || (_cache[7] = $event => (_ctx.setDropdownValue('ekTaxRateType', $event))),
              showClear: true,
              class: _normalizeClass({'p-invalid':_ctx.v$['ekTaxRateType'].$invalid && _ctx.submitted})
            }, null, 8, ["modelValue", "options", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['ekTaxRateType'].$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.discount')) + " 1 [%] ", 1),
            _createVNode(_component_InputNumber, {
              mode: "decimal",
              locale: _ctx.locale,
              min: 0,
              max: 100,
              maxFractionDigits: 2,
              autocomplete: "off",
              modelValue: _ctx.v$['discount1'].$model,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.v$['discount1'].$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$['discount1'].$invalid && _ctx.submitted})
            }, null, 8, ["locale", "modelValue", "class"])
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.discount')) + " 2 [%] ", 1),
            _createVNode(_component_InputNumber, {
              mode: "decimal",
              locale: _ctx.locale,
              min: 0,
              max: 100,
              maxFractionDigits: 2,
              autocomplete: "off",
              modelValue: _ctx.v$['discount2'].$model,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$['discount2'].$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$['discount2'].$invalid && _ctx.submitted})
            }, null, 8, ["locale", "modelValue", "class"])
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.discountGroup')), 1),
            _createVNode(_component_InputText, {
              placeholder: _ctx.$t('labels.productAdd.discountGroup'),
              autocomplete: "off",
              modelValue: _ctx.v$['discountGroup'].$model,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.v$['discountGroup'].$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$['discountGroup'].$invalid && _ctx.submitted})
            }, null, 8, ["placeholder", "modelValue", "class"])
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.trailingDiscount')) + " [EUR] ", 1),
            _createVNode(_component_InputNumber, {
              mode: "decimal",
              locale: _ctx.locale,
              maxFractionDigits: 2,
              autocomplete: "off",
              modelValue: _ctx.v$['trailingDiscount'].$model,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.v$['trailingDiscount'].$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$['trailingDiscount'].$invalid && _ctx.submitted})
            }, null, 8, ["locale", "modelValue", "class"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_25, [
      _createVNode(_component_p_button, {
        severity: "danger",
        textContent: _toDisplayString(_ctx.$t('buttons.back')),
        onClick: _ctx.onBackButtonClicked
      }, null, 8, ["textContent", "onClick"]),
      _createVNode(_component_p_button, {
        disabled: _ctx.savingInProgress,
        severity: "success",
        type: "submit",
        textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('labels.save'))
      }, null, 8, ["disabled", "textContent"])
    ])
  ], 32))
}