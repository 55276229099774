import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/img/akku_groesser.png'
import _imports_1 from '@/assets/img/akku_kleiner.jpg'
import _imports_2 from '@/assets/img/aerosole.png'


const _hoisted_1 = { class: "p-fluid mb-5" }
const _hoisted_2 = { class: "grid mb-2" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "flex align-content-center" }
const _hoisted_5 = { class: "text-2xl" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "mt-3" }
const _hoisted_8 = { class: "text-sm font-semibold mt-2" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "text-xl mb-2 font-semibold" }
const _hoisted_11 = {
  key: 0,
  class: "text-purple-600"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "text-xl mb-2 font-semibold" }
const _hoisted_16 = {
  key: 0,
  class: "font-semibold text-sm"
}
const _hoisted_17 = {
  key: 1,
  class: "font-semibold text-sm"
}
const _hoisted_18 = { class: "font-semibold text-sm" }
const _hoisted_19 = { class: "col" }
const _hoisted_20 = { class: "text-xl mb-2 font-semibold" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { class: "font-semibold ml-2" }
const _hoisted_23 = { class: "col" }
const _hoisted_24 = { class: "text-xl mb-2 font-semibold" }
const _hoisted_25 = { class: "grid mt-1" }
const _hoisted_26 = { class: "col" }
const _hoisted_27 = { class: "flex align-items-center" }
const _hoisted_28 = ["alt"]
const _hoisted_29 = ["alt"]
const _hoisted_30 = ["alt"]
const _hoisted_31 = ["onClick"]
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = { key: 1 }
const _hoisted_34 = {
  key: 0,
  class: "col-3"
}
const _hoisted_35 = { class: "w-full flex" }
const _hoisted_36 = {
  key: 0,
  class: "text-sm"
}
const _hoisted_37 = {
  key: 1,
  class: "grid"
}
const _hoisted_38 = { class: "col" }
const _hoisted_39 = {
  key: 2,
  class: "grid"
}
const _hoisted_40 = { class: "col" }
const _hoisted_41 = { class: "flex justify-content-end mt-3" }
const _hoisted_42 = { key: 0 }
const _hoisted_43 = ["onClick"]
const _hoisted_44 = { class: "confirmation-content" }
const _hoisted_45 = { class: "grid mb-3" }
const _hoisted_46 = {
  key: 0,
  class: "col d-flex justify-content-center"
}
const _hoisted_47 = ["alt"]
const _hoisted_48 = {
  key: 1,
  class: "col d-flex justify-content-center"
}
const _hoisted_49 = ["alt"]
const _hoisted_50 = {
  key: 2,
  class: "col d-flex justify-content-center"
}
const _hoisted_51 = ["alt"]
const _hoisted_52 = {
  key: 3,
  class: "col d-flex justify-content-center mb-2"
}
const _hoisted_53 = { key: 0 }
const _hoisted_54 = { key: 1 }
const _hoisted_55 = { class: "w-full flex justify-content-between" }
const _hoisted_56 = { class: "w-full mb-3" }
const _hoisted_57 = {
  key: 0,
  class: "pi text-green-600 text-lg pi-check-circle mr-2"
}
const _hoisted_58 = {
  key: 1,
  class: "pi text-lg text-red-600 pi-times-circle mr-2"
}
const _hoisted_59 = { class: "font-semibold" }
const _hoisted_60 = { key: 0 }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputText = _resolveComponent("InputText")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_Panel = _resolveComponent("Panel")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_Message = _resolveComponent("Message")
  const _component_PackageDimensionsForm = _resolveComponent("PackageDimensionsForm")
  const _component_ExternalCarrierForm = _resolveComponent("ExternalCarrierForm")
  const _component_p_button = _resolveComponent("p-button")
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")
  const _component_p_dialog = _resolveComponent("p-dialog")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.shipment.shipmentMask')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_InputText, {
            disabled: _ctx.shipmentNumberPreSelected,
            placeholder: _ctx.$t('labels.shipment.shipmentNumber'),
            autocomplete: "off",
            modelValue: _ctx.shipmentNumber,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.shipmentNumber) = $event)),
            modelModifiers: { trim: true },
            onPaste: _ctx.onShipmentNumberPaste,
            onInput: _ctx.onShipmentNumberChange,
            onKeyup: _withKeys(_ctx.onShipmentNumberEnter, ["enter"]),
            ref: "shipmentNumberInputField"
          }, null, 8, ["disabled", "placeholder", "modelValue", "onPaste", "onInput", "onKeyup"])
        ]),
        (_ctx.shipmentDetails)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_Panel, {
                header: 'Übersicht',
                toggleable: ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        (_ctx.shipmentDetails?.salesChannel)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: _normalizeClass(["sales-channel-dot mr-2", {'sales-channel-teltec': _ctx.shipmentDetails.salesChannel === 'NET1', 'sales-channel-videodata': _ctx.shipmentDetails.salesChannel === 'NET2'}])
                            }, null, 2))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", _hoisted_5, [
                          _createElementVNode("a", {
                            href: _ctx.shipmentDetails.url + 'webapp/view/shipment/ShipmentDetailOutgoing.page?entityId=' + _ctx.shipmentDetails.id,
                            target: "_blank"
                          }, _toDisplayString(_ctx.shipmentDetails.shipmentNumber), 9, _hoisted_6)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("span", {
                          class: _normalizeClass(["product-badge text-white", {'bg-orange-600' : _ctx.shipmentDetails.status === 'DELIVERY_NOTE_PRINTED', 'bg-blue-600':_ctx.shipmentDetails.status !== 'DELIVERY_NOTE_PRINTED'}])
                        }, _toDisplayString(_ctx.$t('labels.shipment.statusOptions.' +
                            _ctx.shipmentDetails.status)), 3)
                      ]),
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('labels.productHome.grossWeightShort')) + ": " + _toDisplayString(_ctx.formatter.format(_ctx.getShipmentTotalGrossWeight(_ctx.shipmentDetails))) + " kg ", 1)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('labels.shipment.recipient')), 1),
                      (_ctx.shipmentDetails.shipmentType === 'INTERNAL')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createVNode(_component_font_awesome_icon, {
                              class: "text-2xl text-purple-600 mr-2",
                              icon: ['fas', 'house-user']
                            }),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('labels.shipment.shipmentTypeOptions.INTERNAL')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createVNode(_component_font_awesome_icon, {
                              class: "text-xl mr-2",
                              icon: ['fas', 'user']
                            }),
                            _createElementVNode("a", {
                              href: _ctx.shipmentDetails.url + 'webapp/view/party/PartyDetail.page?entityId=' + _ctx.shipmentDetails.recipientParty?.id,
                              target: "_blank"
                            }, _toDisplayString(_ctx.outputRecipient(_ctx.shipmentDetails)), 9, _hoisted_13)
                          ]))
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('labels.shipment.shipmentAddress')), 1),
                      (_ctx.shipmentDetails.recipientAddress?.company)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.shipmentDetails.recipientAddress?.company), 1))
                        : (_ctx.shipmentDetails.recipientAddress?.lastName)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.shipmentDetails.recipientAddress?.salutation) + " " + _toDisplayString(_ctx.shipmentDetails.recipientAddress?.firstName) + " " + _toDisplayString(_ctx.shipmentDetails.recipientAddress?.lastName), 1))
                          : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.shipmentDetails.recipientAddress?.street1) + ", " + _toDisplayString(_ctx.shipmentDetails.recipientAddress?.zipcode) + " " + _toDisplayString(_ctx.shipmentDetails.recipientAddress?.city) + ", " + _toDisplayString(_ctx.shipmentDetails.recipientAddress?.countryCode), 1)
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('labels.shipment.shippingCarrier')), 1),
                      _createVNode(_component_p_dropdown, {
                        class: _normalizeClass(["w-full", {'p-invalid':_ctx.v$.shippingCarrier.$invalid && _ctx.submitted}]),
                        modelValue: _ctx.v$.shippingCarrier.$model,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$.shippingCarrier.$model) = $event)),
                        optionLabel: "label",
                        optionValue: "value",
                        options: _ctx.shippingCarrierOptions,
                        onChange: _ctx.onShippingCarrierChange,
                        disabled: _ctx.shipmentDetails.isPickup
                      }, null, 8, ["modelValue", "options", "onChange", "disabled", "class"]),
                      (_ctx.shipmentDetails?.customAttributes?.cust_ups_account_no)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_21, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('labels.shipment.upsNumber')), 1),
                            _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.shipmentDetails?.customAttributes?.cust_ups_account_no), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.submitted)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.shippingCarrier.$silentErrors, (error) => {
                            return (_openBlock(), _createElementBlock("small", {
                              style: {"display":"block"},
                              class: "p-error",
                              key: error
                            }, _toDisplayString(error.$message), 1))
                          }), 128))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t('labels.shipment.shipmentLocation')), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shippingLocationOptions, (platformItem) => {
                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                          (_ctx.v$.shippingLocation.$model === platformItem.value)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.toggleDataTable && _ctx.toggleDataTable(...args))),
                                style: {"cursor":"pointer"}
                              }, [
                                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'truck'] }),
                                _createTextVNode("   " + _toDisplayString(platformItem.label), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 256)),
                      (_ctx.submitted)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.shippingLocation.$silentErrors, (error) => {
                            return (_openBlock(), _createElementBlock("small", {
                              style: {"display":"block"},
                              class: "p-error",
                              key: error
                            }, _toDisplayString(error.$message), 1))
                          }), 128))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_component_Panel, {
                    header: _ctx.$t('labels.shipmentPositions'),
                    toggleable: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DataTable, {
                        class: "p-datatable-sm mask-positions",
                        stripedRows: "",
                        ref: "dt",
                        dataKey: "id",
                        value: _ctx.groupedShipmentItems,
                        responsiveLayout: "scroll",
                        expandedRows: _ctx.expandedRows,
                        "onUpdate:expandedRows": _cache[4] || (_cache[4] = $event => ((_ctx.expandedRows) = $event)),
                        rowClass: _ctx.rowClass
                      }, {
                        expansion: _withCtx((slotProps) => [
                          (slotProps.data.description)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                innerHTML: slotProps.data.description
                              }, null, 8, _hoisted_32))
                            : (_openBlock(), _createElementBlock("div", _hoisted_33, "No description available."))
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_Column, {
                            expander: true,
                            headerStyle: "width: 3em"
                          }),
                          _createVNode(_component_Column, {
                            field: "positionNumber",
                            sortable: _ctx.groupedShipmentItems?.length > 1,
                            style: {"min-width":"9rem"}
                          }, null, 8, ["sortable"]),
                          (_ctx.v$.groupedShipments.$model && _ctx.v$.groupedShipments.$model.length > 0)
                            ? (_openBlock(), _createBlock(_component_Column, {
                                key: 0,
                                style: {"min-width":"9rem"},
                                header: _ctx.$t('labels.shipment.shipmentNumber')
                              }, {
                                body: _withCtx(({data}) => [
                                  _createTextVNode(_toDisplayString(data.shipmentNumber), 1)
                                ]),
                                _: 1
                              }, 8, ["header"]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_Column, {
                            field: "title",
                            sortable: _ctx.shipmentDetails.shipmentItems?.length > 1,
                            style: {"min-width":"9rem"},
                            header: _ctx.$t('labels.article')
                          }, {
                            body: _withCtx(({data}) => [
                              _createElementVNode("div", _hoisted_27, [
                                _createTextVNode(_toDisplayString(data.title) + " ", 1),
                                (data.article_flag_akku_groesser_100wh)
                                  ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      src: _imports_0,
                                      alt: _ctx.$t('labels.productHome.dangerousGoods'),
                                      style: {"height":"30px","padding-left":"10px"}
                                    }, null, 8, _hoisted_28)), [
                                      [
                                        _directive_tooltip,
                                        _ctx.$t('labels.productHome.dangerousGoods'),
                                        void 0,
                                        { top: true }
                                      ]
                                    ])
                                  : _createCommentVNode("", true),
                                (data.article_flag_akku_kleiner_100wh)
                                  ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                      key: 1,
                                      src: _imports_1,
                                      alt: _ctx.$t('labels.productHome.dangerousGoodsSmall'),
                                      style: {"height":"30px","padding-left":"10px"}
                                    }, null, 8, _hoisted_29)), [
                                      [
                                        _directive_tooltip,
                                        _ctx.$t('labels.productHome.dangerousGoodsSmall'),
                                        void 0,
                                        { top: true }
                                      ]
                                    ])
                                  : _createCommentVNode("", true),
                                (data.article_flag_aerosole)
                                  ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                      key: 2,
                                      src: _imports_2,
                                      alt: _ctx.$t('labels.productHome.containsAerosols'),
                                      style: {"height":"30px","padding-left":"10px"}
                                    }, null, 8, _hoisted_30)), [
                                      [
                                        _directive_tooltip,
                                        _ctx.$t('labels.productHome.containsAerosols'),
                                        void 0,
                                        { top: true }
                                      ]
                                    ])
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 1
                          }, 8, ["sortable", "header"]),
                          _createVNode(_component_Column, {
                            field: "quantity",
                            sortable: _ctx.shipmentDetails.shipmentItems?.length > 1,
                            style: {"min-width":"9rem"},
                            header: _ctx.$t('labels.shipment.shipmentQuantity')
                          }, {
                            body: _withCtx(({data}) => [
                              _createTextVNode(_toDisplayString(data.quantity) + " " + _toDisplayString(data.unitName), 1)
                            ]),
                            _: 1
                          }, 8, ["sortable", "header"]),
                          _createVNode(_component_Column, {
                            field: "articleNumber",
                            sortable: _ctx.shipmentDetails.shipmentItems?.length > 1,
                            style: {"min-width":"9rem"},
                            header: _ctx.$t('labels.articleNumber')
                          }, null, 8, ["sortable", "header"]),
                          _createVNode(_component_Column, {
                            sortable: _ctx.shipmentDetails.shipmentItems?.length > 1,
                            style: {"min-width":"9rem"},
                            header: _ctx.$t('labels.serialNumbers')
                          }, {
                            body: _withCtx(({data}) => [
                              _createTextVNode(_toDisplayString(data.serialNumbers?.join(', ')), 1)
                            ]),
                            _: 1
                          }, 8, ["sortable", "header"]),
                          _createVNode(_component_Column, { style: {"max-width":"4rem"} }, {
                            body: _withCtx(({data}) => [
                              (_ctx.showPackageDimensionForm && data?.article?.articleGrossWeight && data?.article?.articleLength && data?.article?.articleHeight && data?.article?.articleWidth)
                                ? _withDirectives((_openBlock(), _createElementBlock("i", {
                                    key: 0,
                                    onClick: $event => (_ctx.useArticleDimensions(data)),
                                    class: "pi pi-box ml-2",
                                    style: {"cursor":"pointer"}
                                  }, null, 8, _hoisted_31)), [
                                    [
                                      _directive_tooltip,
                                      'Artikelmaße verwenden',
                                      void 0,
                                      { right: true }
                                    ]
                                  ])
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value", "expandedRows", "rowClass"])
                    ]),
                    _: 1
                  }, 8, ["header"])
                ]),
                ((_ctx.isUpsCarrier || _ctx.isDhlCarrier) && _ctx.groupedShipmentOptions.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                      _createVNode(_component_Panel, { header: 'Weitere Lieferungen des Kunden' }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedShipmentOptions, (grOption, grIndex) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: grIndex,
                              class: "grid p-2 mb-2"
                            }, [
                              _createElementVNode("div", _hoisted_35, [
                                _createVNode(_component_p_checkbox, {
                                  modelValue: _ctx.v$.groupedShipments.$model,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$.groupedShipments.$model) = $event)),
                                  disabled: _ctx.groupedMultiSelectOptionFilterFunction(grOption),
                                  value: grOption.shipmentNumber,
                                  onChange: _ctx.onCheckboxChange
                                }, null, 8, ["modelValue", "disabled", "value", "onChange"]),
                                _createElementVNode("span", {
                                  class: _normalizeClass(["ml-2 mt-1", {'text-red-600' : _ctx.groupedMultiSelectOptionFilterFunction(grOption)}])
                                }, [
                                  _createTextVNode(_toDisplayString(grOption.shipmentNumber) + " ", 1),
                                  ((grOption.shipmentItems || []).length > 0)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_36, " (" + _toDisplayString(_ctx.$t('labels.productHome.grossWeightShort')) + ": " + _toDisplayString(_ctx.formatter.format(_ctx.getShipmentTotalGrossWeight(grOption))) + " kg) ", 1))
                                    : _createCommentVNode("", true)
                                ], 2)
                              ])
                            ]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.shipmentDetails?.pickingInstructions)
                ? (_openBlock(), _createBlock(_component_Message, {
                    key: 0,
                    closable: false,
                    severity: "error"
                  }, {
                    messageicon: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createElementVNode("span", null, null, -1)
                    ])),
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.shipmentDetails.pickingInstructions), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.showPackageDimensionForm)
                ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                    _createElementVNode("div", _hoisted_38, [
                      _createVNode(_component_PackageDimensionsForm, {
                        shipment: _ctx.shipmentDetails,
                        hasDangerousGoods: _ctx.showUpsDangerousGoodsButton,
                        onDataEntered: _ctx.onPackageDimensionsEntered
                      }, null, 8, ["shipment", "hasDangerousGoods", "onDataEntered"]),
                      (_ctx.submitted)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.packageItems.$silentErrors, (error) => {
                            return (_openBlock(), _createElementBlock("small", {
                              style: {"display":"block"},
                              class: "p-error",
                              key: error
                            }, _toDisplayString(error.$message), 1))
                          }), 128))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.isStatusNew && (_ctx.isUpsCarrier || _ctx.isDhlCarrier))
                ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                    _createElementVNode("div", _hoisted_40, [
                      _createVNode(_component_ExternalCarrierForm, {
                        "shipping-carrier-type": _ctx.isUpsCarrier ? 'ups' : (_ctx.isDhlCarrier ? 'dhl' : null),
                        onDataEntered: _ctx.onExternalCarrierDataEntered,
                        toggleable: "",
                        collapsed: ""
                      }, null, 8, ["shipping-carrier-type", "onDataEntered"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_41, [
                (_ctx.isStatusNew && !(_ctx.isUpsCarrier || _ctx.isDhlCarrier))
                  ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_42, [
                      _createVNode(_component_p_button, {
                        class: "ml-2",
                        severity: "info",
                        disabled: _ctx.haveCompleteWorkflowButtonDisabled,
                        textContent: 'Lieferungs-Komplett-Workflow',
                        onClick: _cache[6] || (_cache[6] = $event => (_ctx.handleStatusUpdate('full')))
                      }, null, 8, ["disabled"])
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.haveCompleteWorkflowButtonDisabled,
                        void 0,
                        { top: true }
                      ]
                    ])
                  : _createCommentVNode("", true),
                _withDirectives((_openBlock(), _createElementBlock("span", null, [
                  _createVNode(_component_p_button, {
                    class: "ml-2",
                    severity: "success",
                    disabled: _ctx.haveSavingButtonDisabled,
                    textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : (_ctx.isStatusNew ? _ctx.$t('buttons.createDeliveryNote') : _ctx.$t('buttons.completeShipment'))),
                    onClick: _cache[7] || (_cache[7] = $event => (_ctx.isStatusNew ? _ctx.handleStatusUpdate('create-delivery-note') : _ctx.handleWeightSubmit()))
                  }, null, 8, ["disabled", "textContent"])
                ])), [
                  [
                    _directive_tooltip,
                    _ctx.haveSavingButtonDisabled,
                    void 0,
                    { top: true }
                  ]
                ]),
                _createVNode(_component_p_button, {
                  severity: "danger",
                  disabled: _ctx.savingInProgress,
                  onClick: _ctx.handleCancelClick,
                  class: "ml-2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancelShipment")), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ])
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_OverlayPanel, {
      ref: "opShippingLocations",
      appendTo: "body",
      id: "overlay_panel_shipping_locations"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          value: _ctx.shippingLocationOptions,
          paginator: _ctx.shippingLocationOptions?.length > 5,
          rows: 5,
          responsiveLayout: "scroll"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, { headerStyle: "min-width:12rem;display:none;" }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", {
                  style: {"cursor":"pointer"},
                  onClick: $event => {_ctx.changeShippingLocation(data.value);_ctx.toggleDataTable();}
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fas', 'truck'] }),
                  _createTextVNode("   " + _toDisplayString(data.label), 1)
                ], 8, _hoisted_43)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "paginator"])
      ]),
      _: 1
    }, 512),
    _createVNode(_component_p_dialog, {
      header: _ctx.$t('messages.pleaseConfirm'),
      visible: _ctx.displayDangerousGoodsConfirmationDialog,
      "onUpdate:visible": _cache[9] || (_cache[9] = $event => ((_ctx.displayDangerousGoodsConfirmationDialog) = $event)),
      breakpoints: {'960px': '75vw', '640px': '90vw'},
      style: {width: '450px'},
      modal: true
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_55, [
          _createVNode(_component_p_button, {
            severity: "danger",
            onClick: _cache[8] || (_cache[8] = $event => (_ctx.displayDangerousGoodsConfirmationDialog = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_p_button, {
            severity: "success",
            onClick: _ctx.handleDangerousGoodsConfirmClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_44, [
          _createElementVNode("div", _hoisted_45, [
            (_ctx.confirmationNeededForAkkuGroesser)
              ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                  _withDirectives(_createElementVNode("img", {
                    class: "rounded",
                    src: _imports_0,
                    alt: _ctx.$t('labels.productHome.dangerousGoods'),
                    style: {"height":"120px"}
                  }, null, 8, _hoisted_47), [
                    [
                      _directive_tooltip,
                      _ctx.$t('labels.productHome.dangerousGoods'),
                      void 0,
                      { top: true }
                    ]
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.confirmationNeededForAkkuKleiner)
              ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                  _withDirectives(_createElementVNode("img", {
                    class: "rounded",
                    src: _imports_1,
                    alt: _ctx.$t('labels.productHome.dangerousGoodsSmall'),
                    style: {"height":"120px"}
                  }, null, 8, _hoisted_49), [
                    [
                      _directive_tooltip,
                      _ctx.$t('labels.productHome.dangerousGoodsSmall'),
                      void 0,
                      { top: true }
                    ]
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.confirmationNeededForAerosols)
              ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                  _withDirectives(_createElementVNode("img", {
                    class: "rounded",
                    src: _imports_2,
                    alt: _ctx.$t('labels.productHome.containsAerosols'),
                    style: {"height":"120px"}
                  }, null, 8, _hoisted_51), [
                    [
                      _directive_tooltip,
                      _ctx.$t('labels.productHome.containsAerosols'),
                      void 0,
                      { top: true }
                    ]
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.confirmationNeededForSpedition)
              ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fas', 'cart-flatbed'],
                    style: {"font-size":"4rem"}
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.confirmationNeededForSpedition)
            ? (_openBlock(), _createElementBlock("div", _hoisted_53, _toDisplayString(_ctx.$t('messages.speditionProductWarning', {product:
            _ctx.confirmationNeededForSpedition})), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_54, _toDisplayString(_ctx.$t('messages.dangerousGoodsConfirmation')), 1))
        ])
      ]),
      _: 1
    }, 8, ["header", "visible"]),
    _createVNode(_component_p_dialog, {
      header: _ctx.$t('labels.result'),
      visible: _ctx.showWorkflowExecutionOverviewDialog,
      "onUpdate:visible": _cache[11] || (_cache[11] = $event => ((_ctx.showWorkflowExecutionOverviewDialog) = $event)),
      breakpoints: {'960px': '75vw', '640px': '90vw'},
      style: {width: '600px'},
      modal: "",
      closable: false
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["w-full flex justify-content-end", {'justify-content-between' : _ctx.workflowIdenfier==='full', 'justify-content-end' : _ctx.workflowIdenfier!=='full'}])
        }, [
          false
            ? (_openBlock(), _createBlock(_component_p_button, {
                key: 0,
                severity: "danger",
                onClick: _cache[10] || (_cache[10] = $event => (_ctx.showWorkflowExecutionOverviewDialog = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_p_button, {
            severity: "success",
            onClick: _ctx.handleWorkflowResultsConfirmed
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ], 2)
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workflowExecutionResults, (item) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_56, [
            (!item.error)
              ? (_openBlock(), _createElementBlock("i", _hoisted_57))
              : (_openBlock(), _createElementBlock("i", _hoisted_58)),
            _createElementVNode("span", _hoisted_59, _toDisplayString(_ctx.$t("labels.shipment.workflow.steps." + item?.step)), 1),
            _createElementVNode("div", null, [
              (item.result && typeof item.result === 'string')
                ? (_openBlock(), _createElementBlock("small", _hoisted_60, _toDisplayString(item.result), 1))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 256))
      ]),
      _: 1
    }, 8, ["header", "visible"])
  ], 64))
}