import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "grid formgrid p-fluid" }
const _hoisted_2 = { class: "field col" }
const _hoisted_3 = { class: "ml-2" }
const _hoisted_4 = { class: "field col-9" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "w-full flex justify-content-between mt-2" }

export function render(_ctx, _cache) {
  const _component_LoadingPlugin = _resolveComponent("LoadingPlugin")
  const _component_p_tree = _resolveComponent("p-tree")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_Panel = _resolveComponent("Panel")
  const _component_ProductList = _resolveComponent("ProductList")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")
  const _component_p_button = _resolveComponent("p-button")
  const _component_TabView = _resolveComponent("TabView")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    showHeader: false,
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[5] || (_cache[5] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw'},
    style: {width: '75vw'},
    modal: true,
    position: "top"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LoadingPlugin, {
        active: _ctx.loading,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
      }, null, 8, ["active"]),
      _createVNode(_component_TabView, null, {
        default: _withCtx(() => [
          (_ctx.isShopware5Instance && !_ctx.isRemove)
            ? (_openBlock(), _createBlock(_component_TabPanel, { key: 0 }, {
                header: _withCtx(() => [
                  _cache[6] || (_cache[6] = _createElementVNode("i", { class: "mr-3 mb-1 pi pi-sitemap" }, null, -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('labels.categories')), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_p_tree, {
                    value: _ctx.categories,
                    selectionMode: "multiple",
                    onNodeExpand: _ctx.onNodeExpand,
                    onNodeSelect: _ctx.onNodeSelect,
                    onNodeUnselect: _ctx.onNodeUnselect,
                    selectionKeys: _ctx.selectedCategories,
                    "onUpdate:selectionKeys": _cache[1] || (_cache[1] = $event => ((_ctx.selectedCategories) = $event)),
                    metaKeySelection: false,
                    class: "text-xs"
                  }, null, 8, ["value", "onNodeExpand", "onNodeSelect", "onNodeUnselect", "selectionKeys"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_TabPanel, {
            disabled: !_ctx.showSummaryTab
          }, {
            header: _withCtx(() => [
              _createElementVNode("i", {
                class: _normalizeClass(["mr-3 mb-1", _ctx.isRemove ? 'pi pi-circle-fill text-red-600' : 'pi pi-circle-fill text-green-600'])
              }, null, 2),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('labels.setProducts' + (_ctx.isRemove ? 'Offline' :
                    'Online'))), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_ScrollPanel, {
                style: {"height":"65vh"},
                class: "w-full"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Panel, {
                    header: _ctx.$t('labels.mail.execution'),
                    class: "mb-3"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('labels.immediateExecution')), 1),
                          _createVNode(_component_p_checkbox, {
                            modelValue: _ctx.immediateExecution,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.immediateExecution) = $event)),
                            binary: "",
                            class: "ml-2 mb-1"
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_4, [
                          (!_ctx.immediateExecution)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.at')), 1),
                                _createVNode(_component_Calendar, {
                                  modelValue: _ctx.startTime,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.startTime) = $event)),
                                  showTime: "",
                                  dateFormat: "dd.mm.yy",
                                  minDate: new Date()
                                }, null, 8, ["modelValue", "minDate"])
                              ], 64))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["header"]),
                  _createVNode(_component_Panel, {
                    header: _ctx.$t('labels.products')
                  }, {
                    default: _withCtx(() => [
                      (_ctx.isFilterRelated)
                        ? (_openBlock(), _createElementBlock("h5", _hoisted_5, _toDisplayString(_ctx.$t('messages.changesWillBeAppliedToProducts', {
                        number: _ctx.productTotalRecords})), 1))
                        : (_openBlock(), _createBlock(_component_ProductList, {
                            key: 1,
                            products: _ctx.products
                          }, null, 8, ["products"]))
                    ]),
                    _: 1
                  }, 8, ["header"])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_p_button, {
                  severity: "danger",
                  onClick: _cache[4] || (_cache[4] = $event => (_ctx.closeResponsive(false)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_p_button, {
                  severity: "success",
                  onClick: _ctx.onBulkProcessOnlineStatus,
                  textContent: _toDisplayString(_ctx.$t('buttons.confirm'))
                }, null, 8, ["onClick", "textContent"])
              ])
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible"]))
}