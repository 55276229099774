import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex align-items-center" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "flex justify-content-center" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_p_button = _resolveComponent("p-button")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _component_AddOrEditPrintConfigurationDialog = _resolveComponent("AddOrEditPrintConfigurationDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, null, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('labels.printConfigurations')), 1),
          _createVNode(_component_p_button, {
            class: "ml-2",
            onClick: _cache[1] || (_cache[1] = $event => (_ctx.onConfigurationAddEditClick(null))),
            size: "small",
            severity: "success"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.addNewGeneric")), 1)
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          paginator: "",
          lazy: "",
          scrollable: "",
          scrollHeight: "calc(100vh - 23rem)",
          rows: 10,
          ref: "dt",
          dataKey: "id",
          totalRecords: _ctx.totalRecords,
          value: _ctx.systemLogs,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50],
          "current-page-report-template": "{first} to {last} of {totalRecords}",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[2] || (_cache[2] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          onPage: _ctx.onPage,
          onFilter: _ctx.onFilter,
          onSort: _ctx.onSort
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "hardwareId",
              header: _ctx.$t('labels.hardwareId')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "event",
              header: _ctx.$t('labels.event')
            }, {
              body: _withCtx(({data}) => [
                (data.event)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('events.' + data.event)), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "printerName",
              header: _ctx.$t('labels.printerName')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "printerSize",
              header: _ctx.$t('labels.printerSize')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "author",
              header: _ctx.$t('labels.createdBy')
            }, {
              body: _withCtx(({data}) => [
                (data.author)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.author, (element) => {
                        return (_openBlock(), _createElementBlock("li", null, _toDisplayString(element), 1))
                      }), 256))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "entityName",
              header: _ctx.$t('labels.entityName')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "attributeName",
              header: _ctx.$t('labels.attributeName')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "attributeValue",
              header: _ctx.$t('labels.attributeValue')
            }, null, 8, ["header"]),
            (_ctx.editPermissionAvailable)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 0,
                  style: {"min-width":"5rem"}
                }, {
                  body: _withCtx(({data}) => [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("i", {
                        class: "pi pi-pencil",
                        onClick: $event => (_ctx.onConfigurationAddEditClick(data)),
                        style: {"cursor":"pointer"}
                      }, null, 8, _hoisted_6),
                      _createElementVNode("i", {
                        class: "pi pi-trash text-red-600 ml-2",
                        onClick: $event => (_ctx.removeConfiguration(data)),
                        style: {"cursor":"pointer"}
                      }, null, 8, _hoisted_7)
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["totalRecords", "value", "filters", "onPage", "onFilter", "onSort"])
      ]),
      _: 1
    }),
    _createVNode(_component_AddOrEditPrintConfigurationDialog, {
      "display-dialog": _ctx.showCreatePrintConfigurationDialog,
      onCloseDialog: _ctx.onCloseCreateDialog,
      "existing-configuration": _ctx.existingConfiguration
    }, null, 8, ["display-dialog", "onCloseDialog", "existing-configuration"])
  ], 64))
}