import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "formgrid grid p-fluid"
}
const _hoisted_2 = { class: "field col" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { style: {"margin-left":"0.5rem","vertical-align":"middle"} }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { style: {"margin-left":"0.5rem","vertical-align":"middle"} }
const _hoisted_7 = { class: "p-fluid" }
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = { class: "mt-3 flex justify-content-end" }

export function render(_ctx, _cache) {
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.apiCredentials)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList([{ value: 'teltec-demo', label: 'Teltec DEMO' }, { value: 'teltec', label: 'Teltec LIVE' }, { value: 'videodata-demo', label: 'videodata DEMO' }, { value: 'videodata', label: 'videodata LIVE' }], (item) => {
            return _createElementVNode("div", _hoisted_2, [
              _createElementVNode("label", null, _toDisplayString(item.label), 1),
              (_ctx.apiCredentials && _ctx.apiCredentials[item.value])
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: $event => (_ctx.handleDialogOpen($event, item)),
                    style: {"cursor":"pointer"}
                  }, [
                    _cache[3] || (_cache[3] = _createElementVNode("span", {
                      class: "pi pi-pencil",
                      style: {"vertical-align":"middle"}
                    }, null, -1)),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('buttons.edit')), 1)
                  ], 8, _hoisted_3))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    onClick: $event => (_ctx.handleDialogOpen($event, item)),
                    style: {"cursor":"pointer"}
                  }, [
                    _cache[4] || (_cache[4] = _createElementVNode("span", {
                      class: "pi pi-plus text-green-600",
                      style: {"vertical-align":"middle"}
                    }, null, -1)),
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('labels.addNew')), 1)
                  ], 8, _hoisted_5))
            ])
          }), 64))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_PrimeDialog, {
      visible: _ctx.dialogVisible,
      "onUpdate:visible": _cache[2] || (_cache[2] = $event => ((_ctx.dialogVisible) = $event)),
      breakpoints: {'960px': '75vw'},
      style: {width: '20vw'},
      modal: "",
      onHide: _ctx.onHideDialog,
      dismissableMask: "",
      header: _ctx.dialogHeader
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_InputText, {
              placeholder: "API-Token",
              autocomplete: "off",
              modelValue: _ctx.v$.token.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$.token.$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$.token.$invalid && _ctx.submitted})
            }, null, 8, ["modelValue", "class"]),
            ((_ctx.v$.token.$invalid && _ctx.submitted) || _ctx.v$.token.$pending.$response)
              ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.v$.token.required.$message.replace('Value',
                'API-Token')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_p_button, {
              type: "submit",
              severity: "success",
              disabled: _ctx.savingInProgress
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') :
                _ctx.$t('buttons.confirm')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ])
        ], 32)
      ]),
      _: 1
    }, 8, ["visible", "onHide", "header"])
  ], 64))
}