import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "confirmation-content" }
const _hoisted_2 = {
  key: 0,
  class: "grid mb-1"
}
const _hoisted_3 = { class: "col text-gray-400 font-semibold" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "grid mb-1"
}
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "grid mb-1" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 2,
  class: "grid mb-1"
}
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "grid mb-1" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = {
  key: 3,
  class: "grid mb-1"
}
const _hoisted_15 = { class: "col-10 mt-1" }
const _hoisted_16 = { class: "col" }
const _hoisted_17 = {
  key: 0,
  class: "grid mb-1"
}
const _hoisted_18 = { class: "col text-gray-400 font-semibold" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = {
  key: 1,
  class: "grid mb-1"
}
const _hoisted_21 = { class: "col" }
const _hoisted_22 = { class: "grid mb-1" }
const _hoisted_23 = { class: "col" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = {
  key: 2,
  class: "grid mb-1"
}
const _hoisted_26 = { class: "col" }
const _hoisted_27 = { class: "grid mb-1" }
const _hoisted_28 = { class: "col" }
const _hoisted_29 = {
  key: 3,
  class: "grid mb-1"
}
const _hoisted_30 = { class: "col-10 mt-1" }
const _hoisted_31 = { class: "col" }
const _hoisted_32 = { class: "grid mb-1" }
const _hoisted_33 = { class: "col-4 mt-3 font-bold" }
const _hoisted_34 = { class: "col" }
const _hoisted_35 = { class: "w-full mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")
  const _component_p_divider = _resolveComponent("p-divider")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('messages.pleaseConfirm'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[5] || (_cache[5] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw', '640px': '90vw'},
    style: {width: '450px'},
    modal: "",
    closable: false
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_35, [
        _createVNode(_component_p_button, {
          severity: "danger",
          onClick: _cache[3] || (_cache[3] = $event => (_ctx.handleAnswer(false, $event)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_p_button, {
          disabled: _ctx.disableConfirmButton,
          severity: "success",
          onClick: _cache[4] || (_cache[4] = $event => (_ctx.handleAnswer(true, $event)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "grid mb-1" }, [
          _createElementVNode("div", { class: "col font-bold" }, "Belegadresse/E-Mail")
        ], -1)),
        (_ctx.shopData?.billingAddress?.company)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createTextVNode(_toDisplayString(_ctx.shopData?.billingAddress?.company), 1),
                (_ctx.shopData?.billingAddress?.department)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, ", " + _toDisplayString(_ctx.shopData?.billingAddress?.department), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.shopData?.billingAddress?.firstName && !_ctx.skipSavingContactDataInBillingAddress)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.shopData?.billingAddress?.salutation) + " " + _toDisplayString(_ctx.shopData?.billingAddress?.firstName) + " " + _toDisplayString(_ctx.shopData?.billingAddress?.lastName), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createTextVNode(_toDisplayString(_ctx.shopData?.billingAddress?.street) + ", ", 1),
            (_ctx.shopData?.billingAddress?.additionalAddressLine1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.shopData?.billingAddress?.additionalAddressLine1) + ", ", 1))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.shopData?.billingAddress?.zipcode) + ", " + _toDisplayString(_ctx.shopData?.billingAddress?.city) + ", " + _toDisplayString(_ctx.resolveCountryFromCode(_ctx.shopData?.billingAddress?.country)), 1)
          ])
        ]),
        (_ctx.shopData?.billingAddress?.phoneNumber)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.shopData.billingAddress.phoneNumber), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.shopData.customer?.email), 1)
        ]),
        (_ctx.shopData?.billingAddress?.company)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('messages.skipSavingContactDataInBillingAddress')), 1),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_InputSwitch, {
                  class: "text-sm",
                  modelValue: _ctx.skipSavingContactDataInBillingAddress,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.skipSavingContactDataInBillingAddress) = $event))
                }, null, 8, ["modelValue"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.shopData?.hasSameShippingAddress === false)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
              _createVNode(_component_p_divider),
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "grid mb-1" }, [
                _createElementVNode("div", { class: "col font-bold" }, " Abweichende Lieferadresse/E-Mail ")
              ], -1)),
              (_ctx.shopData?.shippingAddress?.company)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createTextVNode(_toDisplayString(_ctx.shopData?.shippingAddress?.company) + " ", 1),
                      (_ctx.shopData?.shippingAddress?.department)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_19, ", " + _toDisplayString(_ctx.shopData?.shippingAddress?.department), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.shopData?.shippingAddress?.firstName && !_ctx.skipSavingContactDataInShippingAddress)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.shopData?.shippingAddress?.salutation) + " " + _toDisplayString(_ctx.shopData?.shippingAddress?.firstName) + " " + _toDisplayString(_ctx.shopData?.shippingAddress?.lastName), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _createTextVNode(_toDisplayString(_ctx.shopData?.shippingAddress?.street) + ", ", 1),
                  (_ctx.shopData?.shippingAddress?.additionalAddressLine1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_ctx.shopData?.shippingAddress?.additionalAddressLine1) + ", ", 1))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(_ctx.shopData?.shippingAddress?.zipcode) + ", " + _toDisplayString(_ctx.shopData?.shippingAddress?.city) + ", " + _toDisplayString(_ctx.resolveCountryFromCode(_ctx.shopData?.shippingAddress?.country)), 1)
                ])
              ]),
              (_ctx.shopData?.shippingAddress?.phoneNumber)
                ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.shopData.shippingAddress.phoneNumber), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.shopData.customer?.email), 1)
              ]),
              (_ctx.shopData?.shippingAddress?.company)
                ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t('messages.skipSavingContactDataInShippingAddress')), 1),
                    _createElementVNode("div", _hoisted_31, [
                      _createVNode(_component_InputSwitch, {
                        class: "text-sm",
                        modelValue: _ctx.skipSavingContactDataInShippingAddress,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.skipSavingContactDataInShippingAddress) = $event))
                      }, null, 8, ["modelValue"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        (!_ctx.selectedWeclappCustomer)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
              _createVNode(_component_p_divider),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t('labels.sector')), 1),
                _createElementVNode("div", _hoisted_34, [
                  _createVNode(_component_p_dropdown, {
                    class: "w-full",
                    modelValue: _ctx.selectedSectorId,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.selectedSectorId) = $event)),
                    optionValue: "id",
                    optionLabel: "name",
                    options: _ctx.sectorOptions,
                    showClear: ""
                  }, null, 8, ["modelValue", "options"])
                ])
              ])
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}