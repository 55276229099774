export enum ReductionAdditionValueType {
    ReductionPercent = 'REDUCTION_PERCENT',
    AdditionPercent = 'ADDITION_PERCENT',
    ReductionAbsolute = 'REDUCTION_ABS',
    AdditionAbsolute = 'ADDITION_ABS'
}

export enum ReductionAdditionType {
    Promotion = 'Promotion',
    Regular = 'Regular',
    Transport = 'Transport',
    Customs = 'Customs',
    NSR = 'Nsr'
}

export enum PriceType {
    CostPrice = 'Cost',
    SellPrice = 'Sell',
    ListPrice = 'List'
}
