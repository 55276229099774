import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mt-2" }
const _hoisted_2 = { class: "flex align-content-center" }
const _hoisted_3 = { class: "p-button-label" }

export function render(_ctx, _cache) {
  const _component_p_button = _resolveComponent("p-button")
  const _component_Documents = _resolveComponent("Documents")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_TabView = _resolveComponent("TabView")
  const _component_AddEditDialog = _resolveComponent("AddEditDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_p_button, {
        class: "text-sm",
        onClick: _cache[0] || (_cache[0] = $event => {_ctx.displayAddEditDialog = true;}),
        severity: "success"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _cache[1] || (_cache[1] = _createElementVNode("span", {
              class: "p-button-icon p-button-icon-left pi pi-plus",
              "data-pc-section": "icon"
            }, null, -1)),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('buttons.addNewGeneric')), 1),
            (_ctx.productDetails?.shopware)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                }, null, 2))
              : _createCommentVNode("", true),
            (_ctx.productDetails?.secondShopware)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-1", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                }, null, 2))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_TabView, { class: "tabview-custom text-sm" }, {
      default: _withCtx(() => [
        _createVNode(_component_TabPanel, {
          disabled: !_ctx.productDetails?.shopware
        }, {
          header: _withCtx(() => [
            _cache[2] || (_cache[2] = _createElementVNode("i", { class: "pi pi-file-pdf" }, null, -1)),
            _createElementVNode("span", {
              class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
            }, null, 2)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Documents, {
              "shopware-details": _ctx.productDetails?.shopware,
              onReloadProduct: _ctx.loadProductDetails
            }, null, 8, ["shopware-details", "onReloadProduct"])
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(_component_TabPanel, {
          disabled: !_ctx.productDetails?.secondShopware
        }, {
          header: _withCtx(() => [
            _cache[3] || (_cache[3] = _createElementVNode("i", { class: "pi pi-file-pdf" }, null, -1)),
            _createElementVNode("span", {
              class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
            }, null, 2)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Documents, {
              "shopware-details": _ctx.productDetails?.secondShopware,
              onReloadProduct: _ctx.loadProductDetails,
              platform: _ctx.secondPlatform
            }, null, 8, ["shopware-details", "onReloadProduct", "platform"])
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    }),
    _createVNode(_component_AddEditDialog, {
      displayResponsive: _ctx.displayAddEditDialog,
      "product-number": _ctx.productDetails?.shopware?.productNumber,
      onCloseDialog: _ctx.closeDialogListener
    }, null, 8, ["displayResponsive", "product-number", "onCloseDialog"])
  ], 64))
}