import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex w-full justify-content-between" }
const _hoisted_2 = { class: "grid p-fluid" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "mt-1" }
const _hoisted_10 = {
  key: 1,
  class: "col-2 flex justify-content-end"
}

export function render(_ctx, _cache) {
  const _component_Tag = _resolveComponent("Tag")
  const _component_p_button = _resolveComponent("p-button")
  const _component_p_fieldset = _resolveComponent("p-fieldset")
  const _component_AddRetoureWizard = _resolveComponent("AddRetoureWizard")
  const _component_AddTicketCommentDialog = _resolveComponent("AddTicketCommentDialog")
  const _component_CreateSupplierReturnDialog = _resolveComponent("CreateSupplierReturnDialog")
  const _component_CreateCompensatonShipmentDialog = _resolveComponent("CreateCompensatonShipmentDialog")
  const _component_SendDocumentToRemotePrinter = _resolveComponent("SendDocumentToRemotePrinter")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_p_fieldset, {
      class: "mb-3",
      legend: _ctx.$t('labels.ticket.references.customerReturn'),
      toggleable: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.ticketDetails.ticketCustomerReturn && _ctx.ticketDetails.ticketCustomerReturn.length > 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList((_ctx.ticketDetails.ticketCustomerReturn || []), (referenceItem) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", null, [
                        _createElementVNode("a", {
                          href: _ctx.ticketDetails.url + 'webapp/view/shipment/ShipmentDetailArrival.page?entityId=' +
                            referenceItem.id,
                          target: "_blank"
                        }, _toDisplayString(_ctx.$t('labels.ticket.references.incomingGoods.' +
                            referenceItem.incomingGoodsType)) + " " + _toDisplayString(referenceItem.incomingGoodsNumber), 9, _hoisted_4)
                      ]),
                      (_ctx.editPermissionAvailable)
                        ? _withDirectives((_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: "pi pi-plus-circle text-green-600 ml-3",
                            onClick: $event => (_ctx.onCreateSupplierReturnClick(referenceItem, $event))
                          }, null, 8, _hoisted_5)), [
                            [
                              _directive_tooltip,
                              _ctx.$t('labels.ticket.retoure.createSupplierReturn'),
                              void 0,
                              { top: true }
                            ]
                          ])
                        : _createCommentVNode("", true),
                      (_ctx.editPermissionAvailable)
                        ? _withDirectives((_openBlock(), _createElementBlock("i", {
                            key: 1,
                            class: "pi pi-plus-circle text-indigo-600 ml-1",
                            onClick: $event => (_ctx.onCreateCompensationShipmentClick(referenceItem, $event))
                          }, null, 8, _hoisted_6)), [
                            [
                              _directive_tooltip,
                              _ctx.$t('labels.ticket.retoure.createCompensationShipment'),
                              void 0,
                              { top: true }
                            ]
                          ])
                        : _createCommentVNode("", true),
                      _withDirectives(_createElementVNode("i", {
                        onClick: $event => (_ctx.createAndPrintRetoureLabel(referenceItem)),
                        class: "pi pi-book ml-1 text-red-600",
                        style: {"cursor":"pointer"}
                      }, null, 8, _hoisted_7), [
                        [
                          _directive_tooltip,
                          _ctx.$t('labels.ticket.retoure.createAndPrintLabel'),
                          void 0,
                          { top: true }
                        ]
                      ]),
                      _withDirectives(_createElementVNode("i", {
                        onClick: $event => (_ctx.createAndPrintRetoureLabel(referenceItem, 'ticket')),
                        class: "pi pi-book ml-1 text-blue-600",
                        style: {"cursor":"pointer"}
                      }, null, 8, _hoisted_8), [
                        [
                          _directive_tooltip,
                          _ctx.$t('labels.ticket.retoure.createAndPrintTicketLabel'),
                          void 0,
                          { top: true }
                        ]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_Tag, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('labels.ticket.retoure.status.' +
                        referenceItem.status)), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ])
                ]))
              }), 256))
            : _createCommentVNode("", true),
          (_ctx.showAddNewRetoureButton && _ctx.editPermissionAvailable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_p_button, {
                  severity: "success",
                  onClick: _ctx.onAddNewRetoureClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("buttons.addNewRetoure")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["legend"]),
    _createVNode(_component_AddRetoureWizard, {
      "display-add-retoure-dialog": _ctx.showAddRetoureWizardDialog,
      "ticket-details": _ctx.ticketDetails,
      onCloseDialog: _ctx.closeAddNewRetoureDialog
    }, null, 8, ["display-add-retoure-dialog", "ticket-details", "onCloseDialog"]),
    _createVNode(_component_AddTicketCommentDialog, {
      "display-dialog": _ctx.showAddCommentDialog,
      "ticket-details": _ctx.ticketDetails,
      "comment-default-value": _ctx.commentDefaultValue,
      onCloseDialog: _ctx.closeAddTicketCommentDialog
    }, null, 8, ["display-dialog", "ticket-details", "comment-default-value", "onCloseDialog"]),
    _createVNode(_component_CreateSupplierReturnDialog, {
      "display-dialog": _ctx.showCreateSupplierReturnDialog,
      "ticket-details": _ctx.ticketDetails,
      retoure: _ctx.selectedRetoure,
      onCloseSupplierReturnDialog: _ctx.closeCreateSupplierReturnDialog
    }, null, 8, ["display-dialog", "ticket-details", "retoure", "onCloseSupplierReturnDialog"]),
    _createVNode(_component_CreateCompensatonShipmentDialog, {
      "display-dialog": _ctx.showCreateCompensationShipmentDialog,
      "ticket-details": _ctx.ticketDetails,
      retoure: _ctx.selectedRetoure,
      onCloseCreateCompensationShipmentDialog: _ctx.closeCreateCompensationShipmentDialog
    }, null, 8, ["display-dialog", "ticket-details", "retoure", "onCloseCreateCompensationShipmentDialog"]),
    _createVNode(_component_SendDocumentToRemotePrinter, {
      "display-dialog": _ctx.displaySendToRemotePrinterDialog,
      onCloseDialog: _ctx.closePrintDialogListener,
      "selected-document": _ctx.selectedDocument
    }, null, 8, ["display-dialog", "onCloseDialog", "selected-document"])
  ], 64))
}