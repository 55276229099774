import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "m-2" }
const _hoisted_2 = { class: "flex align-items-center mb-5" }
const _hoisted_3 = { class: "flex flex-grow-1" }
const _hoisted_4 = {
  key: 0,
  class: "text-center w-full"
}
const _hoisted_5 = {
  key: 1,
  class: "text-center w-full"
}
const _hoisted_6 = {
  key: 0,
  class: "flex-none flex ml-5"
}
const _hoisted_7 = ["src", "alt"]

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_p_button = _resolveComponent("p-button")
  const _component_Carousel = _resolveComponent("Carousel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _mergeProps(_ctx.getRootProps(), { class: "import-wizard-container font-medium text-sm flex-grow-1 flex" }), [
            _createElementVNode("input", _normalizeProps(_guardReactiveProps(_ctx.getInputProps())), null, 16),
            (_ctx.acceptedFiles.length > 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.dropZoneText), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t("labels.importWizardInfoText")), 1))
          ], 16)
        ]),
        (_ctx.showUploadConfirmButton)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_p_button, {
                class: "p-button-sm p-button-success",
                label: _ctx.$t('buttons.confirm'),
                onClick: _ctx.handleUpload
              }, null, 8, ["label", "onClick"]),
              _createVNode(_component_p_button, {
                style: {"margin-left":"5px"},
                class: "p-button-sm p-button-danger",
                label: _ctx.$t('buttons.cancel'),
                onClick: _cache[1] || (_cache[1] = $event => (_ctx.acceptedFiles.length = 0))
              }, null, 8, ["label"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_Carousel, {
        value: _ctx.images,
        numVisible: 3,
        numScroll: 3
      }, {
        item: _withCtx((slotProps) => [
          (slotProps.data)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: 'data:' + slotProps.data.mediaType + ';base64, ' + slotProps.data.content,
                alt: slotProps.data.name
              }, null, 8, _hoisted_7))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["value"])
    ])
  ], 64))
}