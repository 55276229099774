import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid font-bold" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { class: "col-2" }
const _hoisted_5 = { class: "grid mt-3" }
const _hoisted_6 = { class: "col-4" }
const _hoisted_7 = { class: "font-bold" }
const _hoisted_8 = { class: "col-4" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "text-sm" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "col mt-2" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "grid" }
const _hoisted_17 = { class: "col" }
const _hoisted_18 = { style: {"display":"block"} }
const _hoisted_19 = { class: "w-full flex justify-content-between mt-4" }

export function render(_ctx, _cache) {
  const _component_LoadingPlugin = _resolveComponent("LoadingPlugin")
  const _component_AutoComplete = _resolveComponent("AutoComplete")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_button = _resolveComponent("p-button")
  const _component_AddSerialNumber = _resolveComponent("AddSerialNumber")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LoadingPlugin, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createElementVNode("form", {
      onSubmit: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('labels.article')), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('labels.ticket.retoure.items.shipmentQuantity')), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('labels.ticket.retoure.items.evaluationPrice')), 1),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col-2" }, null, -1))
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.retoureItems, (item, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_AutoComplete, {
                modelValue: item.articleNumber,
                "onUpdate:modelValue": $event => ((item.articleNumber) = $event),
                placeholder: _ctx.$t('labels.article'),
                suggestions: _ctx.filteredProducts,
                class: _normalizeClass([{'p-invalid':_ctx.v$.retoureItems.$each.$response.$data && _ctx.v$.retoureItems.$each.$response.$data[index].articleNumber.$invalid}, "w-full"]),
                onComplete: _cache[1] || (_cache[1] = $event => (_ctx.searchProducts($event))),
                onItemSelect: $event => (_ctx.dropdownSelect(index, $event)),
                dropdown: true,
                optionLabel: "label",
                optionValue: "value",
                forceSelection: ""
              }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "suggestions", "class", "onItemSelect"]),
              (_ctx.v$.retoureItems.$each.$response.$errors)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.retoureItems.$each.$response.$errors[index].articleNumber, (error) => {
                    return (_openBlock(), _createElementBlock("small", {
                      style: {"display":"block"},
                      class: "p-error",
                      key: error
                    }, _toDisplayString(error.$message), 1))
                  }), 128))
                : _createCommentVNode("", true),
              (_ctx.v$.retoureItems.$each.$response.$errors)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.retoureItems.$each.$response.$errors[index].article, (error) => {
                    return (_openBlock(), _createElementBlock("small", {
                      style: {"display":"block"},
                      class: "p-error",
                      key: error
                    }, _toDisplayString(error.$message), 1))
                  }), 128))
                : _createCommentVNode("", true),
              _createElementVNode("small", _hoisted_7, _toDisplayString(item.orderItem?.title || item.article?.name), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_InputNumber, {
                locale: _ctx.locale,
                class: _normalizeClass({'p-invalid':_ctx.v$.retoureItems.$each.$response.$data && _ctx.v$.retoureItems.$each.$response.$data[index].shipmentQuantity.$invalid}),
                autocomplete: "off",
                modelValue: item.shipmentQuantity,
                "onUpdate:modelValue": $event => ((item.shipmentQuantity) = $event),
                disabled: item.serialNumberRequired,
                style: {"width":"85%"}
              }, null, 8, ["locale", "class", "modelValue", "onUpdate:modelValue", "disabled"]),
              _cache[5] || (_cache[5] = _createTextVNode(" Stk. ")),
              (item.serialNumberRequired)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "pi pi-plus-circle text-green-600",
                    onClick: $event => (_ctx.openAddSerialNumberDialog(index, item))
                  }, null, 8, _hoisted_9))
                : _createCommentVNode("", true),
              (item.serialNumberRequired && _ctx.v$.retoureItems.$each.$response.$errors && _ctx.v$.retoureItems.$each.$response.$errors[index].shipmentQuantity.length < 1)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.selectedSerialNumbers, (element) => {
                      return (_openBlock(), _createElementBlock("li", _hoisted_11, [
                        _createTextVNode(_toDisplayString(element) + " ", 1),
                        _createElementVNode("span", {
                          class: "pi pi-times-circle mt-1 text-red-600",
                          onClick: $event => (_ctx.removeSerialNumberItem(index, element, $event))
                        }, null, 8, _hoisted_12)
                      ]))
                    }), 256))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.v$.retoureItems.$each.$response.$errors)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.v$.retoureItems.$each.$response.$errors[index].shipmentQuantity, (error) => {
                    return (_openBlock(), _createElementBlock("small", {
                      style: {"display":"block"},
                      class: "p-error",
                      key: error
                    }, _toDisplayString(error.$message), 1))
                  }), 128))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_InputNumber, {
                mode: "decimal",
                locale: _ctx.locale,
                class: _normalizeClass([{'p-invalid':_ctx.v$.retoureItems.$each.$response.$data && _ctx.v$.retoureItems.$each.$response.$data[index].evaluationPrice.$invalid}, "w-full"]),
                maxFractionDigits: 2,
                autocomplete: "off",
                modelValue: item.evaluationPrice,
                "onUpdate:modelValue": $event => ((item.evaluationPrice) = $event)
              }, null, 8, ["locale", "class", "modelValue", "onUpdate:modelValue"]),
              (_ctx.v$.retoureItems.$each.$response.$errors)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.retoureItems.$each.$response.$errors[index].evaluationPrice, (error) => {
                    return (_openBlock(), _createElementBlock("small", {
                      class: "p-error",
                      key: error
                    }, _toDisplayString(error.$message), 1))
                  }), 128))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_14, [
              (_ctx.state.retoureItems.length - 1 <= index)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "pi pi-plus mr-2 text-green-600",
                    style: {"cursor":"pointer"},
                    onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.addRetoureItem && _ctx.addRetoureItem(...args)))
                  }))
                : _createCommentVNode("", true),
              (_ctx.state.retoureItems.length > 1 || index > 0)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: "pi pi-trash text-red-600",
                    style: {"cursor":"pointer"},
                    onClick: $event => {_ctx.removeRetoureItem(index);}
                  }, null, 8, _hoisted_15))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("small", _hoisted_18, _toDisplayString(_ctx.stripTagsAndTruncate(item.orderItem?.description ||
                    item.article?.description)), 1)
            ])
          ])
        ], 64))
      }), 128)),
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_p_button, {
          severity: "danger",
          textContent: _toDisplayString(_ctx.$t('buttons.back')),
          onClick: _ctx.onBackButtonClicked
        }, null, 8, ["textContent", "onClick"]),
        _createVNode(_component_p_button, {
          severity: "success",
          textContent: _toDisplayString(_ctx.$t('buttons.next')),
          disabled: _ctx.savingInProgress,
          type: "submit"
        }, null, 8, ["textContent", "disabled"])
      ])
    ], 32),
    _createVNode(_component_AddSerialNumber, {
      "display-add-serial-number-dialog": _ctx.showAddSerialNumberDialog,
      "row-index": _ctx.clickedRowIndex,
      "warehouse-stock-movement-filters": _ctx.addSerialNumberDialogFilters,
      "article-id": _ctx.clickedRowArticleId,
      onCloseDialog: _ctx.closeDialogListener
    }, null, 8, ["display-add-serial-number-dialog", "row-index", "warehouse-stock-movement-filters", "article-id", "onCloseDialog"])
  ], 64))
}