import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-1"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_p_button = _resolveComponent("p-button")
  const _component_Tag = _resolveComponent("Tag")
  const _component_Column = _resolveComponent("Column")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_AddEditTicketTemplateDialog = _resolveComponent("AddEditTicketTemplateDialog")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DataTable, {
      class: "p-datatable-sm text-sm",
      stripedRows: "",
      rowClass: _ctx.rowClass,
      paginator: "",
      rows: 20,
      scrollable: "",
      scrollHeight: "calc(100vh - 23rem)",
      ref: "dt",
      dataKey: "id",
      filters: _ctx.filters,
      "onUpdate:filters": _cache[1] || (_cache[1] = $event => ((_ctx.filters) = $event)),
      filterDisplay: "row",
      totalRecords: _ctx.ticketTemplates?.length || 0,
      value: _ctx.ticketTemplates,
      responsiveLayout: "scroll",
      "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
      "rows-per-page-options": [10,20,50],
      "current-page-report-template": "{first} to {last} of {totalRecords}"
    }, {
      header: _withCtx(() => [
        _createVNode(_component_p_button, {
          severity: "success",
          class: "text-sm",
          onClick: _cache[0] || (_cache[0] = $event => {_ctx.selectedTemplate = null;_ctx.showTemplateDialog = true})
        }, {
          default: _withCtx(() => [
            _createTextVNode(" + " + _toDisplayString(_ctx.$t("labels.template")), 1)
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "title",
          sortable: "",
          style: {"max-width":"fit-content"},
          header: _ctx.$t('labels.subject')
        }, {
          body: _withCtx(({data}) => [
            _createTextVNode(_toDisplayString(data.title) + " ", 1),
            (data.id && data.public)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_Tag, { severity: "success" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.public')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "content",
          style: {"max-width":"fit-content"},
          header: _ctx.$t('labels.content')
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("div", {
              innerHTML: data.content
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "section",
          style: {"max-width":"fit-content"},
          header: "Bereich",
          filterMatchModeOptions: [{label: 'Equals', value: _ctx.sectionFilter}]
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_p_dropdown, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
              options: [{'label': _ctx.getSectionLabel('comments'), value: 'comments'}, {'label': _ctx.getSectionLabel('retoure'), value: 'retoure'}, {'label': _ctx.getSectionLabel('compensationShipment'), value: 'compensationShipment'}],
              onChange: filterCallback,
              class: "p-column-filter text-sm",
              optionLabel: "label",
              optionValue: "value"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
          ]),
          body: _withCtx(({data}) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.section, (sec) => {
              return (_openBlock(), _createElementBlock("span", null, [
                _createVNode(_component_Tag, {
                  class: "ml-1 mt-1",
                  style: {"text-transform":"uppercase"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getSectionLabel(sec)), 1)
                  ]),
                  _: 2
                }, 1024)
              ]))
            }), 256))
          ]),
          _: 1
        }, 8, ["filterMatchModeOptions"]),
        _createVNode(_component_Column, {
          frozen: "",
          alignFrozen: "right",
          style: {"min-width":"4rem"}
        }, {
          body: _withCtx(({data}) => [
            _withDirectives(_createElementVNode("i", {
              class: "pi pi-pencil text-color",
              style: {"cursor":"pointer"},
              onClick: $event => {_ctx.selectedTemplate = data;_ctx.showTemplateDialog = true}
            }, null, 8, _hoisted_3), [
              [
                _directive_tooltip,
                _ctx.$t('buttons.edit'),
                void 0,
                { top: true }
              ]
            ]),
            (data.id)
              ? _withDirectives((_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "pi text-red-600 pi-times-circle ml-3",
                  style: {"cursor":"pointer"},
                  onClick: $event => (_ctx.deleteTemplateHandler(data))
                }, null, 8, _hoisted_4)), [
                  [
                    _directive_tooltip,
                    'Löschen',
                    void 0,
                    { top: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["rowClass", "filters", "totalRecords", "value"]),
    _createVNode(_component_AddEditTicketTemplateDialog, {
      "display-dialog": _ctx.showTemplateDialog,
      "selected-template": _ctx.selectedTemplate,
      onCloseDialog: _ctx.closeTemplateDialog
    }, null, 8, ["display-dialog", "selected-template", "onCloseDialog"])
  ], 64))
}