import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const add = async (
    productNumber: string,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.post(
        '/api/v1/products/' + productNumber + '/prices',
        payload
    );
};

export const edit = async (
    productNumber: string,
    priceId: string,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/products/' + productNumber + '/prices/' + priceId,
        payload
    );
};

export const remove = async (
    productNumber: string,
    priceId: string
): Promise<AxiosResponse> => {
    return apiClient.delete(
        '/api/v1/products/' + productNumber + '/prices/' + priceId
    );
};
