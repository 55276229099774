import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "layout-footer justify-content-between" }
const _hoisted_2 = { key: 0 }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("strong", null, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.copyright")) + " © " + _toDisplayString(_ctx.currentYear), 1),
        _cache[0] || (_cache[0] = _createElementVNode("a", {
          href: "https://teltec.de",
          target: "_blank",
          rel: "noopener noreferrer"
        }, [
          _createElementVNode("span", null, " Teltec")
        ], -1)),
        _cache[1] || (_cache[1] = _createElementVNode("span", null, ".", -1))
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("b", null, _toDisplayString(_ctx.$t("labels.version")), 1),
      _createElementVNode("span", null, ": " + _toDisplayString(_ctx.version), 1),
      (_ctx.serverVersion)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, " (Server: " + _toDisplayString(_ctx.serverVersion) + ")", 1))
        : _createCommentVNode("", true)
    ])
  ]))
}