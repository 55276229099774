import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "layout-topbar" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "ml-5 hny-logo"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "mr-2" }

export function render(_ctx, _cache) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_Search = _resolveComponent("Search")
  const _component_Platforms = _resolveComponent("Platforms")
  const _component_Bookmarks = _resolveComponent("Bookmarks")
  const _component_Languages = _resolveComponent("Languages")
  const _component_User = _resolveComponent("User")
  const _component_LayoutSidebar = _resolveComponent("LayoutSidebar")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        to: "/",
        class: "layout-topbar-logo"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: require('@/assets/img/' + _ctx.logoUrl+ '.png'),
            alt: "logo"
          }, null, 8, _hoisted_2)
        ]),
        _: 1
      }),
      _createElementVNode("button", {
        class: "p-link layout-menu-button layout-topbar-button",
        onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onMenuToggle && _ctx.onMenuToggle(...args)))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("i", { class: "pi pi-bars" }, null, -1)
      ])),
      _createElementVNode("button", {
        class: "p-link layout-topbar-menu-button layout-topbar-button",
        onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.onTopBarMenuButton && _ctx.onTopBarMenuButton(...args)))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("i", { class: "pi pi-ellipsis-v" }, null, -1)
      ])),
      (!_ctx.layoutConfig.darkTheme && _ctx.weekNumber === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", {
              src: require('@/assets/img/fnj.png'),
              alt: "fnj",
              style: {"height":"60px"}
            }, null, 8, _hoisted_4)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["layout-topbar-menu", _ctx.topbarMenuClasses])
      }, [
        _createElementVNode("div", null, [
          _createVNode(_component_Search)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_Platforms)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_Bookmarks)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_Languages)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_User)
        ]),
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = $event => {_ctx.layoutConfigSidebarVisible =true;}),
          class: "p-link layout-topbar-button"
        }, _cache[6] || (_cache[6] = [
          _createElementVNode("i", { class: "pi pi-cog" }, null, -1)
        ]))
      ], 2)
    ]),
    _createVNode(_component_LayoutSidebar, {
      visible: _ctx.layoutConfigSidebarVisible,
      onCloseSidebar: _cache[3] || (_cache[3] = $event => (_ctx.layoutConfigSidebarVisible =false))
    }, null, 8, ["visible"])
  ], 64))
}