import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "formgrid grid p-fluid" }
const _hoisted_3 = { class: "col field" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { class: "col field" }
const _hoisted_6 = { class: "formgrid grid p-fluid" }
const _hoisted_7 = { class: "col field" }
const _hoisted_8 = { class: "col field" }
const _hoisted_9 = { class: "mt-3 flex justify-content-end" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "grid mt-4" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["href"]
const _hoisted_16 = {
  key: 1,
  class: "pi text-green-600 pi-check-circle"
}
const _hoisted_17 = { class: "grid mt-4" }
const _hoisted_18 = { class: "col" }
const _hoisted_19 = { class: "col" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = ["href"]
const _hoisted_22 = {
  key: 1,
  class: "pi text-green-600 pi-check-circle"
}
const _hoisted_23 = { class: "grid mt-4" }
const _hoisted_24 = { class: "col" }
const _hoisted_25 = { class: "col" }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = ["href"]
const _hoisted_28 = {
  key: 1,
  class: "pi text-green-600 pi-check-circle"
}
const _hoisted_29 = { class: "grid mt-4" }
const _hoisted_30 = { class: "col" }
const _hoisted_31 = { class: "col" }
const _hoisted_32 = { key: 0 }
const _hoisted_33 = ["href"]
const _hoisted_34 = {
  key: 1,
  class: "pi text-green-600 pi-check-circle"
}
const _hoisted_35 = { class: "w-full mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_button = _resolveComponent("p-button")

  return (!_ctx.showCheckResults)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("form", {
          onSubmit: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.articleName')), 1),
              _createVNode(_component_InputText, {
                autocomplete: "off",
                modelValue: _ctx.v$.name.$model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$.name.$model) = $event)),
                class: _normalizeClass({'p-invalid':_ctx.v$.name.$invalid && _ctx.submitted})
              }, null, 8, ["modelValue", "class"]),
              ((_ctx.v$.name.$invalid && _ctx.submitted) || _ctx.v$.name.$pending.$response)
                ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.v$.name.required.$message.replace('Value',
                    'name')), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.sku')), 1),
              _createVNode(_component_InputText, {
                autocomplete: "off",
                modelValue: _ctx.v$.sku.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.sku.$model) = $event)),
                class: _normalizeClass({'p-invalid':_ctx.v$.sku.$invalid && _ctx.submitted})
              }, null, 8, ["modelValue", "class"])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.mpn')), 1),
              _createVNode(_component_InputText, {
                autocomplete: "off",
                modelValue: _ctx.v$.manufacturerPartNumber.$model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$.manufacturerPartNumber.$model) = $event)),
                class: _normalizeClass({'p-invalid':_ctx.v$.manufacturerPartNumber.$invalid && _ctx.submitted})
              }, null, 8, ["modelValue", "class"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ean')), 1),
              _createVNode(_component_InputText, {
                icon: "align-justify",
                type: "text",
                autocomplete: "off",
                modelValue: _ctx.v$.ean.$model,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.ean.$model) = $event)),
                class: _normalizeClass({'p-invalid':_ctx.v$.ean.$invalid && _ctx.submitted})
              }, null, 8, ["modelValue", "class"])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_p_button, {
              type: "submit",
              disabled: _ctx.savingInProgress,
              textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.check'))
            }, null, 8, ["disabled", "textContent"])
          ])
        ], 32)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_10, [
        _createElementVNode("div", null, [
          _createElementVNode("h5", null, _toDisplayString(_ctx.$t('labels.productAdd.checkDuplicateResult')), 1),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "grid mt-5" }, [
            _createElementVNode("div", { class: "col" }),
            _createElementVNode("div", { class: "col" })
          ], -1)),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.articleName')), 1)
            ]),
            _createElementVNode("div", _hoisted_13, [
              (_ctx.findDuplicateValue(_ctx.duplicates, 'name'))
                ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                    _createElementVNode("a", {
                      href: _ctx.findDuplicateValue(_ctx.duplicates, 'name').url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + _ctx.findDuplicateValue(_ctx.duplicates, 'name').id,
                      target: "_blank"
                    }, _toDisplayString(_ctx.findDuplicateValue(_ctx.duplicates,
                        'name').articleNumber), 9, _hoisted_15)
                  ]))
                : (_openBlock(), _createElementBlock("i", _hoisted_16))
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.mpn')), 1)
            ]),
            _createElementVNode("div", _hoisted_19, [
              (_ctx.findDuplicateValue(_ctx.duplicates, 'manufacturerPartNumber'))
                ? (_openBlock(), _createElementBlock("span", _hoisted_20, [
                    _createElementVNode("a", {
                      href: _ctx.findDuplicateValue(_ctx.duplicates, 'manufacturerPartNumber').url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + _ctx.findDuplicateValue(_ctx.duplicates, 'manufacturerPartNumber').id,
                      target: "_blank"
                    }, _toDisplayString(_ctx.findDuplicateValue(_ctx.duplicates,
                        'manufacturerPartNumber').articleNumber), 9, _hoisted_21),
                    _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" (MPN: " + _toDisplayString(_ctx.v$.manufacturerPartNumber.$model) + ")", 1)
                  ]))
                : (_openBlock(), _createElementBlock("i", _hoisted_22))
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.sku')), 1)
            ]),
            _createElementVNode("div", _hoisted_25, [
              (_ctx.findSku(_ctx.duplicates, _ctx.v$.sku.$model))
                ? (_openBlock(), _createElementBlock("span", _hoisted_26, [
                    _createElementVNode("a", {
                      href: _ctx.findSku(_ctx.duplicates, _ctx.v$.sku.$model)?.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + _ctx.findSku(_ctx.duplicates, _ctx.v$.sku.$model)?.id,
                      target: "_blank"
                    }, _toDisplayString(_ctx.findSku(_ctx.duplicates,
                        _ctx.v$.sku.$model)?.articleNumber), 9, _hoisted_27),
                    _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" (SKU: " + _toDisplayString(_ctx.v$.sku.$model) + ")", 1)
                  ]))
                : (_openBlock(), _createElementBlock("i", _hoisted_28))
            ])
          ]),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ean')), 1)
            ]),
            _createElementVNode("div", _hoisted_31, [
              (_ctx.findDuplicateValue(_ctx.duplicates, 'ean'))
                ? (_openBlock(), _createElementBlock("span", _hoisted_32, [
                    _createElementVNode("a", {
                      href: _ctx.findDuplicateValue(_ctx.duplicates, 'ean').url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + _ctx.findDuplicateValue(_ctx.duplicates, 'ean').id,
                      target: "_blank"
                    }, _toDisplayString(_ctx.findDuplicateValue(_ctx.duplicates,
                        'ean').articleNumber), 9, _hoisted_33),
                    _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" (EAN: " + _toDisplayString(_ctx.v$.ean.$model) + ")", 1)
                  ]))
                : (_openBlock(), _createElementBlock("i", _hoisted_34))
            ])
          ]),
          _createElementVNode("div", _hoisted_35, [
            _createVNode(_component_p_button, {
              severity: "danger",
              textContent: _toDisplayString(_ctx.$t('buttons.back')),
              onClick: _ctx.handleBackClick
            }, null, 8, ["textContent", "onClick"]),
            _createVNode(_component_p_button, {
              severity: "success",
              textContent: _toDisplayString(_ctx.$t('buttons.proceedToAdding')),
              disabled: !_ctx.showNextButton,
              onClick: _ctx.handleProceedToAddingClick
            }, null, 8, ["textContent", "disabled", "onClick"])
          ])
        ])
      ]))
}