<Sidebar
    v-model:visible="visible"
    position="right"
    :transitionOptions="'.3s cubic-bezier(0, 0, 0.2, 1)'"
    class="layout-config-sidebar w-20rem"
    @hide="onHide"
>
    <h5>Maßstab</h5>
    <div class="flex align-items-center">
        <p-button
            icon="pi pi-minus"
            type="button"
            @click="decrementScale()"
            class="p-button-text p-button-rounded w-2rem h-2rem mr-2"
            :disabled="layoutConfig.scale === scales[0]"
        ></p-button>
        <div class="flex gap-2 align-items-center">
            <i
                class="pi pi-circle-fill text-300"
                v-for="s in scales"
                :key="s"
                :class="{ 'text-primary-500': s === layoutConfig.scale }"
            ></i>
        </div>
        <p-button
            icon="pi pi-plus"
            type="button"
            pButton
            @click="incrementScale()"
            class="p-button-text p-button-rounded w-2rem h-2rem ml-2"
            :disabled="layoutConfig.scale === scales[scales.length - 1]"
        ></p-button>
    </div>

    <template v-if="!simple">
        <h5>Menüart</h5>
        <div class="flex">
            <div class="field-radiobutton flex-1">
                <RadioButton
                    name="menuMode"
                    value="static"
                    v-model="layoutConfig.menuMode"
                    inputId="mode1"
                ></RadioButton>
                <label for="mode1">Static</label>
            </div>

            <div class="field-radiobutton flex-1">
                <RadioButton
                    name="menuMode"
                    value="overlay"
                    v-model="layoutConfig.menuMode"
                    inputId="mode2"
                ></RadioButton>
                <label for="mode2">Overlay</label>
            </div>
        </div>
    </template>

    <h5>Layout-Skin</h5>
    <div class="flex align-items-center gap-2 mb-3">
        <img
            src="@/assets/img/themes/lara-light-teal.png"
            alt="Lara Light Teal"
            class="border-circle"
            style="width: 1.5rem"
        /><span class="font-medium">Lara</span>
    </div>
    <div class="flex align-items-center justify-content-between gap-3 mb-3">
        <p-button
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 border-primary"
            style="border-radius: 30px"
            @click="onChangeTheme('lara-light-indigo', 'light', layoutConfig.theme)"
        >
            <span
                class="block h-1rem w-full"
                style="
                    border-radius: 30px;
                    background: linear-gradient(
                        rgb(88, 95, 224) 0%,
                        rgba(88, 95, 224, 0.5) 100%
                    );
                "
            ></span>
        </p-button>

        <p-button
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 border-primary"
            style="border-radius: 30px"
            @click="onChangeTheme('lara-light-blue', 'light', layoutConfig.theme)"
        >
            <span
                class="block h-1rem w-full"
                style="
                    border-radius: 30px;
                    background: linear-gradient(
                        rgb(67, 120, 230) 0%,
                        rgba(67, 120, 230, 0.5) 100%
                    );
                "
            ></span>
        </p-button>
        <p-button
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 border-primary"
            style="border-radius: 30px"
            @click="onChangeTheme('lara-light-purple', 'light', layoutConfig.theme)"
        >
            <span
                class="block h-1rem w-full"
                style="
                    border-radius: 30px;
                    background: linear-gradient(
                        rgb(119, 88, 228) 0%,
                        rgba(119, 88, 228, 0.5) 100%
                    );
                "
            ></span>
        </p-button>
        <p-button
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 border-primary"
            style="border-radius: 30px"
            @click="onChangeTheme('lara-light-teal', 'light', layoutConfig.theme)"
        >
            <span
                class="block h-1rem w-full"
                style="
                    border-radius: 30px;
                    background: linear-gradient(
                        rgb(20, 184, 166) 0%,
                        rgba(20, 184, 166, 0.5) 100%
                    );
                "
            ></span>
        </p-button>
    </div>
    <div class="flex align-items-center gap-2 mb-3">
        <img
            src="@/assets/img/themes/lara-dark-teal.png"
            alt="Lara Light Teal"
            class="border-circle"
            style="width: 1.5rem"
        /><span class="font-medium">Lara Dark</span>
    </div>
    <div class="flex align-items-center justify-content-between gap-3 mb-3">
        <p-button
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 border-primary"
            style="border-radius: 30px"
            @click="onChangeTheme('lara-dark-indigo', 'dark', layoutConfig.theme)"
        >
            <span
                class="block h-1rem w-full"
                style="
                    border-radius: 30px;
                    background: linear-gradient(
                        rgb(88, 95, 224) 0%,
                        rgba(88, 95, 224, 0.5) 100%
                    );
                "
            ></span>
        </p-button>

        <p-button
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 border-primary"
            style="border-radius: 30px"
            @click="onChangeTheme('lara-dark-blue', 'dark', layoutConfig.theme)"
        >
            <span
                class="block h-1rem w-full"
                style="
                    border-radius: 30px;
                    background: linear-gradient(
                        rgb(67, 120, 230) 0%,
                        rgba(67, 120, 230, 0.5) 100%
                    );
                "
            ></span>
        </p-button>
        <p-button
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 border-primary"
            style="border-radius: 30px"
            @click="onChangeTheme('lara-dark-purple', 'dark', layoutConfig.theme)"
        >
            <span
                class="block h-1rem w-full"
                style="
                    border-radius: 30px;
                    background: linear-gradient(
                        rgb(119, 88, 228) 0%,
                        rgba(119, 88, 228, 0.5) 100%
                    );
                "
            ></span>
        </p-button>
        <p-button
            class="bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 border-primary"
            style="border-radius: 30px"
            @click="onChangeTheme('lara-dark-teal', 'dark', layoutConfig.theme)"
        >
            <span
                class="block h-1rem w-full"
                style="
                    border-radius: 30px;
                    background: linear-gradient(
                        rgb(20, 184, 166) 0%,
                        rgba(20, 184, 166, 0.5) 100%
                    );
                "
            ></span>
        </p-button>
    </div>
</Sidebar>
