import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, renderList as _renderList, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-bold" }
const _hoisted_2 = {
  key: 0,
  class: "mt-1 text-sm"
}
const _hoisted_3 = {
  key: 1,
  class: "mt-1 text-sm"
}
const _hoisted_4 = {
  key: 1,
  style: {"cursor":"pointer"}
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { style: {"cursor":"pointer"} }
const _hoisted_8 = { class: "w-full mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Column = _resolveComponent("Column")
  const _component_InputText = _resolveComponent("InputText")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_AutoComplete = _resolveComponent("AutoComplete")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('labels.bookIncomingGoodsItems'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[4] || (_cache[4] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw', '640px': '90vw'},
    modal: true,
    closable: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_loading, {
        active: _ctx.loading,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
      }, null, 8, ["active"]),
      _createVNode(_component_DataTable, {
        class: _normalizeClass({'p-datatable-sm': true, 'p-datatable-fixed-height': _ctx.incomingGoodsItems.length > 9}),
        stripedRows: "",
        ref: "dt",
        dataKey: "id",
        value: _ctx.incomingGoodsItems,
        responsiveLayout: "scroll",
        "current-page-report-template": "{first} to {last} of {totalRecords}",
        filters: _ctx.filters,
        "onUpdate:filters": _cache[1] || (_cache[1] = $event => ((_ctx.filters) = $event)),
        selection: _ctx.selectedItems,
        "onUpdate:selection": _cache[2] || (_cache[2] = $event => ((_ctx.selectedItems) = $event)),
        editingRows: _ctx.editingRows,
        "onUpdate:editingRows": _cache[3] || (_cache[3] = $event => ((_ctx.editingRows) = $event)),
        filterDisplay: "row",
        editMode: "row",
        onRowEditSave: _ctx.onCellEditComplete
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            selectionMode: _ctx.editingRows.length < 1 ? 'multiple' : null,
            headerStyle: "width: 3em"
          }, null, 8, ["selectionMode"]),
          _createVNode(_component_Column, {
            field: "id",
            sortable: true,
            header: _ctx.$t('labels.articleNumber'),
            style: {"min-width":"14rem"},
            filterMatchModeOptions: [{label: 'Contains', value: _ctx.articleComboFilter}]
          }, {
            filter: _withCtx(({filterModel,filterCallback}) => [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: filterModel.value,
                "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                onInput: $event => (filterCallback()),
                class: "p-column-filter text-xs",
                placeholder: '#, ' + _ctx.$t('labels.ean') + ', ' + _ctx.$t('labels.mpn')
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"])
            ]),
            body: _withCtx(({data}) => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(data.articleNumber), 1),
              _createElementVNode("div", null, _toDisplayString(data.article?.name), 1),
              (data.article?.ean)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.ean')) + " : ", 1),
                    _createElementVNode("b", null, _toDisplayString(data.article?.ean), 1)
                  ]))
                : _createCommentVNode("", true),
              (data.article?.manufacturerPartNumber)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.mpn')) + " : ", 1),
                    _createElementVNode("b", null, _toDisplayString(data.article?.manufacturerPartNumber), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header", "filterMatchModeOptions"]),
          _createVNode(_component_Column, {
            field: "quantity",
            header: _ctx.$t('labels.shipment.shipmentQuantity'),
            style: {"min-width":"9rem"}
          }, {
            editor: _withCtx(({ data, field }) => [
              _createVNode(_component_InputNumber, {
                locale: _ctx.locale,
                autocomplete: "off",
                modelValue: data.quantity,
                "onUpdate:modelValue": $event => ((data.quantity) = $event),
                showButtons: !data.serialNumberRequired,
                min: 1,
                max: data.maxQuantity,
                disabled: data.serialNumberRequired
              }, null, 8, ["locale", "modelValue", "onUpdate:modelValue", "showButtons", "max", "disabled"]),
              _createTextVNode(" " + _toDisplayString(data.unitName), 1)
            ]),
            body: _withCtx(({data}) => [
              (data.serialNumberRequired)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(data.selectedSerialNumbers.length) + " " + _toDisplayString(data.unitName), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(data.quantity) + " " + _toDisplayString(data.unitName), 1))
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "originalSerialNumbers",
            header: _ctx.$t('labels.serialNumbers'),
            style: {"min-width":"9rem"}
          }, {
            filter: _withCtx(({filterModel,filterCallback}) => [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: filterModel.value,
                "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                onInput: $event => (filterCallback()),
                class: "p-column-filter text-sm",
                placeholder: "#"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"])
            ]),
            body: _withCtx(({data}) => [
              (data.originalSerialNumbers.length > 0)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(data.originalSerialNumbers, (element, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass({'mt-2': index > 0})
                    }, [
                      _createTextVNode(_toDisplayString(element) + " ", 1),
                      (data.originalSerialNumbers.length > 1 && data.selectedSerialNumbers.includes(element))
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: "pi pi-times-circle text-red-600",
                            style: {"position":"relative","top":"1px","cursor":"pointer"},
                            onClick: $event => (_ctx.removeSerialNumberItem(data.id, element, $event))
                          }, null, 8, _hoisted_5))
                        : (data.originalSerialNumbers.length > 1)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 1,
                              class: "pi pi-plus-circle text-green-600",
                              style: {"position":"relative","top":"1px","cursor":"pointer"},
                              onClick: $event => (_ctx.addSerialNumberItem(data.id, element, $event))
                            }, null, 8, _hoisted_6))
                          : _createCommentVNode("", true)
                    ], 2))
                  }), 256))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "targetWarehouseName",
            header: _ctx.$t('labels.shipment.warehouse'),
            style: {"min-width":"6rem"}
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "targetWarehouseLevel",
            header: _ctx.$t('labels.shipment.warehouseLevel'),
            style: {"min-width":"9rem"}
          }, {
            editor: _withCtx(({ data, field }) => [
              _createVNode(_component_AutoComplete, {
                modelValue: data[field],
                "onUpdate:modelValue": $event => ((data[field]) = $event),
                suggestions: _ctx.warehouseLevels || [],
                onComplete: _ctx.searchWarehouseLevels,
                optionLabel: "label",
                optionValue: "value",
                forceSelection: "",
                placeholder: "Neuer Lagerort"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "suggestions", "onComplete"])
            ]),
            body: _withCtx(({data}) => [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.resolveWarehouseLevel(data.targetWarehouseLevel)), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            rowEditor: "",
            style: {"width":"10%","min-width":"5rem"},
            bodyStyle: "text-align:center"
          })
        ]),
        _: 1
      }, 8, ["class", "value", "filters", "selection", "editingRows", "onRowEditSave"]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_p_button, {
          severity: "danger",
          onClick: _ctx.onCancelButtonClicked
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_p_button, {
          severity: "success",
          disabled: _ctx.selectedItems < 1,
          onClick: _ctx.handleSubmit
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}