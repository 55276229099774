import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid font-bold" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "col-3" }
const _hoisted_4 = { class: "grid mt-3 text-sm" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "font-bold ml-1" }
const _hoisted_7 = { class: "col-3" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "text-sm" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "grid" }
const _hoisted_15 = { class: "col" }
const _hoisted_16 = { style: {"display":"block"} }
const _hoisted_17 = { class: "w-full flex justify-content-between mt-4" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_AutoComplete = _resolveComponent("AutoComplete")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")
  const _component_AddSerialNumber = _resolveComponent("AddSerialNumber")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.savingInProgress,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.savingInProgress) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_PrimeDialog, {
      header: _ctx.dialogHeaderLabel,
      visible: _ctx.showDialog,
      "onUpdate:visible": _cache[6] || (_cache[6] = $event => ((_ctx.showDialog) = $event)),
      closable: false,
      breakpoints: {'960px': '75vw', '640px': '90vw'},
      style: {width: '75vw'},
      modal: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _cache[5] || (_cache[5] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('labels.article')), 1),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('labels.shipment.shipmentQuantity')), 1),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col" }, "Quelllagerplatz", -1)),
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "col" }, "Ziellagerplatz", -1))
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.shipmentItems, (item, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_AutoComplete, {
                    modelValue: item.articleNumber,
                    "onUpdate:modelValue": $event => ((item.articleNumber) = $event),
                    placeholder: _ctx.$t('labels.article'),
                    suggestions: _ctx.filteredProducts,
                    class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.shipmentItems.$each.$response.$data[index].articleNumber.$invalid}),
                    onComplete: _cache[1] || (_cache[1] = $event => (_ctx.searchProducts($event))),
                    onItemSelect: $event => (_ctx.dropdownSelect(index, $event)),
                    dropdown: "",
                    optionLabel: "label",
                    optionValue: "value",
                    forceSelection: ""
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "suggestions", "class", "onItemSelect"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.shipmentItems.$each.$response.$errors[index].articleNumber, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true),
                  _createElementVNode("small", _hoisted_6, _toDisplayString(item.orderItem?.title || item.article?.name), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_InputNumber, {
                    locale: _ctx.locale,
                    class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.shipmentItems.$each.$response.$data[index].shipmentQuantity.$invalid}),
                    autocomplete: "off",
                    modelValue: item.shipmentQuantity,
                    "onUpdate:modelValue": $event => ((item.shipmentQuantity) = $event),
                    disabled: item.serialNumberRequired
                  }, null, 8, ["locale", "class", "modelValue", "onUpdate:modelValue", "disabled"]),
                  _createTextVNode(" " + _toDisplayString(item.unitName) + " ", 1),
                  (item.serialNumberRequired && typeof item.sourceStoragePlaceId === 'object' && item.sourceStoragePlaceId !== null)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: "pi pi-plus-circle text-green-600",
                        onClick: $event => (_ctx.openAddSerialNumberDialog(index, item))
                      }, null, 8, _hoisted_8))
                    : _createCommentVNode("", true),
                  (item.selectedSerialNumbers.length > 0)
                    ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.selectedSerialNumbers, (element) => {
                          return (_openBlock(), _createElementBlock("li", _hoisted_10, [
                            _createTextVNode(_toDisplayString(element) + " ", 1),
                            _createElementVNode("span", {
                              class: "pi pi-times-circle text-red-600 mt-1",
                              onClick: $event => (_ctx.removeSerialNumberItem(index, element, $event))
                            }, null, 8, _hoisted_11)
                          ]))
                        }), 256))
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.v$.shipmentItems.$each.$response.$errors[index].shipmentQuantity, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.v$.shipmentItems.$each.$response.$errors[index].selectedSerialNumbers, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", null, [
                    (_ctx.isCustomerCompensationShipment)
                      ? (_openBlock(), _createBlock(_component_p_dropdown, {
                          key: 0,
                          modelValue: _ctx.customerCompensationSourceWarehouseId,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.customerCompensationSourceWarehouseId) = $event)),
                          options: _ctx.warehouses || [],
                          optionLabel: "name",
                          optionValue: "id",
                          onChange: $event => (item.sourceStoragePlaceId = null),
                          class: "mb-3 w-full"
                        }, null, 8, ["modelValue", "options", "onChange"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_AutoComplete, {
                      modelValue: item.sourceStoragePlaceId,
                      "onUpdate:modelValue": $event => ((item.sourceStoragePlaceId) = $event),
                      suggestions: _ctx.filteredSourceStoragePlaces,
                      class: _normalizeClass([{'p-invalid':_ctx.submitted && _ctx.v$.shipmentItems.$each.$response.$data[index].sourceStoragePlaceId.$invalid}, "w-full"]),
                      onComplete: _cache[3] || (_cache[3] = $event => (_ctx.searchSourceStoragePlaces($event))),
                      forceSelection: "",
                      inputClass: "w-full",
                      optionLabel: "label"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "suggestions", "class"]),
                    (_ctx.submitted)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.shipmentItems.$each.$response.$errors[index].sourceStoragePlaceId, (error) => {
                          return (_openBlock(), _createElementBlock("small", {
                            style: {"display":"block"},
                            class: "p-error",
                            key: error
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_AutoComplete, {
                    modelValue: item.destinationStoragePlaceId,
                    "onUpdate:modelValue": $event => ((item.destinationStoragePlaceId) = $event),
                    suggestions: _ctx.filteredDestinationStoragePlaces,
                    class: _normalizeClass([{'p-invalid':_ctx.submitted && _ctx.v$.shipmentItems.$each.$response.$data[index].destinationStoragePlaceId.$invalid}, "w-full"]),
                    onComplete: _cache[4] || (_cache[4] = $event => (_ctx.searchDestinationStoragePlaces($event))),
                    forceSelection: "",
                    inputClass: "w-full",
                    optionLabel: "label"
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "suggestions", "class"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.shipmentItems.$each.$response.$errors[index].destinationStoragePlaceId, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("small", _hoisted_16, _toDisplayString(_ctx.stripTagsAndTruncate(item.orderItem?.description ||
                        item.article?.description)), 1)
                ])
              ])
            ], 64))
          }), 128)),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_p_button, {
              severity: "danger",
              textContent: _toDisplayString(_ctx.$t('buttons.cancel')),
              onClick: _ctx.onCancelButtonClicked
            }, null, 8, ["textContent", "onClick"]),
            _createVNode(_component_p_button, {
              severity: "success",
              textContent: _toDisplayString(_ctx.$t('buttons.confirm')),
              type: "submit"
            }, null, 8, ["textContent"])
          ])
        ], 32)
      ]),
      _: 1
    }, 8, ["header", "visible"]),
    _createVNode(_component_AddSerialNumber, {
      "display-add-serial-number-dialog": _ctx.showAddSerialNumberDialog,
      "row-index": _ctx.clickedRowIndex,
      "warehouse-stock-movement-filters": _ctx.addSerialNumberDialogFilters,
      "article-id": _ctx.clickedRow?.article?.id,
      onCloseDialog: _ctx.closeDialogListener
    }, null, 8, ["display-add-serial-number-dialog", "row-index", "warehouse-stock-movement-filters", "article-id", "onCloseDialog"])
  ], 64))
}