import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex justify-content-end" }
const _hoisted_2 = { class: "mr-3" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["href"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "pi pi-search-plus text-color" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "font-bold" }
const _hoisted_13 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputSwitch = _resolveComponent("InputSwitch")
  const _component_FilterMenu = _resolveComponent("FilterMenu")
  const _component_Column = _resolveComponent("Column")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_router_link = _resolveComponent("router-link")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.tickets')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("labels.allEnvironments")), 1),
          _createElementVNode("div", null, [
            _createVNode(_component_InputSwitch, {
              modelValue: _ctx.allEnvs,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.allEnvs) = $event)),
              onInput: _ctx.onAllEnvsSwitch
            }, null, 8, ["modelValue", "onInput"])
          ])
        ]),
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          paginator: "",
          rows: 20,
          scrollable: "",
          scrollHeight: "calc(100vh - 25rem)",
          lazy: "",
          ref: "dt",
          dataKey: "id",
          totalRecords: _ctx.totalRecords,
          value: _ctx.tickets,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50],
          "current-page-report-template": "{first} to {last} of {totalRecords}",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[2] || (_cache[2] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          onPage: _cache[3] || (_cache[3] = $event => (_ctx.onPage($event))),
          onFilter: _cache[4] || (_cache[4] = $event => (_ctx.onFilter($event))),
          onSort: _cache[5] || (_cache[5] = $event => (_ctx.onSort($event))),
          onStateRestore: _ctx.onStateRestore,
          expandedRows: _ctx.expandedRows,
          "onUpdate:expandedRows": _cache[6] || (_cache[6] = $event => ((_ctx.expandedRows) = $event)),
          stateStorage: "local",
          stateKey: "tickets-list-state-session"
        }, {
          expansion: _withCtx((slotProps) => [
            (slotProps.data.description)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  innerHTML: slotProps.data.description
                }, null, 8, _hoisted_10))
              : (_openBlock(), _createElementBlock("div", _hoisted_11, "No description available."))
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              expander: true,
              style: {"min-width":"4rem"},
              class: "tt-filter-menu-th"
            }, {
              filter: _withCtx(() => [
                _createVNode(_component_FilterMenu, {
                  "parent-component-id": "tickets-list",
                  "parent-filters": _ctx.filters,
                  onClearFilters: _ctx.clearFilters,
                  onApplyFilters: _ctx.applyFilters
                }, null, 8, ["parent-filters", "onClearFilters", "onApplyFilters"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "ticketNumber",
              sortable: !_ctx.allEnvs,
              style: {"min-width":"9rem"},
              header: _ctx.$t('labels.ticketNumber'),
              filterMatchModeOptions: _ctx.matchModesNumeric
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: "#"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("a", {
                  href: data.url + 'webapp/view/ticketing/ticketDetail.page?entityId=' + data.id,
                  target: "_blank"
                }, _toDisplayString(data.ticketNumber), 9, _hoisted_3)
              ]),
              _: 1
            }, 8, ["sortable", "header", "filterMatchModeOptions"]),
            (_ctx.allEnvs)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 0,
                  field: "platform",
                  header: _ctx.$t('labels.environment'),
                  style: {"min-width":"8rem"}
                }, {
                  body: _withCtx(({data}) => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.getPlatformCellContent(data.platform)
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Column, { style: {"min-width":"4rem"} }, {
              body: _withCtx(({data}) => [
                (data.comments.length > 0)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      style: {"cursor":"pointer"},
                      onClick: $event => (_ctx.showCommentsDialog(data))
                    }, [
                      _cache[8] || (_cache[8] = _createElementVNode("i", {
                        class: "pi pi-comments",
                        style: {"padding-right":"5px"}
                      }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(data.comments.length), 1)
                    ], 8, _hoisted_5))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "createdDate",
              dataType: "date",
              sortable: true,
              header: _ctx.$t('labels.createdAt'),
              style: {"min-width":"10rem"}
            }, {
              filter: _withCtx(({filterModel}) => [
                _createVNode(_component_Calendar, {
                  class: "p-column-filter text-xs",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  dateFormat: "dd.mm.yy"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              body: _withCtx((data) => [
                _createTextVNode(_toDisplayString(data.data.createdDateFormatted), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "subject",
              header: _ctx.$t('labels.subject'),
              sortable: !_ctx.allEnvs,
              style: {"min-width":"12rem"}
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: _ctx.$t('labels.subject')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("a", {
                  href: data.url + 'webapp/view/ticketing/ticketDetail.page?entityId=' + data.id,
                  target: "_blank"
                }, _toDisplayString(data.subject), 9, _hoisted_6)
              ]),
              _: 1
            }, 8, ["header", "sortable"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('labels.status'),
              style: {"min-width":"10rem"},
              field: "ticketStatus",
              sortable: !_ctx.allEnvs,
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.getStatusCellStyle(data.ticketStatus))
                }, _toDisplayString(data.ticketStatus), 3)
              ]),
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_Dropdown, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  options: ['Noch nicht zugewiesen', 'Bestätigt', 'Gerät in interner Reparatur', 'Zugewiesen', 'In Bearbeitung', 'Wartend', 'Warten auf Kundenreaktion', 'Gelöst', 'Kunde wartet auf Reaktion', 'Keine Lösung', 'Kunde wartet auf Gutschrift', 'Geschlossen', 'Warten auf Einsendung'],
                  placeholder: "Any",
                  onChange: $event => (filterCallback()),
                  class: "p-column-filter text-sm",
                  showClear: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              _: 1
            }, 8, ["header", "sortable"]),
            _createVNode(_component_Column, {
              field: "assignedUser",
              style: {"min-width":"10rem"},
              header: _ctx.$t('labels.assignedUser'),
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_Dropdown, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  options: _ctx.userOptions,
                  placeholder: "Any",
                  optionLabel: "name",
                  optionValue: "id",
                  class: "p-column-filter text-sm",
                  showClear: false,
                  onChange: $event => (filterCallback()),
                  filter: true
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "customer",
              header: _ctx.$t('labels.customer'),
              style: {"min-width":"10rem"}
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: _ctx.$t('labels.customer')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                (data.customer?.company)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(data.customer.company), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(data.customer?.firstName) + " " + _toDisplayString(data.customer?.lastName), 1))
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              style: {"min-width":"10rem"},
              field: "email",
              header: _ctx.$t('labels.email'),
              sortable: !_ctx.allEnvs
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm",
                  placeholder: _ctx.$t('labels.email')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              _: 1
            }, 8, ["header", "sortable"]),
            _createVNode(_component_Column, {
              style: 'min-width: 100px; max-width: fit-content',
              frozen: true,
              alignFrozen: "right"
            }, {
              body: _withCtx(({data}) => [
                _createVNode(_component_router_link, {
                  to: {
                                    name: 'TicketDetails',
                                    params: {id: data.ticketNumber}
                                }
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("i", _hoisted_9, null, 512), [
                      [
                        _directive_tooltip,
                        _ctx.$t('labels.ticketDetails'),
                        void 0,
                        { left: true }
                      ]
                    ])
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["totalRecords", "value", "filters", "onStateRestore", "expandedRows"])
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_PrimeDialog, {
      visible: _ctx.commentsDialogVisible,
      "onUpdate:visible": _cache[7] || (_cache[7] = $event => ((_ctx.commentsDialogVisible) = $event)),
      header: _ctx.$t('labels.ticketNumber') + (_ctx.comments?.ticketNumber || ''),
      breakpoints: {'960px': '75vw'},
      style: {width: '50vw'},
      onHide: _ctx.hideCommentsDialog,
      modal: true,
      dismissableMask: true
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.comments?.comments || []), (comment) => {
          return (_openBlock(), _createBlock(_component_Panel, { class: "small mb-3" }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_12, _toDisplayString(comment.author) + " at " + _toDisplayString(comment.createdDate), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.removeStyles(comment.htmlComment || comment.comment)
              }, null, 8, _hoisted_13)
            ]),
            _: 2
          }, 1024))
        }), 256))
      ]),
      _: 1
    }, 8, ["visible", "header", "onHide"])
  ], 64))
}