import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid formgrid p-fluid" }
const _hoisted_2 = { class: "field col" }
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = {
  key: 1,
  class: "p-error"
}
const _hoisted_5 = { class: "flex justify-content-end" }

export function render(_ctx, _cache) {
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_button = _resolveComponent("p-button")
  const _component_p_dialog = _resolveComponent("p-dialog")

  return (_openBlock(), _createBlock(_component_p_dialog, {
    header: _ctx.$t('labels.addVideo'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[2] || (_cache[2] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw'},
    style: {width: '50vw'},
    onHide: _ctx.closeDialog,
    modal: true,
    class: "text-sm"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[3] || (_cache[3] = _createElementVNode("label", null, "Url", -1)),
            _createVNode(_component_InputText, {
              autocomplete: "off",
              modelValue: _ctx.v$.url.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$.url.$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$.url.$invalid && _ctx.submitted})
            }, null, 8, ["modelValue", "class"]),
            (((_ctx.v$.url.required.$invalid && _ctx.submitted) || _ctx.v$.url.$pending.$response))
              ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.v$.url.required.$message.replace('Value', 'Url')), 1))
              : _createCommentVNode("", true),
            (((_ctx.v$.url.url.$invalid && _ctx.submitted) || _ctx.v$.url.$pending.$response))
              ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.v$.url.url.$message.replace('Value', 'Url')), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_p_button, {
            disabled: _ctx.savingInProgress,
            severity: "success",
            textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.confirm')),
            type: "submit"
          }, null, 8, ["disabled", "textContent"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["header", "visible", "onHide"]))
}