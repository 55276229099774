import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, normalizeStyle as _normalizeStyle, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex justify-content-between w-full" }
const _hoisted_2 = {
  key: 0,
  class: "text-lg font-bold"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = { style: {"text-transform":"uppercase"} }
const _hoisted_5 = { class: "mt-2" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "font-semibold" }
const _hoisted_8 = {
  key: 1,
  class: "spacer-dot"
}
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "font-semibold" }
const _hoisted_11 = { class: "grid text-lg" }
const _hoisted_12 = { class: "col-12 lg:col-6 xl:col-4" }
const _hoisted_13 = { class: "card mb-0" }
const _hoisted_14 = {
  key: 0,
  class: "list-none p-0 m-0"
}
const _hoisted_15 = { class: "grid" }
const _hoisted_16 = { class: "col" }
const _hoisted_17 = { class: "font-medium mr-2 mb-1 md:mb-0 text-blue-400" }
const _hoisted_18 = { class: "mt-2 md:mt-0 col flex justify-content-start align-items-center" }
const _hoisted_19 = { class: "text-blue-400 ml-3 font-medium" }
const _hoisted_20 = { class: "grid" }
const _hoisted_21 = { class: "col" }
const _hoisted_22 = { class: "font-medium mr-2 mb-1 md:mb-0 text-green-400" }
const _hoisted_23 = { class: "mt-2 md:mt-0 col flex justify-content-start align-items-center" }
const _hoisted_24 = { class: "text-green-400 ml-3 font-medium" }
const _hoisted_25 = { class: "grid" }
const _hoisted_26 = { class: "col" }
const _hoisted_27 = { class: "font-medium mr-2 mb-1 md:mb-0 text-orange-400" }
const _hoisted_28 = { class: "mt-2 md:mt-0 col flex justify-content-start align-items-center" }
const _hoisted_29 = { class: "text-orange-400 ml-3 font-medium" }
const _hoisted_30 = { class: "col-12 lg:col-6 xl:col-4" }
const _hoisted_31 = { class: "card mb-0" }
const _hoisted_32 = {
  key: 0,
  class: "list-none p-0 m-0"
}
const _hoisted_33 = { class: "grid" }
const _hoisted_34 = { class: "col" }
const _hoisted_35 = { class: "font-medium mr-2 mb-1 md:mb-0 text-green-500" }
const _hoisted_36 = { class: "mt-2 md:mt-0 col flex justify-content-start align-items-center" }
const _hoisted_37 = {
  class: "surface-300 border-round overflow-hidden w-10rem lg:w-6rem",
  style: {"height":"8px"}
}
const _hoisted_38 = { class: "text-green-500 ml-3 font-medium" }
const _hoisted_39 = { class: "grid" }
const _hoisted_40 = { class: "col" }
const _hoisted_41 = { class: "font-medium mr-2 mb-1 md:mb-0 text-orange-500" }
const _hoisted_42 = { class: "mt-2 md:mt-0 col flex justify-content-start align-items-center" }
const _hoisted_43 = {
  class: "surface-300 border-round overflow-hidden w-10rem lg:w-6rem",
  style: {"height":"8px"}
}
const _hoisted_44 = { class: "text-orange-500 ml-3 font-medium" }
const _hoisted_45 = { class: "grid" }
const _hoisted_46 = { class: "col" }
const _hoisted_47 = { class: "font-medium mr-2 mb-1 md:mb-0 text-purple-500" }
const _hoisted_48 = { class: "mt-2 md:mt-0 col flex justify-content-start align-items-center" }
const _hoisted_49 = {
  class: "surface-300 border-round overflow-hidden w-10rem lg:w-6rem",
  style: {"height":"8px"}
}
const _hoisted_50 = { class: "text-purple-500 ml-3 font-medium" }
const _hoisted_51 = { class: "grid" }
const _hoisted_52 = { class: "col" }
const _hoisted_53 = { class: "font-medium mr-2 mb-1 md:mb-0 text-blue-500" }
const _hoisted_54 = { class: "mt-2 md:mt-0 col flex justify-content-start align-items-center" }
const _hoisted_55 = {
  class: "surface-300 border-round overflow-hidden w-10rem lg:w-6rem",
  style: {"height":"8px"}
}
const _hoisted_56 = { class: "text-blue-500 ml-3 font-medium" }
const _hoisted_57 = { class: "col-12 lg:col-6 xl:col-4" }
const _hoisted_58 = { class: "card mb-0" }
const _hoisted_59 = { class: "w-full flex justify-content-between mb-3" }
const _hoisted_60 = { class: "mb-3" }
const _hoisted_61 = {
  key: 0,
  class: "text-900 font-medium text-xl"
}
const _hoisted_62 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_63 = {
  key: 0,
  class: "text-blue-500 font-medium"
}
const _hoisted_64 = { class: "text-green-500 font-medium mr-2" }
const _hoisted_65 = { class: "text-500" }
const _hoisted_66 = { class: "text-green-500 font-medium mr-2" }
const _hoisted_67 = { class: "text-500" }
const _hoisted_68 = { class: "grid" }
const _hoisted_69 = { class: "col-12 lg:col-6 xl:col-6" }
const _hoisted_70 = { class: "card mb-0" }
const _hoisted_71 = { class: "col-12 lg:col-6 xl:col-6" }
const _hoisted_72 = { class: "card mb-0" }
const _hoisted_73 = { class: "card" }
const _hoisted_74 = { class: "grid" }
const _hoisted_75 = { class: "col" }
const _hoisted_76 = { class: "flex justify-content-between mb-5" }
const _hoisted_77 = {
  key: 0,
  class: "flex"
}
const _hoisted_78 = {
  key: 0,
  class: "list-none p-0 m-0"
}
const _hoisted_79 = { class: "mt-1 text-600" }
const _hoisted_80 = { class: "text-sm" }
const _hoisted_81 = { class: "mt-2 md:mt-0 flex align-items-center" }
const _hoisted_82 = {
  class: "surface-300 border-round overflow-hidden w-10rem lg:w-6rem",
  style: {"height":"8px"}
}
const _hoisted_83 = ["onClick"]
const _hoisted_84 = { class: "col flex align-items-center justify-content-center" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Tag = _resolveComponent("Tag")
  const _component_p_button = _resolveComponent("p-button")
  const _component_Chart = _resolveComponent("Chart")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        (_ctx.parentData?.supplierId)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createTextVNode(_toDisplayString(_ctx.parentData?.supplierConfiguration?.supplierName) + " [", 1),
              _createElementVNode("a", {
                href: _ctx.url + 'webapp/view/party/PartyDetail.page?entityId=' + _ctx.parentData.supplierId,
                target: "_blank"
              }, _toDisplayString(_ctx.parentData?.supplierConfiguration?.supplierNumber), 9, _hoisted_3),
              _cache[7] || (_cache[7] = _createTextVNode("] "))
            ]))
          : _createCommentVNode("", true),
        (_ctx.parentData?.manufacturerName)
          ? (_openBlock(), _createBlock(_component_Tag, { key: 1 }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('labels.manufacturer')) + ": " + _toDisplayString(_ctx.parentData.manufacturerName), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.parentData?.uploadedBy)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.uploadedBy')) + ": ", 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.parentData.uploadedBy), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.parentData?.processingEndedAt)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8))
            : _createCommentVNode("", true),
          (_ctx.parentData?.processingEndedAt)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.importedAt')) + ": ", 1),
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.parentData.processingEndedAt), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_p_button, {
          textContent: _toDisplayString(_ctx.$t('labels.importLog')),
          onClick: _ctx.onBackToListClick
        }, null, 8, ["textContent", "onClick"]),
        (!_ctx.parentData?.importInProgress && _ctx.parentData?.processingEndedAt && _ctx.parentData?.statistics?.regularItemsCount > _ctx.parentData?.statistics?.handledItemsCount)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_p_button, {
                class: "ml-3",
                severity: "danger",
                icon: "fas fa-play",
                onClick: _ctx.runManually
              }, null, 8, ["onClick"]),
              (!_ctx.parentData?.lastRunAtTimestamp)
                ? (_openBlock(), _createBlock(_component_p_button, {
                    key: 0,
                    class: "ml-3",
                    severity: "warning",
                    onClick: _ctx.rejectImport,
                    textContent: _toDisplayString(_ctx.$t('buttons.rejectImport'))
                  }, null, 8, ["onClick", "textContent"]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_ScrollPanel, {
      style: {"height":"86vh"},
      class: "mt-5 w-full"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              (_ctx.parentData && _ctx.logs.length > 0)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_14, [
                    _createElementVNode("li", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('labels.priceImport.totalSkus')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", {
                          class: "surface-300 border-round overflow-hidden",
                          style: _normalizeStyle([{"height":"8px"}, 'width: ' + (6 * (_ctx.parentData.itemsCount) / Math.max(_ctx.parentData.itemsCount, _ctx.totalRecords - _ctx.totalIrregular, _ctx.totalEol)) + 'rem'])
                        }, _cache[8] || (_cache[8] = [
                          _createElementVNode("div", { class: "h-full bg-blue-400 w-full" }, null, -1)
                        ]), 4),
                        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.parentData.itemsCount), 1)
                      ])
                    ]),
                    _createElementVNode("li", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('labels.priceImport.mappedSkus')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("div", {
                          class: "surface-300 border-round overflow-hidden",
                          style: _normalizeStyle([{"height":"8px"}, 'width: ' + (6 * (_ctx.totalRecords - _ctx.totalIrregular) / Math.max(_ctx.parentData.itemsCount, _ctx.totalRecords - _ctx.totalIrregular, _ctx.totalEol)) + 'rem'])
                        }, _cache[9] || (_cache[9] = [
                          _createElementVNode("div", { class: "h-full bg-green-400 w-full" }, null, -1)
                        ]), 4),
                        _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.totalRecords - _ctx.totalIrregular), 1)
                      ])
                    ]),
                    _createElementVNode("li", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t('labels.priceImport.eolProducts')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", {
                          class: "surface-300 border-round overflow-hidden",
                          style: _normalizeStyle([{"height":"8px"}, 'width: ' + (6 * (_ctx.totalEol) / Math.max(_ctx.parentData.itemsCount, _ctx.totalRecords - _ctx.totalIrregular, _ctx.totalEol)) + 'rem'])
                        }, _cache[10] || (_cache[10] = [
                          _createElementVNode("div", { class: "h-full bg-orange-400 w-full" }, null, -1)
                        ]), 4),
                        _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.totalEol), 1),
                        _createElementVNode("i", {
                          onClick: _cache[1] || (_cache[1] = $event => (_ctx.onEolDetailsClick())),
                          class: "pi pi-external-link text-sm ml-2 text-orange-400",
                          style: {"cursor":"pointer"}
                        }),
                        _cache[11] || (_cache[11] = _createElementVNode("small", null, null, -1))
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              (_ctx.parentData && _ctx.logs.length > 0)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_32, [
                    _createElementVNode("li", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, [
                        _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.$t('labels.priceImport.unchangedProducts')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_36, [
                        _createElementVNode("div", _hoisted_37, [
                          _createElementVNode("div", {
                            class: "h-full bg-green-500",
                            style: _normalizeStyle('width: ' + (_ctx.totalNonChanged / (_ctx.totalRecords - _ctx.totalIrregular) * 100).toFixed(2) + '%')
                          }, null, 4)
                        ]),
                        _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.totalNonChanged) + " (" + _toDisplayString((_ctx.totalNonChanged /
                                (_ctx.totalRecords - _ctx.totalIrregular) *
                                100).toFixed(2)) + " %)", 1),
                        _createElementVNode("i", {
                          onClick: _cache[2] || (_cache[2] = $event => (_ctx.onMultipleStatusChangeDetailsClick([-1]))),
                          class: "pi pi-external-link text-sm ml-2 text-green-500",
                          style: {"cursor":"pointer"}
                        })
                      ])
                    ]),
                    _createElementVNode("li", _hoisted_39, [
                      _createElementVNode("div", _hoisted_40, [
                        _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.$t('labels.priceImport.costPriceChangeProducts')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_42, [
                        _createElementVNode("div", _hoisted_43, [
                          _createElementVNode("div", {
                            class: "h-full bg-orange-500",
                            style: _normalizeStyle('width: ' + (_ctx.totalCostPriceChanged / (_ctx.totalRecords - _ctx.totalIrregular) * 100).toFixed(2) + '%')
                          }, null, 4)
                        ]),
                        _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.totalCostPriceChanged) + " (" + _toDisplayString((_ctx.totalCostPriceChanged / (_ctx.totalRecords -
                                _ctx.totalIrregular) * 100).toFixed(2)) + " %)", 1),
                        _createElementVNode("i", {
                          onClick: _cache[3] || (_cache[3] = $event => (_ctx.onMultipleStatusChangeDetailsClick([16]))),
                          class: "pi pi-external-link text-sm ml-2 text-orange-500",
                          style: {"cursor":"pointer"}
                        })
                      ])
                    ]),
                    _createElementVNode("li", _hoisted_45, [
                      _createElementVNode("div", _hoisted_46, [
                        _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.$t('labels.priceImport.listPriceChangeProducts')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_48, [
                        _createElementVNode("div", _hoisted_49, [
                          _createElementVNode("div", {
                            class: "h-full bg-purple-500",
                            style: _normalizeStyle('width: ' + (_ctx.totalListPriceChanged / (_ctx.totalRecords - _ctx.totalIrregular) * 100).toFixed(2) + '%')
                          }, null, 4)
                        ]),
                        _createElementVNode("span", _hoisted_50, _toDisplayString(_ctx.totalListPriceChanged) + " (" + _toDisplayString((_ctx.totalListPriceChanged / (_ctx.totalRecords -
                                _ctx.totalIrregular) * 100).toFixed(2)) + " %)", 1),
                        _createElementVNode("i", {
                          onClick: _cache[4] || (_cache[4] = $event => (_ctx.onMultipleStatusChangeDetailsClick([4]))),
                          class: "pi pi-external-link text-sm ml-2 text-purple-500",
                          style: {"cursor":"pointer"}
                        })
                      ])
                    ]),
                    _createElementVNode("li", _hoisted_51, [
                      _createElementVNode("div", _hoisted_52, [
                        _createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.$t('labels.priceImport.wbtChangeProducts')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_54, [
                        _createElementVNode("div", _hoisted_55, [
                          _createElementVNode("div", {
                            class: "h-full bg-blue-500",
                            style: _normalizeStyle('width: ' + (_ctx.totalWbtChanged / (_ctx.totalRecords - _ctx.totalIrregular) * 100).toFixed(2) + '%')
                          }, null, 4)
                        ]),
                        _createElementVNode("span", _hoisted_56, _toDisplayString(_ctx.totalWbtChanged) + " (" + _toDisplayString((_ctx.totalWbtChanged /
                                (_ctx.totalRecords - _ctx.totalIrregular) *
                                100).toFixed(2)) + " %)", 1),
                        _createElementVNode("i", {
                          onClick: _cache[5] || (_cache[5] = $event => (_ctx.onMultipleStatusChangeDetailsClick([128]))),
                          class: "pi pi-external-link text-sm ml-2 text-blue-500",
                          style: {"cursor":"pointer"}
                        })
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_57, [
            _createElementVNode("div", _hoisted_58, [
              _createElementVNode("div", _hoisted_59, [
                _createElementVNode("div", null, [
                  _createElementVNode("h5", _hoisted_60, [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.priceImport.eolProducts')) + " ", 1),
                    _createElementVNode("i", {
                      onClick: _cache[6] || (_cache[6] = $event => (_ctx.onEolDetailsClick())),
                      class: "pi pi-external-link ml-2",
                      style: {"cursor":"pointer"}
                    })
                  ]),
                  (_ctx.totalEol)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_61, _toDisplayString(_ctx.totalEol), 1))
                    : _createCommentVNode("", true)
                ]),
                _cache[12] || (_cache[12] = _createElementVNode("div", {
                  class: "flex align-items-center justify-content-center bg-orange-100 border-round",
                  style: {"width":"2.5rem","height":"2.5rem"}
                }, [
                  _createElementVNode("i", { class: "pi pi-exclamation-triangle text-orange-500 text-xl mr-2 mb-1" })
                ], -1))
              ]),
              (_ctx.lastRunData?.statistics?.eolItemsCount)
                ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                    (_ctx.lastRunData?.statistics?.eolItemsCount === _ctx.totalEol)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_63, _toDisplayString(_ctx.$t('labels.priceImport.noChangesDetected')) + ".", 1))
                      : (_ctx.lastRunData?.statistics?.eolItemsCount > _ctx.totalEol)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createElementVNode("span", _hoisted_64, _toDisplayString(_ctx.lastRunData?.statistics?.eolItemsCount -
                            _ctx.totalEol), 1),
                            _createElementVNode("span", _hoisted_65, _toDisplayString(_ctx.$t('labels.priceImport.lessThanLastRun')) + ".", 1)
                          ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createElementVNode("span", _hoisted_66, _toDisplayString(_ctx.totalEol -
                            _ctx.lastRunData?.statistics?.eolItemsCount), 1),
                            _createElementVNode("span", _hoisted_67, _toDisplayString(_ctx.$t('labels.priceImport.moreThanLastRun')) + ".", 1)
                          ], 64))
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_68, [
          _createElementVNode("div", _hoisted_69, [
            _createElementVNode("div", _hoisted_70, [
              _createElementVNode("h5", null, _toDisplayString(_ctx.$t('labels.priceImport.costPriceChangeProducts')) + " (" + _toDisplayString(_ctx.totalCostPriceChanged) + ") ", 1),
              _createVNode(_component_Chart, {
                type: "bar",
                data: _ctx.costPriceChartData,
                options: _ctx.costPriceChartOptions
              }, null, 8, ["data", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_71, [
            _createElementVNode("div", _hoisted_72, [
              _createElementVNode("h5", null, _toDisplayString(_ctx.$t('labels.priceImport.listPriceChangeProducts')) + " (" + _toDisplayString(_ctx.totalListPriceChanged) + ") ", 1),
              _createVNode(_component_Chart, {
                type: "bar",
                data: _ctx.listPriceChartData,
                options: _ctx.listPriceChartOptions
              }, null, 8, ["data", "options"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_73, [
          _createElementVNode("div", _hoisted_74, [
            _createElementVNode("div", _hoisted_75, [
              _createElementVNode("div", _hoisted_76, [
                (_ctx.logs.length > 0)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_77, " EOL + unregelmäßige Artikel (" + _toDisplayString(_ctx.totalIrregular) + ") ", 1))
                  : _createCommentVNode("", true),
                (_ctx.logs.length > 0 && _ctx.totalIrregular)
                  ? (_openBlock(), _createBlock(_component_p_button, {
                      key: 1,
                      class: "flex",
                      icon: "pi pi-plus",
                      severity: "warning",
                      label: "EOL-Tag in Weclapp",
                      onClick: _ctx.handleAddingWeclappEolTag
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", null, [
                (_ctx.logs.length > 0)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_78, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList([10002, 10003, 10004, 10005, 10006, 1001, 1002, 1003, 1004], (status, index) => {
                        return _createElementVNode("li", {
                          key: index,
                          class: "flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
                        }, [
                          _createElementVNode("div", null, [
                            _createElementVNode("span", {
                              class: _normalizeClass(["font-medium mr-2 mb-1 md:mb-0", 'text-' + _ctx.colorContainer[index % 9] + '-500'])
                            }, "Status " + _toDisplayString(status), 3),
                            _createElementVNode("div", _hoisted_79, [
                              ([1001, 1002, 1003, 1004].indexOf(status) !== -1)
                                ? (_openBlock(), _createBlock(_component_Tag, {
                                    key: 0,
                                    severity: "info",
                                    class: "mr-2"
                                  }, {
                                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                                      _createElementVNode("span", null, "EOL", -1)
                                    ])),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              _createElementVNode("span", _hoisted_80, _toDisplayString(_ctx.getStatusTooltip(status)), 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_81, [
                            _createElementVNode("div", _hoisted_82, [
                              _createElementVNode("div", {
                                class: _normalizeClass(["h-full", 'bg-' + _ctx.colorContainer[index % 9] + '-500']),
                                style: _normalizeStyle('width: ' + _ctx.calculatePercentageForStatus(status) + '%')
                              }, null, 6)
                            ]),
                            _createElementVNode("span", {
                              class: _normalizeClass('text-' + _ctx.colorContainer[index % 9] + '-500 ml-3 font-medium')
                            }, _toDisplayString(_ctx.calculateNumberOfItemsForStatus(status)) + " (" + _toDisplayString(_ctx.calculatePercentageForStatus(status)) + " %)", 3),
                            _createElementVNode("i", {
                              onClick: $event => (_ctx.onStatusDetailsClick(status)),
                              class: _normalizeClass(["pi pi-external-link text-sm ml-2", 'text-' + _ctx.colorContainer[index % 9] + '-500']),
                              style: {"cursor":"pointer"}
                            }, null, 10, _hoisted_83)
                          ])
                        ])
                      }), 64))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_84, [
              _createVNode(_component_Chart, {
                type: "doughnut",
                data: _ctx.statusChartData,
                options: _ctx.statusChartOptions,
                class: "w-full md:w-30rem"
              }, null, 8, ["data", "options"])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}