import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_ProductDescription = _resolveComponent("ProductDescription")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_TabView = _resolveComponent("TabView")

  return (_openBlock(), _createBlock(_component_TabView, { class: "tabview-custom text-sm" }, {
    default: _withCtx(() => [
      _createVNode(_component_TabPanel, {
        disabled: !_ctx.productDetails?.shopware
      }, {
        header: _withCtx(() => [
          _cache[0] || (_cache[0] = _createElementVNode("i", { class: "pi pi-align-justify mr-2" }, null, -1)),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.description")), 1),
          _createElementVNode("span", {
            class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
          }, null, 2)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_ProductDescription, {
            onReloadProduct: _ctx.loadProductDetails,
            onAutoSaveDescription: _ctx.onAutoSaveDescription,
            "shopware-details": _ctx.productDetails?.shopware
          }, null, 8, ["onReloadProduct", "onAutoSaveDescription", "shopware-details"])
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_TabPanel, {
        disabled: !_ctx.productDetails?.secondShopware
      }, {
        header: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("i", { class: "pi pi-align-justify mr-2" }, null, -1)),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.description")), 1),
          _createElementVNode("span", {
            class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
          }, null, 2)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_ProductDescription, {
            onReloadProduct: _ctx.loadProductDetails,
            onAutoSaveDescription: _ctx.onAutoSaveDescription,
            "shopware-details": _ctx.productDetails?.secondShopware,
            platform: _ctx.secondPlatform
          }, null, 8, ["onReloadProduct", "onAutoSaveDescription", "shopware-details", "platform"])
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }))
}