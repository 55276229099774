import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid text-center text-6xl mb-3"
}
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "ml-3 font-bold" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "ml-3 font-bold" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "ml-3 font-bold" }
const _hoisted_8 = { class: "grid" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "text-center" }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "text-center" }
const _hoisted_13 = { class: "grid" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "col" }
const _hoisted_16 = { class: "col" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_DataView = _resolveComponent("DataView")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, null, {
      header: _withCtx(() => [
        _createElementVNode("h5", null, _toDisplayString(_ctx.$t('labels.shipment.openShipments')), 1)
      ]),
      icons: _withCtx(() => [
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.chosenWarehouseId,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.chosenWarehouseId) = $event)),
          options: _ctx.warehouses || [],
          optionLabel: "name",
          optionValue: "id",
          onChange: _ctx.onWarehouseChange
        }, null, 8, ["modelValue", "options", "onChange"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          (_ctx.allOpenCount !== null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fab', 'ups'],
                    style: {"background-color":"#ffb500"}
                  }),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.allOpenCount.ups), 1)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fab', 'dhl'],
                    style: {"background-color":"#ffb500"}
                  }),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.allOpenCount.dhl), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fas', 'truck'] }),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.allOpenCount.total - _ctx.allOpenCount.ups - _ctx.allOpenCount.dhl), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h1", _hoisted_10, _toDisplayString(_ctx.$t("labels.shipment.internalAndTodayShipments")), 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("h1", _hoisted_12, _toDisplayString(_ctx.$t("labels.shipment.pickups")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_DataView, {
                layout: "list",
                rows: 200,
                value: _ctx.shipments,
                lazy: true,
                dataKey: "id",
                ref: "dv"
              }, {
                empty: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("span", null, null, -1)
                ])),
                list: _withCtx((slotProps) => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.items, (internalItem, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: _normalizeClass(["w-full p-2 mb-3 flex align-items-center justify-content-between", {'new-item-in-the-list': _ctx.newShipments.some((item) => item.shipmentNumber === internalItem.shipmentNumber), 'bg-red-100' : internalItem.transportationOrderStatus === 'NEW', 'bg-yellow-100': internalItem.transportationOrderStatus === 'IN_TRANSIT',  'bg-green-100' : (internalItem.transportationOrderStatus === 'COMPLETED' || internalItem.isCommissioningComplete)}])
                    }, [
                      _createElementVNode("div", null, [
                        (internalItem.isUps)
                          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                              key: 0,
                              class: "ml-2 text-7xl",
                              icon: ['fab', 'ups'],
                              style: {"background-color":"#ffb500"}
                            }))
                          : _createCommentVNode("", true),
                        (internalItem.isDhl)
                          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                              key: 1,
                              class: "text-7xl",
                              icon: ['fab', 'dhl'],
                              style: {"background-color":"#ffb500"}
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["font-bold text-center", {'text-7xl': !internalItem.pickingInstructions, 'text-6xl' : internalItem.pickingInstructions}])
                        }, [
                          (internalItem?.salesChannel)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(["sales-channel-dot mr-3 w-2rem h-2rem", {'sales-channel-teltec': internalItem.salesChannel === 'NET1', 'sales-channel-videodata': internalItem.salesChannel === 'NET2'}])
                              }, null, 2))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", null, _toDisplayString(internalItem.shipmentNumber), 1)
                        ], 2),
                        (internalItem.pickingInstructions)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(["text-center mb-3", {'text-6xl': internalItem.pickingInstructions.length < 20, 'text-4xl': internalItem.pickingInstructions.length > 19}])
                            }, _toDisplayString(internalItem.pickingInstructions), 3))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", null, [
                        ('INTERNAL' === internalItem.shipmentType)
                          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                              key: 0,
                              class: "mr-2 text-7xl text-purple-600",
                              icon: ['fas', 'house-user']
                            }))
                          : _createCommentVNode("", true)
                      ])
                    ], 2))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_DataView, {
                layout: "list",
                rows: 200,
                value: _ctx.pickups,
                lazy: true,
                dataKey: "id"
              }, {
                empty: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("span", null, null, -1)
                ])),
                list: _withCtx((slotProps) => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.items, (pickupItem, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: _normalizeClass(["mt-2 grid align-items-center", {'new-item-in-the-list': _ctx.newPickups.some((item) => item.shipmentNumber === pickupItem.shipmentNumber), 'bg-red-100' : pickupItem.transportationOrderStatus === 'NEW', 'bg-yellow-100': pickupItem.transportationOrderStatus === 'IN_TRANSIT',  'bg-green-100' : (pickupItem.transportationOrderStatus === 'COMPLETED' || pickupItem.isCommissioningComplete)}])
                    }, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["text-center font-bold", { 'text-7xl': !pickupItem.pickingInstructions, 'text-6xl' : pickupItem.pickingInstructions}])
                        }, [
                          (pickupItem?.salesChannel)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(["sales-channel-dot mr-3 w-2rem h-2rem", {'sales-channel-teltec': pickupItem.salesChannel === 'NET1', 'sales-channel-videodata': pickupItem.salesChannel === 'NET2'}])
                              }, null, 2))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", null, _toDisplayString(pickupItem.shipmentNumber), 1)
                        ], 2),
                        (pickupItem.pickingInstructions)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(["text-center mb-3", {'text-6xl': pickupItem.pickingInstructions.length < 20, 'text-4xl': pickupItem.pickingInstructions.length > 19}])
                            }, _toDisplayString(pickupItem.pickingInstructions), 3))
                          : _createCommentVNode("", true)
                      ])
                    ], 2))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}