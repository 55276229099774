import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex align-content-center" }
const _hoisted_2 = { style: {"vertical-align":"middle"} }
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_5 = { style: {"vertical-align":"middle"} }
const _hoisted_6 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_7 = { class: "mt-2" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { style: {"vertical-align":"middle"} }
const _hoisted_11 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "font-semibold" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "fas fa-exchange-alt mr-1" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "card" }
const _hoisted_19 = { class: "card-body" }
const _hoisted_20 = { class: "w-full mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_LoadingPlugin = _resolveComponent("LoadingPlugin")
  const _component_Column = _resolveComponent("Column")
  const _component_Badge = _resolveComponent("Badge")
  const _component_TieredMenu = _resolveComponent("TieredMenu")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Tag = _resolveComponent("Tag")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_p_multiselect = _resolveComponent("p-multiselect")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_ProductList = _resolveComponent("ProductList")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")
  const _component_BulkTransferAccessories = _resolveComponent("BulkTransferAccessories")
  const _component_BulkTransferAccessoriesToOtherShop = _resolveComponent("BulkTransferAccessoriesToOtherShop")
  const _component_ReplaceAccessory = _resolveComponent("ReplaceAccessory")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LoadingPlugin, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_DataTable, {
      class: "p-datatable-sm text-sm",
      stripedRows: "",
      paginator: "",
      scrollable: "",
      scrollHeight: 'calc(100vh - 36rem)',
      rows: 10,
      lazy: "",
      ref: "dt",
      dataKey: "id",
      totalRecords: _ctx.totalRecords,
      value: _ctx.products,
      responsiveLayout: "scroll",
      "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
      "rows-per-page-options": [10,20,50,100,200],
      "current-page-report-template": "{first} to {last} of {totalRecords}",
      filters: _ctx.filters,
      "onUpdate:filters": _cache[3] || (_cache[3] = $event => ((_ctx.filters) = $event)),
      filterDisplay: "row",
      onPage: _cache[4] || (_cache[4] = $event => (_ctx.onPage($event))),
      onFilter: _cache[5] || (_cache[5] = $event => (_ctx.onFilter($event))),
      onSort: _cache[6] || (_cache[6] = $event => (_ctx.onSort($event))),
      expandedRows: _ctx.expandedRows,
      "onUpdate:expandedRows": _cache[7] || (_cache[7] = $event => ((_ctx.expandedRows) = $event)),
      selection: _ctx.selectedProducts,
      "onUpdate:selection": _cache[8] || (_cache[8] = $event => ((_ctx.selectedProducts) = $event)),
      onRowSelect: _ctx.onRowSelect,
      onRowSelectAll: _ctx.onRowSelectAll,
      onRowUnselect: _ctx.onRowUnselect,
      onRowUnselectAll: _ctx.onRowUnselectAll
    }, {
      expansion: _withCtx((slotProps) => [
        (slotProps.data?.shopwareData?.description || slotProps.data?.description)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              innerHTML: slotProps.data?.shopwareData?.description || slotProps.data?.description
            }, null, 8, _hoisted_16))
          : (_openBlock(), _createElementBlock("div", _hoisted_17, "No description available."))
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          expander: true,
          headerStyle: "width: 3em"
        }),
        _createVNode(_component_Column, {
          selectionMode: "multiple",
          headerStyle: "width: 3em"
        }, {
          header: _withCtx(() => [
            _createVNode(_component_Badge, {
              value: _ctx.mergedSelection.length
            }, null, 8, ["value"]),
            (_ctx.mergedSelection.length > 0)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "pi pi-times-circle text-red-600",
                  style: {"padding-left":"7px","cursor":"pointer"},
                  onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.clearSelection && _ctx.clearSelection(...args)))
                }))
              : _createCommentVNode("", true),
            (_ctx.editAccessoriesPermissionAvailable && _ctx.products && _ctx.products.length > 0)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 1,
                  class: "pi pi-cog text-green-600",
                  style: {"padding-left":"7px","cursor":"pointer"},
                  onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.bulkMenuToggle && _ctx.bulkMenuToggle(...args))),
                  "aria-haspopup": "true",
                  "aria-controls": "overlay_tmenu"
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_TieredMenu, {
              ref: "bulkMenu",
              id: "overlay_tmenu",
              model: _ctx.bulkItems,
              popup: ""
            }, null, 8, ["model"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "articleNumber",
          sortable: true,
          header: _ctx.$t('labels.articleNumber'),
          style: {"min-width":"6rem"},
          filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
              onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
              class: "p-column-filter text-sm",
              placeholder: _ctx.$t('labels.articleNumber')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
          ]),
          body: _withCtx(({data}) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", {
                class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData && !data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData && !data.shopwareData.active, 'bg-gray-200': !data?.shopwareData?.active}])
              }, null, 2),
              _createElementVNode("div", {
                class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData && !data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData && !data.secondShopwareData.active, 'bg-gray-200': !data?.secondShopwareData?.active}])
              }, null, 2),
              _createElementVNode("span", _hoisted_2, [
                _createElementVNode("a", {
                  href: data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id,
                  target: "_blank"
                }, _toDisplayString(data.articleNumber), 9, _hoisted_3)
              ]),
              (data.shopwareData && data.articleNumber !== data.shopwareData.productNumber)
                ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_4, null, 512)), [
                    [
                      _directive_tooltip,
                      _ctx.$t('labels.inconsistentData') + ': ' + _ctx.$t('labels.articleNumber'),
                      void 0,
                      { top: true }
                    ]
                  ])
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "name",
          sortable: true,
          style: {"min-width":"6rem"},
          header: _ctx.$t('labels.name')
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
              onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
              class: "p-column-filter text-sm",
              placeholder: _ctx.$t('labels.name')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
          ]),
          body: _withCtx(({data}) => [
            _createElementVNode("span", _hoisted_5, _toDisplayString(data.name || data.shopwareData?.name), 1),
            (data.shopwareData && data?.name !== data?.shopwareData?.name)
              ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_6, null, 512)), [
                  [
                    _directive_tooltip,
                    _ctx.$t('labels.inconsistentData') + ': ' + _ctx.$t('labels.name'),
                    void 0,
                    { top: true }
                  ]
                ])
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.tags, (tag) => {
                return (_openBlock(), _createBlock(_component_Tag, {
                  severity: "info",
                  class: "mt-1 mr-1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(tag.toUpperCase().replace('TTC-PRODUCT-META-',
                        '')), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 256))
            ])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "description",
          style: {"max-width":"20rem"},
          header: _ctx.$t('labels.productDescription.webshopText')
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
              onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
              class: "p-column-filter text-sm",
              placeholder: _ctx.$t('labels.productDescription.webshopText')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
          ]),
          body: _withCtx(({data}) => [
            _createElementVNode("div", {
              onClick: $event => (_ctx.expandRow(data)),
              style: {"cursor":"pointer"}
            }, _toDisplayString(_ctx.stripTagsAndTruncate(data.shopwareData?.description, 400,
                '...')), 9, _hoisted_8)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "productCategory",
          header: _ctx.$t('labels.articleCategory')
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
              onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
              class: "p-column-filter text-sm",
              placeholder: _ctx.$t('labels.articleCategory')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
          ]),
          body: _withCtx(({data}) => [
            (data?.shopwareData?.categories)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.shopwareData.categories, (element) => {
                    return (_openBlock(), _createElementBlock("li", null, _toDisplayString(element.name), 1))
                  }), 256))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "manufacturerName",
          sortable: true,
          header: _ctx.$t('labels.manufacturer')
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("span", _hoisted_10, _toDisplayString(data.manufacturerName || data.shopwareData?.manufacturer), 1),
            (data.shopwareData && (data.manufacturerName || data.shopwareData.manufacturer) && (data.manufacturerName !== data.shopwareData.manufacturer))
              ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_11, null, 512)), [
                  [
                    _directive_tooltip,
                    _ctx.$t('labels.inconsistentData') + ': ' + _ctx.$t('labels.manufacturer'),
                    void 0,
                    { top: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          header: _ctx.$t('labels.stock'),
          field: "availableStock",
          style: {"min-width":"8rem"},
          filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
        }, {
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_TriStateCheckbox, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
              onChange: $event => (filterCallback())
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
          ]),
          body: _withCtx(({data}) => [
            _createTextVNode(_toDisplayString(data.shopwareData?.availableStock), 1)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "tags",
          header: _ctx.$t('labels.tags'),
          style: {"min-width":"10rem"},
          filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_p_multiselect, {
              class: "text-sm",
              modelValue: filterModel.value,
              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
              options: _ctx.availableProductTags,
              maxSelectedLabels: 3,
              optionLabel: "name",
              optionValue: "name",
              filter: "",
              onChange: $event => (filterCallback()),
              style: {"min-width":"5rem","max-width":"20rem"}
            }, {
              option: _withCtx(({option}) => [
                _createTextVNode(_toDisplayString(option.name.toUpperCase().replace('TTC-PRODUCT-META-',
                    '')), 1)
              ]),
              value: _withCtx((content) => [
                _createTextVNode(_toDisplayString((content.value || []).map((item) =>
                    item.toUpperCase().replace('TTC-PRODUCT-META-', ''))
                    .join(', ')), 1)
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
          ]),
          body: _withCtx(({data}) => [
            (data?.tags)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_12, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.tags, (tag) => {
                    return (_openBlock(), _createElementBlock("li", null, [
                      _createElementVNode("span", _hoisted_13, _toDisplayString(tag.toUpperCase().replace('TTC-PRODUCT-META-',
                            '')), 1)
                    ]))
                  }), 256))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header"]),
        (_ctx.editAccessoriesPermissionAvailable)
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 0,
              style: {"min-width":"5rem"}
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", {
                  style: {"cursor":"pointer"},
                  onClick: $event => (_ctx.openReplaceDialog(data))
                }, [
                  _withDirectives(_createElementVNode("i", _hoisted_15, null, 512), [
                    [
                      _directive_tooltip,
                      _ctx.$t('labels.replace'),
                      void 0,
                      { left: true }
                    ]
                  ])
                ], 8, _hoisted_14)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["totalRecords", "value", "filters", "expandedRows", "selection", "onRowSelect", "onRowSelectAll", "onRowUnselect", "onRowUnselectAll"]),
    _createVNode(_component_PrimeDialog, {
      header: _ctx.$t('labels.deleteSelected') + '...',
      visible: _ctx.showDeleteDialog,
      "onUpdate:visible": _cache[10] || (_cache[10] = $event => ((_ctx.showDeleteDialog) = $event)),
      breakpoints: {'960px': '75vw'},
      style: {width: '50vw'},
      onHide: _ctx.closeTransferDialog,
      modal: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_ProductList, {
              products: _ctx.selectedProducts,
              platform: _ctx.platform
            }, null, 8, ["products", "platform"]),
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_component_p_button, {
                severity: "danger",
                onClick: _cache[9] || (_cache[9] = $event => (_ctx.showDeleteDialog = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_p_button, {
                onClick: _ctx.removeArticleAccessories,
                severity: "success"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["header", "visible", "onHide"]),
    _createVNode(_component_BulkTransferAccessories, {
      products: _ctx.selectedProducts,
      displayResponsive: _ctx.showTransferDialog,
      onCloseDialog: _ctx.closeTransferDialog,
      platform: _ctx.platform
    }, null, 8, ["products", "displayResponsive", "onCloseDialog", "platform"]),
    _createVNode(_component_BulkTransferAccessoriesToOtherShop, {
      "product-details": _ctx.productDetails,
      accessories: _ctx.selectedProducts.filter(item => item.secondShopwareData),
      displayResponsive: _ctx.showTransferToOtherShopDialog,
      onCloseDialog: _ctx.closeTransferToOtherShopDialog,
      platform: _ctx.crossPlatform?.value
    }, null, 8, ["product-details", "accessories", "displayResponsive", "onCloseDialog", "platform"]),
    _createVNode(_component_ReplaceAccessory, {
      oldAccessory: _ctx.oldAccessory,
      showReplaceDialog: _ctx.showReplaceDialog,
      platform: _ctx.platform,
      onCloseDialog: _ctx.closeReplaceDialog
    }, null, 8, ["oldAccessory", "showReplaceDialog", "platform", "onCloseDialog"])
  ], 64))
}