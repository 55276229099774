import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "grid p-fluid" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_p_fieldset = _resolveComponent("p-fieldset")
  const _component_BookTicketShipment = _resolveComponent("BookTicketShipment")
  const _component_AddTicketCommentDialog = _resolveComponent("AddTicketCommentDialog")
  const _component_SendDocumentToRemotePrinter = _resolveComponent("SendDocumentToRemotePrinter")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_p_fieldset, {
      class: "mb-3",
      legend: _ctx.$t('labels.ticket.references.deliveryNote'),
      toggleable: true
    }, {
      default: _withCtx(() => [
        (_ctx.ticketDetails.ticketDeliveryNote && _ctx.ticketDetails.ticketDeliveryNote.length > 0)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList((_ctx.ticketDetails.ticketDeliveryNote || []), (referenceItem) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("span", {
                    innerHTML: _ctx.getReferenceItemLayout(referenceItem,
                                        _ctx.ticketDetails.url)
                  }, null, 8, _hoisted_3),
                  (_ctx.editPermissionAvailable && referenceItem.status === 'NEW')
                    ? _withDirectives((_openBlock(), _createElementBlock("span", {
                        key: 0,
                        onClick: $event => (_ctx.onCreateCompensationShipmentClick(referenceItem, $event)),
                        style: {"cursor":"pointer"}
                      }, _cache[0] || (_cache[0] = [
                        _createElementVNode("i", { class: "pi pi-check-square ml-1" }, null, -1)
                      ]), 8, _hoisted_4)), [
                        [
                          _directive_tooltip,
                          _ctx.$t('labels.ticket.deliveryNote.bookAndSendCompensationShipment'),
                          void 0,
                          { top: true }
                        ]
                      ])
                    : _createCommentVNode("", true),
                  _createElementVNode("span", null, [
                    (referenceItem.documents && referenceItem.documents.some((item) => {return item.name.startsWith('SH') || item.name.startsWith('LS');}))
                      ? _withDirectives((_openBlock(), _createElementBlock("i", {
                          key: 0,
                          onClick: $event => {_ctx.printDocument(referenceItem.documents.find((item) => {return item.name.startsWith('SH') || item.name.startsWith('LS');}))},
                          class: "pi pi-print ml-2",
                          style: {"cursor":"pointer"}
                        }, null, 8, _hoisted_5)), [
                          [
                            _directive_tooltip,
                            _ctx.$t('labels.printSpecificDocumentRemotely', {name: referenceItem.documents.find((item) => {return item.name.startsWith('SH') ||  item.name.startsWith('LS');}).name}),
                            void 0,
                            { top: true }
                          ]
                        ])
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            }), 256))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["legend"]),
    _createVNode(_component_BookTicketShipment, {
      "display-dialog": _ctx.showBookTicketShipmentDialog,
      "ticket-details": _ctx.ticketDetails,
      shipment: _ctx.selectedShipment,
      onCloseDialog: _ctx.closeBookTicketShipmentDialog
    }, null, 8, ["display-dialog", "ticket-details", "shipment", "onCloseDialog"]),
    _createVNode(_component_AddTicketCommentDialog, {
      "display-dialog": _ctx.showAddCommentDialog,
      "ticket-details": _ctx.ticketDetails,
      "comment-default-value": _ctx.commentDefaultValue,
      onCloseDialog: _ctx.closeAddTicketCommentDialog
    }, null, 8, ["display-dialog", "ticket-details", "comment-default-value", "onCloseDialog"]),
    _createVNode(_component_SendDocumentToRemotePrinter, {
      "display-dialog": _ctx.displaySendToRemotePrinterDialog,
      onCloseDialog: _ctx.closePrintDialogListener,
      "selected-document": _ctx.selectedDocument
    }, null, 8, ["display-dialog", "onCloseDialog", "selected-document"])
  ], 64))
}