import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col flex" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "flex justify-content-end"
}

export function render(_ctx, _cache) {
  const _component_p_button = _resolveComponent("p-button")
  const _component_p_fieldset = _resolveComponent("p-fieldset")
  const _component_AddRepairQuotation = _resolveComponent("AddRepairQuotation")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_p_fieldset, {
      class: "mb-3",
      legend: _ctx.$t('labels.ticket.references.orderPaidRepair'),
      toggleable: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.ticketDetails.ticketOrderPaidRepair && _ctx.ticketDetails.ticketOrderPaidRepair.length > 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList((_ctx.ticketDetails.ticketOrderPaidRepair || []), (referenceItem) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", {
                        innerHTML: _ctx.getReferenceItemLayout(referenceItem,
                                        _ctx.ticketDetails.url)
                      }, null, 8, _hoisted_4)
                    ])
                  ])
                ]))
              }), 256))
            : _createCommentVNode("", true),
          (_ctx.showAddNewQuotationButton && _ctx.editPermissionAvailable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_p_button, {
                  severity: "success",
                  onClick: _ctx.onAddNewQuotationClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("buttons.addNewQuotation")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["legend"]),
    _createVNode(_component_AddRepairQuotation, {
      "display-add-quotation-dialog": _ctx.showAddQuotationWizardDialog,
      "ticket-details": _ctx.ticketDetails,
      onCloseDialog: _ctx.closeAddNewQuotationDialog
    }, null, 8, ["display-add-quotation-dialog", "ticket-details", "onCloseDialog"])
  ], 64))
}