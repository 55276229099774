import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_PaidRepair = _resolveComponent("PaidRepair")
  const _component_p_fieldset = _resolveComponent("p-fieldset")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.ticketDetails)
      ? (_openBlock(), _createBlock(_component_PaidRepair, {
          key: 0,
          "ticket-details": _ctx.ticketDetails,
          onReloadTicketDetails: _ctx.handleReloadTicketEvent
        }, null, 8, ["ticket-details", "onReloadTicketDetails"]))
      : _createCommentVNode("", true),
    (_ctx.ticketDetails)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(({
                            'orderSpare': 'ticketOrderSpare'
                        }), (referenceKey, translationKey) => {
          return _createVNode(_component_p_fieldset, {
            class: "mb-3",
            legend: _ctx.$t('labels.ticket.references.' +
                        translationKey),
            toggleable: true
          }, {
            default: _withCtx(() => [
              (_ctx.ticketDetails[referenceKey] && _ctx.ticketDetails[referenceKey].length > 0)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList((_ctx.ticketDetails[referenceKey] || []), (referenceItem) => {
                    return (_openBlock(), _createElementBlock("div", {
                      innerHTML: _ctx.getReferenceItemLayout(referenceItem,
                                    _ctx.ticketDetails.url)
                    }, null, 8, _hoisted_1))
                  }), 256))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["legend"])
        }), 64))
      : _createCommentVNode("", true)
  ], 64))
}