import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, renderList as _renderList, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-content-between w-full" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "text-center w-full"
}
const _hoisted_5 = {
  key: 1,
  class: "text-center w-full"
}
const _hoisted_6 = { class: "grid mb-3" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = {
  key: 0,
  class: "col"
}
const _hoisted_13 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_14 = {
  key: 1,
  class: "col"
}
const _hoisted_15 = {
  key: 0,
  class: "col"
}
const _hoisted_16 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_17 = {
  key: 1,
  class: "col"
}
const _hoisted_18 = { class: "grid mt-3 mb-3" }
const _hoisted_19 = { class: "col font-bold" }
const _hoisted_20 = { class: "col" }
const _hoisted_21 = {
  key: 0,
  class: "flex align-items-center"
}
const _hoisted_22 = { key: 0 }
const _hoisted_23 = {
  key: 0,
  class: "pi pi-home ml-2"
}
const _hoisted_24 = {
  key: 1,
  class: "pi pi-calculator ml-2"
}
const _hoisted_25 = {
  key: 2,
  class: "pi pi-truck ml-2"
}
const _hoisted_26 = { class: "flex align-items-center" }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = {
  key: 0,
  class: "pi pi-home ml-2"
}
const _hoisted_29 = {
  key: 1,
  class: "pi pi-calculator ml-2"
}
const _hoisted_30 = {
  key: 2,
  class: "pi pi-truck ml-2"
}
const _hoisted_31 = { key: 1 }
const _hoisted_32 = {
  key: 0,
  class: "pi pi-home ml-2"
}
const _hoisted_33 = {
  key: 1,
  class: "pi pi-calculator ml-2"
}
const _hoisted_34 = {
  key: 2,
  class: "pi pi-truck ml-2"
}
const _hoisted_35 = { class: "col" }
const _hoisted_36 = {
  key: 0,
  class: "col"
}
const _hoisted_37 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_38 = {
  key: 1,
  class: "col"
}
const _hoisted_39 = { class: "col" }
const _hoisted_40 = {
  key: 0,
  class: "col"
}
const _hoisted_41 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_42 = {
  key: 1,
  class: "col"
}
const _hoisted_43 = { class: "col" }
const _hoisted_44 = {
  key: 0,
  class: "col"
}
const _hoisted_45 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_46 = {
  key: 1,
  class: "col"
}
const _hoisted_47 = { class: "col" }
const _hoisted_48 = {
  key: 0,
  class: "col"
}
const _hoisted_49 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_50 = {
  key: 1,
  class: "col"
}
const _hoisted_51 = { class: "col" }
const _hoisted_52 = {
  key: 0,
  class: "col"
}
const _hoisted_53 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_54 = {
  key: 1,
  class: "col"
}
const _hoisted_55 = { class: "col" }
const _hoisted_56 = {
  key: 0,
  class: "col"
}
const _hoisted_57 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_58 = {
  key: 1,
  class: "col"
}
const _hoisted_59 = { class: "grid mb-3" }
const _hoisted_60 = { class: "col" }
const _hoisted_61 = {
  key: 0,
  class: "col"
}
const _hoisted_62 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_63 = {
  key: 1,
  class: "col"
}
const _hoisted_64 = { class: "col" }
const _hoisted_65 = {
  key: 0,
  class: "col"
}
const _hoisted_66 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_67 = {
  key: 1,
  class: "col"
}
const _hoisted_68 = { class: "grid mt-5 mb-3" }
const _hoisted_69 = { class: "col font-bold" }
const _hoisted_70 = { class: "col" }
const _hoisted_71 = {
  key: 0,
  class: "flex align-items-center"
}
const _hoisted_72 = { class: "flex align-items-center" }
const _hoisted_73 = { class: "col" }
const _hoisted_74 = {
  key: 0,
  class: "col"
}
const _hoisted_75 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_76 = {
  key: 1,
  class: "col"
}
const _hoisted_77 = { class: "col" }
const _hoisted_78 = {
  key: 0,
  class: "col"
}
const _hoisted_79 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_80 = {
  key: 1,
  class: "col"
}
const _hoisted_81 = { class: "col" }
const _hoisted_82 = {
  key: 0,
  class: "col"
}
const _hoisted_83 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_84 = {
  key: 1,
  class: "col"
}
const _hoisted_85 = { class: "col" }
const _hoisted_86 = {
  key: 0,
  class: "col"
}
const _hoisted_87 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_88 = {
  key: 1,
  class: "col"
}
const _hoisted_89 = { class: "col" }
const _hoisted_90 = {
  key: 0,
  class: "col"
}
const _hoisted_91 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_92 = {
  key: 1,
  class: "col"
}
const _hoisted_93 = {
  key: 2,
  class: "grid mt-5 mb-3"
}
const _hoisted_94 = { class: "col font-bold" }
const _hoisted_95 = { class: "col" }
const _hoisted_96 = {
  key: 3,
  class: "flex justify-content-end w-full"
}

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.savingInProgress,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.savingInProgress) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, null, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[37] || (_cache[37] = _createElementVNode("div", { class: "font-bold" }, "Weclapp", -1)),
          (_ctx.selectedWeclappCustomer)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (!_ctx.isEdit)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: "pi pi-pencil",
                      onClick: _cache[1] || (_cache[1] = $event => {_ctx.isEdit = true;}),
                      style: {"cursor":"pointer"}
                    }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("i", {
                        class: "pi pi-check text-green-600",
                        style: {"cursor":"pointer"},
                        onClick: _cache[2] || (_cache[2] = $event => (_ctx.handleSubmit(!_ctx.v$.$invalid)))
                      }),
                      _createElementVNode("i", {
                        class: "pi pi-times-circle text-red-600 ml-3",
                        style: {"cursor":"pointer"},
                        onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.resetForm && _ctx.resetForm(...args)))
                      })
                    ]))
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        (!_ctx.selectedWeclappCustomer && (_ctx.potentialMatches || []).length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('messages.pleaseSelectOneOfTheCustomers')), 1))
          : _createCommentVNode("", true),
        ((_ctx.potentialMatches || []).length < 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('messages.noWeclappCustomerCouldBeFound')), 1))
          : _createCommentVNode("", true),
        (_ctx.selectedWeclappCustomer)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('labels.customerNumber')), 1),
                _createElementVNode("div", _hoisted_8, [
                  (_ctx.shopData?.customer?.customerNumber)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (!_ctx.resolvedWeclappCustomerNumber)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[38] || (_cache[38] = [
                              _createElementVNode("small", null, "(wird bei Auftragserstellung aktualisiert)", -1)
                            ])))
                          : (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: _normalizeClass({'text-green-400' : _ctx.resolvedWeclappCustomerNumber.includes(_ctx.shopData?.customer?.customerNumber)})
                            }, [
                              _createTextVNode(_toDisplayString(_ctx.resolvedWeclappCustomerNumber) + " ", 1),
                              (!_ctx.resolvedWeclappCustomerNumber.includes(_ctx.shopData?.customer?.customerNumber))
                                ? (_openBlock(), _createElementBlock("small", _hoisted_10, "(wird bei Auftragserstellung erweitert)"))
                                : _createCommentVNode("", true)
                            ], 2))
                      ], 64))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["grid", {'mb-3': !_ctx.isEdit}])
              }, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('labels.email')), 1),
                (!_ctx.isEdit)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      (_ctx.shopwareFormBaseData?.email && !_ctx.selectedWeclappCustomer?.email)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createElementVNode("small", null, _toDisplayString(_ctx.$t('labels.empty')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.resolveBackgroundColorForMatching(_ctx.shopwareFormBaseData?.email, _ctx.selectedWeclappCustomer?.email))
                          }, _toDisplayString(_ctx.selectedWeclappCustomer?.email), 3))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createVNode(_component_InputText, {
                        autocomplete: "off",
                        modelValue: _ctx.v$.email.$model,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$.email.$model) = $event)),
                        modelModifiers: { trim: true },
                        class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.email.$invalid && _ctx.submitted}])
                      }, null, 8, ["modelValue", "class"]),
                      (_ctx.showCopyFieldIcon(_ctx.shopwareFormBaseData?.email, _ctx.v$.email.$model))
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: "pi pi-wrench ml-3 text-xl",
                            style: {"cursor":"pointer"},
                            onClick: _cache[5] || (_cache[5] = $event => (_ctx.v$.email.$model = _ctx.shopwareFormBaseData?.email))
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.submitted)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.email.$silentErrors, (error) => {
                            return (_openBlock(), _createElementBlock("small", {
                              style: {"display":"block"},
                              class: "p-error",
                              key: error
                            }, _toDisplayString(error.$message), 1))
                          }), 128))
                        : _createCommentVNode("", true)
                    ]))
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["grid", {'mb-3': !_ctx.isEdit}])
              }, [
                _cache[39] || (_cache[39] = _createElementVNode("div", { class: "col" }, "USt-ID", -1)),
                (!_ctx.isEdit)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      (_ctx.shopwareFormBaseData?.vatNumber && !_ctx.selectedWeclappCustomer?.taxId && !_ctx.selectedWeclappCustomer?.vatIdentificationNumber)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                            _createElementVNode("small", null, _toDisplayString(_ctx.$t('labels.empty')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.resolveBackgroundColorForMatching(_ctx.shopwareFormBaseData?.vatNumber, _ctx.selectedWeclappCustomer?.taxId || _ctx.selectedWeclappCustomer?.vatIdentificationNumber))
                          }, _toDisplayString(_ctx.selectedWeclappCustomer?.taxId ||
                    _ctx.selectedWeclappCustomer?.vatIdentificationNumber), 3))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createVNode(_component_InputText, {
                        autocomplete: "off",
                        modelValue: _ctx.v$.taxId.$model,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.v$.taxId.$model) = $event)),
                        modelModifiers: { trim: true },
                        class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.taxId.$invalid && _ctx.submitted}])
                      }, null, 8, ["modelValue", "class"]),
                      (_ctx.showCopyFieldIcon(_ctx.shopwareFormBaseData?.vatNumber, _ctx.v$.taxId.$model))
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: "pi pi-wrench ml-3 text-xl",
                            style: {"cursor":"pointer"},
                            onClick: _cache[7] || (_cache[7] = $event => (_ctx.v$.taxId.$model = _ctx.shopwareFormBaseData?.vatNumber))
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.submitted)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.taxId.$silentErrors, (error) => {
                            return (_openBlock(), _createElementBlock("small", {
                              style: {"display":"block"},
                              class: "p-error",
                              key: error
                            }, _toDisplayString(error.$message), 1))
                          }), 128))
                        : _createCommentVNode("", true)
                    ]))
              ], 2),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('labels.address')), 1),
                _createElementVNode("div", _hoisted_20, [
                  (!_ctx.isEdit && (_ctx.selectedWeclappCustomer?.addresses || []).length > 1)
                    ? (_openBlock(), _createBlock(_component_p_dropdown, {
                        key: 0,
                        class: "w-full",
                        onChange: _ctx.resetForm,
                        modelValue: _ctx.selectedAddressId,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.selectedAddressId) = $event)),
                        optionValue: "id",
                        options: _ctx.selectedWeclappCustomer?.addresses
                      }, {
                        value: _withCtx((slotProps) => [
                          (slotProps.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                _createElementVNode("div", null, [
                                  _createTextVNode(_toDisplayString(_ctx.selectedWeclappAddress?.street1), 1),
                                  (_ctx.selectedWeclappAddress?.street2)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.selectedWeclappAddress?.street2), 1))
                                    : _createCommentVNode("", true),
                                  _createTextVNode(", " + _toDisplayString(_ctx.selectedWeclappAddress?.zipcode) + ", " + _toDisplayString(_ctx.selectedWeclappAddress?.city) + ", " + _toDisplayString(_ctx.resolveCountryFromCode(_ctx.selectedWeclappAddress?.countryCode)), 1)
                                ]),
                                (_ctx.selectedWeclappAddress?.primeAddress)
                                  ? (_openBlock(), _createElementBlock("i", _hoisted_23))
                                  : _createCommentVNode("", true),
                                (_ctx.selectedWeclappAddress?.invoiceAddress)
                                  ? (_openBlock(), _createElementBlock("i", _hoisted_24))
                                  : _createCommentVNode("", true),
                                (_ctx.selectedWeclappAddress?.deliveryAddress)
                                  ? (_openBlock(), _createElementBlock("i", _hoisted_25))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        option: _withCtx((slotProps) => [
                          _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("div", null, [
                              _createTextVNode(_toDisplayString(slotProps.option.street1), 1),
                              (slotProps.option.street2)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(slotProps.option.street2), 1))
                                : _createCommentVNode("", true),
                              _createTextVNode(", " + _toDisplayString(slotProps.option.zipcode) + ", " + _toDisplayString(slotProps.option.city) + ", " + _toDisplayString(_ctx.resolveCountryFromCode(slotProps.option.countryCode)), 1)
                            ]),
                            (slotProps.option.primeAddress)
                              ? (_openBlock(), _createElementBlock("i", _hoisted_28))
                              : _createCommentVNode("", true),
                            (slotProps.option.invoiceAddress)
                              ? (_openBlock(), _createElementBlock("i", _hoisted_29))
                              : _createCommentVNode("", true),
                            (slotProps.option.deliveryAddress)
                              ? (_openBlock(), _createElementBlock("i", _hoisted_30))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      }, 8, ["onChange", "modelValue", "options"]))
                    : (_ctx.selectedWeclappAddress)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                          (_ctx.selectedWeclappAddress?.primeAddress)
                            ? (_openBlock(), _createElementBlock("i", _hoisted_32))
                            : _createCommentVNode("", true),
                          (_ctx.selectedWeclappAddress?.invoiceAddress)
                            ? (_openBlock(), _createElementBlock("i", _hoisted_33))
                            : _createCommentVNode("", true),
                          (_ctx.selectedWeclappAddress?.deliveryAddress)
                            ? (_openBlock(), _createElementBlock("i", _hoisted_34))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit, 'mt-5': _ctx.isEdit && !_ctx.shopData?.hasSameShippingAddress && _ctx.shopData?.shippingAddress}])
              }, [
                _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.$t('labels.company')), 1),
                (!_ctx.isEdit)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                      (_ctx.shopwareFormBaseData?.company && !_ctx.selectedWeclappCustomer?.company && !_ctx.selectedWeclappAddress?.company)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                            _createElementVNode("small", null, _toDisplayString(_ctx.$t('labels.empty')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.resolveBackgroundColorForMatching(_ctx.shopwareFormBaseData?.company, _ctx.selectedWeclappAddress?.company || _ctx.selectedWeclappCustomer?.company))
                          }, _toDisplayString(_ctx.selectedWeclappAddress?.company ||
                    _ctx.selectedWeclappCustomer?.company), 3))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_38, [
                      _createVNode(_component_InputText, {
                        autocomplete: "off",
                        modelValue: _ctx.v$.company.$model,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$.company.$model) = $event)),
                        modelModifiers: { trim: true },
                        class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.company.$invalid && _ctx.submitted}])
                      }, null, 8, ["modelValue", "class"]),
                      (_ctx.showCopyFieldIcon(_ctx.shopwareFormBaseData?.company, _ctx.v$.company.$model))
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: "pi pi-wrench ml-3 text-xl",
                            style: {"cursor":"pointer"},
                            onClick: _cache[10] || (_cache[10] = $event => (_ctx.v$.company.$model = _ctx.shopwareFormBaseData?.company))
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.submitted)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.company.$silentErrors, (error) => {
                            return (_openBlock(), _createElementBlock("small", {
                              style: {"display":"block"},
                              class: "p-error",
                              key: error
                            }, _toDisplayString(error.$message), 1))
                          }), 128))
                        : _createCommentVNode("", true)
                    ]))
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
              }, [
                _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.$t('labels.company2')), 1),
                (!_ctx.isEdit)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                      (_ctx.shopwareFormBaseData?.department && ((_ctx.selectedWeclappAddress?.company && !_ctx.selectedWeclappAddress?.company2) || (!_ctx.selectedWeclappAddress?.company && !_ctx.selectedWeclappCustomer?.company2)))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                            _createElementVNode("small", null, _toDisplayString(_ctx.$t('labels.empty')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.resolveBackgroundColorForMatching(_ctx.shopwareFormBaseData?.department, _ctx.selectedWeclappAddress?.company ?
                    _ctx.selectedWeclappAddress?.company2 :
                    _ctx.selectedWeclappCustomer?.company2))
                          }, _toDisplayString(_ctx.selectedWeclappAddress?.company ?
                    _ctx.selectedWeclappAddress?.company2 :
                    _ctx.selectedWeclappCustomer?.company2), 3))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_42, [
                      _createVNode(_component_InputText, {
                        autocomplete: "off",
                        modelValue: _ctx.v$.department.$model,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.v$.department.$model) = $event)),
                        modelModifiers: { trim: true },
                        class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.department.$invalid && _ctx.submitted}])
                      }, null, 8, ["modelValue", "class"]),
                      (_ctx.showCopyFieldIcon(_ctx.shopwareFormBaseData?.department, _ctx.v$.department.$model))
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: "pi pi-wrench ml-3 text-xl",
                            style: {"cursor":"pointer"},
                            onClick: _cache[12] || (_cache[12] = $event => (_ctx.v$.department.$model = _ctx.shopwareFormBaseData?.department))
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.submitted)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.department.$silentErrors, (error) => {
                            return (_openBlock(), _createElementBlock("small", {
                              style: {"display":"block"},
                              class: "p-error",
                              key: error
                            }, _toDisplayString(error.$message), 1))
                          }), 128))
                        : _createCommentVNode("", true)
                    ]))
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
              }, [
                _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.$t('labels.street')), 1),
                (!_ctx.isEdit)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                      (_ctx.shopwareFormBaseData?.street && !_ctx.selectedWeclappAddress?.street1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                            _createElementVNode("small", null, _toDisplayString(_ctx.$t('labels.empty')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.resolveBackgroundColorForMatching(_ctx.shopwareFormBaseData?.street, _ctx.selectedWeclappAddress?.street1))
                          }, _toDisplayString(_ctx.selectedWeclappAddress?.street1), 3))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_46, [
                      _createVNode(_component_InputText, {
                        autocomplete: "off",
                        modelValue: _ctx.v$.street.$model,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.v$.street.$model) = $event)),
                        modelModifiers: { trim: true },
                        class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.street.$invalid && _ctx.submitted}])
                      }, null, 8, ["modelValue", "class"]),
                      (_ctx.showCopyFieldIcon(_ctx.shopwareFormBaseData?.street, _ctx.v$.street.$model))
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: "pi pi-wrench ml-3 text-xl",
                            style: {"cursor":"pointer"},
                            onClick: _cache[14] || (_cache[14] = $event => (_ctx.v$.street.$model = _ctx.shopwareFormBaseData?.street))
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.submitted)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.street.$silentErrors, (error) => {
                            return (_openBlock(), _createElementBlock("small", {
                              style: {"display":"block"},
                              class: "p-error",
                              key: error
                            }, _toDisplayString(error.$message), 1))
                          }), 128))
                        : _createCommentVNode("", true)
                    ]))
              ], 2),
              (_ctx.isEdit || (_ctx.shopwareFormBaseData?.street2 || _ctx.selectedWeclappAddress?.street2))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
                  }, [
                    _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.$t('labels.street2')), 1),
                    (!_ctx.isEdit)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                          (_ctx.shopwareFormBaseData?.street2 && !_ctx.selectedWeclappAddress?.street2)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                                _createElementVNode("small", null, _toDisplayString(_ctx.$t('labels.empty')), 1)
                              ]))
                            : (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass(_ctx.resolveBackgroundColorForMatching(_ctx.shopwareFormBaseData?.street2, _ctx.selectedWeclappAddress?.street2))
                              }, _toDisplayString(_ctx.selectedWeclappAddress?.street2), 3))
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_50, [
                          _createVNode(_component_InputText, {
                            autocomplete: "off",
                            modelValue: _ctx.v$.street2.$model,
                            "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => ((_ctx.v$.street2.$model) = $event)),
                            modelModifiers: { trim: true },
                            class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.street2.$invalid && _ctx.submitted}])
                          }, null, 8, ["modelValue", "class"]),
                          (_ctx.showCopyFieldIcon(_ctx.shopwareFormBaseData?.street2, _ctx.v$.street2.$model))
                            ? (_openBlock(), _createElementBlock("i", {
                                key: 0,
                                class: "pi pi-wrench ml-3 text-xl",
                                style: {"cursor":"pointer"},
                                onClick: _cache[16] || (_cache[16] = $event => (_ctx.v$.street2.$model = _ctx.shopwareFormBaseData?.street2))
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.submitted)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.street2.$silentErrors, (error) => {
                                return (_openBlock(), _createElementBlock("small", {
                                  style: {"display":"block"},
                                  class: "p-error",
                                  key: error
                                }, _toDisplayString(error.$message), 1))
                              }), 128))
                            : _createCommentVNode("", true)
                        ]))
                  ], 2))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["grid mb-1", {'mb-1': !_ctx.isEdit}])
              }, [
                _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.$t('labels.zipCode')), 1),
                (!_ctx.isEdit)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                      (_ctx.shopwareFormBaseData?.zipcode && !_ctx.selectedWeclappAddress?.zipcode)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
                            _createElementVNode("small", null, _toDisplayString(_ctx.$t('labels.empty')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.resolveBackgroundColorForMatching(_ctx.shopwareFormBaseData?.zipcode, _ctx.selectedWeclappAddress?.zipcode))
                          }, _toDisplayString(_ctx.selectedWeclappAddress?.zipcode), 3))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_54, [
                      _createVNode(_component_InputText, {
                        autocomplete: "off",
                        modelValue: _ctx.v$.zipcode.$model,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => ((_ctx.v$.zipcode.$model) = $event)),
                        modelModifiers: { trim: true },
                        class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.zipcode.$invalid && _ctx.submitted}])
                      }, null, 8, ["modelValue", "class"]),
                      (_ctx.showCopyFieldIcon(_ctx.shopwareFormBaseData?.zipcode, _ctx.v$.zipcode.$model))
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: "pi pi-wrench ml-3 text-xl",
                            style: {"cursor":"pointer"},
                            onClick: _cache[18] || (_cache[18] = $event => (_ctx.v$.zipcode.$model = _ctx.shopwareFormBaseData?.zipcode))
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.submitted)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.zipcode.$silentErrors, (error) => {
                            return (_openBlock(), _createElementBlock("small", {
                              style: {"display":"block"},
                              class: "p-error",
                              key: error
                            }, _toDisplayString(error.$message), 1))
                          }), 128))
                        : _createCommentVNode("", true)
                    ]))
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
              }, [
                _createElementVNode("div", _hoisted_55, _toDisplayString(_ctx.$t('labels.city')), 1),
                (!_ctx.isEdit)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                      (_ctx.shopwareFormBaseData?.city && !_ctx.selectedWeclappAddress?.city)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                            _createElementVNode("small", null, _toDisplayString(_ctx.$t('labels.empty')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.resolveBackgroundColorForMatching(_ctx.shopwareFormBaseData?.city, _ctx.selectedWeclappAddress?.city))
                          }, _toDisplayString(_ctx.selectedWeclappAddress?.city), 3))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_58, [
                      _createVNode(_component_InputText, {
                        autocomplete: "off",
                        modelValue: _ctx.v$.city.$model,
                        "onUpdate:modelValue": _cache[19] || (_cache[19] = $event => ((_ctx.v$.city.$model) = $event)),
                        modelModifiers: { trim: true },
                        class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.city.$invalid && _ctx.submitted}])
                      }, null, 8, ["modelValue", "class"]),
                      (_ctx.showCopyFieldIcon(_ctx.shopwareFormBaseData?.city, _ctx.v$.city.$model))
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: "pi pi-wrench ml-3 text-xl",
                            style: {"cursor":"pointer"},
                            onClick: _cache[20] || (_cache[20] = $event => (_ctx.v$.city.$model = _ctx.shopwareFormBaseData?.city))
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.submitted)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.city.$silentErrors, (error) => {
                            return (_openBlock(), _createElementBlock("small", {
                              style: {"display":"block"},
                              class: "p-error",
                              key: error
                            }, _toDisplayString(error.$message), 1))
                          }), 128))
                        : _createCommentVNode("", true)
                    ]))
              ], 2),
              _createElementVNode("div", _hoisted_59, [
                _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.$t('labels.country')), 1),
                (!_ctx.isEdit)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                      (_ctx.shopwareFormBaseData?.country && !_ctx.selectedWeclappAddress?.countryCode)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                            _createElementVNode("small", null, _toDisplayString(_ctx.$t('labels.empty')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.resolveBackgroundColorForMatching(_ctx.shopwareFormBaseData?.country, _ctx.selectedWeclappAddress?.countryCode))
                          }, _toDisplayString(_ctx.resolveCountryFromCode(_ctx.selectedWeclappAddress?.countryCode)), 3))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_63, [
                      _createVNode(_component_p_dropdown, {
                        modelValue: _ctx.v$.country.$model,
                        "onUpdate:modelValue": _cache[21] || (_cache[21] = $event => ((_ctx.v$.country.$model) = $event)),
                        modelModifiers: { trim: true },
                        class: _normalizeClass(["text-sm w-7", {'p-invalid':_ctx.v$.country.$invalid && _ctx.submitted}]),
                        optionLabel: "label",
                        optionValue: "value",
                        options: _ctx.countries
                      }, null, 8, ["modelValue", "class", "options"]),
                      (_ctx.showCopyFieldIcon(_ctx.shopwareFormBaseData?.country, _ctx.v$.country.$model))
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: "pi pi-wrench ml-3 text-xl",
                            style: {"cursor":"pointer"},
                            onClick: _cache[22] || (_cache[22] = $event => (_ctx.v$.country.$model = _ctx.shopwareFormBaseData?.country))
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.submitted)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.country.$silentErrors, (error) => {
                            return (_openBlock(), _createElementBlock("small", {
                              style: {"display":"block"},
                              class: "p-error",
                              key: error
                            }, _toDisplayString(error.$message), 1))
                          }), 128))
                        : _createCommentVNode("", true)
                    ]))
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["grid", {'mb-5': !_ctx.isEdit}])
              }, [
                _createElementVNode("div", _hoisted_64, _toDisplayString(_ctx.$t('labels.phoneNumber')), 1),
                (!_ctx.isEdit)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                      (_ctx.shopwareFormBaseData?.phoneNumber && !_ctx.selectedWeclappCustomer?.phone)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_66, [
                            _createElementVNode("small", null, _toDisplayString(_ctx.$t('labels.empty')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.resolveBackgroundColorForMatching(_ctx.shopwareFormBaseData?.phoneNumber, _ctx.selectedWeclappCustomer?.phone))
                          }, _toDisplayString(_ctx.selectedWeclappCustomer?.phone), 3))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_67, [
                      _createVNode(_component_InputText, {
                        autocomplete: "off",
                        modelValue: _ctx.v$.phoneNumber.$model,
                        "onUpdate:modelValue": _cache[23] || (_cache[23] = $event => ((_ctx.v$.phoneNumber.$model) = $event)),
                        modelModifiers: { trim: true },
                        class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.phoneNumber.$invalid && _ctx.submitted}])
                      }, null, 8, ["modelValue", "class"]),
                      (_ctx.showCopyFieldIcon(_ctx.shopwareFormBaseData?.phoneNumber, _ctx.v$.phoneNumber.$model))
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: "pi pi-wrench ml-3 text-xl",
                            style: {"cursor":"pointer"},
                            onClick: _cache[24] || (_cache[24] = $event => (_ctx.v$.phoneNumber.$model = _ctx.shopwareFormBaseData?.phoneNumber))
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.submitted)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.phoneNumber.$silentErrors, (error) => {
                            return (_openBlock(), _createElementBlock("small", {
                              style: {"display":"block"},
                              class: "p-error",
                              key: error
                            }, _toDisplayString(error.$message), 1))
                          }), 128))
                        : _createCommentVNode("", true)
                    ]))
              ], 2),
              _createElementVNode("div", _hoisted_68, [
                _createElementVNode("div", _hoisted_69, _toDisplayString(_ctx.$t('labels.contactPersonWcp')), 1),
                _createElementVNode("div", _hoisted_70, [
                  (!_ctx.isEdit && (_ctx.selectedWeclappCustomer?.contacts || []).length > 1)
                    ? (_openBlock(), _createBlock(_component_p_dropdown, {
                        key: 0,
                        class: "w-full",
                        modelValue: _ctx.selectedContactId,
                        "onUpdate:modelValue": _cache[25] || (_cache[25] = $event => ((_ctx.selectedContactId) = $event)),
                        optionValue: "id",
                        options: _ctx.selectedWeclappCustomer?.contacts
                      }, {
                        value: _withCtx((slotProps) => [
                          (slotProps.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_71, [
                                _createElementVNode("div", null, _toDisplayString(_ctx.formatWeclappSalutation(_ctx.selectedContact?.salutation)) + " " + _toDisplayString(_ctx.selectedContact?.firstName) + " " + _toDisplayString(_ctx.selectedContact?.lastName), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        option: _withCtx((slotProps) => [
                          _createElementVNode("div", _hoisted_72, [
                            _createElementVNode("div", null, _toDisplayString(_ctx.formatWeclappSalutation(slotProps.option.salutation)) + " " + _toDisplayString(slotProps.option.firstName) + " " + _toDisplayString(slotProps.option.lastName), 1)
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "options"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              (_ctx.selectedContact)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
                    }, [
                      _createElementVNode("div", _hoisted_73, _toDisplayString(_ctx.$t('labels.salutation')), 1),
                      (!_ctx.isEdit)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_74, [
                            (_ctx.shopwareFormBaseData?.salutation && !_ctx.selectedContact?.salutation)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_75, [
                                  _createElementVNode("small", null, _toDisplayString(_ctx.$t('labels.empty')), 1)
                                ]))
                              : (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  class: _normalizeClass(_ctx.resolveBackgroundColorForMatching(_ctx.shopwareFormBaseData?.salutation, _ctx.formatWeclappSalutation(_ctx.selectedContact?.salutation)))
                                }, _toDisplayString(_ctx.formatWeclappSalutation(_ctx.selectedContact?.salutation)), 3))
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_76, [
                            _createVNode(_component_p_dropdown, {
                              class: "w-7",
                              modelValue: _ctx.v$.salutation.$model,
                              "onUpdate:modelValue": _cache[26] || (_cache[26] = $event => ((_ctx.v$.salutation.$model) = $event)),
                              optionLabel: "label",
                              optionValue: "value",
                              options: [{label: 'Herr', value: 'MR'}, {label: 'Frau', value: 'MRS'}, {label: 'Familie', value: 'FAMILY'}, {label: 'Firma', value: 'COMPANY'}, {label: 'Keine Anrede', value: 'NO_SALUTATION'}]
                            }, null, 8, ["modelValue"]),
                            (_ctx.submitted)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.salutation.$silentErrors, (error) => {
                                  return (_openBlock(), _createElementBlock("small", {
                                    style: {"display":"block"},
                                    class: "p-error",
                                    key: error
                                  }, _toDisplayString(error.$message), 1))
                                }), 128))
                              : _createCommentVNode("", true)
                          ]))
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
                    }, [
                      _createElementVNode("div", _hoisted_77, _toDisplayString(_ctx.$t('labels.firstName')), 1),
                      (!_ctx.isEdit)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
                            (_ctx.shopwareFormBaseData?.firstName && !_ctx.selectedContact?.firstName)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_79, [
                                  _createElementVNode("small", null, _toDisplayString(_ctx.$t('labels.empty')), 1)
                                ]))
                              : (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  class: _normalizeClass(_ctx.resolveBackgroundColorForMatching(_ctx.shopwareFormBaseData?.firstName, _ctx.selectedContact?.firstName))
                                }, _toDisplayString(_ctx.selectedContact?.firstName), 3))
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_80, [
                            _createVNode(_component_InputText, {
                              autocomplete: "off",
                              modelValue: _ctx.v$.firstName.$model,
                              "onUpdate:modelValue": _cache[27] || (_cache[27] = $event => ((_ctx.v$.firstName.$model) = $event)),
                              modelModifiers: { trim: true },
                              class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.firstName.$invalid && _ctx.submitted}])
                            }, null, 8, ["modelValue", "class"]),
                            (_ctx.showCopyFieldIcon(_ctx.shopwareFormBaseData?.firstName, _ctx.v$.firstName.$model))
                              ? (_openBlock(), _createElementBlock("i", {
                                  key: 0,
                                  class: "pi pi-wrench ml-3 text-xl",
                                  style: {"cursor":"pointer"},
                                  onClick: _cache[28] || (_cache[28] = $event => (_ctx.v$.firstName.$model = _ctx.shopwareFormBaseData?.firstName))
                                }))
                              : _createCommentVNode("", true),
                            (_ctx.submitted)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.firstName.$silentErrors, (error) => {
                                  return (_openBlock(), _createElementBlock("small", {
                                    style: {"display":"block"},
                                    class: "p-error",
                                    key: error
                                  }, _toDisplayString(error.$message), 1))
                                }), 128))
                              : _createCommentVNode("", true)
                          ]))
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
                    }, [
                      _createElementVNode("div", _hoisted_81, _toDisplayString(_ctx.$t('labels.lastName')), 1),
                      (!_ctx.isEdit)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
                            (_ctx.shopwareFormBaseData?.lastName && !_ctx.selectedContact?.lastName)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_83, [
                                  _createElementVNode("small", null, _toDisplayString(_ctx.$t('labels.empty')), 1)
                                ]))
                              : (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  class: _normalizeClass(_ctx.resolveBackgroundColorForMatching(_ctx.shopwareFormBaseData?.lastName, _ctx.selectedContact?.lastName))
                                }, _toDisplayString(_ctx.selectedContact?.lastName), 3))
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_84, [
                            _createVNode(_component_InputText, {
                              autocomplete: "off",
                              modelValue: _ctx.v$.lastName.$model,
                              "onUpdate:modelValue": _cache[29] || (_cache[29] = $event => ((_ctx.v$.lastName.$model) = $event)),
                              modelModifiers: { trim: true },
                              class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.lastName.$invalid && _ctx.submitted}])
                            }, null, 8, ["modelValue", "class"]),
                            (_ctx.showCopyFieldIcon(_ctx.shopwareFormBaseData?.lastName, _ctx.v$.lastName.$model))
                              ? (_openBlock(), _createElementBlock("i", {
                                  key: 0,
                                  class: "pi pi-wrench ml-3 text-xl",
                                  style: {"cursor":"pointer"},
                                  onClick: _cache[30] || (_cache[30] = $event => (_ctx.v$.lastName.$model = _ctx.shopwareFormBaseData?.lastName))
                                }))
                              : _createCommentVNode("", true),
                            (_ctx.submitted)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.lastName.$silentErrors, (error) => {
                                  return (_openBlock(), _createElementBlock("small", {
                                    style: {"display":"block"},
                                    class: "p-error",
                                    key: error
                                  }, _toDisplayString(error.$message), 1))
                                }), 128))
                              : _createCommentVNode("", true)
                          ]))
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
                    }, [
                      _createElementVNode("div", _hoisted_85, _toDisplayString(_ctx.$t('labels.email')), 1),
                      (!_ctx.isEdit)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_86, [
                            (_ctx.shopwareFormBaseData?.email && !_ctx.selectedContact?.email)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_87, [
                                  _createElementVNode("small", null, _toDisplayString(_ctx.$t('labels.empty')), 1)
                                ]))
                              : (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  class: _normalizeClass(_ctx.resolveBackgroundColorForMatching(_ctx.shopwareFormBaseData?.email, _ctx.selectedContact?.email))
                                }, _toDisplayString(_ctx.selectedContact?.email), 3))
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_88, [
                            _createVNode(_component_InputText, {
                              autocomplete: "off",
                              modelValue: _ctx.v$.contactEmail.$model,
                              "onUpdate:modelValue": _cache[31] || (_cache[31] = $event => ((_ctx.v$.contactEmail.$model) = $event)),
                              modelModifiers: { trim: true },
                              class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.contactEmail.$invalid && _ctx.submitted}])
                            }, null, 8, ["modelValue", "class"]),
                            (_ctx.showCopyFieldIcon(_ctx.shopwareFormBaseData?.email, _ctx.v$.contactEmail.$model))
                              ? (_openBlock(), _createElementBlock("i", {
                                  key: 0,
                                  class: "pi pi-wrench ml-3 text-xl",
                                  style: {"cursor":"pointer"},
                                  onClick: _cache[32] || (_cache[32] = $event => (_ctx.v$.contactEmail.$model = _ctx.shopwareFormBaseData?.email))
                                }))
                              : _createCommentVNode("", true),
                            (_ctx.submitted)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.contactEmail.$silentErrors, (error) => {
                                  return (_openBlock(), _createElementBlock("small", {
                                    style: {"display":"block"},
                                    class: "p-error",
                                    key: error
                                  }, _toDisplayString(error.$message), 1))
                                }), 128))
                              : _createCommentVNode("", true)
                          ]))
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
                    }, [
                      _createElementVNode("div", _hoisted_89, _toDisplayString(_ctx.$t('labels.phoneNumber')), 1),
                      (!_ctx.isEdit)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_90, [
                            (_ctx.shopwareFormBaseData?.phoneNumber && !_ctx.selectedContact?.phone)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_91, [
                                  _createElementVNode("small", null, _toDisplayString(_ctx.$t('labels.empty')), 1)
                                ]))
                              : (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  class: _normalizeClass(_ctx.resolveBackgroundColorForMatching(_ctx.shopwareFormBaseData?.phoneNumber, _ctx.selectedContact?.phone))
                                }, _toDisplayString(_ctx.selectedContact?.phone), 3))
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_92, [
                            _createVNode(_component_InputText, {
                              autocomplete: "off",
                              modelValue: _ctx.v$.contactPhoneNumber.$model,
                              "onUpdate:modelValue": _cache[33] || (_cache[33] = $event => ((_ctx.v$.contactPhoneNumber.$model) = $event)),
                              modelModifiers: { trim: true },
                              class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.contactPhoneNumber.$invalid && _ctx.submitted}])
                            }, null, 8, ["modelValue", "class"]),
                            (_ctx.showCopyFieldIcon(_ctx.shopwareFormBaseData?.phoneNumber, _ctx.v$.contactPhoneNumber.$model))
                              ? (_openBlock(), _createElementBlock("i", {
                                  key: 0,
                                  class: "pi pi-wrench ml-3 text-xl",
                                  style: {"cursor":"pointer"},
                                  onClick: _cache[34] || (_cache[34] = $event => (_ctx.v$.contactPhoneNumber.$model = _ctx.shopwareFormBaseData?.phoneNumber))
                                }))
                              : _createCommentVNode("", true),
                            (_ctx.submitted)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.contactPhoneNumber.$silentErrors, (error) => {
                                  return (_openBlock(), _createElementBlock("small", {
                                    style: {"display":"block"},
                                    class: "p-error",
                                    key: error
                                  }, _toDisplayString(error.$message), 1))
                                }), 128))
                              : _createCommentVNode("", true)
                          ]))
                    ], 2)
                  ], 64))
                : _createCommentVNode("", true),
              (!_ctx.isEdit && (_ctx.selectedWeclappCustomer?.partyEmailAddresses || []).length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_93, [
                    _createElementVNode("div", _hoisted_94, _toDisplayString(_ctx.$t('labels.emailAddresses')), 1),
                    _createElementVNode("div", _hoisted_95, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedWeclappCustomer?.partyEmailAddresses, (em) => {
                        return (_openBlock(), _createElementBlock("div", null, _toDisplayString(em), 1))
                      }), 256))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.selectedWeclappCustomer && _ctx.isEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_96, [
                    _createElementVNode("div", null, [
                      _createElementVNode("i", {
                        class: "pi pi-check text-green-600",
                        style: {"cursor":"pointer"},
                        onClick: _cache[35] || (_cache[35] = $event => (_ctx.handleSubmit(!_ctx.v$.$invalid)))
                      }),
                      _createElementVNode("i", {
                        class: "pi pi-times-circle text-red-600 ml-3",
                        style: {"cursor":"pointer"},
                        onClick: _cache[36] || (_cache[36] = (...args) => (_ctx.resetForm && _ctx.resetForm(...args)))
                      })
                    ])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}