import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex mb-3" }
const _hoisted_2 = { style: {"margin-top":"3px"} }
const _hoisted_3 = { class: "grid p-fluid" }
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = { class: "grid p-fluid" }
const _hoisted_6 = { class: "col field" }
const _hoisted_7 = { class: "grid p-fluid" }
const _hoisted_8 = { class: "col field" }
const _hoisted_9 = { class: "grid p-fluid" }
const _hoisted_10 = { class: "col field" }
const _hoisted_11 = { class: "w-full flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputSwitch = _resolveComponent("InputSwitch")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('labels.ticket.retoure.createSupplierReturn'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[11] || (_cache[11] = $event => ((_ctx.showDialog) = $event)),
    closable: false,
    breakpoints: {'960px': '75vw', '640px': '90vw'},
    style: {width: '640px'},
    modal: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_loading, {
        active: _ctx.savingInProgress,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.savingInProgress) = $event))
      }, null, 8, ["active"]),
      _createElementVNode("form", {
        onSubmit: _cache[10] || (_cache[10] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('labels.ticket.retoure.purchaseOrderAsBase')), 1),
          _createVNode(_component_InputSwitch, {
            modelValue: _ctx.selectUsingPurchaseOrder,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.selectUsingPurchaseOrder) = $event)),
            onInput: _cache[2] || (_cache[2] = $event => {_ctx.setDropdownValue('purchaseOrder', {value: null});_ctx.setDropdownValue('supplier', {value: null})}),
            class: "ml-3"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.retoure.purchaseOrder')), 1),
            _createVNode(_component_p_dropdown, {
              modelValue: _ctx.v$.purchaseOrder.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.purchaseOrder.$model) = $event)),
              options: _ctx.purchaseOrders,
              disabled: !_ctx.selectUsingPurchaseOrder,
              optionLabel: "label",
              optionValue: "value",
              filter: true,
              class: _normalizeClass(["w-full", {'p-invalid':_ctx.v$.purchaseOrder.$invalid && _ctx.submitted}]),
              onChange: _cache[4] || (_cache[4] = $event => (_ctx.setDropdownValue('purchaseOrder', $event))),
              showClear: true
            }, null, 8, ["modelValue", "options", "disabled", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.purchaseOrder.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.supplier')), 1),
            _createVNode(_component_p_dropdown, {
              modelValue: _ctx.v$.supplier.$model,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$.supplier.$model) = $event)),
              options: _ctx.selectUsingPurchaseOrder ? (_ctx.suppliers || []) : (_ctx.allSuppliers || []),
              optionLabel: "label",
              optionValue: "value",
              disabled: _ctx.selectUsingPurchaseOrder,
              filter: true,
              class: _normalizeClass(["w-full", {'p-invalid':_ctx.v$.supplier.$invalid && _ctx.submitted}]),
              onChange: _cache[6] || (_cache[6] = $event => (_ctx.setDropdownValue('supplier', $event))),
              showClear: true
            }, null, 8, ["modelValue", "options", "disabled", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.supplier.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.warehouse')), 1),
            _createVNode(_component_p_dropdown, {
              modelValue: _ctx.v$.warehouse.$model,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.v$.warehouse.$model) = $event)),
              options: _ctx.warehouses || [],
              optionLabel: "name",
              optionValue: "id",
              filter: true,
              class: _normalizeClass(["w-full", {'p-invalid':_ctx.v$.warehouse.$invalid && _ctx.submitted}]),
              onChange: _cache[8] || (_cache[8] = $event => (_ctx.setDropdownValue('warehouse', $event))),
              showClear: true
            }, null, 8, ["modelValue", "options", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.warehouse.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.additional.externalRMA')), 1),
            _createVNode(_component_InputText, {
              autocomplete: "off",
              modelValue: _ctx.v$.externalRMA.$model,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$.externalRMA.$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$.externalRMA.$invalid && _ctx.submitted})
            }, null, 8, ["modelValue", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.externalRMA.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_p_button, {
            severity: "danger",
            onClick: _ctx.onCancelClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_p_button, {
            type: "submit",
            severity: "success"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
            ]),
            _: 1
          })
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}