import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid formgrid p-fluid mb-3" }
const _hoisted_2 = { class: "field col" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { style: {"text-align":"center"} }

export function render(_ctx, _cache) {
  const _component_InputText = _resolveComponent("InputText")
  const _component_FileUpload = _resolveComponent("FileUpload")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_BulkProductAction = _resolveComponent("BulkProductAction")

  return (_openBlock(), _createBlock(_component_BulkProductAction, {
    onCloseDialog: _ctx.closeResponsive,
    onConfirmClicked: _ctx.confirmClicked,
    products: _ctx.products,
    productTotalRecords: _ctx.productTotalRecords,
    displayDialog: _ctx.showDialog,
    isFilterRelated: _ctx.isFilterRelated,
    summaryTabVisible: _ctx.summaryTabVisible,
    loading: _ctx.loading,
    dialogHeader: _ctx.$t('labels.addShopwareDocuments')
  }, {
    "tab-panel-middle": _withCtx(() => [
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('labels.documents')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.description')), 1),
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: _ctx.description,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.description) = $event))
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createVNode(_component_FileUpload, { onFilesSelected: _ctx.onFileSelected }, null, 8, ["onFilesSelected"])
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    "summary-panel-header": _withCtx(() => [
      _createVNode(_component_Panel, { class: "mb-3" }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('labels.documents')), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_DataTable, {
            class: "p-datatable-sm text-sm",
            stripedRows: "",
            paginator: _ctx.selectedFiles && _ctx.selectedFiles.length > 20,
            rows: 20,
            value: _ctx.selectedFiles,
            responsiveLayout: "scroll",
            "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
            "rows-per-page-options": [10,20,50,100,200],
            "current-page-report-template": "{first} to {last} of {totalRecords}"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "name",
                style: {"min-width":"12rem"}
              }),
              _createVNode(_component_Column, { style: {"min-width":"12rem"} }, {
                body: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.description), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["paginator", "value"])
        ]),
        _: 1
      })
    ]),
    "summary-panel-middle": _withCtx(() => [
      _createElementVNode("h1", _hoisted_4, [
        _createVNode(_component_font_awesome_icon, {
          style: {"margin-left":"30px"},
          icon: ['fas', 'angle-double-down']
        }),
        _createVNode(_component_font_awesome_icon, {
          style: {"margin-left":"30px"},
          icon: ['fas', 'angle-double-down']
        }),
        _createVNode(_component_font_awesome_icon, {
          style: {"margin-left":"30px"},
          icon: ['fas', 'angle-double-down']
        })
      ])
    ]),
    _: 1
  }, 8, ["onCloseDialog", "onConfirmClicked", "products", "productTotalRecords", "displayDialog", "isFilterRelated", "summaryTabVisible", "loading", "dialogHeader"]))
}