<PrimeDialog
    :header="$t('labels.productPrices.addScaledPrice')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '65vw'}"
    :closable="false"
    :modal="true"
>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <p-fieldset :legend="$t('labels.productPrices.priceInformation')">
            <div class="grid">
                <div class="col">
                    <label class="form-label text-sm"
                        >{{ $t('labels.price') }}
                    </label>

                    <div>
                        <InputNumber
                            :locale="locale"
                            mode="currency"
                            :currency="currencyName"
                            :maxFractionDigits="2"
                            autocomplete="off"
                            v-model="v$.price.$model"
                            :class="{'p-invalid':v$.price.$invalid && submitted, 'mb-3':  !((v$.price.$invalid && submitted) || v$.price.$pending.$response)}"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.price.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="col">
                    <label class="form-label text-sm"
                        >{{ $t('labels.priceRangeFrom') }}
                    </label>

                    <div>
                        <InputNumber
                            autocomplete="off"
                            showButtons
                            :min="priceScaleMinValue"
                            v-model="v$.priceScaleValue.$model"
                            :class="{'p-invalid':v$.priceScaleValue.$invalid && submitted, 'mb-3':  !((v$.priceScaleValue.$invalid && submitted) || v$.priceScaleValue.$pending.$response)}"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.priceScaleValue.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="col">
                    <label class="form-label text-sm"
                        >{{ $t('labels.startDate') }}
                    </label>
                    <div>
                        <InputText
                            disabled
                            autocomplete="off"
                            v-model="startDate"
                            class="mb-1"
                        />
                    </div>
                </div>
                <div class="col">
                    <label class="form-label text-sm"
                        >{{ $t('labels.endDate') }}
                    </label>

                    <div>
                        <InputText
                            disabled
                            autocomplete="off"
                            v-model="endDate"
                            class="mb-1"
                        />
                    </div>
                </div>
            </div>
        </p-fieldset>
        <Panel class="mt-3">
            <template #header>
                <div>
                    <span class="font-weight-bold"
                        >{{$t('labels.productPrices.reductionAdditions')}}</span
                    ><span
                        class="pi pi-plus-circle ml-2 text-green-600"
                        style="cursor: pointer"
                        @click="addReductionAddition"
                    ></span>
                </div>
            </template>

            <template
                v-for="(item, index) in state.reductionAdditions"
                :key="index"
            >
                <div class="grid mt-3">
                    <div class="col">
                        <label class="form-label text-sm">
                            {{ $t('labels.name') }}
                        </label>
                        <div>
                            <p-dropdown
                                class="w-full"
                                v-model="item.description"
                                :disabled="!!item.id"
                                :options="reductionAdditionValueOptions"
                                optionLabel="label"
                                optionValue="value"
                            >
                            </p-dropdown>
                            <small
                                v-if="submitted"
                                style="display: block"
                                class="p-error"
                                v-for="error in v$.reductionAdditions.$each.$response.$errors[index].description"
                                :key="error"
                            >
                                {{ error.$message }}
                            </small>
                        </div>
                    </div>

                    <div class="col">
                        <label class="form-label text-sm">
                            {{ $t('labels.productPrices.reductionAdditionType')
                            }}
                        </label>
                        <div>
                            <p-dropdown
                                class="w-full"
                                v-model="item.type"
                                :disabled="!!item.id"
                                :options="reductionAdditionValueTypeOptions"
                                optionLabel="label"
                                optionValue="value"
                            >
                            </p-dropdown>
                            <small
                                v-if="submitted"
                                style="display: block"
                                class="p-error"
                                v-for="error in v$.reductionAdditions.$each.$response.$errors[index].type"
                                :key="error"
                            >
                                {{ error.$message }}
                            </small>
                        </div>
                    </div>

                    <div class="col">
                        <label class="form-label text-sm">
                            {{ $t('labels.productPrices.reductionAdditionValue')
                            }}
                        </label>
                        <div>
                            <InputNumber
                                mode="decimal"
                                :locale="locale"
                                :maxFractionDigits="2"
                                v-model="item.value"
                                :class="{'p-invalid':submitted && v$.reductionAdditions.$each.$response.$data[index].value.$invalid}"
                            />
                            <span
                                class="pi pi-minus-circle text-red-600 ml-2 mt-2"
                                style="cursor: pointer"
                                @click="removeReductionAddition(index)"
                            ></span>
                            <small
                                v-if="submitted"
                                style="display: block"
                                class="p-error"
                                v-for="error in v$.reductionAdditions.$each.$response.$errors[index].value"
                                :key="error"
                            >
                                {{ error.$message }}
                            </small>
                        </div>
                    </div>
                </div>
            </template>
        </Panel>
        <div class="row mt-4 flex justify-content-between">
            <p-button
                severity="danger"
                v-text="$t('buttons.cancel')"
                @click="closeResponsive(null)"
            >
            </p-button>

            <p-button
                severity="success"
                type="submit"
                v-text="$t('buttons.confirm')"
            >
            </p-button>
        </div>
    </form>
</PrimeDialog>
