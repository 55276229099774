import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "badge badge-danger" }
const _hoisted_2 = { class: "w-full mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.isLoading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.isLoading) = $event))
    }, null, 8, ["active"]),
    _createElementVNode("h6", null, [
      _createTextVNode(_toDisplayString(_ctx.$t("labels.validationResultInvalidRecords")) + ": ", 1),
      _createElementVNode("span", _hoisted_1, _toDisplayString(Object.keys(_ctx.rowsToSkip).length), 1)
    ]),
    _createVNode(_component_DataTable, {
      class: "p-datatable-sm text-sm",
      rowClass: _ctx.rowClass,
      stripedRows: "",
      paginator: true,
      rows: 10,
      dataKey: "id",
      totalRecords: _ctx.totalRecords,
      value: _ctx.rowsToSkip,
      responsiveLayout: "scroll",
      "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
      "rows-per-page-options": [10,20,50],
      "current-page-report-template": "{first} to {last} of {totalRecords}"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "rowIndex",
          headerStyle: "width: 10%",
          header: _ctx.$t('labels.CSVRowNumber')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "reason",
          headerStyle: "width: 60%",
          header: _ctx.$t('labels.validationError')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          headerStyle: "width: 30%",
          header: _ctx.$t('labels.resolution')
        }, {
          body: _withCtx(({data}) => [
            _createTextVNode(_toDisplayString(_ctx.$t('messages.rowWillBeSkipped')), 1)
          ]),
          _: 1
        }, 8, ["header"])
      ]),
      _: 1
    }, 8, ["rowClass", "totalRecords", "value"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_p_button, {
        severity: "danger",
        onClick: _ctx.discardFile
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("buttons.discardFile")), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_p_button, {
        severity: "success",
        onClick: _ctx.processFile
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("buttons.processFile")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ], 64))
}