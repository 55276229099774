import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-lg text-900 font-bold" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "flex-initial flex align-items-center justify-content-center" }
const _hoisted_6 = {
  key: 0,
  class: "pi pi-exclamation-triangle mr-2"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "flex-initial flex align-items-center justify-content-center" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 0,
  class: "mt-1"
}
const _hoisted_13 = { class: "grid mt-5" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "flex justify-content-between" }
const _hoisted_16 = { class: "flex justify-content-between" }
const _hoisted_17 = { class: "flex justify-content-between" }
const _hoisted_18 = { class: "flex justify-content-between" }
const _hoisted_19 = { class: "flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_LoadingPlugin = _resolveComponent("LoadingPlugin")
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_Column = _resolveComponent("Column")
  const _component_InputText = _resolveComponent("InputText")
  const _component_router_link = _resolveComponent("router-link")
  const _component_Skeleton = _resolveComponent("Skeleton")
  const _component_p_calendar = _resolveComponent("p-calendar")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Divider = _resolveComponent("Divider")
  const _component_Panel = _resolveComponent("Panel")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LoadingPlugin, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, null, {
      header: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('labels.shopOrders')), 1),
        _createElementVNode("div", _hoisted_2, [
          _cache[7] || (_cache[7] = _createTextVNode(" Teltec ")),
          _createVNode(_component_p_checkbox, {
            modelValue: _ctx.searchInTeltec,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.searchInTeltec) = $event)),
            disabled: !_ctx.searchInVideodata,
            binary: "",
            class: "ml-2",
            onChange: _cache[2] || (_cache[2] = $event => {_ctx.handlePlatformClick(false); _ctx.loadData()})
          }, null, 8, ["modelValue", "disabled"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[8] || (_cache[8] = _createTextVNode(" Videodata ")),
          _createVNode(_component_p_checkbox, {
            modelValue: _ctx.searchInVideodata,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.searchInVideodata) = $event)),
            disabled: !_ctx.searchInTeltec,
            binary: "",
            class: "ml-2",
            onChange: _cache[4] || (_cache[4] = $event => {_ctx.handlePlatformClick(true); _ctx.loadData()})
          }, null, 8, ["modelValue", "disabled"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          paginator: "",
          rows: 20,
          "paginator-template": 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
          "rows-per-page-options": [10,20,50,100,200],
          "current-page-report-template": '{first} to {last} of {totalRecords}',
          editMode: "cell",
          ref: "dt",
          dataKey: "id",
          value: _ctx.foundOrders,
          responsiveLayout: "scroll",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[5] || (_cache[5] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          onFilter: _ctx.onFilter,
          expandedRows: _ctx.expandedRows,
          "onUpdate:expandedRows": _cache[6] || (_cache[6] = $event => ((_ctx.expandedRows) = $event))
        }, {
          expansion: _withCtx((slotProps) => [
            (slotProps.data?.orderLines)
              ? (_openBlock(), _createBlock(_component_DataTable, {
                  key: 0,
                  class: "p-datatable-sm text-sm",
                  stripedRows: "",
                  rows: 20,
                  dataKey: "shopInternalId",
                  value: slotProps.data?.orderLines,
                  responsiveLayout: "scroll"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      field: "articleNumber",
                      style: {"max-width":"3rem"},
                      header: _ctx.$t('labels.articleNumber')
                    }, null, 8, ["header"]),
                    _createVNode(_component_Column, null, {
                      body: _withCtx(({data}) => [
                        (data.availability)
                          ? _withDirectives((_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: _normalizeClass(["availability-dot ml-3", {'bg-red-600':'NOTHING_AVAILABLE' === data.availability, 'bg-yellow-600':'PARTIALLY_AVAILABLE' === data.availability, 'bg-green-400':'COMPLETELY_AVAILABLE' === data.availability}])
                            }, null, 2)), [
                              [
                                _directive_tooltip,
                                _ctx.getAvailabilityTooltipText(data.availability),
                                void 0,
                                { left: true }
                              ]
                            ])
                          : _createCommentVNode("", true),
                        (data.availabilityForAllWarehouses)
                          ? _withDirectives((_openBlock(), _createElementBlock("span", {
                              key: 1,
                              class: _normalizeClass(["availability-dot ml-3", {'bg-red-600':'NOTHING_AVAILABLE' === data.availabilityForAllWarehouses, 'yellow-background':'PARTIALLY_AVAILABLE' === data.availabilityForAllWarehouses, 'bg-green-400':'COMPLETELY_AVAILABLE' === data.availabilityForAllWarehouses}])
                            }, null, 2)), [
                              [
                                _directive_tooltip,
                                _ctx.getAvailabilityTooltipText(data.availabilityForAllWarehouses, true),
                                void 0,
                                { right: true }
                              ]
                            ])
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "articleName",
                      header: _ctx.$t('labels.articleName')
                    }, null, 8, ["header"]),
                    _createVNode(_component_Column, {
                      header: _ctx.$t('labels.shopOrderLinePrice')
                    }, {
                      body: _withCtx(({data}) => [
                        _createTextVNode(_toDisplayString(_ctx.getCurrencyFormatted(data.unitPrice)), 1)
                      ]),
                      _: 1
                    }, 8, ["header"]),
                    _createVNode(_component_Column, {
                      field: "quantity",
                      header: _ctx.$t('labels.shopOrderLineQuantity')
                    }, null, 8, ["header"]),
                    _createVNode(_component_Column, {
                      header: _ctx.$t('labels.productAdd.taxRateType')
                    }, {
                      body: _withCtx(({data}) => [
                        _createTextVNode(_toDisplayString((data.taxRate)) + " % ", 1)
                      ]),
                      _: 1
                    }, 8, ["header"]),
                    _createVNode(_component_Column, {
                      header: _ctx.$t('labels.shopOrderLineTotalPrice')
                    }, {
                      body: _withCtx(({data}) => [
                        _createTextVNode(_toDisplayString(_ctx.getCurrencyFormatted(data.totalPrice)), 1)
                      ]),
                      _: 1
                    }, 8, ["header"])
                  ]),
                  _: 2
                }, 1032, ["value"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_13, [
              _cache[14] || (_cache[14] = _createElementVNode("div", { class: "col-8" }, null, -1)),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _cache[9] || (_cache[9] = _createElementVNode("span", null, "Summe", -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.getCurrencyFormatted(slotProps.data?.orderLines.reduce(
                            (accumulator, currentValue) => accumulator +
                            currentValue.totalPrice, 0 ))), 1)
                ]),
                _createVNode(_component_Divider),
                _createElementVNode("div", _hoisted_16, [
                  _cache[10] || (_cache[10] = _createElementVNode("span", null, "Versandkosten", -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.getCurrencyFormatted(slotProps.data?.shippingAmountNet)), 1)
                ]),
                _createVNode(_component_Divider),
                _createElementVNode("div", _hoisted_17, [
                  _cache[11] || (_cache[11] = _createElementVNode("span", { class: "font-bold" }, "Gesamtsumme ohne MwSt.", -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.getCurrencyFormatted(slotProps.data?.orderLines.reduce(
                            (accumulator, currentValue) => accumulator +
                            currentValue.totalPrice, 0 ) +
                            slotProps.data?.shippingAmountNet)), 1)
                ]),
                _createVNode(_component_Divider),
                _createElementVNode("div", _hoisted_18, [
                  _cache[12] || (_cache[12] = _createElementVNode("span", null, "zzgl. 19% MwSt.", -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.getCurrencyFormatted( slotProps.data?.amountTotal
                            - slotProps.data?.orderLines.reduce( (accumulator,
                            currentValue) => accumulator +
                            currentValue.totalPrice, 0 ) -
                            slotProps.data?.shippingAmountNet)), 1)
                ]),
                _createVNode(_component_Divider),
                _createElementVNode("div", _hoisted_19, [
                  _cache[13] || (_cache[13] = _createElementVNode("span", { class: "font-bold" }, "Gesamtsumme inkl. MwSt.", -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.getCurrencyFormatted(
                            slotProps.data?.amountTotal)), 1)
                ])
              ]),
              _cache[15] || (_cache[15] = _createElementVNode("div", { class: "col-1" }, null, -1))
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              expander: "",
              headerStyle: "width: 3em"
            }),
            _createVNode(_component_Column, {
              field: "number",
              header: _ctx.$t('labels.shopOrderNumber'),
              sortable: ""
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: '#'
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    (data.missingWeclappOrder)
                      ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_6, null, 512)), [
                          [
                            _directive_tooltip,
                            data.weclappError || 'Bestellung wurde in weclapp nicht reserviert',
                            void 0,
                            { top: true }
                          ]
                        ])
                      : _createCommentVNode("", true),
                    (_ctx.editPermissionAvailable)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 1,
                          to: {
                                    name: 'ImportShopOrderDetails',
                                    params: {id: data.number},
                                    query: {platform: data.platform}
                                }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(data.number), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      : _createCommentVNode("", true),
                    (_ctx.editPermissionAvailable && data.missingWeclappOrder)
                      ? (_openBlock(), _createElementBlock("i", {
                          key: 2,
                          class: "pi pi-wrench ml-2",
                          style: {"cursor":"pointer"},
                          onClick: $event => (_ctx.handleManualRecreation(data.number, data.platform))
                        }, null, 8, _hoisted_7))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    (data.availability)
                      ? _withDirectives((_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: _normalizeClass(["availability-dot ml-3", {'bg-red-600':'NOTHING_AVAILABLE' === data.availability, 'bg-yellow-600':'PARTIALLY_AVAILABLE' === data.availability, 'bg-green-400':'COMPLETELY_AVAILABLE' === data.availability}])
                        }, null, 2)), [
                          [
                            _directive_tooltip,
                            _ctx.getAvailabilityTooltipText(data.availability),
                            void 0,
                            { left: true }
                          ]
                        ])
                      : _createCommentVNode("", true),
                    (data.availabilityForAllWarehouses)
                      ? _withDirectives((_openBlock(), _createElementBlock("span", {
                          key: 1,
                          class: _normalizeClass(["availability-dot ml-3", {'bg-red-600':'NOTHING_AVAILABLE' === data.availabilityForAllWarehouses, 'yellow-background':'PARTIALLY_AVAILABLE' === data.availabilityForAllWarehouses, 'bg-green-400':'COMPLETELY_AVAILABLE' === data.availabilityForAllWarehouses}])
                        }, null, 2)), [
                          [
                            _directive_tooltip,
                            _ctx.getAvailabilityTooltipText(data.availabilityForAllWarehouses, true),
                            void 0,
                            { right: true }
                          ]
                        ])
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "customerSearchable",
              header: _ctx.$t('labels.customer')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              body: _withCtx(({data}) => [
                (!data?.billingAddress)
                  ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                  : (_openBlock(), _createElementBlock("span", _hoisted_9, [
                      _createTextVNode(_toDisplayString(data.billingAddress.salutation) + " " + _toDisplayString(data.billingAddress.firstName) + " " + _toDisplayString(data.billingAddress.lastName) + " ", 1),
                      (data.billingAddress.company)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, "[" + _toDisplayString(data.billingAddress.company) + "]", 1))
                        : _createCommentVNode("", true)
                    ]))
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "paymentMethodName",
              header: _ctx.$t('labels.shopOrderPaymentMethod')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              body: _withCtx(({data}) => [
                (!data?.paymentMethod)
                  ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                  : (_openBlock(), _createElementBlock("span", _hoisted_11, [
                      _createTextVNode(_toDisplayString(data.paymentMethod?.name) + " ", 1),
                      (data.paymentMethod?.name !== data.paymentMethod?.description)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12))
                        : _createCommentVNode("", true)
                    ]))
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "orderTimeParsed",
              header: _ctx.$t('labels.createdAt'),
              sortable: "",
              dataType: "date"
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_p_calendar, {
                  class: "p-column-filter text-xs",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  dateFormat: "dd.mm.yy",
                  onDateSelect: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onDateSelect"])
              ]),
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.orderTimeFormatted), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "partDelivery",
              header: _ctx.$t('labels.partialDelivery'),
              sortable: "",
              dataType: "boolean"
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_TriStateCheckbox, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onChange: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.partDelivery, 'text-red-600 pi-times-circle': !(data.partDelivery)}])
                }, null, 2)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "amountTotal",
              header: _ctx.$t('labels.shopOrderValue'),
              sortable: ""
            }, {
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(_ctx.getCurrencyFormatted(data.amountTotal)), 1)
              ]),
              _: 1
            }, 8, ["header"])
          ]),
          _: 1
        }, 8, ["value", "filters", "onFilter", "expandedRows"])
      ]),
      _: 1
    })
  ], 64))
}