import {DateTime} from 'luxon';
import {ReductionAdditionValueType} from './enums';
import {i18n} from './i18n';

export const euStates = [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE'
];

export const upsDangerousGoodsGroundTransportStates = [
    'AT',
    'BE',
    'CH',
    'CZ',
    'DK',
    'FR',
    'DE',
    'HU',
    'IT',
    'LI',
    'LU',
    'MC',
    'NL',
    'PL',
    'PT',
    'ES',
    'VA'
];

export const dhlStates = ['DE'];

export const decodeEscapedHTML = (html: string) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
};

export const stripTagsAndTruncate = (
    text: string | null,
    length: number = 50,
    suffix: string = '...'
) => {
    if (!text) {
        return '';
    }

    text = text.replace(/<\/?[^>]+>/gi, ' ');

    if (text.length > length) {
        return text.substring(0, length) + suffix;
    } else {
        return text;
    }
};

export const removeStyles = (str: string) => {
    const regex = /style=(".*?"|'.*?'|[^"'][^\s]*)/gs;
    return str.replace(regex, '').replace(/<style>.*<\/style>/gs, '');
};

export const vkPriceCalculation = (
    data: {articlePrices?: Array<any>} | null,
    formatted: boolean = true,
    salesChannel: string = 'NET1',
    millis: number | null = null
): any => {
    if (!data || !data.articlePrices) {
        return null;
    }

    const startOfToday = DateTime.fromMillis(millis || Date.now()).startOf(
        'day'
    );

    if (data.articlePrices.length === 1) {
        if (
            data.articlePrices[0].salesChannel === salesChannel &&
            (data.articlePrices[0].priceScaleValue || 0) < 1 &&
            !data.articlePrices[0].endDate &&
            !data.articlePrices[0].startDate
        ) {
            return calculateFinalPrice(data.articlePrices[0], formatted);
        }
    }

    let price = null;

    data.articlePrices.forEach((element: any) => {
        if (
            element.salesChannel === salesChannel &&
            (element.priceScaleValue || 0) < 1 &&
            (typeof element['startDate'] === 'undefined' ||
                !element.startDate ||
                DateTime.fromMillis(element.startDate).startOf('day') <=
                    startOfToday) &&
            (typeof element['endDate'] === 'undefined' ||
                !element.endDate ||
                DateTime.fromMillis(element.endDate).startOf('day') >=
                    startOfToday)
        ) {
            price = calculateFinalPrice(element, formatted);
            return false;
        }
    });

    return price;
};

export const uvpPriceCalculation = (
    data: {articleCalculationPrices?: Array<any>} | null,
    formatted: boolean = true,
    millis: number | null = null
): any => {
    if (!data || !data.articleCalculationPrices) {
        return null;
    }

    const startOfToday = DateTime.fromMillis(millis || Date.now()).startOf(
        'day'
    );

    if (data.articleCalculationPrices.length === 1) {
        if (
            !data.articleCalculationPrices[0].endDate &&
            !data.articleCalculationPrices[0].startDate
        ) {
            return calculateFinalPrice(
                data.articleCalculationPrices[0],
                formatted
            );
        }
    }

    let price = null;

    data.articleCalculationPrices.forEach((element: any) => {
        if (
            (typeof element['startDate'] === 'undefined' ||
                !element.startDate ||
                DateTime.fromMillis(element.startDate).startOf('day') <=
                    startOfToday) &&
            (typeof element['endDate'] === 'undefined' ||
                !element.endDate ||
                DateTime.fromMillis(element.endDate).startOf('day') >=
                    startOfToday)
        ) {
            price = calculateFinalPrice(element, formatted);
            return false;
        }
    });

    return price;
};

export const isCurrentlyActivePrice = (price: any) => {
    const currentTimestamp = Date.now();

    return (
        (typeof price['startDate'] === 'undefined' ||
            !price.startDate ||
            price.startDate <= currentTimestamp) &&
        (typeof price['endDate'] === 'undefined' ||
            !price.endDate ||
            price.endDate >= currentTimestamp)
    );
};

export const isFutureTimeLimitedPrice = (price: any) => {
    const currentTimestamp = Date.now();

    return (
        price?.startDate > currentTimestamp && price?.endDate > currentTimestamp
    );
};

export const calculateFinalPrice = (
    priceInstance: {
        price: any;
        reductionAdditions: [];
        currencyName?: string | null;
    },
    formatted: boolean = true
) => {
    const formatter = new Intl.NumberFormat(i18n.global.locale, {
        style: 'currency',
        currency: priceInstance.currencyName || 'EUR'
    });

    if (
        priceInstance.reductionAdditions &&
        priceInstance.reductionAdditions.length > 0
    ) {
        let reducedPrice = priceInstance.price;
        priceInstance.reductionAdditions.forEach((reduction: any) => {
            switch (reduction['type']) {
                case ReductionAdditionValueType.ReductionPercent:
                    reducedPrice =
                        reducedPrice *
                        ((100 - parseFloat(reduction.value)) / 100);
                    break;
                case ReductionAdditionValueType.ReductionAbsolute:
                    reducedPrice -= parseFloat(reduction.value);
                    break;
                case ReductionAdditionValueType.AdditionAbsolute:
                    reducedPrice += parseFloat(reduction.value);
                    break;
                case ReductionAdditionValueType.AdditionPercent:
                    reducedPrice =
                        reducedPrice *
                        ((100 + parseFloat(reduction.value)) / 100);
                    break;
            }
        });

        return formatted ? formatter.format(reducedPrice) : reducedPrice;
    }
    return formatted
        ? formatter.format(priceInstance.price)
        : priceInstance.price;
};

export const resolvePrimarySupplySource = (
    data: {
        articleSupplySources: Array<{articlePrices?: Array<any>}> | null;
        primarySupplySourceId?: string | null;
    } | null,

    supplierNumber: string | null = null
): any => {
    if (
        !data ||
        !data.articleSupplySources ||
        data.articleSupplySources.length < 1
    ) {
        return null;
    }

    if (supplierNumber !== null) {
        return (
            data.articleSupplySources.find(
                (item: any) => item.supplierNumber.toString() === supplierNumber
            ) || null
        );
    }

    if (data.primarySupplySourceId) {
        return (
            data.articleSupplySources.find(
                (item: any) =>
                    item.id.toString() === data.primarySupplySourceId.toString()
            ) || null
        );
    }
    return (
        data.articleSupplySources.find(
            (item: any) => item.positionNumber === 1
        ) || null
    );
};

export const ekPriceCalculation = (
    data: {
        articleSupplySources: Array<{articlePrices?: Array<any>}> | null;
        primarySupplySourceId?: string | null;
    } | null,
    formatted: boolean = true,
    supplierNumber: string | null = null,
    millis: number | null = null
): any => {
    if (
        !data ||
        !data.articleSupplySources ||
        data.articleSupplySources.length < 1
    ) {
        return null;
    }

    const resolvedPrimarySupplySource = resolvePrimarySupplySource(
        data,
        supplierNumber
    );

    return ekPriceCalculationForSupplySource(
        resolvedPrimarySupplySource,
        formatted,
        millis
    );
};

export const resolveActivePriceForSupplySource = (
    supplySource: {
        articlePrices?: Array<any>;
    },
    millis: number | null = null
) => {
    if (!supplySource?.articlePrices || supplySource.articlePrices.length < 1) {
        return null;
    }

    const startOfToday = DateTime.fromMillis(millis || Date.now()).startOf(
        'day'
    );

    if (supplySource.articlePrices.length === 1) {
        if (
            (supplySource.articlePrices[0].priceScaleValue || 0) < 1 &&
            (typeof supplySource.articlePrices[0]['startDate'] ===
                'undefined' ||
                !supplySource.articlePrices[0].startDate ||
                DateTime.fromMillis(
                    supplySource.articlePrices[0].startDate
                ).startOf('day') <= startOfToday) &&
            (typeof supplySource.articlePrices[0]['endDate'] === 'undefined' ||
                !supplySource.articlePrices[0].endDate ||
                DateTime.fromMillis(
                    supplySource.articlePrices[0].endDate
                ).startOf('day') >= startOfToday)
        ) {
            return supplySource.articlePrices[0];
        }
    }

    let price = null;

    supplySource.articlePrices.forEach((element: any) => {
        if (
            (element.priceScaleValue || 0) < 1 &&
            (typeof element['startDate'] === 'undefined' ||
                !element.startDate ||
                DateTime.fromMillis(element.startDate).startOf('day') <=
                    startOfToday) &&
            (typeof element['endDate'] === 'undefined' ||
                !element.endDate ||
                DateTime.fromMillis(element.endDate).startOf('day') >=
                    startOfToday)
        ) {
            price = element;
            return false;
        }
    });

    return price;
};

export const ekPriceCalculationForSupplySource = (
    supplySource: {
        articlePrices?: Array<any>;
    },
    formatted: boolean = true,
    millis: number | null = null
) => {
    if (!supplySource?.articlePrices || supplySource.articlePrices.length < 1) {
        return null;
    }

    const price = resolveActivePriceForSupplySource(supplySource, millis);

    return price ? calculateFinalPrice(price, formatted) : null;
};

export const checkIfArraysAreEqual = (
    array1: Array<string>,
    array2: Array<string>
): boolean => {
    return (
        array1.length == array2.length &&
        array1
            .slice()
            .sort()
            .every((value, index) => value === array2[index])
    );
};

export const resolvePriceInDefaultCurrency = (price: number, rate: number) => {
    return Math.round(price * (Math.round(100 / rate) / 100) * 100) / 100;
};

export const clearEmptyFilters = (filters: any) => {
    const asArray = Object.entries(filters);

    const filtered = asArray.filter((value: any) => {
        return (
            value.length === 2 &&
            (['isNotEmpty', 'isEmpty'].includes(
                value[1]['matchMode'] || null
            ) ||
                (value[1].value !== null &&
                    (typeof value[1].value !== 'string' ||
                        value[1].value.trim() !== '')))
        );
    });

    filtered.map((value: any) => {
        if (value.length === 2 && typeof value[1].value === 'string') {
            value[1].value = value[1].value.trim();
        }
    });

    return Object.fromEntries(filtered);
};

export const isShopware5 = (threeLetterId: string | null = null): boolean => {
    return threeLetterId === 'ttl' || threeLetterId === 'ttd';
};

export const getShopwareUrl = (input: string, threeLetterId: string | null) => {
    const plusReplacement = isShopware5(threeLetterId) ? 'plus' : '';

    return input
        .toLowerCase()
        .replace(/[`~!@#$%^&*()_|\-=?;:'",.<>{}[\]\\/™©®]/gi, ' ')
        .replace(/\+/gi, plusReplacement)
        .replace('°', '0')
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/ß/g, 'ss')
        .replace(/\s\s+/g, ' ')
        .trimEnd()
        .replaceAll(' ', '-');
};

export const getPlatformCellContent = (value: string) => {
    if (!value) {
        return '';
    }

    switch (value.toLowerCase()) {
        case 'teltec':
        case 'teltec live':
            return '<span class="text-red-600"">Teltec Live</span>';
        case 'videodata live':
        case 'videodata':
            return '<span class="text-purple-600">Videodata Live</span>';
        case 'shopware':
            return '<span class="text-orange-600">Shopware</span>';
        case 'weclapp':
            return '<span class="text-green-600">Weclapp</span>';
        case 'videodata-demo':
            return '<span class="text-blue-200">Videodata Demo</span>';
        case 'creativetools-demo':
            return '<span class="text-pink-600">VCT Demo</span>';
        case 'creativetools':
            return '<span class="text-blue-200">VCT Live</span>';
        default:
            return '<span class="text-green-600">Teltec Demo</span>';
    }
};

export const isValueEqualCaseInsensitive = (
    value1: string | null,
    value2: string | null
): boolean => {
    if (!value1 && !value2) {
        return true;
    }

    return (
        value1 &&
        value2 &&
        value1.toString().toLowerCase() === value2.toString().toLowerCase()
    );
};

export const roundTo2Decimals = (num: number | null): number | null => {
    if (num === null) {
        return null;
    }
    return +(Math.round(+(num + 'e+2')) + 'e-2');
};
