import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "text-sm" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "text-xs" }
const _hoisted_7 = {
  key: 0,
  class: "ml-3"
}
const _hoisted_8 = { style: {"cursor":"pointer"} }
const _hoisted_9 = { style: {"cursor":"pointer"} }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { style: {"cursor":"pointer"} }
const _hoisted_12 = { class: "flex justify-content-center" }
const _hoisted_13 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Column = _resolveComponent("Column")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, { class: "panel-container-datatable" }, {
      header: _withCtx(() => [
        _createElementVNode("h5", null, [
          _createTextVNode(_toDisplayString(_ctx.$t("labels.purchaseOrderItems")) + " ", 1),
          (_ctx.purchaseOrderDetails)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                _createElementVNode("a", {
                  href: _ctx.purchaseOrderDetails.url + 'webapp/view/purchase/purchaseOrderDetail.page?entityId=' + _ctx.purchaseOrderDetails.id,
                  target: "_blank"
                }, _toDisplayString(_ctx.purchaseOrderNumber), 9, _hoisted_1),
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.getCurrencyFormatted(_ctx.purchaseOrderDetails.netAmount,
                    _ctx.purchaseOrderDetails?.supplier?.currencyName)), 1),
                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                (_ctx.purchaseOrderDetails.supplier)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: _ctx.purchaseOrderDetails.url + 'webapp/view/party/PartyDetail.page?entityId=' + _ctx.purchaseOrderDetails.supplier.id,
                      target: "_blank"
                    }, _toDisplayString(_ctx.purchaseOrderDetails.supplier.name) + " [" + _toDisplayString(_ctx.purchaseOrderDetails.supplier.supplierNumber) + "]", 9, _hoisted_2))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm",
          stripedRows: "",
          lazy: false,
          editMode: "cell",
          onCellEditComplete: _ctx.onCellEditComplete,
          ref: "dt",
          dataKey: "id",
          value: _ctx.purchaseOrderDetails?.purchaseOrderItems || [],
          responsiveLayout: "scroll"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "positionNumber",
              style: {"min-width":"2rem"},
              header: _ctx.$t('labels.position')
            }, null, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "title",
              style: {"min-width":"9rem"},
              header: _ctx.$t('labels.article')
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_3, [
                  (data.title.length > 60)
                    ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_4, [
                        _createTextVNode(_toDisplayString(_ctx.stripTagsAndTruncate(data.title, 60, '...')), 1)
                      ])), [
                        [
                          _directive_tooltip,
                          data.title,
                          void 0,
                          { top: true }
                        ]
                      ])
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(data.title), 1)
                      ], 64)),
                  _cache[5] || (_cache[5] = _createTextVNode(" [")),
                  _createElementVNode("a", {
                    href: _ctx.purchaseOrderDetails?.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.article?.id,
                    target: "_blank"
                  }, _toDisplayString(data.articleNumber), 9, _hoisted_5),
                  _cache[6] || (_cache[6] = _createTextVNode("] "))
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("b", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.createdAt')) + " " + _toDisplayString(_ctx.getDateFormatted(data.article?.createdDate)), 1),
                    (_ctx.ekPriceCalculation(data.article))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, "EK " + _toDisplayString(_ctx.ekPriceCalculation(data.article)), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "minimumStockQuantity",
              header: "Min"
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_8, _toDisplayString(data.minimumStockQuantity), 1)
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputNumber, {
                  locale: _ctx.locale,
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  showButtons: "",
                  min: 0,
                  disabled: !_ctx.editingAvailable
                }, null, 8, ["locale", "modelValue", "onUpdate:modelValue", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "targetStockQuantity",
              header: "Max"
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_9, _toDisplayString(data.targetStockQuantity), 1)
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputNumber, {
                  locale: _ctx.locale,
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  showButtons: "",
                  min: 0,
                  disabled: !_ctx.editingAvailable
                }, null, 8, ["locale", "modelValue", "onUpdate:modelValue", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { field: "minMaxDate" }, {
              header: _withCtx(() => [
                _cache[7] || (_cache[7] = _createTextVNode(" MinMaxAkt ")),
                _createElementVNode("i", {
                  class: "pi pi-refresh",
                  style: {"padding-left":"7px","cursor":"pointer"},
                  onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.bulkUpdateMinMaxDate && _ctx.bulkUpdateMinMaxDate(...args)))
                })
              ]),
              body: _withCtx(({data}) => [
                (data.minMaxDate)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.getDateFormatted(data.minMaxDate)), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "quantity",
              header: "BE-Menge"
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_11, _toDisplayString(data.quantity), 1)
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputNumber, {
                  locale: _ctx.locale,
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  showButtons: "",
                  min: 0,
                  disabled: !_ctx.editingAvailable
                }, null, 8, ["locale", "modelValue", "onUpdate:modelValue", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "Dispo" }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", null, _toDisplayString(parseInt(_ctx.getStatisticsForArticleNumber(_ctx.purchaseOrderDetails?.statistics,
                        data.articleNumber)?.extraInfo?.orderedQuantity || 0)+
                        parseInt(_ctx.getStatisticsForArticleNumber(_ctx.purchaseOrderDetails?.statistics,
                        data.articleNumber)?.extraInfo?.stockQuantity || 0) -
                        parseInt(_ctx.getStatisticsForArticleNumber(_ctx.purchaseOrderDetails?.statistics,
                        data.articleNumber)?.extraInfo?.plannedSalesQuantity ||
                        0)), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "Positionswert" }, {
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(_ctx.getCurrencyFormatted(data.netAmount,
                _ctx.purchaseOrderDetails?.supplier?.currencyName)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "VK-RG (3M)" }, {
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(_ctx.getStatisticsForArticleNumber(_ctx.purchaseOrderDetails?.statistics,
                data.articleNumber)?.salesInvoices?.lastThreeMonthsItemCount) + " / " + _toDisplayString(_ctx.getStatisticsForArticleNumber(_ctx.purchaseOrderDetails?.statistics,
                data.articleNumber)?.salesInvoices?.lastThreeMonthsCount), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "VK-RG (12M)" }, {
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(_ctx.getStatisticsForArticleNumber(_ctx.purchaseOrderDetails?.statistics,
                data.articleNumber)?.salesInvoices?.itemCount) + " / " + _toDisplayString(_ctx.getStatisticsForArticleNumber(_ctx.purchaseOrderDetails?.statistics,
                data.articleNumber)?.salesInvoices?.count), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "BE (12M)" }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.getStatisticsForArticleNumber(_ctx.purchaseOrderDetails?.statistics,
                        data.articleNumber)?.purchaseOrders?.itemCount), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "Letzte BE" }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.getDateFormatted(_ctx.getStatisticsForArticleNumber(_ctx.purchaseOrderDetails?.statistics,
                        data.articleNumber)?.purchaseOrders?.lastPurchaseOrderDate)), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, null, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("i", {
                    class: "pi pi-trash text-red-600 ml-2",
                    onClick: $event => (_ctx.handleRemoveItemClick(data)),
                    style: {"cursor":"pointer"}
                  }, null, 8, _hoisted_13)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onCellEditComplete", "value"])
      ]),
      _: 1
    })
  ], 64))
}