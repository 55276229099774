import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "font-bold text-sm" }
const _hoisted_2 = { class: "flex justify-content-center w-full text-sm" }
const _hoisted_3 = { class: "flex flex-column justify-content-center text-sm" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["onClick", "aria-controls"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "mt-2 font-bold"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 0,
  class: "text-sm"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "font-bold" }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "text-sm flex justify-content-end" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "w-full flex justify-content-between mt-2" }

export function render(_ctx, _cache) {
  const _component_LoadingPlugin = _resolveComponent("LoadingPlugin")
  const _component_PriceImportTemplateMenu = _resolveComponent("PriceImportTemplateMenu")
  const _component_Tag = _resolveComponent("Tag")
  const _component_Column = _resolveComponent("Column")
  const _component_TieredMenu = _resolveComponent("TieredMenu")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_p_button = _resolveComponent("p-button")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LoadingPlugin, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_DataTable, {
      stripedRows: "",
      rows: 100,
      paginator: "",
      rowsPerPageOptions: [100, 200, 500],
      "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
      "current-page-report-template": "{first} to {last} of {totalRecords}",
      value: _ctx.items,
      scrollable: "",
      scrollHeight: "calc(100vh - 25rem)",
      responsiveLayout: "scroll",
      rowClass: _ctx.rowClass
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, null, {
          header: _withCtx(() => [
            (_ctx.items && (_ctx.items.length || 0) > 0)
              ? (_openBlock(), _createBlock(_component_PriceImportTemplateMenu, {
                  key: 0,
                  "supplier-number": _ctx.supplierConfiguration?.supplierNumber,
                  "sheet-name": _ctx.sheetName,
                  "mapping-definition": _ctx.state,
                  "chosen-manufacturer": _ctx.chosenManufacturer,
                  onClearTemplate: _ctx.clearTemplate,
                  onApplyTemplate: _ctx.applyTemplate
                }, null, 8, ["supplier-number", "sheet-name", "mapping-definition", "chosen-manufacturer", "onClearTemplate", "onApplyTemplate"]))
              : _createCommentVNode("", true)
          ]),
          body: _withCtx(({data}) => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_Tag, { severity: "primary" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(data?.rowIndex+1), 1)
                ]),
                _: 2
              }, 1024)
            ])
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col, colIndex) => {
          return (_openBlock(), _createBlock(_component_Column, {
            bodyClass: "text-center",
            style: {"max-width":"16rem"}
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_Tag, { severity: "success" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(col.header), 1)
                  ]),
                  _: 2
                }, 1024)
              ])
            ]),
            body: _withCtx(({data}) => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", null, [
                  _createElementVNode("span", {
                    class: _normalizeClass({'font-bold': data?.rowIndex === _ctx.state.headerRowIndex})
                  }, [
                    (data?.rowIndex > _ctx.state.headerRowIndex && ['SUPPLIER_ARTICLE_COST_PRICE', 'SUPPLIER_ARTICLE_COST_PRICE_BASE', 'SUPPLIER_ARTICLE_LIST_PRICE'].includes(_ctx.state.columnMappingArray.find((item)=> item.columnIndex === colIndex)?.systemColumn))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.formatRegularPriceColumn(data.rowData,
                            colIndex)), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(data.rowData[colIndex]), 1))
                  ], 2),
                  (data?.rowIndex === _ctx.state.headerRowIndex)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                        _createElementVNode("i", {
                          class: "pi pi-cog ml-2 text-green-500",
                          style: {"cursor":"pointer"},
                          onClick: $event => (_ctx.bulkMenuToggle($event, colIndex)),
                          "aria-haspopup": "true",
                          "aria-controls": 'overlay_tmenu_' + col.header
                        }, null, 8, _hoisted_7),
                        _createVNode(_component_TieredMenu, {
                          ref_for: true,
                          ref: "bulkMenu",
                          id: 'overlay_tmenu_' + col.header,
                          model: _ctx.addBulkItems,
                          popup: ""
                        }, null, 8, ["id", "model"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (data?.rowIndex > _ctx.state.headerRowIndex && !_ctx.rowsToSkip.includes(data?.rowIndex) && _ctx.state.columnMappingArray.find((item) =>
                        item.columnIndex === colIndex)?.systemColumn === 'SUPPLIER_DISCOUNT_GROUP')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_Tag, {
                        class: "ml-1 mt-1",
                        severity: "success"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getDiscountPercentageForRow(data)) + " % ", 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (data?.rowIndex === _ctx.state.headerRowIndex)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_ctx.state.columnMappingArray.some((item) => item.columnIndex === colIndex))
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(_component_Tag, { severity: "danger" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('labels.priceImport.availableColumns.' +
                        _ctx.state.columnMappingArray.find((item) => item.columnIndex
                        === colIndex).systemColumn ).toUpperCase()) + " ", 1),
                              _createElementVNode("i", {
                                class: "pi pi-trash text-xs ml-2",
                                onClick: $event => (_ctx.removeColumnMapping(colIndex)),
                                style: {"cursor":"pointer"}
                              }, null, 8, _hoisted_10)
                            ]),
                            _: 2
                          }, 1024),
                          ([
                        'SUPPLIER_ARTICLE_COST_PRICE',
                        'SUPPLIER_ARTICLE_COST_PRICE_BASE',
                        'SUPPLIER_ARTICLE_LIST_PRICE'
                    ].includes(_ctx.state.columnMappingArray.find((item) =>
                    item.columnIndex === colIndex).systemColumn
                    ))
                            ? (_openBlock(), _createBlock(_component_Tag, {
                                key: 0,
                                class: "ml-1 mt-1",
                                severity: "warning"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.state.columnMappingArray.find((item) =>
                        item.columnIndex === colIndex).grossAmount ? 'BRUTTO' :
                        'NETTO'), 1)
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true),
                          ([
                        'SUPPLIER_ARTICLE_COST_PRICE',
                        'SUPPLIER_ARTICLE_COST_PRICE_BASE',
                        'SUPPLIER_ARTICLE_LIST_PRICE'
                    ].includes(_ctx.state.columnMappingArray.find((item) =>
                    item.columnIndex === colIndex).systemColumn
                    ))
                            ? (_openBlock(), _createBlock(_component_Tag, {
                                key: 1,
                                class: "ml-1 mt-1",
                                severity: "success"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.state.columnMappingArray.find((item) =>
                        item.columnIndex === colIndex).currency .toUpperCase()), 1)
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true),
                          (_ctx.costPriceStartDate && 
                    'SUPPLIER_ARTICLE_COST_PRICE' ===
                _ctx.state.columnMappingArray.find((item) =>
                item.columnIndex === colIndex)?.systemColumn
                )
                            ? (_openBlock(), _createBlock(_component_Tag, {
                                key: 2,
                                class: "ml-1 mt-1"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("AB " + _toDisplayString(_ctx.costPriceStartDate.toLocaleDateString("de-DE")), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.listPriceStartDate && 
                        'SUPPLIER_ARTICLE_LIST_PRICE' ===
                    _ctx.state.columnMappingArray.find((item) =>
                    item.columnIndex === colIndex)?.systemColumn
                    )
                            ? (_openBlock(), _createBlock(_component_Tag, {
                                key: 3,
                                class: "ml-1 mt-1"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("AB " + _toDisplayString(_ctx.listPriceStartDate.toLocaleDateString("de-DE")), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024))
        }), 256)),
        _createVNode(_component_Column, { style: {"min-width":"fit-content"} }, {
          body: _withCtx(({data}) => [
            (_ctx.computedCostPriceBaseColumn?.merchantDiscount || _ctx.computedDiscountGroupColumn)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (data?.rowIndex === _ctx.state.headerRowIndex)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('labels.ekPrice')), 1)
                        ]),
                        _createElementVNode("div", null, [
                          _createVNode(_component_Tag, {
                            severity: "danger",
                            class: "mt-1 mr-1"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('labels.priceImport.availableColumns.SUPPLIER_ARTICLE_COST_PRICE').toUpperCase()), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_Tag, {
                            class: "mr-1",
                            severity: "warning"
                          }, {
                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                              _createTextVNode("NETTO")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_Tag, {
                            class: "mr-1",
                            severity: "success"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.computedCostPriceBaseColumn.currency.toUpperCase()), 1)
                            ]),
                            _: 1
                          }),
                          (_ctx.computedCostPriceBaseColumn?.merchantDiscount)
                            ? (_openBlock(), _createBlock(_component_Tag, {
                                key: 0,
                                class: "mt-1"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.computedCostPriceBaseColumn.merchantDiscount.name
                            ? _ctx.computedCostPriceBaseColumn.merchantDiscount.name
                            + ' : ' :
                            '') + _toDisplayString(_ctx.computedCostPriceBaseColumn.merchantDiscount.percentage) + "% " + _toDisplayString(_ctx.computedCostPriceBaseColumn.merchantDiscount.manufacturerName
                            ? ('(' +
                            _ctx.computedCostPriceBaseColumn.merchantDiscount.manufacturerName
                            + ')') : ''), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.costPriceStartDate)
                            ? (_openBlock(), _createBlock(_component_Tag, {
                                key: 1,
                                class: "ml-1 mt-1"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("AB " + _toDisplayString(_ctx.costPriceStartDate.toLocaleDateString("de-DE")), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.state.headerRowIndex > -1 && data?.rowIndex > _ctx.state.headerRowIndex)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.formatMerchantDiscountColumn(data?.rowIndex)), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { style: {"min-width":"5rem"} }, {
          body: _withCtx(({data}) => [
            _createElementVNode("div", _hoisted_15, [
              (data?.rowIndex !== _ctx.state.headerRowIndex && !_ctx.rowsToSkip.includes(data?.rowIndex))
                ? _withDirectives((_openBlock(), _createElementBlock("i", {
                    key: 0,
                    class: _normalizeClass(["pi pi-star text-sm", {'text-yellow-500': data?.rowIndex > _ctx.state.headerRowIndex}]),
                    style: {"cursor":"pointer"},
                    onClick: $event => (_ctx.setHeaderRow(data?.rowIndex))
                  }, null, 10, _hoisted_16)), [
                    [
                      _directive_tooltip,
                      _ctx.$t('labels.setAsHeader'),
                      void 0,
                      { top: true }
                    ]
                  ])
                : _createCommentVNode("", true),
              (_ctx.state.headerRowIndex > -1 && data?.rowIndex > _ctx.state.headerRowIndex)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.rowsToSkip.includes(data?.rowIndex))
                      ? (_openBlock(), _createElementBlock("i", {
                          key: 0,
                          class: "pi pi-plus text-sm text-green-500 ml-2",
                          style: {"cursor":"pointer"},
                          onClick: $event => (_ctx.addRowToImport(data?.rowIndex))
                        }, null, 8, _hoisted_17))
                      : (_openBlock(), _createElementBlock("i", {
                          key: 1,
                          class: "pi pi-trash text-sm text-red-500 ml-2",
                          style: {"cursor":"pointer"},
                          onClick: $event => (_ctx.removeRowFromImport(data?.rowIndex))
                        }, null, 8, _hoisted_18))
                  ], 64))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "rowClass"]),
    _createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_p_button, {
          severity: "danger",
          textContent: _toDisplayString(_ctx.$t('buttons.back')),
          onClick: _ctx.onBackButtonClicked
        }, null, 8, ["textContent", "onClick"]),
        _createVNode(_component_p_button, {
          severity: "success",
          textContent: _toDisplayString(_ctx.$t('buttons.next')),
          type: "submit"
        }, null, 8, ["textContent"])
      ])
    ], 32)
  ], 64))
}