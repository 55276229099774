import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_p_button = _resolveComponent("p-button")
  const _component_p_dialog = _resolveComponent("p-dialog")

  return (_openBlock(), _createBlock(_component_p_dialog, {
    header: _ctx.$t('labels.deleteLink'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[1] || (_cache[1] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw'},
    style: {width: '50vw'},
    onHide: _ctx.closeDialog,
    modal: true
  }, {
    default: _withCtx(() => [
      _createElementVNode("h6", null, _toDisplayString(_ctx.$t('messages.deleteConfirmation', { item : _ctx.selectedLink.title})), 1),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_p_button, {
          severity: "danger",
          disabled: _ctx.savingInProgress,
          onClick: _cache[0] || (_cache[0] = $event => (_ctx.closeDialog(null)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(_component_p_button, {
          severity: "success",
          disabled: _ctx.savingInProgress,
          onClick: _ctx.confirmDelete,
          textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.confirm'))
        }, null, 8, ["disabled", "onClick", "textContent"])
      ])
    ]),
    _: 1
  }, 8, ["header", "visible", "onHide"]))
}