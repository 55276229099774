import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "layout-menuitem-root-text"
}
const _hoisted_2 = { class: "layout-menuitem-text" }
const _hoisted_3 = ["href", "target"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "layout-menuitem-text" }
const _hoisted_6 = {
  key: 2,
  class: "pi pi-fw pi-angle-down layout-submenu-toggler"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "layout-menuitem-text" }
const _hoisted_9 = {
  key: 2,
  class: "pi pi-fw pi-angle-down layout-submenu-toggler"
}
const _hoisted_10 = { class: "layout-submenu" }

export function render(_ctx, _cache) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_router_link = _resolveComponent("router-link")
  const _component_app_menu_item = _resolveComponent("app-menu-item")

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass({'layout-root-menuitem': _ctx.root, 'active-menuitem': _ctx.isActiveMenu})
  }, [
    (_ctx.root)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            (_ctx.item.name)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t(_ctx.item.name)), 1)
                ], 64))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    ((!_ctx.item.path || _ctx.item.children) && (typeof _ctx.item.isVisible !== 'function' || _ctx.item.isVisible(_ctx.user, _ctx.platform)))
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: _ctx.item.url,
          onClick: _cache[0] || (_cache[0] = $event => (_ctx.itemClick($event, _ctx.item))),
          class: _normalizeClass(_ctx.item.class),
          target: _ctx.item.target,
          tabindex: "0"
        }, [
          (_ctx.item.iconClass)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                class: "layout-menuitem-icon",
                icon: _ctx.item.iconClass
              }, null, 8, ["icon"]))
            : (_ctx.item.iconPath)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: _normalizeClass(["layout-menuitem-icon fas custom-menu-icon", {'dark-icon': _ctx.layoutConfig.darkTheme}]),
                  src: require(`@/assets/img/${_ctx.item.iconPath}`)
                }, null, 10, _hoisted_4))
              : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_5, [
            (_ctx.item.name)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t(_ctx.item.name)), 1)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          (_ctx.item.children)
            ? (_openBlock(), _createElementBlock("i", _hoisted_6))
            : _createCommentVNode("", true)
        ], 10, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.item.path && !_ctx.item.children && (typeof _ctx.item.isVisible !== 'function' || _ctx.item.isVisible(_ctx.user, _ctx.platform)))
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 2,
          onClick: _cache[1] || (_cache[1] = $event => (_ctx.itemClick($event, _ctx.item))),
          class: _normalizeClass([_ctx.item.class, {'active-route': _ctx.checkActiveRoute(_ctx.item)}]),
          tabindex: "0",
          to: _ctx.item.path
        }, {
          default: _withCtx(() => [
            (_ctx.item.iconClass)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  class: "layout-menuitem-icon",
                  icon: _ctx.item.iconClass
                }, null, 8, ["icon"]))
              : (_ctx.item.iconPath)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    class: _normalizeClass(["layout-menuitem-icon fas custom-menu-icon", {'dark-icon': _ctx.layoutConfig.darkTheme}]),
                    src: require(`@/assets/img/${_ctx.item.iconPath}`)
                  }, null, 10, _hoisted_7))
                : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_8, [
              (_ctx.item.name)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t(_ctx.item.name)), 1)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            (_ctx.item.children)
              ? (_openBlock(), _createElementBlock("i", _hoisted_9))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["class", "to"]))
      : _createCommentVNode("", true),
    (_ctx.item.children && (typeof _ctx.item.isVisible !== 'function' || _ctx.item.isVisible(_ctx.user, _ctx.platform)))
      ? (_openBlock(), _createBlock(_Transition, {
          key: 3,
          name: "layout-submenu"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("ul", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (child, i) => {
                return (_openBlock(), _createBlock(_component_app_menu_item, {
                  item: child,
                  parentItemKey: _ctx.item.name,
                  root: false
                }, null, 8, ["item", "parentItemKey"]))
              }), 256))
            ], 512), [
              [_vShow, _ctx.root ? true : _ctx.isActiveMenu]
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}