import {computed, ref, toRefs, watch} from 'vue';
import TabPanel from 'primevue/tabpanel';
import Promotions from '@/pages/products/details/promotions/promotions.vue';
import LoadingPlugin from 'vue-loading-overlay';
import BulkProductAction from './bulk-product-action.vue';
import {bulkUpdateCustomFields} from '@/services/products';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import Panel from 'primevue/panel';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';
import {stripTagsAndTruncate} from '@/utils/helpers';
import {useVuelidate} from '@vuelidate/core';
import Divider from 'primevue/divider';
import {getAll} from '@/services/metadata';

export default {
    emits: ['close-dialog'],
    components: {
        TabPanel,
        Promotions,
        BulkProductAction,
        loading: LoadingPlugin,
        Panel,
        TriStateCheckbox,
        'p-calendar': Calendar,
        'p-dropdown': Dropdown,
        'p-textarea': Textarea,
        InputSwitch,
        Divider
    },
    props: {
        products: Array,
        productFilters: Object,
        productTotalRecords: Number,
        displayDialog: Boolean,
        isFilterRelated: Boolean
    },
    setup(props: any, context: any) {
        const {products, productFilters, productTotalRecords} = toRefs(props);
        const showDialog = ref(false);
        const isFilterRelated = ref(false);
        const loading = ref(false);
        const customAttributesDefinition: any = ref(null);

        const toast = useToast();

        const warrantyTimeOptions = computed(() => {
            return (
                customAttributesDefinition.value?.article_garantiezeit
                    ?.selectableValues || []
            ).map((item: {id: string; value: string}) => {
                return {
                    label: stripTagsAndTruncate(item.value, 100, '...'),
                    value: item.id
                };
            });
        });

        const state = ref({
            article_memo: null,
            article_comment_delivery_note: null,
            art_eol_datum: null,
            article_garantiezeit: null,
            priceUponRequest: null,
            SN_AUTO_GENERATE: null,
            article_batteriegesetz_relevant: null,
            article_ueberlaenge: null,
            article_sell_out: null,
            article_markenmehrwert: null,
            article_sperrgut: null,
            article_flag_aerosole: null,
            article_flag_akku_groesser_100wh: null,
            article_flag_akku_kleiner_100wh: null,
            article_no_pl_match_spare_part: null
        });

        const rules = {
            article_memo: {},
            article_comment_delivery_note: {},
            art_eol_datum: {},
            article_garantiezeit: {},
            priceUponRequest: {},
            SN_AUTO_GENERATE: {},
            article_batteriegesetz_relevant: {},
            article_ueberlaenge: {},
            article_sell_out: {},
            article_markenmehrwert: {},
            article_sperrgut: {},
            article_flag_aerosole: {},
            article_flag_akku_groesser_100wh: {},
            article_flag_akku_kleiner_100wh: {},
            article_no_pl_match_spare_part: {}
        };

        const v$ = useVuelidate(rules, state);

        const bulkEditSwitches = ref({
            article_memo: false,
            article_comment_delivery_note: false,
            art_eol_datum: false,
            article_garantiezeit: false,
            priceUponRequest: false
        });

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            isFilterRelated.value = args.isFilterRelated || false;
            if (showDialog.value) {
                getAll(['customAttributeDefinition'])
                    .then((cus: any) => {
                        if (
                            typeof cus.data?.customAttributeDefinition ===
                                'object' &&
                            Object.values(cus.data?.customAttributeDefinition)
                                .length > 0
                        ) {
                            Object.values(
                                cus.data?.customAttributeDefinition
                            ).forEach((cad: any) => {
                                customAttributesDefinition.value =
                                    Object.assign(
                                        customAttributesDefinition.value || {},
                                        {
                                            [cad.attributeKey]: {...cad}
                                        }
                                    );
                            });
                        }
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                        loading.value = false;
                    });
            }
        });

        const closeResponsive = (forceResfresh: boolean = false) => {
            bulkEditSwitches.value = Object.assign(
                {},
                {
                    article_memo: false,
                    article_comment_delivery_note: false,
                    art_eol_datum: false,
                    article_garantiezeit: false,
                    priceUponRequest: false
                }
            );
            state.value = Object.assign(
                {},
                {
                    article_memo: null,
                    article_comment_delivery_note: null,
                    art_eol_datum: null,
                    article_garantiezeit: null,
                    priceUponRequest: null,
                    SN_AUTO_GENERATE: null,
                    article_batteriegesetz_relevant: null,
                    article_ueberlaenge: null,
                    article_sell_out: null,
                    article_markenmehrwert: null,
                    article_sperrgut: null,
                    article_flag_aerosole: null,
                    article_flag_akku_groesser_100wh: null,
                    article_flag_akku_kleiner_100wh: null,
                    article_no_pl_match_spare_part: null
                }
            );
            context.emit('close-dialog', forceResfresh);
        };

        const filteredInput = computed(() => {
            const formDirty =
                Object.entries(bulkEditSwitches.value || []).filter((sw) => sw)
                    .length > 0 || v$.value.$anyDirty;

            if (!formDirty) {
                return null;
            }

            return Object.fromEntries(
                Object.entries(state.value).filter((item: Array<any>) => {
                    if (
                        ![
                            'article_memo',
                            'article_comment_delivery_note',
                            'art_eol_datum',
                            'article_garantiezeit',
                            'priceUponRequest'
                        ].includes(item[0])
                    ) {
                        return item[1] !== null;
                    }

                    return (
                        bulkEditSwitches.value[
                            item[0] as keyof typeof bulkEditSwitches.value
                        ] === true
                    );
                })
            );
        });

        const summaryTabVisible = computed(() => {
            return (
                filteredInput.value &&
                Object.keys(filteredInput.value).length > 0
            );
        });

        const confirmClicked = (startTime: string | null = null) => {
            loading.value = true;

            bulkUpdateCustomFields(
                products.value,
                productFilters.value,
                filteredInput.value,
                isFilterRelated.value,
                startTime
            )
                .then(() => {
                    toast.success(
                        i18n.global.t(
                            isFilterRelated.value || startTime
                                ? 'messages.cronJobExecutionScheduled'
                                : 'messages.changesSavedSuccessfully'
                        )
                    );
                    closeResponsive(true);
                    loading.value = false;
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    loading.value = false;
                });
        };

        return {
            products,
            productTotalRecords,
            isFilterRelated,
            showDialog,
            closeResponsive,
            confirmClicked,
            summaryTabVisible,
            loading,
            filteredInput,
            bulkEditSwitches,
            priceUponRequestOptions: [
                {
                    value: '0',
                    label: i18n.global.t('labels.productHome.deactivated')
                },
                {
                    value: '1',
                    label: i18n.global.t('labels.productHome.activated')
                },
                {
                    value: '2',
                    label: i18n.global.t(
                        'labels.productHome.individualPriceUponRequest'
                    )
                }
            ],
            warrantyTimeOptions,
            v$,
            fieldTranslationMapping: {
                article_memo: 'memo',
                article_comment_delivery_note: 'commentDeliveryNote',
                art_eol_datum: 'eol',
                article_garantiezeit: 'warrantyTime',
                priceUponRequest: 'priceUponRequest',
                SN_AUTO_GENERATE: 'serialNumberIntern',
                article_batteriegesetz_relevant: 'battg',
                article_ueberlaenge: 'oversized',
                article_no_pl_match_spare_part: 'notInPriceListSparePart',
                article_sell_out: 'sellOut',
                article_markenmehrwert: 'brandAddedValue',
                article_sperrgut: 'bulkGoods',
                article_flag_aerosole: 'containsAerosols',
                article_flag_akku_groesser_100wh: 'dangerousGoods',
                article_flag_akku_kleiner_100wh: 'dangerousGoodsSmall'
            },
            customAttributesDefinition
        };
    }
};
