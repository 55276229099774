import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex justify-content-end" }
const _hoisted_2 = { class: "mr-3 small" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = {
  key: 1,
  style: {"text-align":"center","font-size":"1.5rem","font-weight":"600","margin-left":"30px"}
}
const _hoisted_6 = { style: {"text-align":"center"} }
const _hoisted_7 = { class: "w-full mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_LoadingPlugin = _resolveComponent("LoadingPlugin")
  const _component_ProductList = _resolveComponent("ProductList")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_InputSwitch = _resolveComponent("InputSwitch")
  const _component_ProductListLazy = _resolveComponent("ProductListLazy")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_p_button = _resolveComponent("p-button")
  const _component_TabView = _resolveComponent("TabView")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('labels.replaceArticleAccessories'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[2] || (_cache[2] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw'},
    style: {width: '50vw'},
    onHide: _ctx.closeDialog,
    modal: true,
    position: "top"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LoadingPlugin, {
        active: _ctx.loading,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
      }, null, 8, ["active"]),
      _createVNode(_component_TabView, null, {
        default: _withCtx(() => [
          _createVNode(_component_TabPanel, {
            header: _ctx.$t('labels.oldArticleAccessory')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ProductList, {
                products: [_ctx.oldAccessory],
                platform: _ctx.platform
              }, null, 8, ["products", "platform"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_TabPanel, {
            header: _ctx.$t('labels.newArticleAccessory')
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("labels.replaceWithNothing")), 1),
                _createElementVNode("div", null, [
                  _createVNode(_component_InputSwitch, {
                    modelValue: _ctx.replaceWithNothing,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.replaceWithNothing) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              (!_ctx.replaceWithNothing)
                ? (_openBlock(), _createBlock(_component_ProductListLazy, {
                    key: 0,
                    onOnSelectionChange: _ctx.onNewAccessorySelectionChange,
                    selectionMode: "single",
                    platform: _ctx.platform
                  }, null, 8, ["onOnSelectionChange", "platform"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_TabPanel, {
            header: _ctx.$t('labels.mainArticle')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ProductListLazy, {
                onOnSelectionChange: _ctx.onProductSelectionChange,
                selectionMode: "multiple",
                preDefinedFilters: { accessories: { value: _ctx.oldAccessory.articleNumber, matchMode: 'contains'} },
                platform: _ctx.platform
              }, null, 8, ["onOnSelectionChange", "preDefinedFilters", "platform"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_TabPanel, {
            header: _ctx.$t('labels.summary'),
            disabled: !_ctx.showSummaryTab
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_ProductList, {
                    products: [_ctx.oldAccessory],
                    tableHeader: _ctx.$t('labels.oldArticleAccessory'),
                    platform: _ctx.platform
                  }, null, 8, ["products", "tableHeader", "platform"]),
                  (!_ctx.replaceWithNothing)
                    ? (_openBlock(), _createBlock(_component_ProductList, {
                        key: 0,
                        products: [_ctx.selectedNewAccessory],
                        tableHeader: _ctx.$t('labels.newArticleAccessory'),
                        platform: _ctx.platform
                      }, null, 8, ["products", "tableHeader", "platform"]))
                    : _createCommentVNode("", true),
                  (_ctx.replaceWithNothing)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t("labels.replaceWithNothing")), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("h1", _hoisted_6, [
                    _createVNode(_component_font_awesome_icon, {
                      style: {"margin-left":"30px"},
                      icon: ['fas', 'angle-double-down']
                    }),
                    _createVNode(_component_font_awesome_icon, {
                      style: {"margin-left":"30px"},
                      icon: ['fas', 'angle-double-down']
                    }),
                    _createVNode(_component_font_awesome_icon, {
                      style: {"margin-left":"30px"},
                      icon: ['fas', 'angle-double-down']
                    })
                  ]),
                  _createVNode(_component_ProductList, {
                    products: _ctx.selectedProducts,
                    tableHeader: _ctx.$t('labels.mainArticle'),
                    platform: _ctx.platform
                  }, null, 8, ["products", "tableHeader", "platform"]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_p_button, {
                      severity: "danger",
                      onClick: _ctx.closeDialog
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_p_button, {
                      onClick: _ctx.replaceArticleAccessories,
                      severity: "success"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["header", "disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["header", "visible", "onHide"]))
}