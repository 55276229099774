import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin-top":"-0.75rem"} }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "text-xs" }
const _hoisted_4 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_Badge = _resolveComponent("Badge")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      id: "bookmarkIcon",
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.toggleDataTable && _ctx.toggleDataTable(...args))),
      class: "flex align-items-start"
    }, [
      _createElementVNode("span", null, [
        _createVNode(_component_font_awesome_icon, {
          icon: ['fas', 'star'],
          style: {"color":"#ffc107","height":"1.3rem"}
        })
      ]),
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_Badge, {
          value: _ctx.bookmarks?.length || 0,
          severity: "danger"
        }, null, 8, ["value"])
      ])
    ]),
    _createVNode(_component_OverlayPanel, {
      ref: "opBookmarks",
      appendTo: "body",
      id: "overlay_panel_bookmarks"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          value: _ctx.bookmarks,
          paginator: _ctx.bookmarks?.length > 5,
          rows: 5,
          responsiveLayout: "scroll"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, { headerStyle: "min-width:12rem;display:none;" }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", {
                  style: {"cursor":"pointer"},
                  onClick: $event => (_ctx.navigateToBookmark(data.path))
                }, [
                  _createTextVNode(_toDisplayString(_ctx.$t(data.localizedTitle) || data.entityName) + " " + _toDisplayString(data.entityId) + " ", 1),
                  _createElementVNode("p", _hoisted_3, _toDisplayString(data.description), 1)
                ], 8, _hoisted_2)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { headerStyle: "max-width:3rem;display:none;" }, {
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: "pi pi-times-circle mt-3 mr-3 text-red-600",
                  style: {"cursor":"pointer"},
                  onClick: $event => (_ctx.removeBookmark(data.id))
                }, null, 8, _hoisted_4)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "paginator"])
      ]),
      _: 1
    }, 512)
  ], 64))
}