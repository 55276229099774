import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "formgrid grid p-fluid" }
const _hoisted_2 = { class: "field col" }
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = { class: "formgrid grid p-fluid" }
const _hoisted_5 = { class: "field col" }
const _hoisted_6 = {
  key: 0,
  class: "p-error"
}
const _hoisted_7 = {
  key: 1,
  class: "p-error"
}
const _hoisted_8 = { class: "formgrid grid p-fluid" }
const _hoisted_9 = { class: "field col" }
const _hoisted_10 = { style: {"text-align":"center"} }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "flex justify-content-end mt-2" }

export function render(_ctx, _cache) {
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_button = _resolveComponent("p-button")
  const _component_p_dialog = _resolveComponent("p-dialog")

  return (_openBlock(), _createBlock(_component_p_dialog, {
    header: _ctx.$t('labels.addImages'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[3] || (_cache[3] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw'},
    style: {width: '50vw'},
    onHide: _ctx.closeDialog,
    modal: true,
    class: "text-sm"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.description')), 1),
            _createVNode(_component_InputText, {
              placeholder: _ctx.$t('labels.description'),
              autocomplete: "off",
              modelValue: _ctx.v$.title.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$.title.$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$.title.$invalid && _ctx.submitted})
            }, null, 8, ["placeholder", "modelValue", "class"]),
            ((_ctx.v$.title.$invalid && _ctx.submitted) || _ctx.v$.title.$pending.$response)
              ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.v$.title.required.$message.replace('Value',
                    'Title')), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[4] || (_cache[4] = _createElementVNode("label", null, "Url", -1)),
            _createVNode(_component_InputText, {
              autocomplete: "off",
              modelValue: _ctx.v$.url.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.url.$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$.url.$invalid && _ctx.submitted})
            }, null, 8, ["modelValue", "class"]),
            (((_ctx.v$.url.required.$invalid && _ctx.submitted) || _ctx.v$.url.$pending.$response))
              ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.v$.url.required.$message.replace('Value', 'Url')), 1))
              : _createCommentVNode("", true),
            (((_ctx.v$.url.url.$invalid && _ctx.submitted) || _ctx.v$.url.$pending.$response))
              ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.v$.url.url.$message.replace('Value', 'Url')), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.or')), 1),
            _createElementVNode("div", _mergeProps(_ctx.getRootProps(), { class: "import-wizard-container" }), [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("input", _normalizeProps(_guardReactiveProps(_ctx.getInputProps())), null, 16),
                (_ctx.acceptedFiles.length > 0)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.acceptedFiles.map(u => u.name).join(', ')), 1))
                  : (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.$t("labels.importWizardInfoText")), 1))
              ])
            ], 16)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_p_button, {
            disabled: _ctx.savingInProgress,
            severity: "success",
            textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.confirm')),
            type: "submit"
          }, null, 8, ["disabled", "textContent"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["header", "visible", "onHide"]))
}