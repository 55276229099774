import LoadingPlugin from 'vue-loading-overlay';
import {useRoute} from 'vue-router';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import DataView from 'primevue/dataview';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import OverlayPanel from 'primevue/overlaypanel';
import MultiSelect from 'primevue/multiselect';
import Toolbar from 'primevue/toolbar';
import ProductHome from './home/home.vue';
import WeclappDescription from './weclapp-description/description.vue';
import ShopDescriptionContainer from './shop-description/description-container.vue';
import GroupTab from './group/group.vue';
import BadgesContainer from './badges/badges-container.vue';
import CategoriesContainer from './categories/categories-container.vue';
import ShopContentContainer from './shop-content/shop-content.vue';
import Promotions from './promotions/promotions.vue';
import Compliance from './compliance/compliance.vue';
import GoogleAdwordsContainer from './google-adwords/google-adwords-container.vue';
import BStock from './b-stock/b-stock.vue';
import AccessoriesContainer from '../accessories/accessories-container.vue';
import Panel from 'primevue/panel';
import Prices from './prices/prices.vue';

import {computed, onMounted, onUnmounted, ref} from 'vue';
import {getAll, getSingle, updateShopDescription} from '@/services/products';
import {getAll as getAllMetadata} from '@/services/metadata';
import {getAll as getAllExchangeRates} from '@/services/exchange-rates';
import {useToast} from 'vue-toastification';
import router from '@/router';
import {i18n} from '@/utils/i18n';
import {
    calculateFinalPrice,
    getShopwareUrl,
    resolveActivePriceForSupplySource,
    resolvePriceInDefaultCurrency,
    resolvePrimarySupplySource,
    uvpPriceCalculation,
    vkPriceCalculation
} from '@/utils/helpers';
import {
    getCollidingUsers,
    setEntityWeclappVersion,
    signalHeartBeat
} from '@/services/user-activity';
import store from '@/store';
import {add, remove} from '@/services/boomarks';

export default {
    components: {
        TabPanel,
        TabView,
        'p-multiselect': MultiSelect,
        Toolbar,
        ProductHome,
        GroupTab,
        WeclappDescription,
        ShopDescriptionContainer,
        BadgesContainer,
        CategoriesContainer,
        ShopContentContainer,
        Promotions,
        Compliance,
        GoogleAdwordsContainer,
        BStock,
        AccessoriesContainer,
        Prices,
        'p-dialog': Dialog,
        'p-button': Button,
        loading: LoadingPlugin,
        OverlayPanel,
        Panel,
        DataView
    },

    setup() {
        const route = useRoute();
        const productDetails = ref();
        const toast = useToast();
        const loading = ref(false);
        const showCollidingUsersDialog = ref(false);
        const collidingUsers = ref([]);
        const collidingUsersInterval = ref(null);
        const heartBeatInterval = ref(null);
        const activeTabIndex = ref(0);
        const overlayPanel = ref(null);
        const historyProducts = ref([]);
        const tabSelectorComponent = ref();
        const exchangeRates = ref(null);

        const tabOptions = [
            {
                label: 'Home',
                value: 'Home',
                optionDisabled: true
            },
            {
                label: 'Group ID',
                value: 'Group ID',
                optionDisabled: true
            },
            {
                label: i18n.global.t('labels.description'),
                value: 'Description',
                optionDisabled: true
            },
            {
                label: i18n.global.t('labels.articleAccessories'),
                value: 'Accessories'
            },
            {
                label: i18n.global.t('labels.categories'),
                value: 'Categories'
            },
            {
                label: i18n.global.t('labels.badges'),
                value: 'Badges'
            },
            {
                label: i18n.global.t('labels.prices'),
                value: 'Prices',
                optionDisabled: true
            },
            {
                label: i18n.global.t('labels.promotions'),
                value: 'Promotions'
            },
            {
                label: i18n.global.t('labels.compliance'),
                value: 'Compliance'
            },
            {
                label: i18n.global.t('labels.productGoogleAds.label'),
                value: 'GoogleAds'
            },
            {
                label: 'B-Stock',
                value: 'BStock'
            }
        ];

        const visibleTabs = ref([]);

        const selectedTabs = ref([]);

        const customAttributesDefinition = ref({});

        onMounted(async () => {
            const webshopView = history?.state?.webshopView || false;
            if (webshopView) {
                activeTabIndex.value = 7;
            }

            const result = await getCollidingUsers('product', route.params.id);
            collidingUsers.value = result.data;
            if (collidingUsers.value.length > 0) {
                showCollidingUsersDialog.value = true;
            } else {
                continueAnywayHandler();
            }

            const cus = await getAllMetadata(['customAttributeDefinition']);

            if (
                typeof cus.data?.customAttributeDefinition === 'object' &&
                cus.data?.customAttributeDefinition !== null &&
                Object.values(cus.data?.customAttributeDefinition).length > 0
            ) {
                Object.values(cus.data?.customAttributeDefinition).forEach(
                    (cad: any) => {
                        Object.assign(customAttributesDefinition.value, {
                            [cad.attributeKey]: {...cad}
                        });
                    }
                );
            }

            const er = await getAllExchangeRates();

            exchangeRates.value = er.data;

            if (localStorage.getItem('products-details-tab-list-session')) {
                const stateDump = JSON.parse(
                    localStorage.getItem('products-details-tab-list-session')
                );

                visibleTabs.value = tabOptions
                    .filter(
                        (tabOption) =>
                            tabOption.optionDisabled ||
                            stateDump.some((x: string) => tabOption.value === x)
                    )
                    .map((a) => a.value);

                selectedTabs.value = tabOptions
                    .filter(
                        (tabOption) =>
                            tabOption.optionDisabled ||
                            stateDump.some((x: string) => tabOption.value === x)
                    )
                    .map((a) => a.value);
            } else {
                visibleTabs.value = tabOptions.map((item) => item.value);
                selectedTabs.value = tabOptions.map((item) => item.value);
            }
        });

        onUnmounted(() => {
            if (collidingUsersInterval.value !== null) {
                clearInterval(collidingUsersInterval.value);
            }
            if (heartBeatInterval.value !== null) {
                clearInterval(heartBeatInterval.value);
            }
        });

        const loadProductDetails = () => {
            loading.value = true;
            getSingle(route.params.id)
                .then((data) => {
                    if (data?.data?.shopware || data?.data?.weclapp) {
                        productDetails.value = data.data;
                        setEntityWeclappVersion(
                            'product',
                            route.params.id,
                            productDetails.value.weclapp.version
                        );
                    } else {
                        toast.error(i18n.global.t('messages.noSuchProduct'));
                        router.push({
                            name: 'Products'
                        });
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const vkPrice = computed(() => {
            return vkPriceCalculation(productDetails.value?.weclapp, false);
        });

        const ekPrice = computed(() => {
            const supplySource = resolvePrimarySupplySource(
                productDetails.value?.weclapp
            );
            const price = resolveActivePriceForSupplySource(supplySource);

            if (!price) {
                return null;
            }

            if (
                !price?.currencyName ||
                price.currencyName === 'EUR' ||
                !Object.keys(exchangeRates.value || {}).includes(
                    price.currencyName
                )
            ) {
                return calculateFinalPrice(price, false);
            }

            return resolvePriceInDefaultCurrency(
                calculateFinalPrice(price, false),
                exchangeRates.value[price.currencyName]
            );
        });

        const calcEkPrice = computed(() => {
            const supplySource = resolvePrimarySupplySource(
                productDetails.value?.weclapp
            );

            if (!supplySource?.customAttributes.supply_source_bonus_discount) {
                return null;
            }

            const price = resolveActivePriceForSupplySource(supplySource);

            if (!price) {
                return null;
            }

            const valueInNativeCurrency =
                Math.round(
                    (calculateFinalPrice(price, false) -
                        parseFloat(
                            supplySource?.customAttributes
                                .supply_source_bonus_discount
                        )) *
                        100
                ) / 100;

            if (
                !price?.currencyName ||
                price.currencyName === 'EUR' ||
                !Object.keys(exchangeRates.value || {}).includes(
                    price.currencyName
                )
            ) {
                return valueInNativeCurrency;
            }

            return resolvePriceInDefaultCurrency(
                valueInNativeCurrency,
                exchangeRates.value[price.currencyName]
            );
        });

        const listPrice = computed(() => {
            return uvpPriceCalculation(productDetails.value?.weclapp);
        });

        const cancelHandler = () => {
            router.push({
                name: 'Products'
            });
        };

        const continueAnywayHandler = async () => {
            showCollidingUsersDialog.value = false;
            await signalHeartBeat('product', route.params.id);

            if (heartBeatInterval.value !== null) {
                clearInterval(heartBeatInterval.value);
            }

            if (process.env.NODE_ENV !== 'development') {
                heartBeatInterval.value = setInterval(() => {
                    signalHeartBeat('product', route.params.id);
                }, 8000);
            }

            loadProductDetails();

            if (collidingUsersInterval.value !== null) {
                clearInterval(collidingUsersInterval.value);
            }

            if (process.env.NODE_ENV !== 'development') {
                collidingUsersInterval.value = setInterval(async () => {
                    const result = await getCollidingUsers(
                        'product',
                        route.params.id
                    );
                    collidingUsers.value = result.data;
                }, 5000);
            }
        };

        const resolvedPlatform = computed(() => {
            const currentPlatform = store.getters['auth/platform'];
            const platforms = store.getters['auth/platforms'];
            return platforms.find(
                (item: any) => item.value === currentPlatform
            );
        });

        const secondPlatform = computed(() => {
            switch (resolvedPlatform.value?.threeLetterId) {
                case 'ttd':
                    return store.getters['auth/platforms'].find(
                        (item: any) => item.value === 'vdd'
                    );
                case 'ttl':
                    return store.getters['auth/platforms'].find(
                        (item: any) => item.value === 'vdl'
                    );
                case 'vdd':
                    return store.getters['auth/platforms'].find(
                        (item: any) => item.value === 'ttd'
                    );
                default:
                    return store.getters['auth/platforms'].find(
                        (item: any) => item.value === 'ttl'
                    );
            }
        });

        const onAutoSaveDescription = async (
            productNumber: string,
            values: any,
            platform: string | null = null
        ) => {
            if (3 === activeTabIndex.value) {
                try {
                    await updateShopDescription(
                        productNumber,
                        values,
                        platform
                    );
                } catch (error: any) {
                    toast.error(error.response?.data?.error || error.message);
                }
            }
        };

        const resolvedShopwareUrl = (
            shopwareData: {
                url: string;
                name: string | null;
                seoUrl: string | null;
            },
            threeLetterId: string
        ) => {
            return (
                shopwareData.url +
                (shopwareData.seoUrl !== null
                    ? shopwareData.seoUrl
                    : getShopwareUrl(shopwareData.name, threeLetterId) +
                      (threeLetterId === 'vct' ? '' : '.html'))
            );
        };

        const toggleOverlayPanel = (event: any) => {
            overlayPanel.value.toggle(event);
        };

        const showOverlayButton = computed(() => {
            const historyExists = history?.state?.historyFilters || false;

            if (historyExists) {
                const historyFilters = JSON.parse(
                    history.state?.historyFilters || {}
                );
                getAll({
                    first: historyFilters.first || 0,
                    rows: historyFilters.rows || 20,
                    columns: ['id', 'articleNumber', 'name'],
                    filters: historyFilters.filters || {},
                    sortField: historyFilters.sortField || 'id',
                    sortOrder: historyFilters.sortOrder || 'asc'
                })
                    .then((data) => {
                        historyProducts.value = data.data.items || [];
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    });
            }

            return historyExists;
        });

        const onHistoryItemSelected = (id: string) => {
            router.push({
                name: 'ProductDetails',
                params: {id},
                state: {historyFilters: history.state?.historyFilters || null}
            });
        };

        const previousHistoryItem = computed(() => {
            if (!historyProducts.value) {
                return null;
            }

            const index = historyProducts.value.findIndex((item: any) => {
                return item.articleNumber === route.params.id;
            });
            return index > 0 ? historyProducts.value[index - 1] : null;
        });

        const nextHistoryItem = computed(() => {
            if (!historyProducts.value) {
                return null;
            }

            const index = historyProducts.value.findIndex((item: any) => {
                return item.articleNumber === route.params.id;
            });
            return index > -1 && index < historyProducts.value.length - 1
                ? historyProducts.value[index + 1]
                : null;
        });

        const toggleBookmark = () => {
            if (isBookmarked.value) {
                remove(isBookmarked.value)
                    .then(() => {
                        store.dispatch('ui/deleteBookmark', {
                            id: isBookmarked.value
                        });
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    });
            } else {
                add(
                    'article',
                    route.params.id,
                    route.fullPath,
                    productDetails.value?.weclapp?.name
                )
                    .then((data) =>
                        store.dispatch('ui/addBookmark', {
                            id: data.data.id,
                            entityName: 'article',
                            entityId: route.params.id,
                            description: productDetails.value?.weclapp?.name,
                            path: route.fullPath
                        })
                    )
                    .catch((error) => {
                        toast.error(error.message);
                    });
            }
        };

        const isBookmarked = computed(() => {
            return store.getters['ui/bookmarks'].find(
                (obj: any) =>
                    'article' === obj.entityName &&
                    route.params.id === obj.entityId
            )?.id;
        });

        const onToggleTabVisibility = (value: any) => {
            const cols = ['Home', 'Group ID', 'Description'].concat(
                value.value || []
            );

            selectedTabs.value = cols.filter((c: string, index: number) => {
                return cols.indexOf(c) === index;
            });
        };

        const onConfirmTabsSelection = () => {
            visibleTabs.value = selectedTabs.value.filter(() => {
                return true;
            });

            localStorage.setItem(
                'products-details-tab-list-session',
                JSON.stringify(visibleTabs.value)
            );

            loadProductDetails();

            tabSelectorComponent.value.hide();
        };

        const onCancelTabsSelection = () => {
            selectedTabs.value = visibleTabs.value.filter(() => {
                return true;
            });

            tabSelectorComponent.value.hide();
        };

        const editDescriptionPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return (
                user?.permissions?.indexOf('product-description-edit') !== -1
            );
        });

        return {
            loading,
            productNumber: route.params.id,
            productDetails,
            vkPrice,
            ekPrice,
            listPrice,
            loadProductDetails,
            resolvedShopwareUrl,
            showCollidingUsersDialog,
            collidingUsers,
            cancelHandler,
            continueAnywayHandler,
            resolvedPlatform,
            onAutoSaveDescription,
            activeTabIndex,
            overlayPanel,
            toggleOverlayPanel,
            showOverlayButton,
            historyProducts,
            onHistoryItemSelected,
            previousHistoryItem,
            nextHistoryItem,
            toggleBookmark,
            isBookmarked,
            tabOptions,
            onToggleTabVisibility,
            onConfirmTabsSelection,
            onCancelTabsSelection,
            visibleTabs,
            selectedTabs,
            tabSelectorComponent,
            formatter: new Intl.NumberFormat(i18n.global.locale, {
                style: 'currency',
                currency: 'EUR'
            }),
            editDescriptionPermissionAvailable,
            secondPlatform,
            customAttributesDefinition,
            calcEkPrice,
            exchangeRates
        };
    }
};
