import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { style: {"text-align":"center"} }

export function render(_ctx, _cache) {
  const _component_Chips = _resolveComponent("Chips")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_Tag = _resolveComponent("Tag")
  const _component_Panel = _resolveComponent("Panel")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_BulkProductAction = _resolveComponent("BulkProductAction")

  return (_openBlock(), _createBlock(_component_BulkProductAction, {
    onCloseDialog: _ctx.closeResponsive,
    onConfirmClicked: _ctx.confirmClicked,
    products: _ctx.products,
    productTotalRecords: _ctx.productTotalRecords,
    displayDialog: _ctx.showDialog,
    isFilterRelated: _ctx.isFilterRelated,
    summaryTabVisible: _ctx.tags.length > 0,
    loading: _ctx.loading,
    dialogHeader: _ctx.isRemove ? _ctx.$t('labels.removeTags') : _ctx.$t('labels.addTags')
  }, {
    "tab-panel-middle": _withCtx(() => [
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('labels.tags')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Chips, {
                modelValue: _ctx.tags,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.tags) = $event)),
                allowDuplicate: false,
                placeholder: _ctx.$t('labels.metatagsSeparatorInfo'),
                separator: ","
              }, null, 8, ["modelValue", "placeholder"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    "summary-panel-header": _withCtx((slotProps) => [
      _createVNode(_component_Panel, {
        header: _ctx.$t('labels.tags')
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
            return (_openBlock(), _createBlock(_component_Tag, {
              severity: "info",
              class: "mt-1 mr-1"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(tag.toUpperCase().replace('TTC-PRODUCT-META-',
                    '')), 1)
              ]),
              _: 2
            }, 1024))
          }), 256))
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    "summary-panel-middle": _withCtx(() => [
      _createElementVNode("h1", _hoisted_3, [
        (_ctx.isRemove)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px","color":"#ef4444"},
                icon: ['fas', 'trash-can']
              }),
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px","color":"#ef4444"},
                icon: ['fas', 'trash-can']
              }),
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px","color":"#ef4444"},
                icon: ['fas', 'trash-can']
              })
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px"},
                icon: ['fas', 'angle-double-down']
              }),
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px"},
                icon: ['fas', 'angle-double-down']
              }),
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px"},
                icon: ['fas', 'angle-double-down']
              })
            ], 64))
      ])
    ]),
    _: 1
  }, 8, ["onCloseDialog", "onConfirmClicked", "products", "productTotalRecords", "displayDialog", "isFilterRelated", "summaryTabVisible", "loading", "dialogHeader"]))
}