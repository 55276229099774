import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache) {
  const _component_Weclapp = _resolveComponent("Weclapp")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_TabView = _resolveComponent("TabView")
  const _component_TicketTemplatesList = _resolveComponent("TicketTemplatesList")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createBlock(_component_Panel, {
    header: _ctx.$t('labels.profile')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_TabView, {
        class: "tabview-custom text-sm",
        ref: "tabview4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TabPanel, null, {
            header: _withCtx(() => [
              _cache[0] || (_cache[0] = _createElementVNode("i", { class: "pi pi-key" }, null, -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.apiCredentials")), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_TabView, {
                class: "tabview-custom text-sm",
                ref: "tabview4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_TabPanel, null, {
                    header: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createElementVNode("i", { class: "pi pi-heart" }, null, -1),
                      _createElementVNode("span", null, "Weclapp", -1)
                    ])),
                    default: _withCtx(() => [
                      _createVNode(_component_Weclapp)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512)
            ]),
            _: 1
          }),
          (_ctx.ticketTabVisible)
            ? (_openBlock(), _createBlock(_component_TabPanel, { key: 0 }, {
                header: _withCtx(() => [
                  _cache[2] || (_cache[2] = _createElementVNode("i", { class: "pi pi-wrench" }, null, -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.tickets")), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_TabView, {
                    class: "tabview-custom text-sm",
                    ref: "tabview5"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TabPanel, null, {
                        header: _withCtx(() => [
                          _cache[3] || (_cache[3] = _createElementVNode("i", { class: "pi pi-comment" }, null, -1)),
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.templates")), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_TicketTemplatesList)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["header"]))
}