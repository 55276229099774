import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]

export function render(_ctx, _cache) {
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      id: "languageIcon",
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.toggleDataTable && _ctx.toggleDataTable(...args)))
    }, [
      _createElementVNode("img", {
        src: require('@/assets/img/flag_placeholder.png'),
        class: _normalizeClass(["flag", _ctx.flagIcon])
      }, null, 10, _hoisted_1)
    ]),
    _createVNode(_component_OverlayPanel, {
      ref: "opLanguages",
      appendTo: "body",
      id: "overlay_panel_languages"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          value: _ctx.languages,
          rows: 5,
          responsiveLayout: "scroll"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, { headerStyle: "display:none;" }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", {
                  style: {"cursor":"pointer"},
                  onClick: $event => (_ctx.changeLanguage(data.key))
                }, [
                  _createElementVNode("img", {
                    src: require('@/assets/img/flag_placeholder.png'),
                    class: _normalizeClass(["flag mr-2", data.flag])
                  }, null, 10, _hoisted_3),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t(data.label)), 1)
                ], 8, _hoisted_2)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"])
      ]),
      _: 1
    }, 512)
  ], 64))
}