import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "col" }
const _hoisted_2 = { class: "font-bold ml-1" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = {
  key: 0,
  style: {"display":"block"}
}
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = {
  key: 0,
  class: "col-1"
}
const _hoisted_10 = {
  key: 1,
  class: "col"
}
const _hoisted_11 = {
  key: 2,
  class: "col"
}
const _hoisted_12 = {
  key: 1,
  style: {"display":"block"}
}
const _hoisted_13 = { class: "col-1" }
const _hoisted_14 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_Panel = _resolveComponent("Panel")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
  }, [
    _createVNode(_component_Panel, null, {
      header: _withCtx(() => _cache[2] || (_cache[2] = [
        _createElementVNode("div", { class: "flex justify-content-between w-full" }, [
          _createElementVNode("div", { class: "font-bold" }, "Maße & Gewichte")
        ], -1)
      ])),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.packageItems, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "grid"
          }, [
            _createElementVNode("div", _hoisted_1, [
              _cache[3] || (_cache[3] = _createElementVNode("label", null, "Karton scannen", -1)),
              _createElementVNode("div", null, [
                (index === 0)
                  ? (_openBlock(), _createBlock(_component_AutoComplete, {
                      key: 0,
                      modelValue: item.weclappProduct,
                      "onUpdate:modelValue": $event => ((item.weclappProduct) = $event),
                      suggestions: _ctx.filteredProducts,
                      class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.packageItems.$each.$response.$data[index].weclappProduct.$invalid}),
                      onComplete: $event => (_ctx.searchProducts($event, index)),
                      onItemSelect: $event => (_ctx.onProductChange($event, index)),
                      optionLabel: "name",
                      dropdown: "",
                      ref_for: true,
                      ref: "firstRowAutocompleteField",
                      id: 'package_selection_' + index
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "suggestions", "class", "onComplete", "onItemSelect", "id"]))
                  : (_openBlock(), _createBlock(_component_AutoComplete, {
                      key: 1,
                      modelValue: item.weclappProduct,
                      "onUpdate:modelValue": $event => ((item.weclappProduct) = $event),
                      suggestions: _ctx.filteredProducts,
                      class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.packageItems.$each.$response.$data[index].weclappProduct.$invalid}),
                      onComplete: $event => (_ctx.searchProducts($event, index)),
                      onItemSelect: $event => (_ctx.onProductChange($event, index)),
                      optionLabel: "name",
                      dropdown: "",
                      ref_for: true,
                      ref: "firstRowAutocompleteField",
                      id: 'package_selection_' + index
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "suggestions", "class", "onComplete", "onItemSelect", "id"])),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.v$.packageItems.$each.$response.$errors[index].weclappProduct, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true),
                _createElementVNode("small", _hoisted_2, _toDisplayString(item.orderItem?.title ||
                            item.article?.name), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.grossWeightShort')), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_InputNumber, {
                  locale: _ctx.locale,
                  class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.packageItems.$each.$response.$data[index].grossWeight.$invalid}),
                  mode: "decimal",
                  min: item?.weclappProduct?.articleGrossWeight || 0,
                  showButtons: "",
                  suffix: ' kg',
                  minFractionDigits: 1,
                  step: 0.1,
                  modelValue: item.grossWeight,
                  "onUpdate:modelValue": $event => ((item.grossWeight) = $event),
                  onInput: $event => (_ctx.onPastedDimension(index, 'grossWeight')),
                  ref_for: true,
                  ref: "grossWeightField",
                  id: 'gross_weight_' + index
                }, null, 8, ["locale", "class", "min", "modelValue", "onUpdate:modelValue", "onInput", "id"]),
                (item?.weclappProduct?.articleGrossWeight)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_4, [
                      _cache[4] || (_cache[4] = _createElementVNode("span", null, "Kartongewicht: ", -1)),
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.formatter.format(item?.weclappProduct?.articleGrossWeight)) + " kg", 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.packageItems.$each.$response.$errors[index].grossWeight, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.lengthShort')), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_InputNumber, {
                  locale: _ctx.locale,
                  suffix: ' cm',
                  min: 0,
                  class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.packageItems.$each.$response.$data[index].length.$invalid}),
                  showButtons: "",
                  step: 1,
                  maxFractionDigits: 1,
                  modelValue: item.length,
                  "onUpdate:modelValue": $event => ((item.length) = $event),
                  onInput: $event => (_ctx.onPastedDimension(index, 'length')),
                  ref_for: true,
                  ref: "lengthField",
                  id: 'length_' + index
                }, null, 8, ["locale", "class", "modelValue", "onUpdate:modelValue", "onInput", "id"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.packageItems.$each.$response.$errors[index].length, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.widthShort')), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_InputNumber, {
                  locale: _ctx.locale,
                  suffix: ' cm',
                  min: 0,
                  class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.packageItems.$each.$response.$data[index].width.$invalid}),
                  showButtons: "",
                  step: 1,
                  maxFractionDigits: 1,
                  modelValue: item.width,
                  "onUpdate:modelValue": $event => ((item.width) = $event),
                  onInput: $event => (_ctx.onPastedDimension(index, 'width')),
                  ref_for: true,
                  ref: "widthField",
                  id: 'width_' + index
                }, null, 8, ["locale", "class", "modelValue", "onUpdate:modelValue", "onInput", "id"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.packageItems.$each.$response.$errors[index].width, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.heightShort')), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_InputNumber, {
                  locale: _ctx.locale,
                  suffix: ' cm',
                  min: 0,
                  class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.packageItems.$each.$response.$data[index].height.$invalid}),
                  showButtons: "",
                  step: 1,
                  maxFractionDigits: 1,
                  modelValue: item.height,
                  "onUpdate:modelValue": $event => ((item.height) = $event),
                  onInput: $event => (_ctx.onPastedDimension(index, 'height')),
                  ref_for: true,
                  ref: "heightField",
                  id: 'height_' + index
                }, null, 8, ["locale", "class", "modelValue", "onUpdate:modelValue", "onInput", "id"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.packageItems.$each.$response.$errors[index].height, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ]),
            (_ctx.hasDangerousGoods)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _cache[5] || (_cache[5] = _createElementVNode("label", null, "Gefahrgut", -1)),
                  _withDirectives((_openBlock(), _createElementBlock("div", null, [
                    _createVNode(_component_p_checkbox, {
                      disabled: !_ctx.dangerousGoodsSuitableRecipientAddress,
                      binary: "",
                      class: _normalizeClass(["mt-2", {'p-invalid':_ctx.submitted && _ctx.v$.packageItems.$each.$response.$data[index].dangerousGoods.$invalid}]),
                      modelValue: item.dangerousGoods,
                      "onUpdate:modelValue": $event => ((item.dangerousGoods) = $event)
                    }, null, 8, ["disabled", "class", "modelValue", "onUpdate:modelValue"])
                  ])), [
                    [
                      _directive_tooltip,
                      _ctx.dangerousGoodsSuitableRecipientAddress ? '' : _ctx.$t('messages.deliveryOfDangerousGoodsToNotSuitableCountry'),
                      void 0,
                      { left: true }
                    ]
                  ]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.packageItems.$each.$response.$errors[index].dangerousGoods, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.hasDangerousGoods)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.netWeightShort')), 1),
                  _createElementVNode("div", null, [
                    _withDirectives(_createVNode(_component_InputNumber, {
                      locale: _ctx.locale,
                      min: 0,
                      class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.packageItems.$each.$response.$data[index].netWeight.$invalid}),
                      mode: "decimal",
                      suffix: ' kg',
                      showButtons: "",
                      minFractionDigits: 1,
                      step: 0.1,
                      disabled: !item.dangerousGoods || !_ctx.dangerousGoodsSuitableRecipientAddress,
                      modelValue: item.netWeight,
                      "onUpdate:modelValue": $event => ((item.netWeight) = $event),
                      onInput: $event => (_ctx.onPastedDimension(index, 'netWeight'))
                    }, null, 8, ["locale", "class", "disabled", "modelValue", "onUpdate:modelValue", "onInput"]), [
                      [
                        _directive_tooltip,
                        (_ctx.dangerousGoodsSuitableRecipientAddress || !item.dangerousGoods) ? '' : _ctx.$t('messages.deliveryOfDangerousGoodsToNotSuitableCountry'),
                        void 0,
                        { left: true }
                      ]
                    ]),
                    (_ctx.submitted)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.packageItems.$each.$response.$errors[index].netWeight, (error) => {
                          return (_openBlock(), _createElementBlock("small", {
                            style: {"display":"block"},
                            class: "p-error",
                            key: error
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.hasDangerousGoods)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.upsChemicalId')), 1),
                  _createElementVNode("div", null, [
                    _createVNode(_component_p_dropdown, {
                      modelValue: item.chemicalId,
                      "onUpdate:modelValue": $event => ((item.chemicalId) = $event),
                      options: _ctx.chemicalOptions,
                      optionLabel: "idNumber",
                      optionValue: "idNumber",
                      class: _normalizeClass(["w-full", {'p-invalid':_ctx.submitted && _ctx.v$.packageItems.$each.$response.$data[index].chemicalId.$invalid}]),
                      showClear: "",
                      disabled: !item.dangerousGoods || !_ctx.dangerousGoodsSuitableRecipientAddress
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "disabled", "class"]),
                    (_ctx.submitted)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.packageItems.$each.$response.$errors[index].chemicalId, (error) => {
                          return (_openBlock(), _createElementBlock("small", {
                            style: {"display":"block"},
                            class: "p-error",
                            key: error
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      : _createCommentVNode("", true),
                    (item.chemicalId)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_12, _toDisplayString(_ctx.chemicalOptions.find((opt) => opt.idNumber ===
                            item.chemicalId).hazardousMaterialsDescription), 1))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("span", {
                class: "pi mt-4 pi-plus-circle text-green-600 text-2xl",
                style: {"position":"relative","top":"1px","cursor":"pointer"},
                onClick: _cache[0] || (_cache[0] = $event => (_ctx.addPackageItem(null)))
              }),
              (index > 0)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "pi ml-3 pi-times-circle text-red-600 text-2xl",
                    style: {"position":"relative","top":"1px","cursor":"pointer"},
                    onClick: $event => (_ctx.removePackageItem(index))
                  }, null, 8, _hoisted_14))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128))
      ]),
      _: 1
    })
  ], 32))
}