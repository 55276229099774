import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "m-2" }
const _hoisted_2 = { class: "grid formgrid p-fluid mt-3 mb-3" }
const _hoisted_3 = { class: "col field" }
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = { class: "col field" }
const _hoisted_6 = { class: "grid formgrid p-fluid mb-3" }
const _hoisted_7 = { class: "field col" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "field col" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "grid formgrid p-fluid mb-3" }
const _hoisted_12 = { class: "field col" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "field col" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "grid formgrid p-fluid mb-3" }
const _hoisted_17 = { class: "field col" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "field col" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { class: "field col" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { class: "field col" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { class: "grid formgrid p-fluid mb-3" }
const _hoisted_26 = { class: "field col" }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { class: "field col" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { class: "field col" }
const _hoisted_31 = { key: 0 }
const _hoisted_32 = { class: "field col" }
const _hoisted_33 = { key: 0 }
const _hoisted_34 = {
  key: 0,
  class: "flex justify-content-end"
}

export function render(_ctx, _cache) {
  const _component_app_checkbox = _resolveComponent("app-checkbox")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_InputSwitch = _resolveComponent("InputSwitch")
  const _component_p_textarea = _resolveComponent("p-textarea")
  const _component_tiny = _resolveComponent("tiny")
  const _component_p_calendar = _resolveComponent("p-calendar")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")
  const _component_p_button = _resolveComponent("p-button")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[30] || (_cache[30] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
    }, [
      _createVNode(_component_ScrollPanel, { style: {"height":"calc(100vh - 28rem)"} }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productPromotions.ekPromoSwitch')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_promo_ek_switch?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (!_ctx.isBulkEdit)
                ? (_openBlock(), _createBlock(_component_app_checkbox, {
                    key: 0,
                    modelValue: _ctx.v$.ekPromoSwitch.$model,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$.ekPromoSwitch.$model) = $event)),
                    binary: true
                  }, null, 8, ["modelValue"]))
                : (_openBlock(), _createBlock(_component_TriStateCheckbox, {
                    key: 1,
                    modelValue: _ctx.v$.ekPromoSwitch.$model,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.ekPromoSwitch.$model) = $event))
                  }, null, 8, ["modelValue"]))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productPromotions.vkPromoSwitch')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_promo_vk_switch?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (!_ctx.isBulkEdit)
                ? (_openBlock(), _createBlock(_component_app_checkbox, {
                    key: 0,
                    modelValue: _ctx.v$.vkPromoSwitch.$model,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$.vkPromoSwitch.$model) = $event)),
                    binary: true
                  }, null, 8, ["modelValue"]))
                : (_openBlock(), _createBlock(_component_TriStateCheckbox, {
                    key: 1,
                    modelValue: _ctx.v$.vkPromoSwitch.$model,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.vkPromoSwitch.$model) = $event))
                  }, null, 8, ["modelValue"]))
            ]),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productPromotions.promoFlag')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_promo_flag?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (!_ctx.isBulkEdit)
                ? (_openBlock(), _createBlock(_component_app_checkbox, {
                    key: 0,
                    modelValue: _ctx.v$.promoFlag.$model,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$.promoFlag.$model) = $event)),
                    binary: true
                  }, null, 8, ["modelValue"]))
                : (_openBlock(), _createBlock(_component_TriStateCheckbox, {
                    key: 1,
                    modelValue: _ctx.v$.promoFlag.$model,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$.promoFlag.$model) = $event))
                  }, null, 8, ["modelValue"]))
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productPromotions.ekPromoText')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_promo_ek_text?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.ekPromoText,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.bulkEditSwitches.ekPromoText) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_p_textarea, {
                rows: "4",
                autocomplete: "off",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.ekPromoText,
                modelValue: _ctx.v$.ekPromoText.$model,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.v$.ekPromoText.$model) = $event))
              }, null, 8, ["disabled", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productPromotions.vkPromoText')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_promo_vk_text?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.vkPromoText,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.bulkEditSwitches.vkPromoText) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_p_textarea, {
                rows: "4",
                autocomplete: "off",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.vkPromoText,
                modelValue: _ctx.v$.vkPromoText.$model,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$.vkPromoText.$model) = $event))
              }, null, 8, ["disabled", "modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productDescription.internalNoteSupplySource')), 1),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.internalNoteSupplySource,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.bulkEditSwitches.internalNoteSupplySource) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_tiny, {
                tinymceScriptSrc: _ctx.tinyUrl,
                modelValue: _ctx.v$.internalNoteSupplySource.$model,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.v$.internalNoteSupplySource.$model) = $event)),
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.internalNoteSupplySource,
                init: {
                    height: 200,
                    menubar: false,
                    plugins: 'lists link image emoticons code table anchor charmap fullscreen paste',
                    toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code',
                    browser_spellcheck: true
                }
              }, null, 8, ["tinymceScriptSrc", "modelValue", "disabled"]),
              _createElementVNode("small", null, _toDisplayString(_ctx.$t('messages.numberOfCharsLeft', {value:
                        _ctx.labelCharactersLeft?.internalNoteSupplySource})), 1),
              (_ctx.submitted)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.internalNoteSupplySource.$silentErrors, (error) => {
                    return (_openBlock(), _createElementBlock("small", {
                      style: {"display":"block"},
                      class: "p-error",
                      key: error
                    }, _toDisplayString(error.$message), 1))
                  }), 128))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productDescription.internalNote')), 1),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.internalNote,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => ((_ctx.bulkEditSwitches.internalNote) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_tiny, {
                tinymceScriptSrc: _ctx.tinyUrl,
                modelValue: _ctx.v$.internalNote.$model,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.v$.internalNote.$model) = $event)),
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.internalNote,
                init: {
                    height: 200,
                    menubar: false,
                    plugins: 'lists link image emoticons code table anchor charmap fullscreen paste',
                    toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code',
                    browser_spellcheck: true
                }
              }, null, 8, ["tinymceScriptSrc", "modelValue", "disabled"]),
              (_ctx.submitted)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.internalNote.$silentErrors, (error) => {
                    return (_openBlock(), _createElementBlock("small", {
                      style: {"display":"block"},
                      class: "p-error",
                      key: error
                    }, _toDisplayString(error.$message), 1))
                  }), 128))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productPromotions.ekPromoStart')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_promo_ek_start?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.ekPromoStart,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.bulkEditSwitches.ekPromoStart) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_p_calendar, {
                autocomplete: "off",
                dateFormat: "dd.mm.yy",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.ekPromoStart,
                modelValue: _ctx.v$.ekPromoStart.$model,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => ((_ctx.v$.ekPromoStart.$model) = $event)),
                class: "full-width",
                showButtonBar: ""
              }, null, 8, ["disabled", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productPromotions.ekPromoEnd')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_promo_ek_ende?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.ekPromoEnd,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => ((_ctx.bulkEditSwitches.ekPromoEnd) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_p_calendar, {
                autocomplete: "off",
                dateFormat: "dd.mm.yy",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.ekPromoEnd,
                modelValue: _ctx.v$.ekPromoEnd.$model,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => ((_ctx.v$.ekPromoEnd.$model) = $event)),
                class: "full-width",
                showButtonBar: ""
              }, null, 8, ["disabled", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productPromotions.vkPromoStart')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_vk_promo_start?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.vkPromoStart,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => ((_ctx.bulkEditSwitches.vkPromoStart) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_p_calendar, {
                autocomplete: "off",
                dateFormat: "dd.mm.yy",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.vkPromoStart,
                modelValue: _ctx.v$.vkPromoStart.$model,
                "onUpdate:modelValue": _cache[19] || (_cache[19] = $event => ((_ctx.v$.vkPromoStart.$model) = $event)),
                class: "full-width",
                showButtonBar: ""
              }, null, 8, ["disabled", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productPromotions.vkPromoEnd')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_vk_promo_end?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.vkPromoEnd,
                      "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => ((_ctx.bulkEditSwitches.vkPromoEnd) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_p_calendar, {
                autocomplete: "off",
                dateFormat: "dd.mm.yy",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.vkPromoEnd,
                modelValue: _ctx.v$.vkPromoEnd.$model,
                "onUpdate:modelValue": _cache[21] || (_cache[21] = $event => ((_ctx.v$.vkPromoEnd.$model) = $event)),
                class: "full-width",
                showButtonBar: ""
              }, null, 8, ["disabled", "modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productPromotions.promoValue')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_promo_ek_value?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.promoValue,
                      "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => ((_ctx.bulkEditSwitches.promoValue) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_InputNumber, {
                mode: "decimal",
                locale: _ctx.locale,
                maxFractionDigits: 2,
                autocomplete: "off",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.promoValue,
                modelValue: _ctx.v$.promoValue.$model,
                "onUpdate:modelValue": _cache[23] || (_cache[23] = $event => ((_ctx.v$.promoValue.$model) = $event)),
                class: "full-width"
              }, null, 8, ["locale", "disabled", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_28, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productPromotions.blackWeekMax')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_bw_vk?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.blackWeekMax,
                      "onUpdate:modelValue": _cache[24] || (_cache[24] = $event => ((_ctx.bulkEditSwitches.blackWeekMax) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_InputNumber, {
                mode: "decimal",
                locale: _ctx.locale,
                maxFractionDigits: 2,
                autocomplete: "off",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.blackWeekMax,
                modelValue: _ctx.v$.blackWeekMax.$model,
                "onUpdate:modelValue": _cache[25] || (_cache[25] = $event => ((_ctx.v$.blackWeekMax.$model) = $event)),
                class: "full-width"
              }, null, 8, ["locale", "disabled", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_30, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productPromotions.uvpLockStart')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_uvp_lock_start?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.uvpLockStart,
                      "onUpdate:modelValue": _cache[26] || (_cache[26] = $event => ((_ctx.bulkEditSwitches.uvpLockStart) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_p_calendar, {
                autocomplete: "off",
                dateFormat: "dd.mm.yy",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.uvpLockStart,
                modelValue: _ctx.v$.uvpLockStart.$model,
                "onUpdate:modelValue": _cache[27] || (_cache[27] = $event => ((_ctx.v$.uvpLockStart.$model) = $event)),
                class: "full-width",
                showButtonBar: ""
              }, null, 8, ["disabled", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_32, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productPromotions.uvpLockEnd')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_uvp_lock_end?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.uvpLockEnd,
                      "onUpdate:modelValue": _cache[28] || (_cache[28] = $event => ((_ctx.bulkEditSwitches.uvpLockEnd) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_p_calendar, {
                autocomplete: "off",
                dateFormat: "dd.mm.yy",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.uvpLockEnd,
                modelValue: _ctx.v$.uvpLockEnd.$model,
                "onUpdate:modelValue": _cache[29] || (_cache[29] = $event => ((_ctx.v$.uvpLockEnd.$model) = $event)),
                class: "full-width",
                showButtonBar: ""
              }, null, 8, ["disabled", "modelValue"])
            ])
          ])
        ]),
        _: 1
      }),
      (!_ctx.isBulkEdit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
            _createVNode(_component_p_button, {
              disabled: _ctx.savingInProgress,
              severity: "success",
              textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.confirm')),
              type: "submit"
            }, null, 8, ["disabled", "textContent"])
          ]))
        : _createCommentVNode("", true)
    ], 32)
  ]))
}