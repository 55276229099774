import {computed, ref, watch} from 'vue';
import Dialog from 'primevue/dialog';
import useVuelidate from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';
import {i18n} from '@/utils/i18n';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';
import Fieldset from 'primevue/fieldset';
import InputText from 'primevue/inputtext';
import Panel from 'primevue/panel';
import {
    PriceType,
    ReductionAdditionType,
    ReductionAdditionValueType
} from '@/utils/enums';
import {DateTime} from 'luxon';

export default {
    components: {
        PrimeDialog: Dialog,
        'p-button': Button,
        'p-dropdown': Dropdown,
        InputNumber,
        'p-calendar': Calendar,
        'p-fieldset': Fieldset,
        InputText,
        Panel
    },
    emits: ['close-dialog'],
    props: {
        displayCostPriceDialog: {type: Boolean, default: false},
        currencyName: {type: String, default: null},
        priceType: {type: String, default: null},
        originalPrice: {type: Object, default: null}
    },
    setup(props: any, context: any) {
        const showDialog = ref(props.displayCostPriceDialog);
        const priceType = ref(props.priceType);
        const currencyName = ref(props.currencyName);
        const originalPrice = ref(props.originalPrice);

        const submitted = ref(false);

        watch(
            () => props.displayCostPriceDialog,
            (val) => {
                showDialog.value = val;
            }
        );

        watch(
            () => props.currencyName,
            (val) => {
                currencyName.value = val;
            }
        );

        watch(
            () => props.priceType,
            (val) => {
                priceType.value = val;
            }
        );

        watch(
            () => props.originalPrice,
            (val) => {
                originalPrice.value = val;
                state.value = {
                    price: originalPrice.value?.price || null,
                    startDate: originalPrice.value?.startDate
                        ? DateTime.fromMillis(originalPrice.value?.startDate)
                              .setLocale(i18n.global.locale)
                              .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                              .toJSDate()
                        : null,
                    endDate: originalPrice.value?.endDate
                        ? DateTime.fromMillis(originalPrice.value?.endDate)
                              .setLocale(i18n.global.locale)
                              .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                              .toJSDate()
                        : null,
                    reductionAdditions: (
                        originalPrice.value?.reductionAdditions || []
                    ).map((item: any) => {
                        return {
                            ...item,
                            value: parseFloat(item.value),
                            description: Object.values(
                                ReductionAdditionType
                            ).includes(item.name as ReductionAdditionType)
                                ? item.name
                                : ReductionAdditionType.Regular
                        };
                    })
                };
            }
        );

        const closeResponsive = (dialogData: any = null) => {
            showDialog.value = false;
            context.emit(
                'close-dialog',
                dialogData ? {...dialogData, priceType: priceType.value} : null
            );

            state.value = {
                price: null,
                startDate: null,
                endDate: null,
                reductionAdditions: []
            };

            submitted.value = false;
        };

        const state = ref({
            price: null,
            startDate: null,
            endDate: null,
            reductionAdditions: []
        });

        const rules = {
            price: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            startDate: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            endDate: {
                greaterOrEqualStartDate: helpers.withMessage(
                    i18n.global.t('messages.valueCannotBePriorToStartDate'),
                    (value: any) => {
                        return (
                            value === null || value >= state.value?.startDate
                        );
                    }
                )
            },
            reductionAdditions: {
                $each: helpers.forEach({
                    description: {},
                    type: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    },
                    value: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    }
                })
            }
        };

        const v$ = useVuelidate(rules, state);

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            closeResponsive(state.value);
        };

        const addReductionAddition = () => {
            state.value.reductionAdditions.push({
                type: null,
                value: null
            });
        };

        const removeReductionAddition = (index: number) => {
            state.value.reductionAdditions.splice(index, 1);
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            if (!event.value) {
                return;
            }

            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;
            Object.assign(state.value, temp);
        };

        const isCostPrice = computed(() => {
            return PriceType.CostPrice === priceType.value;
        });

        const isSellPrice = computed(() => {
            return PriceType.SellPrice === priceType.value;
        });

        const reductionAdditionValueOptions = computed(() => {
            return isCostPrice.value
                ? [
                      {
                          label: i18n.global.t(
                              'labels.productPrices.reductionAdditionTypeSet.' +
                                  ReductionAdditionType.Regular
                          ),
                          value: ReductionAdditionType.Regular
                      },
                      {
                          label: i18n.global.t(
                              'labels.productPrices.reductionAdditionTypeSet.' +
                                  ReductionAdditionType.Transport
                          ),
                          value: ReductionAdditionType.Transport
                      },
                      {
                          label: i18n.global.t(
                              'labels.productPrices.reductionAdditionTypeSet.' +
                                  ReductionAdditionType.Customs
                          ),
                          value: ReductionAdditionType.Customs
                      },
                      {
                          label: i18n.global.t(
                              'labels.productPrices.reductionAdditionTypeSet.' +
                                  ReductionAdditionType.NSR
                          ),
                          value: ReductionAdditionType.NSR
                      }
                  ]
                : [
                      {
                          label: i18n.global.t(
                              'labels.productPrices.reductionAdditionTypeSet.' +
                                  ReductionAdditionType.Regular
                          ),
                          value: ReductionAdditionType.Regular
                      }
                  ];
        });

        const isEdit = computed(() => !!originalPrice.value);

        return {
            showDialog,
            closeResponsive,
            state,
            v$,
            handleSubmit,
            submitted,
            setDropdownValue,
            reductionAdditionValueOptions,
            getReductionAdditionValueTypeOptions: (item: any) => {
                switch (item.description) {
                    case ReductionAdditionType.NSR:
                        return [
                            {
                                label: i18n.global.t(
                                    'labels.productPrices.reductionAdditionValueTypeSet.' +
                                        ReductionAdditionValueType.ReductionAbsolute
                                ),
                                value: ReductionAdditionValueType.ReductionAbsolute
                            },
                            {
                                label: i18n.global.t(
                                    'labels.productPrices.reductionAdditionValueTypeSet.' +
                                        ReductionAdditionValueType.AdditionAbsolute
                                ),
                                value: ReductionAdditionValueType.AdditionAbsolute
                            }
                        ];
                    case ReductionAdditionType.Customs:
                    case ReductionAdditionType.Transport:
                        return [
                            {
                                label: i18n.global.t(
                                    'labels.productPrices.reductionAdditionValueTypeSet.' +
                                        ReductionAdditionValueType.AdditionPercent
                                ),
                                value: ReductionAdditionValueType.AdditionPercent
                            },
                            {
                                label: i18n.global.t(
                                    'labels.productPrices.reductionAdditionValueTypeSet.' +
                                        ReductionAdditionValueType.AdditionAbsolute
                                ),
                                value: ReductionAdditionValueType.AdditionAbsolute
                            }
                        ];
                    default:
                        return [
                            {
                                label: i18n.global.t(
                                    'labels.productPrices.reductionAdditionValueTypeSet.' +
                                        ReductionAdditionValueType.ReductionPercent
                                ),
                                value: ReductionAdditionValueType.ReductionPercent
                            },
                            {
                                label: i18n.global.t(
                                    'labels.productPrices.reductionAdditionValueTypeSet.' +
                                        ReductionAdditionValueType.ReductionAbsolute
                                ),
                                value: ReductionAdditionValueType.ReductionAbsolute
                            }
                        ];
                }
            },
            locale: i18n.global.locale,
            addReductionAddition,
            removeReductionAddition,
            currencyName,
            isCostPrice,
            isSellPrice,
            priceType,
            isEdit
        };
    }
};
