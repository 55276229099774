import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-content-start"
}
const _hoisted_2 = { class: "grid font-bold" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "col-2" }
const _hoisted_7 = { class: "col-2" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "col-2" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "col-2" }
const _hoisted_15 = { class: "col-1 flex align-items-center" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "w-full flex justify-content-between mt-4" }

export function render(_ctx, _cache) {
  const _component_p_button = _resolveComponent("p-button")
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_calendar = _resolveComponent("p-calendar")

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
  }, [
    (_ctx.state.supplySources.length < 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_p_button, {
            class: "mb-4 text-sm",
            onClick: _ctx.addSupplySource,
            label: _ctx.$t('buttons.addNewGeneric'),
            icon: "pi pi-plus text-sm",
            severity: "success"
          }, null, 8, ["onClick", "label"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('labels.productAdd.sku')), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('labels.articleName')), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('labels.productAdd.supplier')), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('labels.ekPrice')), 1),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('labels.productAdd.ekStartDate')), 1),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-1" }, null, -1))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.supplySources, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "grid p-fluid"
      }, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_InputText, {
            autocomplete: "off",
            modelValue: item.articleNumber,
            "onUpdate:modelValue": $event => ((item.articleNumber) = $event),
            class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.supplySources.$each.$response.$data[index].articleNumber.$invalid})
          }, null, 8, ["modelValue", "onUpdate:modelValue", "class"]),
          (_ctx.submitted)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.supplySources.$each.$response.$errors[index].articleNumber, (error) => {
                return (_openBlock(), _createElementBlock("small", {
                  style: {"display":"block"},
                  class: "p-error",
                  key: error
                }, _toDisplayString(error.$message), 1))
              }), 128))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_InputText, {
            autocomplete: "off",
            modelValue: item.name,
            "onUpdate:modelValue": $event => ((item.name) = $event),
            class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.supplySources.$each.$response.$data[index].name.$invalid})
          }, null, 8, ["modelValue", "onUpdate:modelValue", "class"]),
          (_ctx.submitted)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.supplySources.$each.$response.$errors[index].name, (error) => {
                return (_openBlock(), _createElementBlock("small", {
                  style: {"display":"block"},
                  class: "p-error",
                  key: error
                }, _toDisplayString(error.$message), 1))
              }), 128))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, [
          (item.id)
            ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(item.supplier?.name), 1))
            : (_openBlock(), _createBlock(_component_p_dropdown, {
                key: 1,
                modelValue: item.supplier,
                "onUpdate:modelValue": $event => ((item.supplier) = $event),
                options: (_ctx.allSuppliers || []),
                optionLabel: "label",
                optionValue: "value",
                filter: "",
                showClear: "",
                class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.supplySources.$each.$response.$data[index].supplier.$invalid})
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "class"])),
          (_ctx.submitted)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.v$.supplySources.$each.$response.$errors[index].supplier, (error) => {
                return (_openBlock(), _createElementBlock("small", {
                  style: {"display":"block"},
                  class: "p-error",
                  key: error
                }, _toDisplayString(error.$message), 1))
              }), 128))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_12, [
          (item.id)
            ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(item.ekPrice), 1))
            : (_openBlock(), _createBlock(_component_InputNumber, {
                key: 1,
                mode: "decimal",
                locale: _ctx.locale,
                min: 0,
                maxFractionDigits: 2,
                autocomplete: "off",
                modelValue: item.ekPrice,
                "onUpdate:modelValue": $event => ((item.ekPrice) = $event),
                class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.supplySources.$each.$response.$data[index].ekPrice.$invalid})
              }, null, 8, ["locale", "modelValue", "onUpdate:modelValue", "class"])),
          (_ctx.submitted)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.v$.supplySources.$each.$response.$errors[index].ekPrice, (error) => {
                return (_openBlock(), _createElementBlock("small", {
                  style: {"display":"block"},
                  class: "p-error",
                  key: error
                }, _toDisplayString(error.$message), 1))
              }), 128))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_14, [
          (!item.id)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_p_calendar, {
                  autocomplete: "off",
                  dateFormat: "dd.mm.yy",
                  modelValue: item.ekStartDate,
                  "onUpdate:modelValue": $event => ((item.ekStartDate) = $event),
                  class: _normalizeClass({'p-invalid':_ctx.submitted && _ctx.v$.supplySources.$each.$response.$data[index].ekStartDate.$invalid})
                }, null, 8, ["modelValue", "onUpdate:modelValue", "class"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.supplySources.$each.$response.$errors[index].ekStartDate, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_15, [
          (_ctx.state.supplySources.length - 1 <= index)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "pi pi-plus mr-2 float-right",
                style: {"color":"green","cursor":"pointer"},
                onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.addSupplySource && _ctx.addSupplySource(...args)))
              }))
            : _createCommentVNode("", true),
          (index >= 0)
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "pi pi-trash float-right",
                style: {"color":"red"},
                onClick: $event => {_ctx.removeSupplySource(index);}
              }, null, 8, _hoisted_16))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_17, [
      _createVNode(_component_p_button, {
        severity: "danger",
        textContent: _toDisplayString(_ctx.$t('buttons.back')),
        onClick: _ctx.onBackButtonClicked
      }, null, 8, ["textContent", "onClick"]),
      _createVNode(_component_p_button, {
        disabled: _ctx.savingInProgress,
        severity: "success",
        type: "submit",
        textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('labels.save'))
      }, null, 8, ["disabled", "textContent"])
    ])
  ], 32))
}