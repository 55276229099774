import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "layout-sidebar" }
const _hoisted_3 = { class: "layout-main-container" }
const _hoisted_4 = { class: "layout-main" }

export function render(_ctx, _cache) {
  const _component_app_topbar = _resolveComponent("app-topbar")
  const _component_app_menu = _resolveComponent("app-menu")
  const _component_router_view = _resolveComponent("router-view")
  const _component_app_footer = _resolveComponent("app-footer")
  const _component_NewSoftwareVersionDialog = _resolveComponent("NewSoftwareVersionDialog")
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["layout-wrapper", _ctx.containerClass])
    }, [
      _createVNode(_component_app_topbar),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_app_menu)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_view)
        ]),
        _createVNode(_component_app_footer)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "layout-mask" }, null, -1))
    ], 2),
    _createVNode(_component_NewSoftwareVersionDialog, {
      displayDialog: _ctx.displayReloadConfirmationDialog,
      onConfirmed: _ctx.onReloadConfirmed
    }, null, 8, ["displayDialog", "onConfirmed"]),
    _createVNode(_component_ConfirmDialog)
  ]))
}