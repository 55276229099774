import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "text-xs" }
const _hoisted_3 = { class: "text-xs" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "text-xs" }
const _hoisted_7 = { class: "text-xs" }
const _hoisted_8 = { class: "text-xs" }
const _hoisted_9 = {
  key: 0,
  class: "text-xs"
}
const _hoisted_10 = {
  key: 1,
  class: "text-sm"
}
const _hoisted_11 = {
  key: 1,
  class: "text-sm"
}
const _hoisted_12 = { class: "button-container" }
const _hoisted_13 = { key: 1 }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Column = _resolveComponent("Column")
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_router_link = _resolveComponent("router-link")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.purchaseOrders')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          paginator: true,
          scrollable: "",
          scrollHeight: "calc(100vh - 23rem)",
          rows: 10,
          lazy: true,
          ref: "dt",
          dataKey: "id",
          totalRecords: _ctx.totalRecords,
          value: _ctx.purchaseOrders,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50],
          "current-page-report-template": "{first} to {last} of {totalRecords}",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[1] || (_cache[1] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          onPage: _cache[2] || (_cache[2] = $event => (_ctx.onPage($event))),
          onFilter: _cache[3] || (_cache[3] = $event => (_ctx.onFilter($event))),
          onSort: _cache[4] || (_cache[4] = $event => (_ctx.onSort($event))),
          onStateRestore: _ctx.onStateRestore,
          stateStorage: "local",
          stateKey: "purchase-order-list-state-session"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "purchaseOrderNumber",
              sortable: true,
              style: {"min-width":"7rem"},
              header: _ctx.$t('labels.purchaseOrder.purchaseOrderNumber')
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("a", {
                  href: data.url + 'webapp/view/purchase/purchaseOrderDetail.page?entityId=' + data.id,
                  target: "_blank"
                }, _toDisplayString(data.purchaseOrderNumber), 9, _hoisted_1),
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.formatter.format(data.netAmount)), 1)
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _cache[5] || (_cache[5] = _createTextVNode(" Anzahl der Positionen: ")),
                  _createElementVNode("b", null, _toDisplayString((data.purchaseOrderItems || []).length), 1)
                ])
              ]),
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: "#"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('labels.productAdd.supplier'),
              style: {"min-width":"7rem"},
              field: "supplierCombo",
              filterMatchModeOptions: [{label: 'Contains', value: 'contains'}]
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: "#"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              body: _withCtx(({data}) => [
                (data.supplier)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("a", {
                        href: data.url + 'webapp/view/party/PartyDetail.page?entityId=' + data.supplier.id,
                        target: "_blank"
                      }, _toDisplayString(data.supplier.name) + " [" + _toDisplayString(data.supplier.supplierNumber) + "]", 9, _hoisted_5)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              style: {"min-width":"7rem"},
              field: "recipient",
              header: _ctx.$t('labels.shipment.recipient')
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", null, _toDisplayString(data.deliveryAddress?.company), 1),
                  _createElementVNode("div", _hoisted_6, _toDisplayString(data.deliveryAddress?.street1), 1),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(data.deliveryAddress?.zipcode) + " " + _toDisplayString(data.deliveryAddress?.city), 1),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.countries.getName(data.deliveryAddress?.countryCode,
                        _ctx.locale)), 1),
                  (data.deliveryAddress?.phoneNumber)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(data.deliveryAddress?.phoneNumber), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "createdDate",
              dataType: "date",
              sortable: true,
              header: _ctx.$t('labels.createdAt'),
              style: {"min-width":"4rem","max-width":"fit-content"}
            }, {
              filter: _withCtx(({filterModel}) => [
                _createVNode(_component_Calendar, {
                  class: "p-column-filter text-xs",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  dateFormat: "dd.mm.yy"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.createdAtFormatted), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('labels.status'),
              style: {"max-width":"12rem"},
              field: "status",
              sortable: true,
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("span", {
                  class: _normalizeClass({'text-green-200': 'ORDER_ENTRY_IN_PROGRESS' === data.status, 'text-orange-600': 'ORDER_DOCUMENTS_PRINTED' === data.status, 'text-blue-600' : 'CONFIRMED' === data.status, 'text-green-600' : 'CLOSED' === data.status, 'text-red-600' : 'CANCELLED' === data.status })
                }, _toDisplayString(_ctx.$t('labels.purchaseOrder.statusOptions.' + data.status)), 3)
              ]),
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_Dropdown, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  options: ['ORDER_ENTRY_IN_PROGRESS', 'ORDER_DOCUMENTS_PRINTED', 'CONFIRMED', 'CLOSED', 'CANCELLED'],
                  placeholder: "Any",
                  class: "p-column-filter text-xs",
                  onChange: $event => (filterCallback()),
                  showClear: true
                }, {
                  value: _withCtx((slotProps) => [
                    (slotProps.value)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: _normalizeClass({'text-green-200': 'ORDER_ENTRY_IN_PROGRESS' === slotProps.value, 'text-orange-600': 'ORDER_DOCUMENTS_PRINTED' === slotProps.value, 'text-blue-600' : 'CONFIRMED' === slotProps.value, 'text-green-600' : 'CLOSED' === slotProps.value, 'text-red-600' : 'CANCELLED' === slotProps.value })
                        }, _toDisplayString(_ctx.$t('labels.purchaseOrder.statusOptions.' +
                            slotProps.value)), 3))
                      : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(slotProps.placeholder), 1))
                  ]),
                  option: _withCtx((slotProps) => [
                    _createElementVNode("span", {
                      class: _normalizeClass({'text-green-200': 'ORDER_ENTRY_IN_PROGRESS' === slotProps.option, 'text-orange-600': 'ORDER_DOCUMENTS_PRINTED' === slotProps.option, 'text-blue-600' : 'CONFIRMED' === slotProps.option, 'text-green-600' : 'CLOSED' === slotProps.option, 'text-red-600' : 'CANCELLED' === slotProps.option })
                    }, _toDisplayString(_ctx.$t('labels.purchaseOrder.statusOptions.' +
                            slotProps.option)), 3)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('labels.purchaseOrder.purchaseOrderType'),
              style: {"max-width":"12rem"},
              field: "purchaseOrderType",
              sortable: true,
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("span", {
                  class: _normalizeClass({'text-orange-600': 'SALES_ORDER' === data.purchaseOrderType, 'text-blue-600' : 'NORMAL' === data.purchaseOrderType, 'text-green-200' : 'TRIANGULAR' === data.purchaseOrderType, 'text-red-600' : 'BLANKET_PURCHASE_ORDER' === data.purchaseOrderType })
                }, _toDisplayString(_ctx.$t('labels.purchaseOrder.purchaseOrderTypeOptions.' +
                    data.purchaseOrderType)), 3)
              ]),
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_Dropdown, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  options: ['NORMAL', 'SALES_ORDER', 'TRIANGULAR', 'BLANKET_PURCHASE_ORDER'],
                  placeholder: "Any",
                  class: "p-column-filter text-xs",
                  onChange: $event => (filterCallback()),
                  showClear: true
                }, {
                  value: _withCtx((slotProps) => [
                    (slotProps.value)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: _normalizeClass({'text-orange-600': 'SALES_ORDER' === slotProps.value, 'text-blue-600' : 'NORMAL' === slotProps.value, 'text-green-200' : 'TRIANGULAR' === slotProps.value, 'text-red-600' : 'BLANKET_PURCHASE_ORDER' === slotProps.value })
                        }, _toDisplayString(_ctx.$t('labels.purchaseOrder.purchaseOrderTypeOptions.'
                            + slotProps.value)), 3))
                      : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(slotProps.placeholder), 1))
                  ]),
                  option: _withCtx((slotProps) => [
                    _createElementVNode("span", {
                      class: _normalizeClass({'text-orange-600': 'SALES_ORDER' === slotProps.option, 'text-blue-600' : 'NORMAL' === slotProps.option, 'text-green-200' : 'TRIANGULAR' === slotProps.option, 'text-red-600' : 'BLANKET_PURCHASE_ORDER' === slotProps.option })
                    }, _toDisplayString(_ctx.$t('labels.purchaseOrder.purchaseOrderTypeOptions.'
                            + slotProps.option)), 3)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "warehouse",
              sortable: true,
              style: {"min-width":"9rem"},
              header: _ctx.$t('labels.shipment.warehouse')
            }, {
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.warehouse?.name), 1)
              ]),
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: _ctx.$t('labels.shipment.warehouse')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, { style: {"min-width":"60px","max-width":"'fit-content'"} }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_12, [
                  (_ctx.editPermissionAvailable)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: {
                                    name: 'PurchaseOrderDetails',
                                    params: {purchaseOrderNumber: data.purchaseOrderNumber}
                                }
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createElementVNode("i", { class: "pi pi-pencil text-sm mr-2 text-color" }, null, -1)
                        ])),
                        _: 2
                      }, 1032, ["to"]))
                    : _createCommentVNode("", true),
                  ((data.warehouse?.name || '').toUpperCase() === 'WIESBADEN' && (data.supplier?.name || '').toUpperCase().includes('RINGFOTO') )
                    ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_13, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['fas', 'file-csv'],
                          class: "ml-2 text-2xl text-green-400",
                          style: {"cursor":"pointer"},
                          onClick: $event => (_ctx.downloadPurchaseOrderDraft(data))
                        }, null, 8, ["onClick"])
                      ])), [
                        [
                          _directive_tooltip,
                          'Bestellvorlage',
                          void 0,
                          { left: true }
                        ]
                      ])
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["totalRecords", "value", "filters", "onStateRestore"])
      ]),
      _: 1
    }, 8, ["header"])
  ], 64))
}