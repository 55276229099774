<loading v-model:active="loading" />
<div class="row">
    <div class="col-9">
        <DataTable
            class="p-datatable-sm text-sm mb-5"
            stripedRows
            :paginator="articleSupplySources && articleSupplySources.length > 20"
            :rows="20"
            v-model:selection="selectedSupplySource"
            v-model:editingRows="editingRows"
            dataKey="id"
            :value="articleSupplySources"
            responsiveLayout="scroll"
            paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rows-per-page-options="[10,20,50,100,200]"
            current-page-report-template="{first} to {last} of {totalRecords}"
            editMode="row"
            @row-edit-save="onRowEditComplete"
            :rowClass="(data) => {return (data.id || null).toString() === primarySupplySourceId
                ? 'bg-green-100'
                : '';}"
        >
            <Column
                selectionMode="single"
                headerStyle="width: 3rem"
                v-if="articleSupplySources.length > 1"
            ></Column>
            <Column field="positionNumber" :header="$t('labels.position')">
                <template #body="{data}"> {{data.positionNumber}} </template>
            </Column>
            <Column
                field="articleNumber"
                :header="$t('labels.salesOrder.supplierArticleNumber')"
            >
            </Column>
            <Column field="supplier" :header="$t('labels.productAdd.supplier')"
                ><template #body="{data}"> {{data.supplier?.name}} </template>
            </Column>
            <Column
                style="min-width: 10rem"
                field="discountedPriceFormatted"
                :header="$t('labels.salesOrder.price')"
            >
                <template #body="{data}">
                    <div>
                        {{formatPrice(data?.supplier?.currencyName || 'EUR',
                        data.finalPrice)}}
                        <span v-if="data.finalPriceInDefaultCurrency">
                            [<span class="font-semibold"
                                >{{formatPrice('EUR',
                                data.finalPriceInDefaultCurrency)}}</span
                            >]
                        </span>
                    </div>
                </template>
            </Column>
            <Column :header="'Kalkulatorischer EK'" style="min-width: 10rem">
                ><template #body="{data}">
                    <div v-if="data.finalPriceIncludingNsr">
                        {{formatPrice(data?.supplier?.currencyName || 'EUR',
                        data.finalPriceIncludingNsr)}}
                        <span
                            v-if="data.finalPriceIncludingNsrInDefaultCurrency"
                        >
                            [<span class="font-semibold"
                                >{{formatPrice('EUR',
                                data.finalPriceIncludingNsrInDefaultCurrency)}}</span
                            >]
                        </span>
                    </div>
                </template>
            </Column>

            <Column field="nsrStandard" header="NSR Standard">
                <template #editor="{ data, field }">
                    <InputNumber
                        v-model="data[field]"
                        mode="decimal"
                        :locale="locale"
                        :maxFractionDigits="2"
                        inputClass="w-6"
                        class="text-sm"
                    />
                </template>
                <template #body="{data}">
                    <div
                        v-if="data?.customAttributes?.supply_source_nsr_regular"
                    >
                        {{formatPrice(data?.supplier?.currencyName || 'EUR',
                        data.customAttributes?.supply_source_nsr_regular)}}
                    </div>
                </template>
            </Column>
            <Column field="nsrPromo" header="NSR Promo"
                ><template #editor="{ data, field }">
                    <InputNumber
                        v-model="data[field]"
                        mode="decimal"
                        :locale="locale"
                        :maxFractionDigits="2"
                        class="text-sm"
                        inputClass="w-6"
                    /> </template
                ><template #body="{data}">
                    <div v-if="data?.customAttributes?.supply_source_nsr_promo">
                        {{formatPrice(data?.supplier?.currencyName || 'EUR',
                        data.customAttributes?.supply_source_nsr_promo)}}
                    </div>
                </template>
            </Column>
            <Column header="NSR Promo Startdatum" field="nsrPromoStart">
                <template #editor="{ data, field }">
                    <p-calendar
                        dateFormat="dd.mm.yy"
                        v-model="data[field]"
                        :minDate="new Date()"
                    />
                </template>
                <template #body="{data}">
                    {{data.promoStartDateFormatted}}
                </template>
            </Column>
            <Column header="NSR Promo Enddatum" field="nsrPromoEnd">
                <template #editor="{ data, field }">
                    <p-calendar
                        dateFormat="dd.mm.yy"
                        v-model="data[field]"
                        :minDate="new Date()"
                    />
                </template>
                <template #body="{data}">
                    {{data.promoEndDateFormatted}}
                </template>
            </Column>
            <Column
                field="nsrPromoAppliesForStock"
                header="Promo gilt für Lagerware"
                ><template #body="{data}">
                    <i
                        v-if="data.customAttributes?.supply_source_nsr_promo_applies_for_stock"
                        class="pi pi-check text-green-600"
                    ></i>
                    <i v-else class="pi pi-times-circle text-red-600"></i>
                </template>
                <template #editor="{ data, field }">
                    <p-checkbox v-model="data[field]" binary> </p-checkbox>
                </template>
            </Column>
            <Column
                rowEditor
                v-if="editPermissionAvailable"
                style="width: 10%; min-width: 5rem"
                bodyStyle="text-align:center"
            ></Column>
            <template #empty>{{ $t('messages.noRecordsFound') }}. </template>
        </DataTable>
    </div>
    <div class="col-3"></div>
</div>
<div class="row">
    <div class="col-9">
        <DataTable
            class="p-datatable-sm text-sm mb-5"
            :rowClass="rowClass"
            stripedRows
            :paginator="productCostPrices && productCostPrices.length > 20"
            :rows="20"
            :value="productCostPrices"
            responsiveLayout="scroll"
            paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rows-per-page-options="[10,20,50,100,200]"
            current-page-report-template="{first} to {last} of {totalRecords}"
        >
            <template #header>
                {{ $t('labels.costPriceLong') }}
                <span
                    class="pi pi-plus-circle text-green-600 ml-2"
                    v-if="editPermissionAvailable"
                    style="cursor: pointer"
                    @click="originalPrice = null;dialogPriceType = PriceType.CostPrice;showProductPriceDialog = true;"
                ></span
            ></template>
            <template #footer>
                <div class="flex justify-content-end">
                    <span class="mr-3 mt-1"
                        >{{ $t("labels.inactivePrices") }}</span
                    >
                    <div>
                        <InputSwitch v-model="showCostPricesInThePast" />
                    </div>
                </div>
            </template>
            <Column field="startDateFormatted" :header="$t('labels.startDate')">
            </Column>
            <Column field="endDateFormatted" :header="$t('labels.endDate')">
            </Column>
            <Column field="priceFormatted" :header="$t('labels.ekBasePrice')">
                <template #body="{data}">
                    <span>{{data.priceFormatted}}</span>
                    <Tag
                        v-if="(data?.description || '').toUpperCase() === 'PROMO'"
                        severity="success"
                        class="ml-2"
                    >
                        <div>
                            <font-awesome-icon icon="fa-solid fa-star" />
                            PROMO
                        </div>
                    </Tag>
                </template>
            </Column>
            <Column :header="$t('labels.weclappPriceDiscounts')">
                <template #body="{data}">
                    <ReductionAddition
                        :price="data"
                        :nsrValue="nsrValue"
                    ></ReductionAddition>
                </template>
            </Column>
            <Column
                field="discountedPriceFormatted"
                :header="$t('labels.ekDiscountPrice')"
            >
            </Column>
            <Column
                field="priceScaleValue"
                :header="$t('labels.priceRangeFrom')"
            >
                <template #body="{data}">
                    {{data.priceScaleValue}}
                    <span
                        v-if="!data.priceScaleValue && editPermissionAvailable"
                        class="pi pi-plus-circle text-green-600 ml-1"
                        style="cursor: pointer"
                        @click="originalPrice = data;showScaledPriceDialog = true;"
                    ></span>
                </template>
            </Column>
            <Column>
                <template #body="{data}">
                    <span
                        v-if="editPermissionAvailable && !data.priceScaleValue && (!data.endDate || data.endDate >= Date.now())"
                        class="pi pi-pencil"
                        style="cursor: pointer"
                        @click="originalPrice = data;dialogPriceType = PriceType.CostPrice;showProductPriceDialog = true;"
                    ></span>
                    <span
                        v-if="editPermissionAvailable"
                        class="pi pi-minus-circle text-red-600"
                        style="cursor: pointer"
                        :class="{'ml-2': !data.priceScaleValue && (!data.endDate || data.endDate >= Date.now())}"
                        @click="removePrice(data, !!data.priceScaleValue)"
                    ></span>
                </template>
            </Column>
            <template #empty>{{ $t('messages.noRecordsFound') }}. </template>
        </DataTable>
    </div>
    <div class="col-3"></div>
</div>
<div class="row">
    <div class="col-8">
        <DataTable
            class="p-datatable-sm text-sm mb-5"
            stripedRows
            :rowClass="rowClass"
            :paginator="productSellPrices && productSellPrices.length > 20"
            :rows="20"
            :value="productSellPrices"
            responsiveLayout="scroll"
            paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rows-per-page-options="[10,20,50,100,200]"
            current-page-report-template="{first} to {last} of {totalRecords}"
        >
            <template #header>
                {{ $t('labels.sellPriceLong') }}<span
                    v-if="editPermissionAvailable"
                    class="pi pi-plus-circle text-green-600 ml-2"
                    style="cursor: pointer"
                    @click="originalPrice = null;dialogPriceType = PriceType.SellPrice;showProductPriceDialog = true;"
                ></span>
            </template>
            <template #footer>
                <div class="flex justify-content-end">
                    <span class="mr-3 mt-1"
                        >{{ $t("labels.inactivePrices") }}</span
                    >
                    <div>
                        <InputSwitch v-model="showSellPricesInThePast" />
                    </div>
                </div>
            </template>
            <Column field="startDateFormatted" :header="$t('labels.startDate')">
            </Column>
            <Column field="endDateFormatted" :header="$t('labels.endDate')">
            </Column>
            <Column
                field="salesChannel"
                :header="$t('labels.productHome.salesChannel')"
            >
                <template #body="{data}">
                    <div
                        class="sales-channel-dot w-1rem h-1rem ml-2"
                        :class="{'sales-channel-teltec': data?.salesChannel === 'NET1', 'sales-channel-videodata': data?.salesChannel === 'NET2'}"
                    ></div>
                </template>
            </Column>
            <Column field="priceFormatted" :header="$t('labels.vkPrice')">
                <template #body="{data}">
                    <span>{{data.priceFormatted}}</span>
                    <Tag
                        v-if="(data?.description || '').toUpperCase() === 'PROMO'"
                        severity="success"
                        class="ml-2"
                    >
                        <div>
                            <font-awesome-icon icon="fa-solid fa-star" />
                            PROMO
                        </div>
                    </Tag>
                </template>
            </Column>
            <Column :header="$t('labels.weclappPriceDiscounts')">
                <template #body="{data}">
                    <ReductionAddition :price="data"></ReductionAddition>
                </template>
            </Column>
            <Column
                field="discountedPriceFormatted"
                :header="$t('labels.vkDiscountPrice')"
            >
            </Column>
            <Column
                field="priceScaleValue"
                :header="$t('labels.priceRangeFrom')"
            >
                <template #body="{data}">
                    {{data.priceScaleValue}}
                    <span
                        v-if="editPermissionAvailable && !data.priceScaleValue"
                        class="pi pi-plus-circle text-green-600 ml-1"
                        style="cursor: pointer"
                        @click="originalPrice = data;showScaledPriceDialog = true;"
                    ></span>
                </template>
            </Column>
            <Column>
                <template #body="{data}">
                    <span
                        v-if="editPermissionAvailable && !data.endDate || data.endDate >= Date.now()"
                        class="pi pi-pencil"
                        style="cursor: pointer"
                        @click="originalPrice = data;dialogPriceType = PriceType.SellPrice;showProductPriceDialog = true;"
                    ></span>
                    <span
                        class="pi pi-minus-circle text-red-600"
                        v-if="editPermissionAvailable"
                        style="cursor: pointer"
                        :class="{'ml-2': !data.endDate || data.endDate >= Date.now()}"
                        @click="removePrice(data)"
                    ></span>
                </template>
            </Column>
            <template #empty>{{ $t('messages.noRecordsFound') }}. </template>
        </DataTable>
    </div>
    <div class="col-4"></div>
</div>
<div class="row">
    <div class="col-5">
        <DataTable
            class="p-datatable-sm text-sm"
            stripedRows
            :rowClass="rowClass"
            :paginator="productListPrices && productListPrices.length > 20"
            :rows="20"
            :value="productListPrices"
            responsiveLayout="scroll"
            paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rows-per-page-options="[10,20,50,100,200]"
            current-page-report-template="{first} to {last} of {totalRecords}"
        >
            <template #header>
                {{ $t('labels.listPriceLong') }}<span
                    v-if="editPermissionAvailable"
                    class="pi pi-plus-circle text-green-600 ml-2"
                    style="cursor: pointer"
                    @click="originalPrice = null;dialogPriceType = PriceType.ListPrice;showProductPriceDialog = true;"
                ></span>
            </template>
            <template #footer>
                <div class="flex justify-content-end">
                    <span class="mr-3 mt-1"
                        >{{ $t("labels.inactivePrices") }}</span
                    >
                    <div>
                        <InputSwitch v-model="showListPricesInThePast" />
                    </div>
                </div>
            </template>
            <Column field="startDateFormatted" :header="$t('labels.startDate')">
            </Column>
            <Column field="endDateFormatted" :header="$t('labels.endDate')">
            </Column>
            <Column field="priceFormatted" :header="$t('labels.price')">
            </Column>
            <Column>
                <template #body="{data}">
                    <span
                        v-if="editPermissionAvailable && !data.endDate || data.endDate >= Date.now()"
                        class="pi pi-pencil"
                        style="cursor: pointer"
                        @click="originalPrice = data;dialogPriceType = PriceType.ListPrice;showProductPriceDialog = true;"
                    ></span>
                    <span
                        class="pi pi-minus-circle text-red-600"
                        style="cursor: pointer"
                        v-if="editPermissionAvailable"
                        :class="{'ml-2': !data.endDate || data.endDate >= Date.now()}"
                        @click="removePrice(data)"
                    ></span>
                </template>
            </Column>
            <template #empty>{{ $t('messages.noRecordsFound') }}. </template>
        </DataTable>
    </div>
    <div class="col-7"></div>
</div>
<ProductPriceDialog
    :display-cost-price-dialog="showProductPriceDialog"
    :priceType="dialogPriceType"
    :originalPrice="originalPrice"
    :currencyName="supplierCurrencyName"
    @close-dialog="onCloseProductPriceDialog"
></ProductPriceDialog>
<ScaledProductPriceDialog
    :display-scaled-price-dialog="showScaledPriceDialog"
    :originalPrice="originalPrice"
    @close-dialog="onCloseScaledPriceDialog"
>
</ScaledProductPriceDialog>
