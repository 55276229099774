import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "formgrid grid p-fluid" }
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = { class: "col field" }
const _hoisted_6 = {
  key: 0,
  class: "formgrid grid p-fluid"
}
const _hoisted_7 = { class: "col field" }
const _hoisted_8 = { class: "col field" }
const _hoisted_9 = { class: "col field" }
const _hoisted_10 = { class: "col field" }
const _hoisted_11 = { class: "formgrid grid p-fluid" }
const _hoisted_12 = { class: "col field" }
const _hoisted_13 = { class: "p-input-icon-right" }
const _hoisted_14 = { class: "formgrid grid p-fluid" }
const _hoisted_15 = { class: "col field" }
const _hoisted_16 = { class: "col field" }
const _hoisted_17 = {
  key: 0,
  class: "formgrid grid p-fluid"
}
const _hoisted_18 = { class: "col field" }
const _hoisted_19 = { class: "p-input-icon-right" }
const _hoisted_20 = { class: "formgrid grid p-fluid" }
const _hoisted_21 = { class: "col field" }
const _hoisted_22 = { class: "p-input-icon-right" }
const _hoisted_23 = { class: "formgrid grid p-fluid" }
const _hoisted_24 = { class: "col field" }
const _hoisted_25 = { class: "p-input-icon-right" }
const _hoisted_26 = { class: "w-full flex justify-content-between mt-4" }

export function render(_ctx, _cache) {
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_tiny = _resolveComponent("tiny")
  const _component_Panel = _resolveComponent("Panel")
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_fieldset = _resolveComponent("p-fieldset")
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[14] || (_cache[14] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createVNode(_component_Panel, null, {
            header: _withCtx(() => _cache[15] || (_cache[15] = [
              _createElementVNode("div", { class: "font-bold flex justify-content-between w-full" }, [
                _createElementVNode("div", null, "Webshop Teltec"),
                _createElementVNode("div", { class: "sales-channel-dot sales-channel-teltec" })
              ], -1)
            ])),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.availableInShop')), 1),
                  _createVNode(_component_p_checkbox, {
                    modelValue: _ctx.v$['availableInShop'].$model,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$['availableInShop'].$model) = $event)),
                    disabled: _ctx.v$['activeInShop'].$model,
                    binary: true,
                    class: "ml-2 mb-1"
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.activeInShop')), 1),
                  _createVNode(_component_p_checkbox, {
                    modelValue: _ctx.v$['activeInShop'].$model,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$['activeInShop'].$model) = $event)),
                    binary: true,
                    class: "ml-2 mb-1",
                    onInput: _cache[2] || (_cache[2] = $event => (_ctx.onActiveInShopChange('availableInShop', $event)))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              (_ctx.v$['activeInShop'].$model || _ctx.v$['availableInShop'].$model)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_ctx.existingShopwareData)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.copyAccessories',
                                    {count:_ctx.existingShopwareData.accessories.length
                                    })), 1),
                            _createVNode(_component_p_checkbox, {
                              modelValue: _ctx.v$['copyShopAccessories'].$model,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$['copyShopAccessories'].$model) = $event)),
                              binary: "",
                              disabled: _ctx.existingShopwareData.accessories.length < 1,
                              class: "ml-2 mb-1"
                            }, null, 8, ["modelValue", "disabled"])
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.copyImages',
                                    {count:_ctx.existingShopwareData.images.length })), 1),
                            _createVNode(_component_p_checkbox, {
                              modelValue: _ctx.v$['copyShopImages'].$model,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$['copyShopImages'].$model) = $event)),
                              binary: "",
                              disabled: _ctx.existingShopwareData.images.length < 1,
                              class: "ml-2 mb-1"
                            }, null, 8, ["modelValue", "disabled"])
                          ]),
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.copyVideos',
                                    {count:_ctx.existingShopwareData.videos.length })), 1),
                            _createVNode(_component_p_checkbox, {
                              modelValue: _ctx.v$['copyShopVideos'].$model,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$['copyShopVideos'].$model) = $event)),
                              binary: "",
                              disabled: _ctx.existingShopwareData.videos.length < 1,
                              class: "ml-2 mb-1"
                            }, null, 8, ["modelValue", "disabled"])
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.copyLinks',
                                    {count:_ctx.existingShopwareData.links.length })), 1),
                            _createVNode(_component_p_checkbox, {
                              modelValue: _ctx.v$['copyShopLinks'].$model,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.v$['copyShopLinks'].$model) = $event)),
                              binary: "",
                              disabled: _ctx.existingShopwareData.links.length < 1,
                              class: "ml-2 mb-1"
                            }, null, 8, ["modelValue", "disabled"])
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productDescription.webshopText')), 1),
                        _createElementVNode("span", _hoisted_13, [
                          _createVNode(_component_tiny, {
                            tinymceScriptSrc: _ctx.tinyUrl,
                            modelValue: _ctx.v$['webshopText'].$model,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.v$['webshopText'].$model) = $event)),
                            init: {
                            
                            menubar: false,
                            plugins: 'lists link image emoticons code table anchor charmap fullscreen paste autoresize',
                            toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code save',
                            browser_spellcheck: true,
                            autoresize_bottom_margin: 50,
                            min_height: 500
                        }
                          }, null, 8, ["tinymceScriptSrc", "modelValue"]),
                          (_ctx.submitted)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['webshopText'].$silentErrors, (error) => {
                                return (_openBlock(), _createElementBlock("small", {
                                  style: {"display":"block"},
                                  class: "p-error",
                                  key: error
                                }, _toDisplayString(error.$message), 1))
                              }), 128))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ])
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          (_ctx.hasSecondShop)
            ? (_openBlock(), _createBlock(_component_Panel, {
                key: 0,
                class: "mt-3"
              }, {
                header: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createElementVNode("div", { class: "font-bold flex justify-content-between w-full" }, [
                    _createElementVNode("div", null, "Webshop Video Data"),
                    _createElementVNode("div", { class: "sales-channel-dot sales-channel-videodata" })
                  ], -1)
                ])),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.availableInShop')), 1),
                      _createVNode(_component_p_checkbox, {
                        modelValue: _ctx.v$['availableInShop2'].$model,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.v$['availableInShop2'].$model) = $event)),
                        disabled: _ctx.v$['activeInShop2'].$model,
                        binary: true,
                        class: "ml-2 mb-1"
                      }, null, 8, ["modelValue", "disabled"])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.activeInShop')), 1),
                      _createVNode(_component_p_checkbox, {
                        modelValue: _ctx.v$['activeInShop2'].$model,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$['activeInShop2'].$model) = $event)),
                        binary: true,
                        class: "ml-2 mb-1",
                        onInput: _cache[10] || (_cache[10] = $event => (_ctx.onActiveInShopChange('availableInShop2', $event)))
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  (_ctx.v$['activeInShop2'].$model || _ctx.v$['availableInShop2'].$model)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productDescription.webshopText')), 1),
                          _createElementVNode("span", _hoisted_19, [
                            _createVNode(_component_tiny, {
                              tinymceScriptSrc: _ctx.tinyUrl,
                              modelValue: _ctx.v$['webshopText2'].$model,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.v$['webshopText2'].$model) = $event)),
                              init: {
                            
                            menubar: false,
                            plugins: 'lists link image emoticons code table anchor charmap fullscreen paste autoresize',
                            toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code save',
                            browser_spellcheck: true,
                            autoresize_bottom_margin: 50,
                            min_height: 500
                        }
                            }, null, 8, ["tinymceScriptSrc", "modelValue"]),
                            (_ctx.submitted)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['webshopText2'].$silentErrors, (error) => {
                                  return (_openBlock(), _createElementBlock("small", {
                                    style: {"display":"block"},
                                    class: "p-error",
                                    key: error
                                  }, _toDisplayString(error.$message), 1))
                                }), 128))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_p_fieldset, {
            legend: _ctx.$t('labels.description'),
            class: "mt-5"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productDescription.shortDescription')), 1),
                  _createElementVNode("span", _hoisted_22, [
                    _createVNode(_component_InputText, {
                      autocomplete: "off",
                      maxLength: 100,
                      modelValue: _ctx.v$['shortDescription'].$model,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => ((_ctx.v$['shortDescription'].$model) = $event)),
                      class: _normalizeClass({'p-invalid':_ctx.v$['shortDescription'].$invalid && _ctx.submitted})
                    }, null, 8, ["modelValue", "class"]),
                    (_ctx.submitted)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['shortDescription'].$silentErrors, (error) => {
                          return (_openBlock(), _createElementBlock("small", {
                            style: {"display":"block"},
                            class: "p-error",
                            key: error
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.description')) + " [Weclapp] ", 1),
                  _createElementVNode("span", _hoisted_25, [
                    _createVNode(_component_tiny, {
                      tinymceScriptSrc: _ctx.tinyUrl,
                      modelValue: _ctx.v$['longDescription'].$model,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.v$['longDescription'].$model) = $event)),
                      init: {
                                
                                menubar: false,
                                plugins: 'lists link image emoticons code table anchor charmap fullscreen paste autoresize',
                                toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code save',
                                browser_spellcheck: true,
                                autoresize_bottom_margin: 50,
                                min_height: 500
                            }
                    }, null, 8, ["tinymceScriptSrc", "modelValue"]),
                    (_ctx.submitted)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['longDescription'].$silentErrors, (error) => {
                          return (_openBlock(), _createElementBlock("small", {
                            style: {"display":"block"},
                            class: "p-error",
                            key: error
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["legend"])
        ], true)
      ])
    ]),
    _createElementVNode("div", _hoisted_26, [
      _createVNode(_component_p_button, {
        severity: "danger",
        textContent: _toDisplayString(_ctx.$t('buttons.back')),
        onClick: _ctx.onBackButtonClicked
      }, null, 8, ["textContent", "onClick"]),
      _createVNode(_component_p_button, {
        severity: "success",
        type: "submit",
        textContent: _toDisplayString(_ctx.$t('buttons.next'))
      }, null, 8, ["textContent"])
    ])
  ], 32))
}