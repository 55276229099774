import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "col-4" }
const _hoisted_2 = { class: "col-8" }
const _hoisted_3 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_4 = { class: "ml-3" }
const _hoisted_5 = {
  key: 0,
  class: "w-full flex justify-content-between"
}
const _hoisted_6 = {
  key: 1,
  class: "flex justify-content-end"
}

export function render(_ctx, _cache) {
  const _component_CheckDuplicateStep = _resolveComponent("CheckDuplicateStep")
  const _component_StepperPanel = _resolveComponent("StepperPanel")
  const _component_GlobalFieldsStep = _resolveComponent("GlobalFieldsStep")
  const _component_EnvSpecificDescriptionsStep = _resolveComponent("EnvSpecificDescriptionsStep")
  const _component_EnvSpecificFieldsStep = _resolveComponent("EnvSpecificFieldsStep")
  const _component_EnvSpecificSupplySourcesStep = _resolveComponent("EnvSpecificSupplySourcesStep")
  const _component_Stepper = _resolveComponent("Stepper")
  const _component_Panel = _resolveComponent("Panel")
  const _component_router_link = _resolveComponent("router-link")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")
  const _component_ConfirmLeave = _resolveComponent("ConfirmLeave")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.addArticle')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Stepper, {
          linear: "",
          activeStep: _ctx.activeIndex,
          "onUpdate:activeStep": _cache[0] || (_cache[0] = $event => ((_ctx.activeIndex) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_StepperPanel, {
              header: _ctx.$t('labels.productAdd.checkDuplicate')
            }, {
              content: _withCtx(({ nextCallback }) => [
                _createVNode(_component_CheckDuplicateStep, {
                  onEnvironmentsSelected: $event => {_ctx.onEnvironmentsSelected($event);nextCallback($event);}
                }, null, 8, ["onEnvironmentsSelected"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_StepperPanel, {
              header: _ctx.$t('labels.productHome.masterData') + ' 1/3'
            }, {
              content: _withCtx(({ prevCallback, nextCallback }) => [
                _createVNode(_component_GlobalFieldsStep, {
                  duplicateCheckValues: _ctx.duplicateCheckValues,
                  pairValues: _ctx.pairValues,
                  "custom-attributes-definition": _ctx.customAttributesDefinition,
                  onBackButtonClicked: prevCallback,
                  onNextButtonClicked: $event => {_ctx.onNextButtonClicked($event);nextCallback($event);},
                  onAddWizardFormDirty: _ctx.onAddWizardFormDirty
                }, null, 8, ["duplicateCheckValues", "pairValues", "custom-attributes-definition", "onBackButtonClicked", "onNextButtonClicked", "onAddWizardFormDirty"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_StepperPanel, {
              header: _ctx.$t('labels.productHome.masterData') + ' 2/3'
            }, {
              content: _withCtx(({ prevCallback, nextCallback }) => [
                _createVNode(_component_EnvSpecificDescriptionsStep, {
                  pairValues: _ctx.pairValues,
                  onBackButtonClicked: $event => {_ctx.onBackButtonClicked($event);prevCallback();},
                  onNextButtonClicked: $event => {_ctx.onNextButtonClicked($event);nextCallback($event);},
                  onAddWizardFormDirty: _ctx.onAddWizardFormDirty
                }, null, 8, ["pairValues", "onBackButtonClicked", "onNextButtonClicked", "onAddWizardFormDirty"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_StepperPanel, {
              header: _ctx.$t('labels.productHome.masterData') + ' 3/3'
            }, {
              content: _withCtx(({ prevCallback, nextCallback }) => [
                _createVNode(_component_EnvSpecificFieldsStep, {
                  pairValues: _ctx.pairValues,
                  onBackButtonClicked: $event => {_ctx.onBackButtonClicked($event);prevCallback();},
                  onNextButtonClicked: $event => {_ctx.onNextButtonClicked($event);nextCallback($event);},
                  onAddWizardFormDirty: _ctx.onAddWizardFormDirty
                }, null, 8, ["pairValues", "onBackButtonClicked", "onNextButtonClicked", "onAddWizardFormDirty"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_StepperPanel, {
              header: _ctx.$t('labels.salesOrder.supplySources')
            }, {
              content: _withCtx(({ prevCallback, nextCallback }) => [
                _createVNode(_component_EnvSpecificSupplySourcesStep, {
                  duplicateCheckValues: _ctx.duplicateCheckValues,
                  pairValues: _ctx.pairValues,
                  savingInProgress: _ctx.savingInProgress,
                  onBackButtonClicked: $event => {_ctx.onBackButtonClicked($event);prevCallback();},
                  onNextButtonClicked: $event => {_ctx.onNextButtonClicked($event);nextCallback($event);},
                  onAddWizardFormDirty: _ctx.onAddWizardFormDirty
                }, null, 8, ["duplicateCheckValues", "pairValues", "savingInProgress", "onBackButtonClicked", "onNextButtonClicked", "onAddWizardFormDirty"])
              ]),
              _: 1
            }, 8, ["header"])
          ]),
          _: 1
        }, 8, ["activeStep"])
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_PrimeDialog, {
      header: _ctx.$t('labels.result'),
      visible: _ctx.showResultDialog,
      "onUpdate:visible": _cache[1] || (_cache[1] = $event => ((_ctx.showResultDialog) = $event)),
      breakpoints: {'960px': '75vw', '640px': '90vw'},
      style: {width: '640px'},
      modal: true,
      closable: false
    }, {
      footer: _withCtx(() => [
        (_ctx.hasError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_p_button, {
                severity: "warning",
                onClick: _ctx.onStartOverClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("buttons.startOver")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_router_link, { to: {
            name: 'Products'
        } }, {
                default: _withCtx(() => [
                  _createVNode(_component_p_button, { severity: "danger" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_router_link, { to: {
            name: 'Products'
        } }, {
                default: _withCtx(() => [
                  _createVNode(_component_p_button, { severity: "success" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("buttons.backToProductList")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.resolvedPlatform.label), 1),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.saveResult)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.saveResult['error'])
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('labels.error')) + ": " + _toDisplayString(_ctx.saveResult['error']), 1))
                  : _createCommentVNode("", true),
                (_ctx.saveResult['id'])
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _cache[2] || (_cache[2] = _createElementVNode("i", { class: "pi text-green-600 pi-check-circle" }, null, -1)),
                      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('labels.articleNumber')) + ":", 1),
                      _createVNode(_component_router_link, {
                        class: "ml-3",
                        to: {
                            name: 'ProductDetails',
                            params: {
                                id: _ctx.saveResult['id']
                            }
                        }
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.saveResult['id']), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["header", "visible"]),
    _createVNode(_component_ConfirmLeave, {
      displayConfirmLeaveDialog: _ctx.displayConfirmLeaveDialog,
      onChoiceConfirmed: _ctx.onChoiceConfirmed
    }, null, 8, ["displayConfirmLeaveDialog", "onChoiceConfirmed"])
  ], 64))
}