import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-bold" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "font-semibold" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "grid mt-1" }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "font-semibold" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "col" }
const _hoisted_16 = { class: "grid mt-2" }
const _hoisted_17 = { class: "col field p-fluid" }
const _hoisted_18 = { class: "font-bold" }
const _hoisted_19 = { class: "grid" }
const _hoisted_20 = { class: "col field p-fluid" }
const _hoisted_21 = { class: "grid" }
const _hoisted_22 = { class: "col field p-fluid" }
const _hoisted_23 = { class: "flex justify-content-end mt-5" }

export function render(_ctx, _cache) {
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_p_textarea = _resolveComponent("p-textarea")
  const _component_AutoComplete = _resolveComponent("AutoComplete")
  const _component_p_inputtext = _resolveComponent("p-inputtext")
  const _component_tiny = _resolveComponent("tiny")
  const _component_Panel = _resolveComponent("Panel")
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createBlock(_component_Panel, null, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.ticketDetails?.subject), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[11] || (_cache[11] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.customer')), 1),
            _createElementVNode("div", null, [
              (_ctx.ticketDetails?.customer)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: _ctx.ticketDetails?.url + 'webapp/view/party/PartyDetail.page?entityId=' + _ctx.ticketDetails?.partyId,
                    target: "_blank"
                  }, _toDisplayString(_ctx.ticketDetails?.customer.company ??
                        _ctx.ticketDetails?.customer.firstName + ' ' +
                        _ctx.ticketDetails?.customer.lastName), 9, _hoisted_4))
                : _createCommentVNode("", true),
              (_ctx.ticketDetails?.email)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(" (" + _toDisplayString(_ctx.ticketDetails?.email) + ")", 1)
                  ], 64))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticketChannel')), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.ticketDetails?.ticketChannel), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.assignedUser')), 1),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_p_dropdown, {
                modelValue: _ctx.v$.assignedUserId.$model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$.assignedUserId.$model) = $event)),
                class: _normalizeClass(["w-full", {'p-invalid':_ctx.v$.assignedUserId.$invalid && _ctx.submitted}]),
                options: _ctx.userOptions || [],
                optionLabel: "name",
                optionValue: "id",
                filter: ""
              }, null, 8, ["modelValue", "options", "class"]),
              (_ctx.submitted)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.assignedUserId.$silentErrors, (error) => {
                    return (_openBlock(), _createElementBlock("small", {
                      style: {"display":"block"},
                      class: "p-error",
                      key: error
                    }, _toDisplayString(error.$message), 1))
                  }), 128))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.note')), 1),
            _createElementVNode("div", null, [
              _createVNode(_component_p_textarea, {
                class: "w-full",
                modelValue: _ctx.v$.note.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.note.$model) = $event)),
                modelModifiers: { trim: true }
              }, null, 8, ["modelValue"]),
              (_ctx.submitted)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.note.$silentErrors, (error) => {
                    return (_openBlock(), _createElementBlock("small", {
                      style: {"display":"block"},
                      class: "p-error",
                      key: error
                    }, _toDisplayString(error.$message), 1))
                  }), 128))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.status')), 1),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_p_dropdown, {
                modelValue: _ctx.v$.ticketStatusId.$model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$.ticketStatusId.$model) = $event)),
                class: _normalizeClass(["w-full", {'p-invalid':_ctx.v$.ticketStatusId.$invalid && _ctx.submitted}]),
                options: _ctx.ticketStatusOptions || [],
                optionLabel: "name",
                optionValue: "id",
                filter: ""
              }, null, 8, ["modelValue", "options", "class"]),
              (_ctx.submitted)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.ticketStatusId.$silentErrors, (error) => {
                    return (_openBlock(), _createElementBlock("small", {
                      style: {"display":"block"},
                      class: "p-error",
                      key: error
                    }, _toDisplayString(error.$message), 1))
                  }), 128))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.article')), 1),
            _createVNode(_component_AutoComplete, {
              modelValue: _ctx.v$.ticketArticleId.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.ticketArticleId.$model) = $event)),
              suggestions: _ctx.filteredProducts,
              class: _normalizeClass([{'p-invalid':_ctx.v$.ticketArticleId.$invalid && _ctx.submitted}, "w-full"]),
              onComplete: _cache[4] || (_cache[4] = $event => (_ctx.searchProducts($event))),
              dropdown: "",
              optionLabel: "label",
              optionValue: "value",
              forceSelection: ""
            }, null, 8, ["modelValue", "suggestions", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.ticketArticleId.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.references.invoice')), 1),
            _createVNode(_component_AutoComplete, {
              modelValue: _ctx.v$.ticketSalesInvoiceId.$model,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$.ticketSalesInvoiceId.$model) = $event)),
              suggestions: _ctx.filteredSalesInvoices,
              class: _normalizeClass([{'p-invalid':_ctx.v$.ticketSalesInvoiceId.$invalid && _ctx.submitted}, "w-full"]),
              onComplete: _cache[6] || (_cache[6] = $event => (_ctx.searchSalesInvoices($event))),
              dropdown: "",
              optionLabel: "label",
              optionValue: "value"
            }, null, 8, ["modelValue", "suggestions", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.ticketSalesInvoiceId.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.salesOrder.orderNumber')), 1),
            _createVNode(_component_AutoComplete, {
              modelValue: _ctx.v$.ticketSalesOrderId.$model,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.v$.ticketSalesOrderId.$model) = $event)),
              suggestions: _ctx.filteredSalesOrders,
              class: _normalizeClass([{'p-invalid':_ctx.v$.ticketSalesOrderId.$invalid && _ctx.submitted}, "w-full"]),
              onComplete: _cache[8] || (_cache[8] = $event => (_ctx.searchSalesOrders($event))),
              dropdown: "",
              optionLabel: "label",
              optionValue: "value"
            }, null, 8, ["modelValue", "suggestions", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.ticketSalesOrderId.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(({
        'externalRMA': 'ticket_external_rma',
        'internalComment': 'ticket_internal_comment',
        'serialNumber': 'ticket_serialnumber',
        'inboundTracking': 'ticket_inbound_tracking'
    }), (customAtrributeKey, translationKey) => {
            return _createElementVNode("div", _hoisted_17, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.additional.' + translationKey)), 1),
              _createVNode(_component_p_textarea, {
                modelValue: _ctx.v$[customAtrributeKey].$model,
                "onUpdate:modelValue": $event => ((_ctx.v$[customAtrributeKey].$model) = $event),
                modelModifiers: { trim: true }
              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
              (_ctx.submitted)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$[customAtrributeKey].$silentErrors, (error) => {
                    return (_openBlock(), _createElementBlock("small", {
                      style: {"display":"block"},
                      class: "p-error",
                      key: error
                    }, _toDisplayString(error.$message), 1))
                  }), 128))
                : _createCommentVNode("", true)
            ])
          }), 64))
        ]),
        _createVNode(_component_Panel, null, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('labels.description')), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.subject')), 1),
                _createVNode(_component_p_inputtext, {
                  modelValue: _ctx.v$.subject.$model,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$.subject.$model) = $event)),
                  modelModifiers: { trim: true }
                }, null, 8, ["modelValue"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.subject.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.description')), 1),
                _createVNode(_component_tiny, {
                  tinymceScriptSrc: _ctx.tinyUrl,
                  modelValue: _ctx.v$.description.$model,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.v$.description.$model) = $event)),
                  init: {
height: 200,
menubar: false,
plugins: 'lists link image emoticons code table anchor charmap fullscreen paste',
toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code',
browser_spellcheck: true
}
                }, null, 8, ["tinymceScriptSrc", "modelValue"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.description.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_23, [
          _createVNode(_component_p_button, {
            disabled: _ctx.savingInProgress || !_ctx.v$.$anyDirty,
            severity: "success",
            textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.confirm')),
            type: "submit"
          }, null, 8, ["disabled", "textContent"])
        ])
      ], 32)
    ]),
    _: 1
  }))
}