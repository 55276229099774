import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-bold" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.ticketDetails?.comments || []), (comment) => {
    return (_openBlock(), _createBlock(_component_Panel, { class: "mb-3" }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(comment.author) + " " + _toDisplayString(_ctx.$t("labels.at")) + " " + _toDisplayString(comment.createdDate), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          innerHTML: _ctx.removeStyles(comment.htmlComment || comment.comment)
        }, null, 8, _hoisted_2)
      ]),
      _: 2
    }, 1024))
  }), 256))
}