import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "m-2" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col-8" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = { class: "flex justify-content-end" }
const _hoisted_7 = { class: "mt-3 flex justify-content-end" }

export function render(_ctx, _cache) {
  const _component_Tag = _resolveComponent("Tag")
  const _component_AutoComplete = _resolveComponent("AutoComplete")
  const _component_p_tree = _resolveComponent("p-tree")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productCategories, (item) => {
          return (_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_Tag, { class: "mb-1" }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(item.breadcrumb.join(' -> ')), 1),
                _createElementVNode("i", {
                  class: "pi pi-times",
                  style: {"cursor":"pointer"},
                  onClick: $event => (_ctx.removeSingleCategory(item))
                }, null, 8, _hoisted_4)
              ]),
              _: 2
            }, 1024)
          ]))
        }), 256))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_AutoComplete, {
            modelValue: _ctx.searchTerm,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.searchTerm) = $event)),
            suggestions: _ctx.filteredResults,
            placeholder: _ctx.searchTermPlaceHolder,
            onComplete: _ctx.loadData,
            onItemSelect: _ctx.onTermSelected,
            optionLabel: "name",
            optionValue: "id"
          }, null, 8, ["modelValue", "suggestions", "placeholder", "onComplete", "onItemSelect"])
        ])
      ])
    ]),
    _createVNode(_component_ScrollPanel, { style: {"height":"calc(100vh - 38rem)"} }, {
      default: _withCtx(() => [
        _createVNode(_component_p_tree, {
          value: _ctx.categories,
          onNodeExpand: _ctx.onNodeExpand,
          selectionMode: "multiple",
          selectionKeys: _ctx.selectedCategories,
          "onUpdate:selectionKeys": _cache[1] || (_cache[1] = $event => ((_ctx.selectedCategories) = $event)),
          metaKeySelection: false,
          expandedKeys: _ctx.expandedKeys
        }, null, 8, ["value", "onNodeExpand", "selectionKeys", "expandedKeys"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_p_button, {
        severity: "danger",
        disabled: _ctx.buttonsDisabled || _ctx.buttonsHidden,
        onClick: _ctx.discardChanges,
        textContent: _toDisplayString(_ctx.$t('labels.revert')),
        class: "mr-3"
      }, null, 8, ["disabled", "onClick", "textContent"]),
      _createVNode(_component_p_button, {
        severity: "success",
        onClick: _ctx.saveChanges,
        disabled: _ctx.buttonsDisabled || _ctx.buttonsHidden,
        textContent: _toDisplayString(_ctx.buttonsDisabled ? _ctx.$t('labels.inProgress') : _ctx.$t('labels.save'))
      }, null, 8, ["onClick", "disabled", "textContent"])
    ])
  ]))
}