import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_ImagesContainer = _resolveComponent("ImagesContainer")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_VideosContainer = _resolveComponent("VideosContainer")
  const _component_DocumentsContainer = _resolveComponent("DocumentsContainer")
  const _component_LinksContainer = _resolveComponent("LinksContainer")
  const _component_TabView = _resolveComponent("TabView")

  return (_openBlock(), _createBlock(_component_TabView, { class: "tabview-custom text-sm" }, {
    default: _withCtx(() => [
      _createVNode(_component_TabPanel, {
        disabled: !_ctx.productDetails?.shopware && !_ctx.productDetails?.secondShopware
      }, {
        header: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("i", { class: "pi pi-images" }, null, -1)),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.images")), 1),
          (_ctx.productDetails?.shopware?.images || _ctx.productDetails?.secondShopware?.images)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[0] || (_cache[0] = _createTextVNode(" - ")),
                (_ctx.productDetails?.shopware?.images)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(" (" + _toDisplayString(_ctx.productDetails.shopware.images.length) + ") ", 1),
                      _createElementVNode("span", {
                        class: _normalizeClass(["sales-channel-dot w-1rem h-1rem", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                      }, null, 2)
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.productDetails?.secondShopware?.images)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(" | (" + _toDisplayString(_ctx.productDetails.secondShopware.images.length) + ") ", 1),
                      _createElementVNode("span", {
                        class: _normalizeClass(["sales-channel-dot w-1rem h-1rem", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                      }, null, 2)
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_ImagesContainer, {
            "product-details": _ctx.productDetails,
            onReloadProduct: _ctx.loadProductDetails
          }, null, 8, ["product-details", "onReloadProduct"])
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_TabPanel, {
        disabled: !_ctx.productDetails?.shopware && !_ctx.productDetails?.secondShopware
      }, {
        header: _withCtx(() => [
          _cache[3] || (_cache[3] = _createElementVNode("i", { class: "pi pi-youtube" }, null, -1)),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.videos")), 1),
          (_ctx.productDetails?.shopware?.videos || _ctx.productDetails?.secondShopware?.videos)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[2] || (_cache[2] = _createTextVNode(" - ")),
                (_ctx.productDetails?.shopware?.videos)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(" (" + _toDisplayString(_ctx.productDetails.shopware.videos.length) + ") ", 1),
                      _createElementVNode("span", {
                        class: _normalizeClass(["sales-channel-dot w-1rem h-1rem", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                      }, null, 2)
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.productDetails?.secondShopware?.videos)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(" | (" + _toDisplayString(_ctx.productDetails.secondShopware.videos.length) + ") ", 1),
                      _createElementVNode("span", {
                        class: _normalizeClass(["sales-channel-dot w-1rem h-1rem", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                      }, null, 2)
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_VideosContainer, {
            "product-details": _ctx.productDetails,
            onReloadProduct: _ctx.loadProductDetails
          }, null, 8, ["product-details", "onReloadProduct"])
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_TabPanel, {
        disabled: !_ctx.productDetails?.shopware && !_ctx.productDetails?.secondShopware
      }, {
        header: _withCtx(() => [
          _cache[5] || (_cache[5] = _createElementVNode("i", { class: "pi pi-file-pdf" }, null, -1)),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.documents")), 1),
          (_ctx.productDetails?.shopware?.documents || _ctx.productDetails?.secondShopware?.documents)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[4] || (_cache[4] = _createTextVNode(" - ")),
                (_ctx.productDetails?.shopware?.documents)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(" (" + _toDisplayString(_ctx.productDetails.shopware.documents.length) + ") ", 1),
                      _createElementVNode("span", {
                        class: _normalizeClass(["sales-channel-dot w-1rem h-1rem", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                      }, null, 2)
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.productDetails?.secondShopware?.documents)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(" | (" + _toDisplayString(_ctx.productDetails.secondShopware.documents.length) + ") ", 1),
                      _createElementVNode("span", {
                        class: _normalizeClass(["sales-channel-dot w-1rem h-1rem", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                      }, null, 2)
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_DocumentsContainer, {
            "product-details": _ctx.productDetails,
            onReloadProduct: _ctx.loadProductDetails
          }, null, 8, ["product-details", "onReloadProduct"])
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_TabPanel, {
        disabled: !_ctx.productDetails?.shopware && !_ctx.productDetails?.secondShopware
      }, {
        header: _withCtx(() => [
          _cache[7] || (_cache[7] = _createElementVNode("i", { class: "pi pi-external-link" }, null, -1)),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.links")), 1),
          (_ctx.productDetails?.shopware?.links || _ctx.productDetails?.secondShopware?.links)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[6] || (_cache[6] = _createTextVNode(" - ")),
                (_ctx.productDetails?.shopware?.links)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(" (" + _toDisplayString(_ctx.productDetails.shopware.links.length) + ") ", 1),
                      _createElementVNode("span", {
                        class: _normalizeClass(["sales-channel-dot w-1rem h-1rem", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                      }, null, 2)
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.productDetails?.secondShopware?.links)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(" | (" + _toDisplayString(_ctx.productDetails.secondShopware.links.length) + ") ", 1),
                      _createElementVNode("span", {
                        class: _normalizeClass(["sales-channel-dot w-1rem h-1rem", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                      }, null, 2)
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_LinksContainer, {
            "product-details": _ctx.productDetails,
            onReloadProduct: _ctx.loadProductDetails
          }, null, 8, ["product-details", "onReloadProduct"])
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }))
}