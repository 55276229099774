import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-2" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "button-container" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_AddEditDialog = _resolveComponent("AddEditDialog")
  const _component_DeleteDialog = _resolveComponent("DeleteDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_DataTable, {
        class: "p-datatable-sm text-sm",
        stripedRows: "",
        paginator: _ctx.productLinks && _ctx.productLinks.length > 20,
        rows: 20,
        lazy: true,
        value: _ctx.productLinks,
        responsiveLayout: "scroll",
        "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
        "rows-per-page-options": [10,20,50,100,200],
        "current-page-report-template": "{first} to {last} of {totalRecords}"
      }, {
        empty: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('messages.noRecordsFound')) + ". ", 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            header: "Link",
            style: {"min-width":"12rem"}
          }, {
            body: _withCtx(({data}) => [
              _createElementVNode("h5", null, _toDisplayString(data.title), 1),
              _createElementVNode("div", null, [
                _cache[1] || (_cache[1] = _createElementVNode("i", { class: "pi pi-link" }, null, -1)),
                _createElementVNode("a", {
                  class: "ml-2",
                  href: data.url.replace(/^(?!https)/, 'https://'),
                  target: "_blank"
                }, _toDisplayString(data.url), 9, _hoisted_2)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, { style: {"min-width":"5rem"} }, {
            body: _withCtx(({data}) => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("i", {
                  class: "pi pi-trash text-sm mr-2",
                  onClick: $event => (_ctx.showDeleteDialog(data))
                }, null, 8, _hoisted_4),
                _createElementVNode("i", {
                  class: "pi pi-pencil text-sm mr-2",
                  onClick: $event => (_ctx.showDialog(data))
                }, null, 8, _hoisted_5)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["paginator", "value"])
    ]),
    _createVNode(_component_AddEditDialog, {
      displayResponsive: _ctx.displayAddEditDialog,
      "product-number": _ctx.shopwareDetails?.productNumber,
      selectedLink: _ctx.selectedLink,
      onCloseDialog: _ctx.closeDialogListener,
      platform: _ctx.platform
    }, null, 8, ["displayResponsive", "product-number", "selectedLink", "onCloseDialog", "platform"]),
    _createVNode(_component_DeleteDialog, {
      displayResponsive: _ctx.displayDeleteDialog,
      "product-number": _ctx.shopwareDetails?.productNumber,
      selectedLink: _ctx.selectedLink,
      onCloseDialog: _ctx.closeDialogListener,
      platform: _ctx.platform
    }, null, 8, ["displayResponsive", "product-number", "selectedLink", "onCloseDialog", "platform"])
  ], 64))
}