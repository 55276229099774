import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "m-2" }
const _hoisted_2 = { class: "flex flex-column mb-5" }
const _hoisted_3 = {
  key: 0,
  class: "text-lg font-semibold"
}
const _hoisted_4 = {
  key: 0,
  class: "text-center w-full"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "pi text-green-600 pi-check-circle ml-1"
}
const _hoisted_9 = {
  key: 0,
  class: "pi text-green-600 pi-check-circle ml-1"
}
const _hoisted_10 = {
  key: 0,
  class: "pi text-green-600 pi-check-circle ml-1"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 0,
  class: "flex align-items-stretch ml-1"
}
const _hoisted_13 = {
  key: 0,
  class: "pi text-green-600 pi-check-circle flex align-items-center"
}
const _hoisted_14 = {
  key: 1,
  class: "pi pi-exclamation-triangle flex align-items-center"
}
const _hoisted_15 = { key: 0 }
const _hoisted_16 = {
  key: 0,
  class: "flex align-items-stretch ml-1"
}
const _hoisted_17 = {
  key: 0,
  class: "pi text-green-600 pi-check-circle flex align-items-center"
}
const _hoisted_18 = {
  key: 1,
  class: "pi pi-exclamation-triangle flex align-items-center"
}
const _hoisted_19 = { key: 0 }
const _hoisted_20 = {
  key: 0,
  class: "flex align-items-stretch ml-1"
}
const _hoisted_21 = {
  key: 0,
  class: "pi text-green-600 pi-check-circle flex align-items-center"
}
const _hoisted_22 = {
  key: 1,
  class: "pi pi-exclamation-triangle flex align-items-center"
}
const _hoisted_23 = { class: "text-sm flex justify-content-end" }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = { class: "flex justify-content-end w-full mt-5" }
const _hoisted_26 = { class: "grid" }
const _hoisted_27 = { class: "col" }
const _hoisted_28 = { class: "col" }
const _hoisted_29 = { class: "grid" }
const _hoisted_30 = { class: "col-2 font-semibold" }
const _hoisted_31 = { class: "col-10" }
const _hoisted_32 = {
  key: 0,
  class: "text-red-600"
}
const _hoisted_33 = { class: "ml-3" }
const _hoisted_34 = ["href"]
const _hoisted_35 = { class: "flex justify-content-end" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Column = _resolveComponent("Column")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_p_button = _resolveComponent("p-button")
  const _component_Panel = _resolveComponent("Panel")
  const _component_ShopDataOverview = _resolveComponent("ShopDataOverview")
  const _component_WeclappDataOverview = _resolveComponent("WeclappDataOverview")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")
  const _component_router_link = _resolveComponent("router-link")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")
  const _component_CreateOrderOverview = _resolveComponent("CreateOrderOverview")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.savingInProgress,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.savingInProgress) = $event))
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ScrollPanel, { style: {"height":"calc(100vh - 14rem)"} }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              (_ctx.shopData)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                    _createTextVNode(_toDisplayString(_ctx.shopData.number) + " ", 1),
                    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                    _createTextVNode(_toDisplayString(_ctx.shopData.paymentMethod?.name), 1),
                    _cache[6] || (_cache[6] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.getCurrencyFormatted(_ctx.shopData.amountTotal)) + " ", 1),
                    _cache[7] || (_cache[7] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('labels.createdAt')) + ": ", 1),
                    _createElementVNode("b", null, _toDisplayString(_ctx.getDateFormatted(_ctx.shopData.orderTime)), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createVNode(_component_Panel, {
            header: "Potenzielle Matches",
            class: "mb-5"
          }, {
            default: _withCtx(() => [
              (!_ctx.potentialMatches || _ctx.potentialMatches.length < 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('messages.noWeclappCustomerCouldBeFound')), 1))
                : _createCommentVNode("", true),
              (_ctx.potentialMatches.length > 0)
                ? (_openBlock(), _createBlock(_component_DataTable, {
                    key: 1,
                    class: "p-datatable-sm text-sm",
                    stripedRows: "",
                    rowClass: _ctx.rowClass,
                    rows: 20,
                    dataKey: "id",
                    value: _ctx.potentialMatches,
                    responsiveLayout: "scroll"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        header: _ctx.$t('labels.customer')
                      }, {
                        body: _withCtx(({data}) => [
                          _cache[8] || (_cache[8] = _createTextVNode(" [")),
                          _createElementVNode("a", {
                            href: data.url + 'webapp/view/party/PartyDetail.page?entityId=' + data.id,
                            target: "_blank"
                          }, _toDisplayString(data.customerNumber), 9, _hoisted_5),
                          _cache[9] || (_cache[9] = _createTextVNode("] ")),
                          (data.company)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(data.company), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(data.firstName) + " " + _toDisplayString(data.lastName), 1))
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        header: _ctx.$t('labels.email'),
                        field: "email"
                      }, {
                        body: _withCtx(({data}) => [
                          _createElementVNode("div", {
                            class: _normalizeClass({'font-bold': _ctx.nonEmptyStringMatch(_ctx.shopData?.customer?.email, data.email)})
                          }, [
                            _createTextVNode(_toDisplayString(data.email) + " ", 1),
                            (_ctx.nonEmptyStringMatch(_ctx.shopData?.customer?.email, data.email))
                              ? (_openBlock(), _createElementBlock("i", _hoisted_8))
                              : _createCommentVNode("", true)
                          ], 2)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, {
                        header: 'Shop-' + _ctx.$t('labels.customerNumber')
                      }, {
                        body: _withCtx(({data}) => [
                          _createElementVNode("div", {
                            class: _normalizeClass({'font-bold': _ctx.nonEmptyStringMatch(_ctx.shopData?.customer?.customerNumber, ['ttd',
                            'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) ?
                            data.teltecShopCustomerNumber:
                            data.videodataShopCustomerNumber)})
                          }, [
                            _createTextVNode(_toDisplayString(['ttd',
                            'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) ?
                            data.teltecShopCustomerNumber:
                            data.videodataShopCustomerNumber) + " ", 1),
                            (_ctx.nonEmptyStringMatch(_ctx.shopData?.customer?.customerNumber, ['ttd',
                                'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) ?
                                data.teltecShopCustomerNumber:
                                data.videodataShopCustomerNumber))
                              ? (_openBlock(), _createElementBlock("i", _hoisted_9))
                              : _createCommentVNode("", true)
                          ], 2)
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createVNode(_component_Column, { header: "USt-ID" }, {
                        body: _withCtx(({data}) => [
                          _createElementVNode("div", {
                            class: _normalizeClass({'font-bold': _ctx.nonEmptyStringMatch(_ctx.shopData?.customer?.vatNumber, data.taxId || data.vatIdentificationNumber)})
                          }, [
                            _createTextVNode(_toDisplayString(data.taxId || data.vatIdentificationNumber) + " ", 1),
                            (_ctx.nonEmptyStringMatch(_ctx.shopData?.customer?.vatNumber, data.taxId || data.vatIdentificationNumber))
                              ? (_openBlock(), _createElementBlock("i", _hoisted_10))
                              : _createCommentVNode("", true)
                          ], 2)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, null, {
                        header: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('labels.primeAddress')) + " ", 1),
                          _cache[10] || (_cache[10] = _createElementVNode("i", { class: "pi pi-home ml-2" }, null, -1))
                        ]),
                        body: _withCtx(({data}) => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((data?.addresses || []), (address) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                              (address.primeAddress)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass([{'font-bold': _ctx.addressMatch(address)}, "flex justify-content-start"])
                                  }, [
                                    _createElementVNode("div", null, [
                                      (address.company)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(address.company), 1))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("div", null, _toDisplayString(address.street1), 1),
                                      _createElementVNode("div", null, _toDisplayString(address.street2), 1),
                                      _createElementVNode("div", null, _toDisplayString(address.zipcode) + " " + _toDisplayString(address.city), 1),
                                      _createElementVNode("div", null, _toDisplayString(_ctx.resolveCountryFromCode(address.countryCode)), 1)
                                    ]),
                                    (_ctx.addressMatch(address))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                          (_ctx.extractHouseNumberFromAddress(_ctx.shopData?.billingAddress?.street) === _ctx.extractHouseNumberFromAddress(address.street1))
                                            ? (_openBlock(), _createElementBlock("i", _hoisted_13))
                                            : (_openBlock(), _createElementBlock("i", _hoisted_14))
                                        ]))
                                      : _createCommentVNode("", true)
                                  ], 2))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 256))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, null, {
                        header: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('labels.invoiceAddress')) + " ", 1),
                          _cache[11] || (_cache[11] = _createElementVNode("i", { class: "pi pi-calculator ml-2" }, null, -1))
                        ]),
                        body: _withCtx(({data}) => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((data?.addresses || []), (address) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                              (address.invoiceAddress)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass([{'font-bold': _ctx.addressMatch(address)}, "flex justify-content-start"])
                                  }, [
                                    _createElementVNode("div", null, [
                                      (address.company)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(address.company), 1))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("div", null, _toDisplayString(address.street1), 1),
                                      _createElementVNode("div", null, _toDisplayString(address.street2), 1),
                                      _createElementVNode("div", null, _toDisplayString(address.zipcode) + " " + _toDisplayString(address.city), 1),
                                      _createElementVNode("div", null, _toDisplayString(_ctx.resolveCountryFromCode(address.countryCode)), 1)
                                    ]),
                                    (_ctx.addressMatch(address))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                          (_ctx.extractHouseNumberFromAddress(_ctx.shopData?.billingAddress?.street) === _ctx.extractHouseNumberFromAddress(address.street1))
                                            ? (_openBlock(), _createElementBlock("i", _hoisted_17))
                                            : (_openBlock(), _createElementBlock("i", _hoisted_18))
                                        ]))
                                      : _createCommentVNode("", true)
                                  ], 2))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 256))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, null, {
                        header: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('labels.deliveryAddress')) + " ", 1),
                          _cache[12] || (_cache[12] = _createElementVNode("i", { class: "pi pi-truck ml-2" }, null, -1))
                        ]),
                        body: _withCtx(({data}) => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((data?.addresses || []), (address) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                              (address.deliveryAddress)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass([{'font-bold': _ctx.addressMatch(address)}, "flex justify-content-start"])
                                  }, [
                                    _createElementVNode("div", null, [
                                      (address.company)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(address.company), 1))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("div", null, _toDisplayString(address.street1), 1),
                                      _createElementVNode("div", null, _toDisplayString(address.street2), 1),
                                      _createElementVNode("div", null, _toDisplayString(address.zipcode) + " " + _toDisplayString(address.city), 1),
                                      _createElementVNode("div", null, _toDisplayString(_ctx.resolveCountryFromCode(address.countryCode)), 1)
                                    ]),
                                    (_ctx.addressMatch(address))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                          (_ctx.extractHouseNumberFromAddress(_ctx.shopData?.billingAddress?.street) === _ctx.extractHouseNumberFromAddress(address.street1))
                                            ? (_openBlock(), _createElementBlock("i", _hoisted_21))
                                            : (_openBlock(), _createElementBlock("i", _hoisted_22))
                                        ]))
                                      : _createCommentVNode("", true)
                                  ], 2))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 256))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, { style: {"min-width":"5rem"} }, {
                        body: _withCtx(({data}) => [
                          _createElementVNode("div", _hoisted_23, [
                            (_ctx.selectedWeclappCustomer?.id !== data.id)
                              ? _withDirectives((_openBlock(), _createElementBlock("i", {
                                  key: 0,
                                  class: "pi pi-star text-sm text-yellow-500",
                                  style: {"cursor":"pointer"},
                                  onClick: $event => (_ctx.selectedWeclappCustomer = data)
                                }, null, 8, _hoisted_24)), [
                                  [
                                    _directive_tooltip,
                                    _ctx.$t('labels.useThisCustomer'),
                                    void 0,
                                    { top: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (_ctx.selectedWeclappCustomer?.id === data.id)
                              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                  key: 1,
                                  icon: ['fas', 'star'],
                                  style: {"color":"#ffc107","cursor":"pointer"},
                                  onClick: _cache[1] || (_cache[1] = $event => (_ctx.selectedWeclappCustomer = null))
                                }))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["rowClass", "value"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_25, [
                _createVNode(_component_p_button, {
                  severity: "danger",
                  disabled: _ctx.savingInProgress,
                  onClick: _ctx.retryMatching
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('buttons.repeatComparison')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"]),
                (_ctx.selectedWeclappCustomer)
                  ? (_openBlock(), _createBlock(_component_p_button, {
                      key: 0,
                      class: "ml-3",
                      severity: "info",
                      disabled: _ctx.savingInProgress,
                      onClick: _cache[2] || (_cache[2] = $event => {_ctx.displayConfirmationDialog = true;})
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('buttons.createOrderWithSelectedCustomer')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"]))
                  : _createCommentVNode("", true),
                (_ctx.showAddButton && !_ctx.selectedWeclappCustomer)
                  ? (_openBlock(), _createBlock(_component_p_button, {
                      key: 1,
                      class: "ml-3",
                      severity: "success",
                      disabled: _ctx.savingInProgress,
                      onClick: _cache[3] || (_cache[3] = $event => {_ctx.displayConfirmationDialog = true;})
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('buttons.createOrderWithShopCustomer')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createVNode(_component_ShopDataOverview, {
                "shop-data": _ctx.shopData,
                "weclapp-form-base-data": _ctx.weclappFormBaseData,
                onShopDataSaved: _ctx.retryMatching,
                onShopwareBaseDataComputed: _ctx.handleShopwareBaseDataComputed
              }, null, 8, ["shop-data", "weclapp-form-base-data", "onShopDataSaved", "onShopwareBaseDataComputed"])
            ]),
            _createElementVNode("div", _hoisted_28, [
              _createVNode(_component_WeclappDataOverview, {
                "shop-data": _ctx.shopData,
                "selected-weclapp-customer": _ctx.selectedWeclappCustomer,
                "potential-matches": _ctx.potentialMatches,
                "shopware-form-base-data": _ctx.shopwareFormBaseData,
                onWeclappDataSaved: _ctx.retryMatching,
                onWeclappBaseDataComputed: _ctx.handleWeclappBaseDataComputed
              }, null, 8, ["shop-data", "selected-weclapp-customer", "potential-matches", "shopware-form-base-data", "onWeclappDataSaved", "onWeclappBaseDataComputed"])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_PrimeDialog, {
        header: _ctx.$t('labels.result'),
        visible: _ctx.showResultDialog,
        "onUpdate:visible": _cache[4] || (_cache[4] = $event => ((_ctx.showResultDialog) = $event)),
        breakpoints: {'960px': '75vw', '640px': '90vw'},
        style: {width: '640px'},
        modal: "",
        closable: !!_ctx.importResult?.error
      }, {
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_35, [
            _createVNode(_component_router_link, { to: {
            name: 'Import Shop Orders'
        } }, {
              default: _withCtx(() => [
                _createVNode(_component_p_button, { severity: "success" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("buttons.backToList")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.resolvedPlatform.label), 1),
            _createElementVNode("div", _hoisted_31, [
              (_ctx.importResult)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_ctx.importResult.error)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_ctx.importResult.error), 1))
                      : _createCommentVNode("", true),
                    (_ctx.importResult.id)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _cache[13] || (_cache[13] = _createElementVNode("i", { class: "pi text-green-600 pi-check-circle" }, null, -1)),
                          _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.$t('labels.salesOrder.orderNumber')) + ":", 1),
                          _createElementVNode("a", {
                            href: _ctx.importResult.url + 'webapp/view/op/order/salesOrderDetail.page?entityId=' + _ctx.importResult.id,
                            target: "_blank"
                          }, _toDisplayString(_ctx.importResult.orderNumber), 9, _hoisted_34)
                        ], 64))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }, 8, ["header", "visible", "closable"]),
      _createVNode(_component_CreateOrderOverview, {
        displayConfirmationDialog: _ctx.displayConfirmationDialog,
        "shop-data": _ctx.shopData,
        "selected-weclapp-customer": _ctx.selectedWeclappCustomer,
        onChoiceConfirmed: _ctx.onChoiceConfirmed
      }, null, 8, ["displayConfirmationDialog", "shop-data", "selected-weclapp-customer", "onChoiceConfirmed"])
    ])
  ], 64))
}