import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-fluid mb-3" }

export function render(_ctx, _cache) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AutoComplete, {
      modelValue: _ctx.chosenSupplier,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.chosenSupplier) = $event)),
      placeholder: _ctx.$t('labels.productAdd.supplier'),
      suggestions: _ctx.filteredSuppliers || [],
      onComplete: _cache[1] || (_cache[1] = $event => (_ctx.searchSuppliers($event))),
      onItemSelect: _ctx.setDropdownValue,
      dropdown: "",
      optionLabel: "label",
      optionValue: "value",
      forceSelection: "",
      class: "w-full"
    }, null, 8, ["modelValue", "placeholder", "suggestions", "onItemSelect"])
  ]))
}