import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-content-between w-full" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "grid mb-3" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = ["href"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "mr-2" }
const _hoisted_15 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_16 = {
  key: 1,
  class: "mb-3"
}
const _hoisted_17 = { class: "font-bold mr-3" }
const _hoisted_18 = { key: 2 }
const _hoisted_19 = { class: "font-bold mr-3" }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_22 = {
  key: 1,
  class: "mb-3"
}
const _hoisted_23 = { class: "font-bold mr-3" }
const _hoisted_24 = { key: 2 }
const _hoisted_25 = { class: "font-bold mr-3" }
const _hoisted_26 = { class: "grid mt-3 mb-3" }
const _hoisted_27 = { class: "col font-bold" }
const _hoisted_28 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_29 = { class: "col" }
const _hoisted_30 = {
  key: 0,
  class: "flex align-items-center"
}
const _hoisted_31 = { key: 0 }
const _hoisted_32 = {
  key: 0,
  class: "pi pi-calculator ml-2"
}
const _hoisted_33 = {
  key: 1,
  class: "pi pi-truck ml-2"
}
const _hoisted_34 = { class: "flex align-items-center" }
const _hoisted_35 = { key: 0 }
const _hoisted_36 = {
  key: 0,
  class: "pi pi-calculator ml-2"
}
const _hoisted_37 = {
  key: 1,
  class: "pi pi-truck ml-2"
}
const _hoisted_38 = { key: 1 }
const _hoisted_39 = {
  key: 0,
  class: "pi pi-calculator ml-2"
}
const _hoisted_40 = {
  key: 1,
  class: "pi pi-truck ml-2"
}
const _hoisted_41 = { class: "col flex justify-content-between" }
const _hoisted_42 = { class: "col" }
const _hoisted_43 = { key: 0 }
const _hoisted_44 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_45 = { key: 1 }
const _hoisted_46 = { class: "col" }
const _hoisted_47 = { class: "col" }
const _hoisted_48 = { key: 0 }
const _hoisted_49 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_50 = { key: 1 }
const _hoisted_51 = { class: "grid mb-1" }
const _hoisted_52 = { class: "col" }
const _hoisted_53 = { class: "col" }
const _hoisted_54 = { key: 0 }
const _hoisted_55 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_56 = { key: 1 }
const _hoisted_57 = { class: "col" }
const _hoisted_58 = { class: "col" }
const _hoisted_59 = { key: 0 }
const _hoisted_60 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_61 = { key: 1 }
const _hoisted_62 = { class: "col" }
const _hoisted_63 = { class: "col" }
const _hoisted_64 = { key: 0 }
const _hoisted_65 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_66 = { key: 1 }
const _hoisted_67 = { class: "col" }
const _hoisted_68 = { class: "col" }
const _hoisted_69 = { key: 0 }
const _hoisted_70 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_71 = { key: 1 }
const _hoisted_72 = { class: "col" }
const _hoisted_73 = { class: "col" }
const _hoisted_74 = { key: 0 }
const _hoisted_75 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_76 = { key: 1 }
const _hoisted_77 = { class: "col" }
const _hoisted_78 = { class: "col" }
const _hoisted_79 = { key: 0 }
const _hoisted_80 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_81 = { key: 1 }
const _hoisted_82 = {
  key: 1,
  class: "grid mt-3 mb-1"
}
const _hoisted_83 = { class: "col mt-1" }
const _hoisted_84 = { class: "pi pi-question-circle ml-1" }
const _hoisted_85 = { class: "col" }
const _hoisted_86 = { class: "grid mt-5 mb-3" }
const _hoisted_87 = { class: "col font-bold" }
const _hoisted_88 = { class: "col" }
const _hoisted_89 = { class: "col" }
const _hoisted_90 = { key: 0 }
const _hoisted_91 = { key: 1 }
const _hoisted_92 = { class: "col" }
const _hoisted_93 = { class: "col" }
const _hoisted_94 = { key: 0 }
const _hoisted_95 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_96 = { key: 1 }
const _hoisted_97 = { class: "col" }
const _hoisted_98 = { class: "col" }
const _hoisted_99 = { key: 0 }
const _hoisted_100 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_101 = { key: 1 }
const _hoisted_102 = { class: "grid mb-1" }
const _hoisted_103 = { class: "col" }
const _hoisted_104 = { class: "col" }
const _hoisted_105 = { class: "grid mb-1" }
const _hoisted_106 = { class: "col" }
const _hoisted_107 = { class: "col" }
const _hoisted_108 = {
  key: 2,
  class: "flex justify-content-end w-full"
}

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputText = _resolveComponent("InputText")
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")
  const _component_Tag = _resolveComponent("Tag")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_InputSwitch = _resolveComponent("InputSwitch")
  const _component_Panel = _resolveComponent("Panel")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.savingInProgress,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.savingInProgress) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, null, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[40] || (_cache[40] = _createElementVNode("div", { class: "font-bold" }, "Shop", -1)),
          (!_ctx.isEdit)
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                class: "pi pi-pencil",
                onClick: _cache[1] || (_cache[1] = $event => {_ctx.isEdit = true;}),
                style: {"cursor":"pointer"}
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("i", {
                  class: "pi pi-check text-green-600",
                  style: {"cursor":"pointer"},
                  onClick: _cache[2] || (_cache[2] = $event => (_ctx.handleSubmit(!_ctx.v$.$invalid)))
                }),
                _createElementVNode("i", {
                  class: "pi pi-times-circle text-red-600 ml-3",
                  style: {"cursor":"pointer"},
                  onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.resetForm && _ctx.resetForm(...args)))
                })
              ]))
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('labels.customerNumber')), 1),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.shopData?.customer?.customerNumber), 1)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["grid", {'mb-3': !_ctx.isEdit}])
        }, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('labels.email')), 1),
          _createElementVNode("div", _hoisted_7, [
            (!_ctx.isEdit)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                  _createTextVNode(_toDisplayString(_ctx.shopData?.customer?.email) + " ", 1),
                  (_ctx.shopData?.customer?.email)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: 'https://' + _ctx.shopData.customer.email.split('@').pop(),
                        target: "_blank"
                      }, _cache[41] || (_cache[41] = [
                        _createElementVNode("i", {
                          class: "pi pi-external-link ml-1",
                          style: {"cursor":"pointer"}
                        }, null, -1)
                      ]), 8, _hoisted_9))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_InputText, {
                    autocomplete: "off",
                    modelValue: _ctx.v$.email.$model,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$.email.$model) = $event)),
                    modelModifiers: { trim: true },
                    class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.email.$invalid && _ctx.submitted}])
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.showCopyFieldIcon(_ctx.weclappFormBaseData?.email, _ctx.v$.email.$model))
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "pi pi-wrench ml-3 text-xl",
                        style: {"cursor":"pointer"},
                        onClick: _cache[5] || (_cache[5] = $event => (_ctx.v$.email.$model = _ctx.weclappFormBaseData?.email))
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.email.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]))
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["grid", {'mb-3': !_ctx.isEdit}])
        }, [
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('labels.taxId')), 1),
          _createElementVNode("div", _hoisted_12, [
            (!_ctx.isEdit)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.shopData?.customer?.vatNumber), 1),
                  (_ctx.checkVatNumberCountryResolved)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_OverlayPanel, {
                          ref: "op",
                          class: "text-sm vat-number-overlay"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.vatNumberData)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                  (_ctx.vatNumberData.valid)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _cache[42] || (_cache[42] = _createElementVNode("i", { class: "pi text-green-600 pi-check-circle mr-2" }, null, -1)),
                                        _cache[43] || (_cache[43] = _createTextVNode("MwSt-Nummer gültig "))
                                      ], 64))
                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        _cache[44] || (_cache[44] = _createElementVNode("i", { class: "pi text-red-600 pi-times-circle mr-2" }, null, -1)),
                                        _cache[45] || (_cache[45] = _createTextVNode("MwSt-Nummer ungültig "))
                                      ], 64))
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.vatNumberData?.name)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('labels.user.displayName')), 1),
                                  _createTextVNode(_toDisplayString(_ctx.vatNumberData.name) + " ", 1),
                                  (_ctx.vatNumberData.valid && _ctx.isEdit)
                                    ? _withDirectives((_openBlock(), _createElementBlock("i", {
                                        key: 0,
                                        class: "pi pi-copy ml-2",
                                        style: {"cursor":"pointer"},
                                        onClick: _cache[6] || (_cache[6] = $event => (_ctx.copyToClipboard(_ctx.vatNumberData.name, $event)))
                                      }, null, 512)), [
                                        [
                                          _directive_tooltip,
                                          _ctx.$t('labels.copyToClipboard'),
                                          void 0,
                                          { left: true }
                                        ]
                                      ])
                                    : _createCommentVNode("", true)
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.vatNumberData?.address)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                  _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('labels.address')), 1),
                                  _createTextVNode(_toDisplayString(_ctx.vatNumberData.address), 1),
                                  (_ctx.vatNumberData.valid && _ctx.isEdit)
                                    ? _withDirectives((_openBlock(), _createElementBlock("i", {
                                        key: 0,
                                        class: "pi pi-copy ml-2",
                                        style: {"cursor":"pointer"},
                                        onClick: _cache[7] || (_cache[7] = $event => (_ctx.copyToClipboard(_ctx.vatNumberData.address, $event)))
                                      }, null, 512)), [
                                        [
                                          _directive_tooltip,
                                          _ctx.$t('labels.copyToClipboard'),
                                          void 0,
                                          { left: true }
                                        ]
                                      ])
                                    : _createCommentVNode("", true)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 512),
                        _createElementVNode("i", {
                          class: "pi pi-info-circle text-xl text-green-600",
                          style: {"cursor":"pointer"},
                          onClick: _cache[8] || (_cache[8] = (...args) => (_ctx.toggleOverlay && _ctx.toggleOverlay(...args)))
                        })
                      ], 64))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createVNode(_component_InputText, {
                    autocomplete: "off",
                    class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.vatNumber.$invalid && _ctx.submitted}]),
                    modelValue: _ctx.v$.vatNumber.$model,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$.vatNumber.$model) = $event)),
                    modelModifiers: { trim: true }
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.checkVatNumberCountryResolved)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_OverlayPanel, {
                          ref: "op",
                          class: "text-sm vat-number-overlay"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.vatNumberData)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                  (_ctx.vatNumberData.valid)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _cache[46] || (_cache[46] = _createElementVNode("i", { class: "pi text-green-600 pi-check-circle mr-2" }, null, -1)),
                                        _cache[47] || (_cache[47] = _createTextVNode("MwSt-Nummer gültig "))
                                      ], 64))
                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        _cache[48] || (_cache[48] = _createElementVNode("i", { class: "pi text-red-600 pi-times-circle mr-2" }, null, -1)),
                                        _cache[49] || (_cache[49] = _createTextVNode("MwSt-Nummer ungültig "))
                                      ], 64))
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.vatNumberData?.name)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                  _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t('labels.user.displayName')), 1),
                                  _createTextVNode(_toDisplayString(_ctx.vatNumberData.name) + " ", 1),
                                  (_ctx.vatNumberData.valid && _ctx.isEdit)
                                    ? _withDirectives((_openBlock(), _createElementBlock("i", {
                                        key: 0,
                                        class: "pi pi-copy ml-2",
                                        style: {"cursor":"pointer"},
                                        onClick: _cache[10] || (_cache[10] = $event => (_ctx.copyToClipboard(_ctx.vatNumberData.name, $event)))
                                      }, null, 512)), [
                                        [
                                          _directive_tooltip,
                                          _ctx.$t('labels.copyToClipboard'),
                                          void 0,
                                          { left: true }
                                        ]
                                      ])
                                    : _createCommentVNode("", true)
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.vatNumberData?.address)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                  _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t('labels.address')), 1),
                                  _createTextVNode(_toDisplayString(_ctx.vatNumberData.address), 1),
                                  (_ctx.vatNumberData.valid && _ctx.isEdit)
                                    ? _withDirectives((_openBlock(), _createElementBlock("i", {
                                        key: 0,
                                        class: "pi pi-copy ml-2",
                                        style: {"cursor":"pointer"},
                                        onClick: _cache[11] || (_cache[11] = $event => (_ctx.copyToClipboard(_ctx.vatNumberData.address, $event)))
                                      }, null, 512)), [
                                        [
                                          _directive_tooltip,
                                          _ctx.$t('labels.copyToClipboard'),
                                          void 0,
                                          { left: true }
                                        ]
                                      ])
                                    : _createCommentVNode("", true)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 512),
                        _createElementVNode("i", {
                          class: "pi pi-info-circle text-xl text-green-600 ml-3",
                          style: {"cursor":"pointer"},
                          onClick: _cache[12] || (_cache[12] = (...args) => (_ctx.toggleOverlay && _ctx.toggleOverlay(...args)))
                        })
                      ], 64))
                    : _createCommentVNode("", true),
                  (_ctx.showCopyFieldIcon(_ctx.weclappFormBaseData?.taxId, _ctx.v$.vatNumber.$model))
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 1,
                        class: "pi pi-wrench ml-3 text-xl",
                        style: {"cursor":"pointer"},
                        onClick: _cache[13] || (_cache[13] = $event => (_ctx.v$.vatNumber.$model = _ctx.weclappFormBaseData?.taxId))
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.v$.vatNumber.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]))
          ])
        ], 2),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createTextVNode(_toDisplayString(_ctx.$t('labels.address')) + " ", 1),
            (!_ctx.shopData?.hasSameShippingAddress && _ctx.shopData?.shippingAddress)
              ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                  _createVNode(_component_Tag, { severity: "danger" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.alternativeDeliveryAddress')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_29, [
            (!_ctx.isEdit && (_ctx.shopData && !_ctx.shopData.hasSameShippingAddress))
              ? (_openBlock(), _createBlock(_component_p_dropdown, {
                  key: 0,
                  class: "w-full",
                  onChange: _ctx.resetForm,
                  modelValue: _ctx.selectedAddressType,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.selectedAddressType) = $event)),
                  optionValue: "type",
                  options: [{type: 'billing', ..._ctx.shopData?.billingAddress}, {type: 'shipping', ..._ctx.shopData?.shippingAddress} ]
                }, {
                  value: _withCtx((slotProps) => [
                    (slotProps.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                          _createElementVNode("div", null, [
                            _createTextVNode(_toDisplayString(_ctx.selectedShopAddress?.street), 1),
                            (_ctx.selectedShopAddress?.additionalAddressLine1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_31, ", " + _toDisplayString(_ctx.selectedShopAddress?.additionalAddressLine1), 1))
                              : _createCommentVNode("", true),
                            _createTextVNode(", " + _toDisplayString(_ctx.selectedShopAddress?.zipcode) + ", " + _toDisplayString(_ctx.selectedShopAddress?.city) + ", " + _toDisplayString(_ctx.resolveCountryFromCode(_ctx.selectedShopAddress?.country)), 1)
                          ]),
                          (_ctx.selectedAddressType === 'billing')
                            ? (_openBlock(), _createElementBlock("i", _hoisted_32))
                            : _createCommentVNode("", true),
                          (_ctx.selectedAddressType === 'shipping')
                            ? (_openBlock(), _createElementBlock("i", _hoisted_33))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  option: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_34, [
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(slotProps.option.street), 1),
                        (slotProps.option.additionalAddressLine1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_35, ", " + _toDisplayString(slotProps.option.additionalAddressLine1), 1))
                          : _createCommentVNode("", true),
                        _createTextVNode(", " + _toDisplayString(slotProps.option.zipcode) + ", " + _toDisplayString(slotProps.option.city) + ", " + _toDisplayString(_ctx.resolveCountryFromCode(slotProps.option.country)), 1)
                      ]),
                      (slotProps.option.type === 'billing')
                        ? (_openBlock(), _createElementBlock("i", _hoisted_36))
                        : _createCommentVNode("", true),
                      (slotProps.option.type === 'shipping')
                        ? (_openBlock(), _createElementBlock("i", _hoisted_37))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }, 8, ["onChange", "modelValue", "options"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_38, [
                  (_ctx.shopData?.billingAddress && (!_ctx.isEdit || _ctx.selectedAddressType === 'billing'))
                    ? (_openBlock(), _createElementBlock("i", _hoisted_39))
                    : _createCommentVNode("", true),
                  (_ctx.shopData?.hasSameShippingAddress || (_ctx.isEdit && _ctx.selectedAddressType === 'shipping'))
                    ? (_openBlock(), _createElementBlock("i", _hoisted_40))
                    : _createCommentVNode("", true)
                ]))
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
        }, [
          _createElementVNode("div", _hoisted_41, [
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('labels.company')), 1),
            (_ctx.isEdit && _ctx.v$?.company?.$model && !_ctx.v$?.department?.$model && _ctx.v$?.firstName?.$model && _ctx.v$?.lastName?.$model)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "pi pi-user-plus text-xl",
                  style: {"cursor":"pointer","margin-top":"8px","margin-right":"-15px"},
                  onClick: _cache[15] || (_cache[15] = $event => {_ctx.v$.department.$model = _ctx.v$.company.$model; _ctx.v$.company.$model = (_ctx.selectedShopAddress?.salutation + ' ' + _ctx.v$.firstName.$model + ' ' + _ctx.v$.lastName.$model).trim()})
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_42, [
            (!_ctx.isEdit)
              ? (_openBlock(), _createElementBlock("span", _hoisted_43, [
                  _createTextVNode(_toDisplayString(_ctx.selectedShopAddress?.company) + " ", 1),
                  ((_ctx.selectedShopAddress?.company || _ctx.resolveSelectShopAddressPropertyValue('company')) && _ctx.selectedShopAddress?.company !== _ctx.resolveSelectShopAddressPropertyValue('company'))
                    ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_44, null, 512)), [
                        [
                          _directive_tooltip,
                          _ctx.$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: _ctx.selectedShopAddress?.company, customer: _ctx.resolveSelectShopAddressPropertyValue('company') }),
                          void 0,
                          { top: true }
                        ]
                      ])
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_45, [
                  _createVNode(_component_InputText, {
                    autocomplete: "off",
                    class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.company.$invalid && _ctx.submitted}]),
                    modelValue: _ctx.v$.company.$model,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => ((_ctx.v$.company.$model) = $event)),
                    modelModifiers: { trim: true }
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.showCopyFieldIcon(_ctx.weclappFormBaseData?.company, _ctx.v$.company.$model))
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "pi pi-wrench ml-3 text-xl",
                        style: {"cursor":"pointer"},
                        onClick: _cache[17] || (_cache[17] = $event => (_ctx.v$.company.$model = _ctx.weclappFormBaseData?.company))
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.company.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]))
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
        }, [
          _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.$t('labels.company2')), 1),
          _createElementVNode("div", _hoisted_47, [
            (!_ctx.isEdit)
              ? (_openBlock(), _createElementBlock("span", _hoisted_48, [
                  _createTextVNode(_toDisplayString(_ctx.selectedShopAddress?.department), 1),
                  ((_ctx.selectedShopAddress?.department || _ctx.resolveSelectShopAddressPropertyValue('department')) && _ctx.selectedShopAddress?.department !== _ctx.resolveSelectShopAddressPropertyValue('department'))
                    ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_49, null, 512)), [
                        [
                          _directive_tooltip,
                          _ctx.$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: _ctx.selectedShopAddress?.department, customer: _ctx.resolveSelectShopAddressPropertyValue('department') }),
                          void 0,
                          { top: true }
                        ]
                      ])
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_50, [
                  _createVNode(_component_InputText, {
                    autocomplete: "off",
                    class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.department.$invalid && _ctx.submitted}]),
                    modelValue: _ctx.v$.department.$model,
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => ((_ctx.v$.department.$model) = $event)),
                    modelModifiers: { trim: true }
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.showCopyFieldIcon(_ctx.weclappFormBaseData?.department, _ctx.v$.department.$model))
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "pi pi-wrench ml-3 text-xl",
                        style: {"cursor":"pointer"},
                        onClick: _cache[19] || (_cache[19] = $event => (_ctx.v$.department.$model = _ctx.weclappFormBaseData?.department))
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.department.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]))
          ])
        ], 2),
        _createElementVNode("div", _hoisted_51, [
          _createElementVNode("div", _hoisted_52, _toDisplayString(_ctx.$t('labels.street')), 1),
          _createElementVNode("div", _hoisted_53, [
            (!_ctx.isEdit)
              ? (_openBlock(), _createElementBlock("span", _hoisted_54, [
                  _createTextVNode(_toDisplayString(_ctx.selectedShopAddress?.street), 1),
                  ((_ctx.selectedShopAddress?.street || _ctx.resolveSelectShopAddressPropertyValue('street')) && _ctx.selectedShopAddress?.street !== _ctx.resolveSelectShopAddressPropertyValue('street'))
                    ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_55, null, 512)), [
                        [
                          _directive_tooltip,
                          _ctx.$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: _ctx.selectedShopAddress?.street, customer: _ctx.resolveSelectShopAddressPropertyValue('street') }),
                          void 0,
                          { top: true }
                        ]
                      ])
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_56, [
                  _createVNode(_component_InputText, {
                    autocomplete: "off",
                    class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.street.$invalid && _ctx.submitted}]),
                    modelValue: _ctx.v$.street.$model,
                    "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => ((_ctx.v$.street.$model) = $event)),
                    modelModifiers: { trim: true }
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.showCopyFieldIcon(_ctx.weclappFormBaseData?.street, _ctx.v$.street.$model))
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "pi pi-wrench ml-3 text-xl",
                        style: {"cursor":"pointer"},
                        onClick: _cache[21] || (_cache[21] = $event => (_ctx.v$.street.$model = _ctx.weclappFormBaseData?.street))
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.street.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]))
          ])
        ]),
        (_ctx.isEdit || _ctx.selectedShopAddress?.additionalAddressLine1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
            }, [
              _createElementVNode("div", _hoisted_57, _toDisplayString(_ctx.$t('labels.street2')), 1),
              _createElementVNode("div", _hoisted_58, [
                (!_ctx.isEdit)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_59, [
                      _createTextVNode(_toDisplayString(_ctx.selectedShopAddress?.additionalAddressLine1), 1),
                      ((_ctx.selectedShopAddress?.additionalAddressLine1 || _ctx.resolveSelectShopAddressPropertyValue('additionalAddressLine1')) && _ctx.selectedShopAddress?.additionalAddressLine1 !== _ctx.resolveSelectShopAddressPropertyValue('additionalAddressLine1'))
                        ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_60, null, 512)), [
                            [
                              _directive_tooltip,
                              _ctx.$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: _ctx.selectedShopAddress?.additionalAddressLine1, customer: _ctx.resolveSelectShopAddressPropertyValue('additionalAddressLine1') }),
                              void 0,
                              { top: true }
                            ]
                          ])
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_61, [
                      _createVNode(_component_InputText, {
                        autocomplete: "off",
                        class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.street2.$invalid && _ctx.submitted}]),
                        modelValue: _ctx.v$.street2.$model,
                        "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => ((_ctx.v$.street2.$model) = $event)),
                        modelModifiers: { trim: true }
                      }, null, 8, ["modelValue", "class"]),
                      (_ctx.showCopyFieldIcon(_ctx.weclappFormBaseData?.street2, _ctx.v$.street2.$model))
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: "pi pi-wrench ml-3 text-xl",
                            style: {"cursor":"pointer"},
                            onClick: _cache[23] || (_cache[23] = $event => (_ctx.v$.street2.$model = _ctx.weclappFormBaseData?.street2))
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.submitted)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.street2.$silentErrors, (error) => {
                            return (_openBlock(), _createElementBlock("small", {
                              style: {"display":"block"},
                              class: "p-error",
                              key: error
                            }, _toDisplayString(error.$message), 1))
                          }), 128))
                        : _createCommentVNode("", true)
                    ]))
              ])
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
        }, [
          _createElementVNode("div", _hoisted_62, _toDisplayString(_ctx.$t('labels.zipCode')), 1),
          _createElementVNode("div", _hoisted_63, [
            (!_ctx.isEdit)
              ? (_openBlock(), _createElementBlock("span", _hoisted_64, [
                  _createTextVNode(_toDisplayString(_ctx.selectedShopAddress?.zipcode), 1),
                  ((_ctx.selectedShopAddress?.zipcode || _ctx.resolveSelectShopAddressPropertyValue('zipcode')) && _ctx.selectedShopAddress?.zipcode !== _ctx.resolveSelectShopAddressPropertyValue('zipcode'))
                    ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_65, null, 512)), [
                        [
                          _directive_tooltip,
                          _ctx.$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: _ctx.selectedShopAddress?.zipcode, customer: _ctx.resolveSelectShopAddressPropertyValue('zipcode') }),
                          void 0,
                          { top: true }
                        ]
                      ])
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_66, [
                  _createVNode(_component_InputText, {
                    autocomplete: "off",
                    class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.zipcode.$invalid && _ctx.submitted}]),
                    modelValue: _ctx.v$.zipcode.$model,
                    "onUpdate:modelValue": _cache[24] || (_cache[24] = $event => ((_ctx.v$.zipcode.$model) = $event)),
                    modelModifiers: { trim: true }
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.showCopyFieldIcon(_ctx.weclappFormBaseData?.zipcode, _ctx.v$.zipcode.$model))
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "pi pi-wrench ml-3 text-xl",
                        style: {"cursor":"pointer"},
                        onClick: _cache[25] || (_cache[25] = $event => (_ctx.v$.zipcode.$model = _ctx.weclappFormBaseData?.zipcode))
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.zipcode.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]))
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
        }, [
          _createElementVNode("div", _hoisted_67, _toDisplayString(_ctx.$t('labels.city')), 1),
          _createElementVNode("div", _hoisted_68, [
            (!_ctx.isEdit)
              ? (_openBlock(), _createElementBlock("span", _hoisted_69, [
                  _createTextVNode(_toDisplayString(_ctx.selectedShopAddress?.city), 1),
                  ((_ctx.selectedShopAddress?.city || _ctx.resolveSelectShopAddressPropertyValue('city')) && _ctx.selectedShopAddress?.city !== _ctx.resolveSelectShopAddressPropertyValue('city'))
                    ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_70, null, 512)), [
                        [
                          _directive_tooltip,
                          _ctx.$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: _ctx.selectedShopAddress?.city, customer: _ctx.resolveSelectShopAddressPropertyValue('city') }),
                          void 0,
                          { top: true }
                        ]
                      ])
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_71, [
                  _createVNode(_component_InputText, {
                    autocomplete: "off",
                    class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.city.$invalid && _ctx.submitted}]),
                    modelValue: _ctx.v$.city.$model,
                    "onUpdate:modelValue": _cache[26] || (_cache[26] = $event => ((_ctx.v$.city.$model) = $event)),
                    modelModifiers: { trim: true }
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.showCopyFieldIcon(_ctx.weclappFormBaseData?.city, _ctx.v$.city.$model))
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "pi pi-wrench ml-3 text-xl",
                        style: {"cursor":"pointer"},
                        onClick: _cache[27] || (_cache[27] = $event => (_ctx.v$.city.$model = _ctx.weclappFormBaseData?.city))
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.city.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]))
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["grid", {'mb-3': true}])
        }, [
          _createElementVNode("div", _hoisted_72, _toDisplayString(_ctx.$t('labels.country')), 1),
          _createElementVNode("div", _hoisted_73, [
            (!_ctx.isEdit)
              ? (_openBlock(), _createElementBlock("span", _hoisted_74, [
                  _createTextVNode(_toDisplayString(_ctx.resolveCountryFromCode(_ctx.selectedShopAddress?.country)), 1),
                  ((_ctx.selectedShopAddress?.country || _ctx.resolveSelectShopAddressPropertyValue('country')) && _ctx.selectedShopAddress?.country !== _ctx.resolveSelectShopAddressPropertyValue('country'))
                    ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_75, null, 512)), [
                        [
                          _directive_tooltip,
                          _ctx.$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: _ctx.selectedShopAddress?.country, customer: _ctx.resolveSelectShopAddressPropertyValue('country') }),
                          void 0,
                          { top: true }
                        ]
                      ])
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_76, [
                  _createVNode(_component_p_dropdown, {
                    class: _normalizeClass(["text-sm w-7", {'p-invalid':_ctx.v$.country.$invalid && _ctx.submitted}]),
                    modelValue: _ctx.v$.country.$model,
                    "onUpdate:modelValue": _cache[28] || (_cache[28] = $event => ((_ctx.v$.country.$model) = $event)),
                    optionLabel: "label",
                    optionValue: "value",
                    options: _ctx.countries
                  }, null, 8, ["modelValue", "class", "options"]),
                  (_ctx.showCopyFieldIcon(_ctx.weclappFormBaseData?.country, _ctx.v$.country.$model))
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "pi pi-wrench ml-3 text-xl",
                        style: {"cursor":"pointer"},
                        onClick: _cache[29] || (_cache[29] = $event => (_ctx.v$.country.$model = _ctx.weclappFormBaseData?.country))
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.country.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]))
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["grid", {'mb-5': !_ctx.isEdit}])
        }, [
          _createElementVNode("div", _hoisted_77, _toDisplayString(_ctx.$t('labels.phoneNumber')), 1),
          _createElementVNode("div", _hoisted_78, [
            (!_ctx.isEdit)
              ? (_openBlock(), _createElementBlock("span", _hoisted_79, [
                  _createTextVNode(_toDisplayString(_ctx.selectedShopAddress?.phoneNumber), 1),
                  ((_ctx.selectedShopAddress?.phoneNumber || _ctx.resolveSelectShopAddressPropertyValue('phoneNumber')) && _ctx.selectedShopAddress?.phoneNumber !== _ctx.resolveSelectShopAddressPropertyValue('phoneNumber'))
                    ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_80, null, 512)), [
                        [
                          _directive_tooltip,
                          _ctx.$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: _ctx.selectedShopAddress?.phoneNumber, customer: _ctx.resolveSelectShopAddressPropertyValue('phoneNumber') }),
                          void 0,
                          { top: true }
                        ]
                      ])
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_81, [
                  _createVNode(_component_InputText, {
                    autocomplete: "off",
                    class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.phoneNumber.$invalid && _ctx.submitted}]),
                    modelValue: _ctx.v$.phoneNumber.$model,
                    "onUpdate:modelValue": _cache[30] || (_cache[30] = $event => ((_ctx.v$.phoneNumber.$model) = $event)),
                    modelModifiers: { trim: true }
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.showCopyFieldIcon(_ctx.weclappFormBaseData?.phoneNumber, _ctx.v$.phoneNumber.$model))
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "pi pi-wrench ml-3 text-xl",
                        style: {"cursor":"pointer"},
                        onClick: _cache[31] || (_cache[31] = $event => (_ctx.v$.phoneNumber.$model = _ctx.weclappFormBaseData?.phoneNumber))
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.phoneNumber.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]))
          ])
        ], 2),
        (_ctx.isEdit && _ctx.v$?.$anyDirty)
          ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
              _createElementVNode("div", _hoisted_83, [
                _createTextVNode(_toDisplayString(_ctx.$t('messages.saveCustomerDataAsWellTooltip')), 1),
                _withDirectives(_createElementVNode("span", _hoisted_84, null, 512), [
                  [
                    _directive_tooltip,
                    _ctx.$t(_ctx.selectedAddressType === 'billing' ? (_ctx.shopData?.hasSameShippingAddress ? 'messages.changesWillBeSavedinCustomerDefaultAddresses' : 'messages.changesWillBeSavedinCustomerDefaultBillingAddress') : 'messages.changesWillBeSavedinCustomerDefaultShippingAddress'),
                    void 0,
                    { top: true }
                  ]
                ])
              ]),
              _createElementVNode("div", _hoisted_85, [
                _createVNode(_component_InputSwitch, {
                  class: "text-sm",
                  modelValue: _ctx.v$.saveDataInCustomer.$model,
                  "onUpdate:modelValue": _cache[32] || (_cache[32] = $event => ((_ctx.v$.saveDataInCustomer.$model) = $event))
                }, null, 8, ["modelValue"])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_86, [
          _createElementVNode("div", _hoisted_87, _toDisplayString(_ctx.$t('labels.contactPersonWcp')), 1)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
        }, [
          _createElementVNode("div", _hoisted_88, _toDisplayString(_ctx.$t('labels.salutation')), 1),
          _createElementVNode("div", _hoisted_89, [
            (!_ctx.isEdit)
              ? (_openBlock(), _createElementBlock("span", _hoisted_90, _toDisplayString(_ctx.selectedShopAddress?.salutation), 1))
              : (_openBlock(), _createElementBlock("div", _hoisted_91, [
                  _createVNode(_component_p_dropdown, {
                    class: "w-7",
                    modelValue: _ctx.v$.salutation.$model,
                    "onUpdate:modelValue": _cache[33] || (_cache[33] = $event => ((_ctx.v$.salutation.$model) = $event)),
                    optionLabel: "displayName",
                    optionValue: "id",
                    options: _ctx.salutationOptions
                  }, null, 8, ["modelValue", "options"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.salutation.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]))
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
        }, [
          _createElementVNode("div", _hoisted_92, _toDisplayString(_ctx.$t('labels.firstName')), 1),
          _createElementVNode("div", _hoisted_93, [
            (!_ctx.isEdit)
              ? (_openBlock(), _createElementBlock("span", _hoisted_94, [
                  _createTextVNode(_toDisplayString(_ctx.selectedShopAddress?.firstName), 1),
                  ((_ctx.selectedShopAddress?.firstName || _ctx.resolveSelectShopAddressPropertyValue('firstName')) && _ctx.selectedShopAddress?.firstName !== _ctx.resolveSelectShopAddressPropertyValue('firstName'))
                    ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_95, null, 512)), [
                        [
                          _directive_tooltip,
                          _ctx.$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: _ctx.selectedShopAddress?.firstName, customer: _ctx.resolveSelectShopAddressPropertyValue('firstName') }),
                          void 0,
                          { top: true }
                        ]
                      ])
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_96, [
                  _createVNode(_component_InputText, {
                    autocomplete: "off",
                    class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.firstName.$invalid && _ctx.submitted}]),
                    modelValue: _ctx.v$.firstName.$model,
                    "onUpdate:modelValue": _cache[34] || (_cache[34] = $event => ((_ctx.v$.firstName.$model) = $event)),
                    modelModifiers: { trim: true }
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.showCopyFieldIcon(_ctx.weclappFormBaseData?.firstName, _ctx.v$.firstName.$model))
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "pi pi-wrench ml-3 text-xl",
                        style: {"cursor":"pointer"},
                        onClick: _cache[35] || (_cache[35] = $event => (_ctx.v$.firstName.$model = _ctx.weclappFormBaseData?.firstName))
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.firstName.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]))
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["grid", {'mb-1': !_ctx.isEdit}])
        }, [
          _createElementVNode("div", _hoisted_97, _toDisplayString(_ctx.$t('labels.lastName')), 1),
          _createElementVNode("div", _hoisted_98, [
            (!_ctx.isEdit)
              ? (_openBlock(), _createElementBlock("span", _hoisted_99, [
                  _createTextVNode(_toDisplayString(_ctx.selectedShopAddress?.lastName), 1),
                  ((_ctx.selectedShopAddress?.lastName || _ctx.resolveSelectShopAddressPropertyValue('lastName')) && _ctx.selectedShopAddress?.lastName !== _ctx.resolveSelectShopAddressPropertyValue('lastName'))
                    ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_100, null, 512)), [
                        [
                          _directive_tooltip,
                          _ctx.$t('messages.shopAddressFieldDifferBetweenOrderAndCustomer', {order: _ctx.selectedShopAddress?.lastName, customer: _ctx.resolveSelectShopAddressPropertyValue('lastName') }),
                          void 0,
                          { top: true }
                        ]
                      ])
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_101, [
                  _createVNode(_component_InputText, {
                    autocomplete: "off",
                    class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.lastName.$invalid && _ctx.submitted}]),
                    modelValue: _ctx.v$.lastName.$model,
                    "onUpdate:modelValue": _cache[36] || (_cache[36] = $event => ((_ctx.v$.lastName.$model) = $event)),
                    modelModifiers: { trim: true }
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.showCopyFieldIcon(_ctx.weclappFormBaseData?.lastName, _ctx.v$.lastName.$model))
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "pi pi-wrench ml-3 text-xl",
                        style: {"cursor":"pointer"},
                        onClick: _cache[37] || (_cache[37] = $event => (_ctx.v$.lastName.$model = _ctx.weclappFormBaseData?.lastName))
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.v$.lastName.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]))
          ])
        ], 2),
        _createElementVNode("div", _hoisted_102, [
          _createElementVNode("div", _hoisted_103, _toDisplayString(_ctx.$t('labels.email')), 1),
          _createElementVNode("div", _hoisted_104, _toDisplayString(_ctx.shopData?.customer?.email), 1)
        ]),
        _createElementVNode("div", _hoisted_105, [
          _createElementVNode("div", _hoisted_106, _toDisplayString(_ctx.$t('labels.phoneNumber')), 1),
          _createElementVNode("div", _hoisted_107, _toDisplayString(_ctx.selectedShopAddress?.phoneNumber), 1)
        ]),
        (_ctx.isEdit)
          ? (_openBlock(), _createElementBlock("div", _hoisted_108, [
              _createElementVNode("div", null, [
                _createElementVNode("i", {
                  class: "pi pi-check text-green-600",
                  style: {"cursor":"pointer"},
                  onClick: _cache[38] || (_cache[38] = $event => (_ctx.handleSubmit(!_ctx.v$.$invalid)))
                }),
                _createElementVNode("i", {
                  class: "pi pi-times-circle text-red-600 ml-3",
                  style: {"cursor":"pointer"},
                  onClick: _cache[39] || (_cache[39] = (...args) => (_ctx.resetForm && _ctx.resetForm(...args)))
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}