import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      id: "platformIcon",
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.toggleDataTable && _ctx.toggleDataTable(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.platforms, (platformItem) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (_ctx.platform === platformItem.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'anchor'] }),
                _createTextVNode("   " + _toDisplayString(platformItem.label), 1)
              ], 64))
            : _createCommentVNode("", true)
        ], 64))
      }), 256))
    ]),
    _createVNode(_component_OverlayPanel, {
      ref: "opPlatforms",
      appendTo: "body",
      id: "overlay_panel_platforms"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          value: _ctx.platforms,
          rows: 5,
          responsiveLayout: "scroll"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, { headerStyle: "display:none;" }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", {
                  style: {"cursor":"pointer"},
                  onClick: $event => (_ctx.changePlatform(data))
                }, _toDisplayString(data.label), 9, _hoisted_1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"])
      ]),
      _: 1
    }, 512)
  ], 64))
}