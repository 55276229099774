import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mb-3 p-fluid" }
const _hoisted_2 = { class: "mt-2" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "flex justify-content-end" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_TreeSelect = _resolveComponent("TreeSelect")
  const _component_p_button = _resolveComponent("p-button")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.isLoading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.isLoading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.workflows.title')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t("labels.chooseWizardWorkflow")), 1),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_TreeSelect, {
              modelValue: _ctx.identifier,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.identifier) = $event)),
              options: _ctx.workflowWizardOptions,
              selectionMode: "single",
              placeholder: _ctx.$t('labels.none'),
              onChange: _ctx.onChange
            }, null, 8, ["modelValue", "options", "placeholder", "onChange"])
          ])
        ]),
        (_ctx.identifier && _ctx.componentName)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.comp), {
              key: 0,
              onDataEntered: _ctx.onRequiredDataEntered
            }, null, 40, ["onDataEntered"]))
          : _createCommentVNode("", true),
        (_ctx.identifier)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_p_button, {
                  onClick: _ctx.runWorkflow,
                  disabled: !_ctx.startButtonEnabled
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("buttons.runWorkflow")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["header"])
  ], 64))
}