import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import LoadingPlugin from 'vue-loading-overlay';
import Tag from 'primevue/tag';
import {computed, ref, watch} from 'vue';
import {DateTime} from 'luxon';
import {i18n} from '@/utils/i18n';
import {
    calculateFinalPrice,
    isCurrentlyActivePrice,
    isFutureTimeLimitedPrice,
    resolvePriceInDefaultCurrency
} from '@/utils/helpers';
import ReductionAddition from '@/components/reduction-addition/reduction-addition.vue';
import ProductPriceDialog from '@/components/dialog/product-prices/add-edit-product-price.vue';
import ScaledProductPriceDialog from '@/components/dialog/product-prices/add-scaled-product-price.vue';
import {PriceType} from '@/utils/enums';
import {add, edit, remove} from '@/services/product-prices';
import {useToast} from 'vue-toastification';
import ToggleButton from 'primevue/togglebutton';
import InputSwitch from 'primevue/inputswitch';
import {useConfirm} from 'primevue/useconfirm';
import store from '@/store';
import {ILayoutConfig} from '@/interfaces/layout/config';
import InputNumber from 'primevue/inputnumber';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import {updateSupplySource} from '@/services/supply-sources';
import {getProfile} from '@/services/auth';

export default {
    emits: ['reload-product'],
    components: {
        DataTable,
        Column,
        'p-button': Button,
        'p-checkbox': Checkbox,
        loading: LoadingPlugin,
        Tag,
        ReductionAddition,
        ProductPriceDialog,
        ScaledProductPriceDialog,
        ToggleButton,
        InputSwitch,
        InputNumber,
        'p-calendar': Calendar
    },
    props: {
        productDetails: {
            type: Object,
            default: null
        },
        exchangeRates: {
            type: Object,
            default: null
        }
    },
    setup(props: any, context: any) {
        const loading = ref(false);
        const productDetails = ref(props.productDetails);
        const exchangeRates = ref(props.exchangeRates);
        const showProductPriceDialog = ref(false);
        const showScaledPriceDialog = ref(false);
        const dialogPriceType = ref(PriceType.CostPrice);
        const toast = useToast();
        const showCostPricesInThePast = ref(false);
        const showSellPricesInThePast = ref(false);
        const showListPricesInThePast = ref(false);
        const selectedSupplySource = ref(null);
        const originalPrice = ref(null);
        const confirm = useConfirm();
        const editingRows = ref([]);

        watch(
            () => props.productDetails,
            (val) => {
                productDetails.value = val;
                if (
                    (productDetails.value?.weclapp?.articleSupplySources || [])
                        .length > 0
                ) {
                    selectedSupplySource.value =
                        productDetails.value.weclapp.articleSupplySources[0];
                }
            }
        );

        watch(
            () => props.exchangeRates,
            (val) => {
                exchangeRates.value = val;
            }
        );

        const mappedSupplySource = computed(() => {
            const selectedIndex =
                selectedSupplySource.value?.positionNumber || 1;

            return (
                productDetails.value?.weclapp?.articleSupplySources || []
            ).find((source: any) => source.positionNumber === selectedIndex);
        });

        const productCostPrices = computed(() => {
            return (mappedSupplySource.value?.articlePrices || [])
                .map((item: any) => {
                    return {
                        ...item,
                        startDateFormatted: item.startDate
                            ? DateTime.fromSeconds(item.startDate / 1000)
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString({
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit'
                                  })
                            : '',
                        endDateFormatted: item.endDate
                            ? DateTime.fromSeconds(item.endDate / 1000)
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString({
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit'
                                  })
                            : '',
                        priceFormatted: formatPrice(
                            item.currencyName,
                            item.price
                        ),
                        discountedPriceFormatted: calculateFinalPrice(
                            item,
                            true
                        )
                    };
                })
                .filter((item: any) => {
                    return (
                        !item.endDate ||
                        showCostPricesInThePast.value ||
                        new Date().getTime() < item.endDate
                    );
                })
                .sort((a: any, b: any) => (a.startDate > b.startDate ? -1 : 1));
        });

        const productSellPrices = computed(() => {
            return (productDetails.value?.weclapp?.articlePrices || [])
                .map((item: any) => {
                    return {
                        ...item,
                        startDateFormatted: item.startDate
                            ? DateTime.fromSeconds(item.startDate / 1000)
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString({
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit'
                                  })
                            : '',
                        endDateFormatted: item.endDate
                            ? DateTime.fromSeconds(item.endDate / 1000)
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString({
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit'
                                  })
                            : '',
                        priceFormatted: formatPrice(
                            item.currencyName,
                            item.price
                        ),
                        discountedPriceFormatted: calculateFinalPrice(
                            item,
                            true
                        )
                    };
                })
                .filter((item: any) => {
                    return (
                        !item.endDate ||
                        showSellPricesInThePast.value ||
                        new Date().getTime() < item.endDate
                    );
                })
                .sort((a: any, b: any) => (a.startDate > b.startDate ? -1 : 1));
        });

        const productListPrices = computed(() => {
            return (
                productDetails.value?.weclapp?.articleCalculationPrices || []
            )
                .map((item: any) => {
                    return {
                        ...item,
                        startDateFormatted: item.startDate
                            ? DateTime.fromSeconds(item.startDate / 1000)
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString({
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit'
                                  })
                            : '',
                        endDateFormatted: item.endDate
                            ? DateTime.fromSeconds(item.endDate / 1000)
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString({
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit'
                                  })
                            : '',
                        priceFormatted: calculateFinalPrice(item, true)
                    };
                })
                .filter((item: any) => {
                    return (
                        !item.endDate ||
                        showListPricesInThePast.value ||
                        new Date().getTime() < item.endDate
                    );
                })
                .sort((a: any, b: any) => (a.startDate > b.startDate ? -1 : 1));
        });

        const articleSupplySources = computed(() => {
            return (
                productDetails.value?.weclapp?.articleSupplySources || []
            ).map((item: any) => {
                const price = (item.articlePrices || []).find((pr: any) =>
                    isCurrentlyActivePrice(pr)
                );

                return {
                    ...item,
                    finalPrice: price
                        ? calculateFinalPrice(price, false)
                        : null,
                    finalPriceInDefaultCurrency:
                        price && price.currencyName !== 'EUR'
                            ? resolvePriceInDefaultCurrency(
                                  calculateFinalPrice(price, false),
                                  exchangeRates.value[price.currencyName]
                              )
                            : null,
                    finalPriceIncludingNsr: price
                        ? calculateFinalPrice(price, false) -
                          parseFloat(
                              item.customAttributes
                                  ?.supply_source_bonus_discount || '0.0'
                          )
                        : null,
                    nsrStandard: item.customAttributes
                        ?.supply_source_nsr_regular
                        ? parseFloat(
                              item.customAttributes?.supply_source_nsr_regular
                          )
                        : null,
                    nsrPromo: item.customAttributes?.supply_source_nsr_promo
                        ? parseFloat(
                              item.customAttributes?.supply_source_nsr_promo
                          )
                        : null,
                    nsrPromoStart: item.customAttributes
                        ?.supply_source_price_lock_start_date
                        ? DateTime.fromSeconds(
                              item.customAttributes
                                  ?.supply_source_price_lock_start_date / 1000
                          )
                              .setLocale(i18n.global.locale)
                              .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                              .toJSDate()
                        : null,
                    nsrPromoEnd: item.customAttributes
                        ?.supply_source_price_lock_end_date
                        ? DateTime.fromSeconds(
                              item.customAttributes
                                  ?.supply_source_price_lock_end_date / 1000
                          )
                              .setLocale(i18n.global.locale)
                              .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                              .toJSDate()
                        : null,
                    nsrPromoAppliesForStock:
                        !!item.customAttributes
                            ?.supply_source_nsr_promo_applies_for_stock,
                    finalPriceIncludingNsrInDefaultCurrency:
                        price && price.currencyName !== 'EUR'
                            ? resolvePriceInDefaultCurrency(
                                  calculateFinalPrice(price, false) -
                                      parseFloat(
                                          item.customAttributes
                                              ?.supply_source_bonus_discount ||
                                              '0.0'
                                      ),
                                  exchangeRates.value[price.currencyName]
                              )
                            : null,

                    promoStartDateFormatted: item.customAttributes
                        ?.supply_source_price_lock_start_date
                        ? DateTime.fromSeconds(
                              item.customAttributes
                                  ?.supply_source_price_lock_start_date / 1000
                          )
                              .setLocale(i18n.global.locale)
                              .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                              .toLocaleString({
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit'
                              })
                        : '',
                    promoEndDateFormatted: item.customAttributes
                        ?.supply_source_price_lock_end_date
                        ? DateTime.fromSeconds(
                              item.customAttributes
                                  ?.supply_source_price_lock_end_date / 1000
                          )
                              .setLocale(i18n.global.locale)
                              .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                              .toLocaleString({
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit'
                              })
                        : ''
                };
            });
        });

        const nsrValue = computed(() => {
            return mappedSupplySource.value?.customAttributes
                ?.supply_source_bonus_discount
                ? parseFloat(
                      mappedSupplySource.value.customAttributes
                          .supply_source_bonus_discount
                  )
                : null;
        });

        const formatPrice = (
            currency: string,
            value: number,
            isPercentage: boolean = false
        ) => {
            if (isPercentage) {
                return value + ' %';
            }

            const formatter = new Intl.NumberFormat(i18n.global.locale, {
                style: 'currency',
                currency: currency
            });

            return formatter.format(value);
        };

        const onCloseProductPriceDialog = (dialogData: any) => {
            if (!dialogData) {
                originalPrice.value = null;
                showProductPriceDialog.value = false;
                return;
            }
            let needsMinusMargeConfirmation =
                [PriceType.CostPrice, PriceType.SellPrice].includes(
                    dialogData?.priceType
                ) &&
                isCurrentlyActivePrice({
                    startDate: dialogData?.startDate?.getTime() || null,
                    endDate: dialogData?.endDate?.getTime() || null
                });

            if (needsMinusMargeConfirmation) {
                let referenceMinusMargePrice = null;
                if (dialogData?.priceType === PriceType.CostPrice) {
                    (
                        productDetails.value?.weclapp?.articlePrices || []
                    ).forEach((sellPrice: any) => {
                        if (isCurrentlyActivePrice(sellPrice)) {
                            referenceMinusMargePrice = calculateFinalPrice(
                                sellPrice,
                                false
                            );
                            return false;
                        }
                    });

                    needsMinusMargeConfirmation =
                        !!referenceMinusMargePrice &&
                        dialogData.price > referenceMinusMargePrice;
                } else {
                    (articleSupplySources.value || []).forEach(
                        (ssource: any) => {
                            if (ssource.id === primarySupplySourceId.value) {
                                referenceMinusMargePrice =
                                    ssource.finalPriceIncludingNsrInDefaultCurrency ||
                                    ssource.finalPriceIncludingNsr;
                                return false;
                            }
                        }
                    );

                    needsMinusMargeConfirmation =
                        !!referenceMinusMargePrice &&
                        dialogData.price < referenceMinusMargePrice;
                }
            }

            if (!needsMinusMargeConfirmation) {
                handleAddOrEdit(dialogData);
                originalPrice.value = null;
                showProductPriceDialog.value = false;
            } else {
                confirm.require({
                    message: i18n.global.t(
                        'messages.priceWillIntroduceMiusMargeConfirmation'
                    ),
                    header: i18n.global.t('messages.pleaseConfirm'),
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: i18n.global.t('labels.yes'),
                    rejectLabel: i18n.global.t('labels.no'),
                    accept: async () => {
                        handleAddOrEdit(dialogData);
                        originalPrice.value = null;
                        showProductPriceDialog.value = false;
                    },
                    reject: async () => {
                        originalPrice.value = null;
                        showProductPriceDialog.value = false;
                    }
                });
            }
        };

        const onCloseScaledPriceDialog = (dialogData: any) => {
            if (!dialogData) {
                showScaledPriceDialog.value = false;
                originalPrice.value = false;
                return;
            }
            if (dialogData.price > originalPrice.value?.price) {
                confirm.require({
                    message: i18n.global.t(
                        'messages.scaledPriceBiggerThanOriginalConfirmation'
                    ),
                    header: i18n.global.t('messages.pleaseConfirm'),
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: i18n.global.t('labels.yes'),
                    rejectLabel: i18n.global.t('labels.no'),
                    accept: async () => {
                        loading.value = true;
                        add(productDetails.value.weclapp?.articleNumber, {
                            ...dialogData,
                            selectedSupplySource:
                                selectedSupplySource.value?.id || null
                        })
                            .then(() => {
                                toast.success(
                                    i18n.global.t(
                                        'messages.changesSavedSuccessfully'
                                    )
                                );
                                context.emit('reload-product');
                            })
                            .catch((error) => {
                                toast.error(
                                    error.response?.data?.error || error.message
                                );
                            })
                            .finally(() => {
                                loading.value = false;
                            });
                    }
                });
            } else {
                loading.value = true;
                add(productDetails.value.weclapp?.articleNumber, {
                    ...dialogData,
                    selectedSupplySource: selectedSupplySource.value?.id || null
                })
                    .then(() => {
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully')
                        );
                        context.emit('reload-product');
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    })
                    .finally(() => {
                        loading.value = false;
                    });
            }

            showScaledPriceDialog.value = false;
            originalPrice.value = false;
        };

        const supplierCurrencyName = computed(() => {
            return mappedSupplySource.value?.supplier?.currencyName || 'EUR';
        });

        const removePrice = (data: {id: string}, scaled: boolean = false) => {
            confirm.require({
                message: i18n.global.t(
                    scaled
                        ? 'messages.deleteScaledPriceConfirmation'
                        : 'messages.deletePriceConfirmation'
                ),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: async () => {
                    loading.value = true;
                    try {
                        await remove(
                            productDetails.value.weclapp?.articleNumber,
                            data.id
                        );
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully')
                        );
                        context.emit('reload-product');
                    } catch (error: any) {
                        loading.value = false;
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    } finally {
                        loading.value = false;
                    }
                }
            });
        };

        const primarySupplySourceId = computed(
            () => productDetails.value?.weclapp?.primarySupplySourceId
        );

        const layoutConfig = computed(() => {
            return store.getters['ui/layoutConfig'] as ILayoutConfig;
        });

        const onRowEditComplete = (event: {data: any; newData: any}) => {
            loading.value = true;
            const {
                nsrStandard,
                nsrPromo,
                nsrPromoStart,
                nsrPromoEnd,
                nsrPromoAppliesForStock
            } = event.newData;
            updateSupplySource(event.data.id, {
                nsrStandard,
                nsrPromo,
                nsrPromoStart,
                nsrPromoEnd,
                nsrPromoAppliesForStock
            })
                .then(() => {
                    context.emit('reload-product');
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const editPermissionAvailable = computed(async () => {
            const user = store.getters['auth/user'] || (await getProfile());
            return user?.permissions?.indexOf('products-edit') !== -1;
        });

        const handleAddOrEdit = (dialogData: any) => {
            loading.value = true;
            if (originalPrice.value) {
                edit(
                    productDetails.value.weclapp?.articleNumber,
                    originalPrice.value.id,
                    dialogData
                )
                    .then(() => {
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully')
                        );
                        context.emit('reload-product');
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    })
                    .finally(() => {
                        loading.value = false;
                    });
            } else {
                add(productDetails.value.weclapp?.articleNumber, {
                    ...dialogData,
                    selectedSupplySource: selectedSupplySource.value?.id || null
                })
                    .then(() => {
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully')
                        );
                        context.emit('reload-product');
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    })
                    .finally(() => {
                        loading.value = false;
                    });
            }
        };

        return {
            loading,
            productCostPrices,
            productSellPrices,
            productListPrices,
            articleSupplySources,
            rowClass: (data: any) => {
                const colorVariant = layoutConfig.value?.darkTheme ? 600 : 100;
                return isCurrentlyActivePrice(data)
                    ? 'bg-green-' + colorVariant
                    : isFutureTimeLimitedPrice(data)
                    ? 'bg-yellow-' + colorVariant
                    : '';
            },
            nsrValue,
            showProductPriceDialog,
            showScaledPriceDialog,
            onCloseProductPriceDialog,
            onCloseScaledPriceDialog,
            supplierCurrencyName,
            dialogPriceType,
            PriceType,
            showCostPricesInThePast,
            showSellPricesInThePast,
            showListPricesInThePast,
            selectedSupplySource,
            removePrice,
            originalPrice,
            primarySupplySourceId,
            formatPrice,
            exchangeRates,
            editingRows,
            locale: i18n.global.locale,
            onRowEditComplete,
            editPermissionAvailable
        };
    }
};
