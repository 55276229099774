import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "mt-2" }
const _hoisted_2 = { class: "formgrid grid p-fluid" }
const _hoisted_3 = { class: "field col" }
const _hoisted_4 = { class: "field col" }
const _hoisted_5 = { class: "field col" }
const _hoisted_6 = { class: "field col" }
const _hoisted_7 = { class: "formgrid grid p-fluid" }
const _hoisted_8 = { class: "field col" }
const _hoisted_9 = { class: "field col" }
const _hoisted_10 = { class: "field col" }
const _hoisted_11 = { class: "field col" }
const _hoisted_12 = { class: "formgrid grid p-fluid" }
const _hoisted_13 = { class: "field col" }
const _hoisted_14 = { class: "field col" }
const _hoisted_15 = { class: "field col" }
const _hoisted_16 = {
  key: 1,
  class: "formgrid grid p-fluid mb-2"
}
const _hoisted_17 = { class: "field col" }
const _hoisted_18 = { class: "font-semibold" }
const _hoisted_19 = { class: "grid" }
const _hoisted_20 = { class: "col" }
const _hoisted_21 = { class: "formgrid grid p-fluid" }
const _hoisted_22 = { class: "field col-6" }
const _hoisted_23 = { class: "field col-6" }
const _hoisted_24 = { class: "col" }
const _hoisted_25 = { class: "formgrid grid p-fluid" }
const _hoisted_26 = { class: "field col-6" }
const _hoisted_27 = { class: "field col-6" }
const _hoisted_28 = { class: "formgrid grid p-fluid" }
const _hoisted_29 = { class: "field col" }
const _hoisted_30 = { class: "field col" }
const _hoisted_31 = { class: "field col" }
const _hoisted_32 = { class: "field col" }
const _hoisted_33 = { class: "field col" }
const _hoisted_34 = { class: "formgrid grid p-fluid" }
const _hoisted_35 = { class: "field col" }
const _hoisted_36 = { class: "field col" }
const _hoisted_37 = { class: "field col" }
const _hoisted_38 = { class: "formgrid grid p-fluid" }
const _hoisted_39 = { class: "field col" }
const _hoisted_40 = { class: "formgrid grid p-fluid" }
const _hoisted_41 = { class: "col field" }
const _hoisted_42 = { class: "col field" }
const _hoisted_43 = { class: "grid" }
const _hoisted_44 = { class: "field col-3" }
const _hoisted_45 = { class: "field col" }
const _hoisted_46 = { class: "field col" }
const _hoisted_47 = { class: "field col" }
const _hoisted_48 = { class: "field col" }
const _hoisted_49 = { class: "field col" }
const _hoisted_50 = { class: "field col-2" }
const _hoisted_51 = { style: {"font-weight":"bold"} }
const _hoisted_52 = { class: "formgrid grid p-fluid" }
const _hoisted_53 = { class: "field col" }
const _hoisted_54 = { class: "field col" }
const _hoisted_55 = { style: {"font-weight":"bold"} }
const _hoisted_56 = { class: "formgrid grid p-fluid mb-2" }
const _hoisted_57 = { class: "field col" }
const _hoisted_58 = { class: "flex" }
const _hoisted_59 = { class: "formgrid grid p-fluid" }
const _hoisted_60 = { class: "field col" }
const _hoisted_61 = { class: "field col" }
const _hoisted_62 = { class: "field col" }
const _hoisted_63 = { class: "field col" }
const _hoisted_64 = { class: "formgrid grid p-fluid" }
const _hoisted_65 = { class: "field col" }
const _hoisted_66 = { class: "field col" }
const _hoisted_67 = { class: "field col" }
const _hoisted_68 = { class: "formgrid grid p-fluid" }
const _hoisted_69 = { class: "field col" }
const _hoisted_70 = { class: "field col" }
const _hoisted_71 = { class: "field col" }
const _hoisted_72 = { class: "formgrid grid p-fluid" }
const _hoisted_73 = { class: "field col" }
const _hoisted_74 = { class: "field col" }
const _hoisted_75 = { class: "field col" }
const _hoisted_76 = { class: "mt-3 flex justify-content-end" }
const _hoisted_77 = { class: "w-full mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_Divider = _resolveComponent("Divider")
  const _component_Chips = _resolveComponent("Chips")
  const _component_p_fieldset = _resolveComponent("p-fieldset")
  const _component_app_checkbox = _resolveComponent("app-checkbox")
  const _component_Panel = _resolveComponent("Panel")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_calendar = _resolveComponent("p-calendar")
  const _component_p_textarea = _resolveComponent("p-textarea")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")
  const _component_p_button = _resolveComponent("p-button")
  const _component_p_dialog = _resolveComponent("p-dialog")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("form", {
        onSubmit: _cache[58] || (_cache[58] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        _createVNode(_component_ScrollPanel, { style: {"height":"calc(100vh - 29rem)"} }, {
          default: _withCtx(() => [
            _createVNode(_component_p_fieldset, {
              legend: _ctx.$t('labels.productHome.masterData'),
              toggleable: true,
              class: "mb-2"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.articleNumber')), 1),
                    _createVNode(_component_InputText, {
                      disabled: "",
                      placeholder: _ctx.$t('labels.articleNumber'),
                      autocomplete: "off",
                      modelValue: _ctx.v$.productNumber.$model,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$.productNumber.$model) = $event)),
                      class: _normalizeClass({'tt-form-input': true, 'p-invalid':_ctx.v$.productNumber.$invalid && _ctx.submitted})
                    }, null, 8, ["placeholder", "modelValue", "class"]),
                    (_ctx.submitted)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.productNumber.$silentErrors, (error) => {
                          return (_openBlock(), _createElementBlock("small", {
                            style: {"display":"block"},
                            class: "p-error",
                            key: error
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.articleName')), 1),
                    _createVNode(_component_InputText, {
                      placeholder: _ctx.$t('labels.articleName'),
                      autocomplete: "off",
                      modelValue: _ctx.v$.productName.$model,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.productName.$model) = $event)),
                      class: _normalizeClass({'p-invalid':_ctx.v$.productName.$invalid && _ctx.submitted})
                    }, null, 8, ["placeholder", "modelValue", "class"]),
                    (_ctx.submitted)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.productName.$silentErrors, (error) => {
                          return (_openBlock(), _createElementBlock("small", {
                            style: {"display":"block"},
                            class: "p-error",
                            key: error
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _withDirectives((_openBlock(), _createElementBlock("label", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.articleNumber')), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.customAttributesDefinition?.article_webshop_VDHarticleNumber?.attributeDescription,
                        void 0,
                        { top: true }
                      ]
                    ]),
                    _cache[61] || (_cache[61] = _createElementVNode("div", { class: "sales-channel-dot sales-channel-videodata w-1rem h-1rem ml-1" }, null, -1)),
                    _createVNode(_component_InputText, {
                      disabled: "",
                      autocomplete: "off",
                      modelValue: _ctx.v$.article_webshop_VDHarticleNumber.$model,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$.article_webshop_VDHarticleNumber.$model) = $event)),
                      class: _normalizeClass({'tt-form-input': true, 'p-invalid':_ctx.v$.article_webshop_VDHarticleNumber.$invalid && _ctx.submitted})
                    }, null, 8, ["modelValue", "class"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _withDirectives((_openBlock(), _createElementBlock("label", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.articleName')), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.customAttributesDefinition?.article_webshop_VDHname?.attributeDescription,
                        void 0,
                        { top: true }
                      ]
                    ]),
                    _cache[62] || (_cache[62] = _createElementVNode("div", { class: "sales-channel-dot sales-channel-videodata w-1rem h-1rem ml-1" }, null, -1)),
                    _createVNode(_component_InputText, {
                      autocomplete: "off",
                      disabled: "",
                      modelValue: _ctx.v$.article_webshop_VDHname.$model,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.article_webshop_VDHname.$model) = $event)),
                      class: _normalizeClass({'p-invalid':_ctx.v$.article_webshop_VDHname.$invalid && _ctx.submitted})
                    }, null, 8, ["modelValue", "class"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.manufacturer')), 1),
                    _createVNode(_component_p_dropdown, {
                      filter: true,
                      showClear: true,
                      modelValue: _ctx.v$.manufacturer.$model,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$.manufacturer.$model) = $event)),
                      options: _ctx.manufacturerOptions,
                      optionLabel: "label",
                      optionValue: "value",
                      onChange: _cache[5] || (_cache[5] = $event => (_ctx.setDropdownValue('manufacturer', $event))),
                      class: _normalizeClass({'p-invalid':_ctx.v$.manufacturer.$invalid && _ctx.submitted})
                    }, null, 8, ["modelValue", "options", "class"]),
                    (_ctx.submitted)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.manufacturer.$silentErrors, (error) => {
                          return (_openBlock(), _createElementBlock("small", {
                            style: {"display":"block"},
                            class: "p-error",
                            key: error
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ean')), 1),
                    _createVNode(_component_InputText, {
                      placeholder: _ctx.$t('labels.ean'),
                      autocomplete: "off",
                      modelValue: _ctx.v$.ean.$model,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.v$.ean.$model) = $event)),
                      class: _normalizeClass({'p-invalid':_ctx.v$.ean.$invalid && _ctx.submitted})
                    }, null, 8, ["placeholder", "modelValue", "class"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.customsTariffNumber')), 1),
                    _createVNode(_component_p_dropdown, {
                      filter: true,
                      showClear: true,
                      modelValue: _ctx.v$.customsTariffNumber.$model,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.v$.customsTariffNumber.$model) = $event)),
                      options: _ctx.customsTariffNumberOptions,
                      optionLabel: "label",
                      optionValue: "value",
                      onChange: _cache[8] || (_cache[8] = $event => (_ctx.setDropdownValue('customsTariffNumber', $event))),
                      class: _normalizeClass({'p-invalid':_ctx.v$.customsTariffNumber.$invalid && _ctx.submitted})
                    }, null, 8, ["modelValue", "options", "class"])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mpn')), 1),
                    _createVNode(_component_InputText, {
                      placeholder: _ctx.$t('labels.mpn'),
                      autocomplete: "off",
                      modelValue: _ctx.v$.mpn.$model,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$.mpn.$model) = $event)),
                      class: _normalizeClass({'p-invalid':_ctx.v$.mpn.$invalid && _ctx.submitted})
                    }, null, 8, ["placeholder", "modelValue", "class"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.matchCode')), 1),
                    _createVNode(_component_InputText, {
                      icon: "align-justify",
                      type: "text",
                      placeholder: _ctx.$t('labels.productAdd.matchCode'),
                      autocomplete: "off",
                      modelValue: _ctx.v$.matchCode.$model,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.v$.matchCode.$model) = $event))
                    }, null, 8, ["placeholder", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.countryOfOrigin')), 1),
                    _createVNode(_component_p_dropdown, {
                      filter: true,
                      showClear: true,
                      modelValue: _ctx.v$.countryOfOrigin.$model,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.v$.countryOfOrigin.$model) = $event)),
                      options: _ctx.countryOptions,
                      optionLabel: "label",
                      optionValue: "value",
                      onChange: _cache[12] || (_cache[12] = $event => (_ctx.setDropdownValue('countryOfOrigin', $event)))
                    }, null, 8, ["modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.articleWeclappCategory')), 1),
                    _createVNode(_component_p_dropdown, {
                      filter: true,
                      modelValue: _ctx.v$.articleCategory.$model,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.v$.articleCategory.$model) = $event)),
                      options: _ctx.articleCategoryOptions,
                      optionLabel: "label",
                      optionValue: "value",
                      onChange: _cache[14] || (_cache[14] = $event => (_ctx.setDropdownValue('articleCategory', $event))),
                      showClear: true
                    }, null, 8, ["modelValue", "options"])
                  ])
                ]),
                (_ctx.tagsPermissionAvailable)
                  ? (_openBlock(), _createBlock(_component_Divider, {
                      key: 0,
                      align: "left",
                      type: "dashed"
                    }))
                  : _createCommentVNode("", true),
                (_ctx.tagsPermissionAvailable)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('labels.tags')), 1),
                        _createVNode(_component_Chips, {
                          class: "w-full",
                          modelValue: _ctx.v$.tags.$model,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => ((_ctx.v$.tags.$model) = $event)),
                          allowDuplicate: false,
                          placeholder: _ctx.$t('labels.metatagsSeparatorInfo'),
                          separator: ","
                        }, null, 8, ["modelValue", "placeholder"])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["legend"]),
            _createVNode(_component_p_fieldset, {
              legend: _ctx.$t('labels.productHome.ecommerceSettings'),
              toggleable: true,
              class: "mb-2"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createVNode(_component_Panel, null, {
                      header: _withCtx(() => _cache[63] || (_cache[63] = [
                        _createElementVNode("div", { class: "font-bold flex justify-content-between w-full" }, [
                          _createElementVNode("div", null, "Webshop Teltec"),
                          _createElementVNode("div", { class: "sales-channel-dot sales-channel-teltec" })
                        ], -1)
                      ])),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_21, [
                          _createElementVNode("div", _hoisted_22, [
                            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.availableInShop')), 1),
                            _createVNode(_component_app_checkbox, {
                              modelValue: _ctx.v$.availableInShop.$model,
                              "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => ((_ctx.v$.availableInShop.$model) = $event)),
                              binary: true,
                              class: "ml-2 mb-1",
                              disabled: _ctx.alreadyAvailableInShop
                            }, null, 8, ["modelValue", "disabled"])
                          ]),
                          _createElementVNode("div", _hoisted_23, [
                            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.activeInShop')), 1),
                            _createVNode(_component_app_checkbox, {
                              modelValue: _ctx.v$.activeInShop.$model,
                              "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => ((_ctx.v$.activeInShop.$model) = $event)),
                              binary: true,
                              class: "ml-2 mb-1"
                            }, null, 8, ["modelValue"])
                          ]),
                          _cache[64] || (_cache[64] = _createElementVNode("div", { class: "field col" }, null, -1))
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    _createVNode(_component_Panel, null, {
                      header: _withCtx(() => _cache[65] || (_cache[65] = [
                        _createElementVNode("div", { class: "font-bold flex justify-content-between w-full" }, [
                          _createElementVNode("div", null, "Webshop Video Data"),
                          _createElementVNode("div", { class: "sales-channel-dot sales-channel-videodata" })
                        ], -1)
                      ])),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_25, [
                          _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.availableInShop')), 1),
                            _createVNode(_component_app_checkbox, {
                              modelValue: _ctx.v$.availableInShop2.$model,
                              "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => ((_ctx.v$.availableInShop2.$model) = $event)),
                              binary: true,
                              class: "ml-2 mb-1",
                              disabled: _ctx.alreadyAvailableInShop2
                            }, null, 8, ["modelValue", "disabled"])
                          ]),
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.activeInShop')), 1),
                            _createVNode(_component_app_checkbox, {
                              modelValue: _ctx.v$.activeInShop2.$model,
                              "onUpdate:modelValue": _cache[19] || (_cache[19] = $event => ((_ctx.v$.activeInShop2.$model) = $event)),
                              binary: true,
                              class: "ml-2 mb-1"
                            }, null, 8, ["modelValue"])
                          ]),
                          _cache[66] || (_cache[66] = _createElementVNode("div", { class: "field col" }, null, -1))
                        ])
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            }, 8, ["legend"]),
            _createVNode(_component_p_fieldset, {
              legend: _ctx.$t('labels.productHome.weightAndDimensions'),
              toggleable: true,
              class: "mb-2"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.grossWeight')), 1),
                    _createVNode(_component_InputNumber, {
                      mode: "decimal",
                      locale: _ctx.locale,
                      minFractionDigits: 1,
                      autocomplete: "off",
                      modelValue: _ctx.v$.articleGrossWeight.$model,
                      "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => ((_ctx.v$.articleGrossWeight.$model) = $event)),
                      class: _normalizeClass({'p-invalid':_ctx.v$.articleGrossWeight.$invalid && _ctx.submitted})
                    }, null, 8, ["locale", "modelValue", "class"]),
                    (_ctx.submitted)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.articleGrossWeight.$silentErrors, (error) => {
                          return (_openBlock(), _createElementBlock("small", {
                            style: {"display":"block"},
                            class: "p-error",
                            key: error
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.netWeight')), 1),
                    _createVNode(_component_InputNumber, {
                      mode: "decimal",
                      locale: _ctx.locale,
                      minFractionDigits: 1,
                      autocomplete: "off",
                      modelValue: _ctx.v$.articleNetWeight.$model,
                      "onUpdate:modelValue": _cache[21] || (_cache[21] = $event => ((_ctx.v$.articleNetWeight.$model) = $event)),
                      class: _normalizeClass({'p-invalid':_ctx.v$.articleNetWeight.$invalid && _ctx.submitted})
                    }, null, 8, ["locale", "modelValue", "class"]),
                    (_ctx.submitted)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.articleNetWeight.$silentErrors, (error) => {
                          return (_openBlock(), _createElementBlock("small", {
                            style: {"display":"block"},
                            class: "p-error",
                            key: error
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.length')), 1),
                    _createVNode(_component_InputNumber, {
                      mode: "decimal",
                      locale: _ctx.locale,
                      minFractionDigits: 1,
                      maxFractionDigits: 1,
                      autocomplete: "off",
                      modelValue: _ctx.v$.articleLength.$model,
                      "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => ((_ctx.v$.articleLength.$model) = $event)),
                      class: _normalizeClass({'p-invalid':_ctx.v$.articleLength.$invalid && _ctx.submitted})
                    }, null, 8, ["locale", "modelValue", "class"]),
                    (_ctx.submitted)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.articleLength.$silentErrors, (error) => {
                          return (_openBlock(), _createElementBlock("small", {
                            style: {"display":"block"},
                            class: "p-error",
                            key: error
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.width')), 1),
                    _createVNode(_component_InputNumber, {
                      mode: "decimal",
                      locale: _ctx.locale,
                      minFractionDigits: 1,
                      maxFractionDigits: 1,
                      autocomplete: "off",
                      modelValue: _ctx.v$.articleWidth.$model,
                      "onUpdate:modelValue": _cache[23] || (_cache[23] = $event => ((_ctx.v$.articleWidth.$model) = $event)),
                      class: _normalizeClass({'p-invalid':_ctx.v$.articleWidth.$invalid && _ctx.submitted})
                    }, null, 8, ["locale", "modelValue", "class"]),
                    (_ctx.submitted)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.articleWidth.$silentErrors, (error) => {
                          return (_openBlock(), _createElementBlock("small", {
                            style: {"display":"block"},
                            class: "p-error",
                            key: error
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_33, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.height')), 1),
                    _createVNode(_component_InputNumber, {
                      mode: "decimal",
                      locale: _ctx.locale,
                      minFractionDigits: 1,
                      maxFractionDigits: 1,
                      autocomplete: "off",
                      modelValue: _ctx.v$.articleHeight.$model,
                      "onUpdate:modelValue": _cache[24] || (_cache[24] = $event => ((_ctx.v$.articleHeight.$model) = $event)),
                      class: _normalizeClass({'p-invalid':_ctx.v$.articleHeight.$invalid && _ctx.submitted})
                    }, null, 8, ["locale", "modelValue", "class"]),
                    (_ctx.submitted)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.articleHeight.$silentErrors, (error) => {
                          return (_openBlock(), _createElementBlock("small", {
                            style: {"display":"block"},
                            class: "p-error",
                            key: error
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_34, [
                  _createElementVNode("div", _hoisted_35, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.purchaseUnit')), 1),
                    _createVNode(_component_InputNumber, {
                      mode: "decimal",
                      locale: _ctx.locale,
                      minFractionDigits: 1,
                      autocomplete: "off",
                      modelValue: _ctx.v$.purchaseUnit.$model,
                      "onUpdate:modelValue": _cache[25] || (_cache[25] = $event => ((_ctx.v$.purchaseUnit.$model) = $event)),
                      class: _normalizeClass({'p-invalid':_ctx.v$.purchaseUnit.$invalid && _ctx.submitted})
                    }, null, 8, ["locale", "modelValue", "class"]),
                    (_ctx.submitted)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.purchaseUnit.$silentErrors, (error) => {
                          return (_openBlock(), _createElementBlock("small", {
                            style: {"display":"block"},
                            class: "p-error",
                            key: error
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_36, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.dimensionUnit')), 1),
                    _createVNode(_component_p_dropdown, {
                      filter: true,
                      modelValue: _ctx.v$.dimensionUnit.$model,
                      "onUpdate:modelValue": _cache[26] || (_cache[26] = $event => ((_ctx.v$.dimensionUnit.$model) = $event)),
                      options: _ctx.shopwareUnitOptions,
                      optionLabel: "name",
                      optionValue: "name",
                      onChange: _cache[27] || (_cache[27] = $event => (_ctx.setDropdownValue('dimensionUnit', $event)))
                    }, null, 8, ["modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.referenceUnit')), 1),
                    _createVNode(_component_InputNumber, {
                      mode: "decimal",
                      locale: _ctx.locale,
                      minFractionDigits: 1,
                      modelValue: _ctx.v$.referenceUnit.$model,
                      "onUpdate:modelValue": _cache[28] || (_cache[28] = $event => ((_ctx.v$.referenceUnit.$model) = $event)),
                      class: _normalizeClass({'p-invalid':_ctx.v$.referenceUnit.$invalid && _ctx.submitted}),
                      autocomplete: "off"
                    }, null, 8, ["locale", "modelValue", "class"]),
                    (_ctx.submitted)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.referenceUnit.$silentErrors, (error) => {
                          return (_openBlock(), _createElementBlock("small", {
                            style: {"display":"block"},
                            class: "p-error",
                            key: error
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["legend"]),
            _createVNode(_component_p_fieldset, {
              legend: _ctx.$t('labels.productHome.salesManagement'),
              toggleable: true,
              class: "mb-2"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("div", _hoisted_39, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.sellFromDate')), 1),
                    _createVNode(_component_p_calendar, {
                      placeholder: _ctx.$t('labels.productHome.sellFromDate'),
                      autocomplete: "off",
                      dateFormat: "dd.mm.yy",
                      modelValue: _ctx.v$.sellFromDate.$model,
                      "onUpdate:modelValue": _cache[29] || (_cache[29] = $event => ((_ctx.v$.sellFromDate.$model) = $event)),
                      class: _normalizeClass({'tt-form-input': true, 'p-invalid':_ctx.v$.sellFromDate.$invalid && _ctx.submitted})
                    }, null, 8, ["placeholder", "modelValue", "class"])
                  ]),
                  _cache[67] || (_cache[67] = _createElementVNode("div", { class: "field col" }, null, -1)),
                  _cache[68] || (_cache[68] = _createElementVNode("div", { class: "field col" }, null, -1))
                ])
              ]),
              _: 1
            }, 8, ["legend"]),
            _createVNode(_component_p_fieldset, {
              legend: _ctx.$t('labels.customAttributes'),
              toggleable: true,
              class: "mb-2"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_40, [
                  _createElementVNode("div", _hoisted_41, [
                    _withDirectives((_openBlock(), _createElementBlock("label", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.memo')), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.customAttributesDefinition?.article_memo?.attributeDescription,
                        void 0,
                        { top: true }
                      ]
                    ]),
                    _createVNode(_component_p_textarea, {
                      rows: "4",
                      autocomplete: "off",
                      modelValue: _ctx.v$.article_memo.$model,
                      "onUpdate:modelValue": _cache[30] || (_cache[30] = $event => ((_ctx.v$.article_memo.$model) = $event)),
                      class: _normalizeClass({'p-invalid':_ctx.v$.article_memo.$invalid && _ctx.submitted})
                    }, null, 8, ["modelValue", "class"]),
                    _withDirectives((_openBlock(), _createElementBlock("label", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.commentDeliveryNote')), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.customAttributesDefinition?.article_comment_delivery_note?.attributeDescription,
                        void 0,
                        { right: true }
                      ]
                    ]),
                    _createVNode(_component_p_textarea, {
                      rows: "4",
                      autocomplete: "off",
                      modelValue: _ctx.v$.article_comment_delivery_note.$model,
                      "onUpdate:modelValue": _cache[31] || (_cache[31] = $event => ((_ctx.v$.article_comment_delivery_note.$model) = $event)),
                      class: _normalizeClass({'p-invalid':_ctx.v$.article_comment_delivery_note.$invalid && _ctx.submitted})
                    }, null, 8, ["modelValue", "class"])
                  ]),
                  _createElementVNode("div", _hoisted_42, [
                    _withDirectives((_openBlock(), _createElementBlock("label", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.eol')), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.customAttributesDefinition?.art_eol_datum?.attributeDescription,
                        void 0,
                        { top: true }
                      ]
                    ]),
                    _createVNode(_component_p_calendar, {
                      autocomplete: "off",
                      dateFormat: "dd.mm.yy",
                      modelValue: _ctx.v$.art_eol_datum.$model,
                      "onUpdate:modelValue": _cache[32] || (_cache[32] = $event => ((_ctx.v$.art_eol_datum.$model) = $event)),
                      class: _normalizeClass({'tt-form-input': true, 'p-invalid':_ctx.v$.art_eol_datum.$invalid && _ctx.submitted})
                    }, null, 8, ["modelValue", "class"]),
                    _withDirectives((_openBlock(), _createElementBlock("label", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.warrantyTime')), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.customAttributesDefinition?.article_garantiezeit?.attributeDescription,
                        void 0,
                        { top: true }
                      ]
                    ]),
                    _createVNode(_component_p_dropdown, {
                      modelValue: _ctx.v$.article_garantiezeit.$model,
                      "onUpdate:modelValue": _cache[33] || (_cache[33] = $event => ((_ctx.v$.article_garantiezeit.$model) = $event)),
                      options: _ctx.warrantyTimeOptions,
                      optionLabel: "label",
                      optionValue: "value",
                      onChange: _cache[34] || (_cache[34] = $event => (_ctx.setDropdownValue('article_garantiezeit', $event))),
                      class: _normalizeClass({'p-invalid':_ctx.v$.article_garantiezeit.$invalid && _ctx.submitted})
                    }, null, 8, ["modelValue", "options", "class"]),
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.priceUponRequest')), 1),
                    _createVNode(_component_p_dropdown, {
                      modelValue: _ctx.v$.priceUponRequest.$model,
                      "onUpdate:modelValue": _cache[35] || (_cache[35] = $event => ((_ctx.v$.priceUponRequest.$model) = $event)),
                      options: _ctx.priceUponRequestOptions,
                      optionLabel: "label",
                      optionValue: "value",
                      onChange: _cache[36] || (_cache[36] = $event => (_ctx.setDropdownValue('priceUponRequest', $event))),
                      class: _normalizeClass({'p-invalid':_ctx.v$.priceUponRequest.$invalid && _ctx.submitted})
                    }, null, 8, ["modelValue", "options", "class"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("div", _hoisted_44, [
                    _withDirectives((_openBlock(), _createElementBlock("label", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.serialNumberIntern')), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.customAttributesDefinition?.SN_AUTO_GENERATE?.attributeDescription,
                        void 0,
                        { top: true }
                      ]
                    ]),
                    _createVNode(_component_app_checkbox, {
                      modelValue: _ctx.v$.SN_AUTO_GENERATE.$model,
                      "onUpdate:modelValue": _cache[37] || (_cache[37] = $event => ((_ctx.v$.SN_AUTO_GENERATE.$model) = $event)),
                      binary: true,
                      class: "ml-2 mb-1"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_45, [
                    _withDirectives((_openBlock(), _createElementBlock("label", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.battg')), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.customAttributesDefinition?.article_batteriegesetz_relevant?.attributeDescription,
                        void 0,
                        { top: true }
                      ]
                    ]),
                    _createVNode(_component_app_checkbox, {
                      modelValue: _ctx.v$.article_batteriegesetz_relevant.$model,
                      "onUpdate:modelValue": _cache[38] || (_cache[38] = $event => ((_ctx.v$.article_batteriegesetz_relevant.$model) = $event)),
                      binary: true,
                      class: "ml-2 mb-1"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_46, [
                    _withDirectives((_openBlock(), _createElementBlock("label", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.oversized')), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.customAttributesDefinition?.article_ueberlaenge?.attributeDescription,
                        void 0,
                        { top: true }
                      ]
                    ]),
                    _createVNode(_component_app_checkbox, {
                      modelValue: _ctx.v$.article_ueberlaenge.$model,
                      "onUpdate:modelValue": _cache[39] || (_cache[39] = $event => ((_ctx.v$.article_ueberlaenge.$model) = $event)),
                      binary: true,
                      class: "ml-2 mb-1"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_47, [
                    _withDirectives((_openBlock(), _createElementBlock("label", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.sellOut')), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.customAttributesDefinition?.article_sell_out?.attributeDescription,
                        void 0,
                        { top: true }
                      ]
                    ]),
                    _createVNode(_component_app_checkbox, {
                      modelValue: _ctx.v$.article_sell_out.$model,
                      "onUpdate:modelValue": _cache[40] || (_cache[40] = $event => ((_ctx.v$.article_sell_out.$model) = $event)),
                      binary: true,
                      class: "ml-2 mb-1"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_48, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.shippingFree')), 1),
                    _createVNode(_component_app_checkbox, {
                      modelValue: _ctx.v$.shippingFree.$model,
                      "onUpdate:modelValue": _cache[41] || (_cache[41] = $event => ((_ctx.v$.shippingFree.$model) = $event)),
                      binary: true,
                      class: "ml-2 mb-1"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_49, [
                    _withDirectives((_openBlock(), _createElementBlock("label", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.brandAddedValue')), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.customAttributesDefinition?.article_markenmehrwert?.attributeDescription,
                        void 0,
                        { top: true }
                      ]
                    ]),
                    _createVNode(_component_app_checkbox, {
                      modelValue: _ctx.v$.article_markenmehrwert.$model,
                      "onUpdate:modelValue": _cache[42] || (_cache[42] = $event => ((_ctx.v$.article_markenmehrwert.$model) = $event)),
                      binary: true,
                      class: "ml-2 mb-1"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_50, [
                    _withDirectives((_openBlock(), _createElementBlock("label", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.notInPriceListSparePart')), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.customAttributesDefinition?.article_no_pl_match_spare_part?.attributeDescription,
                        void 0,
                        { top: true }
                      ]
                    ]),
                    _createVNode(_component_app_checkbox, {
                      modelValue: _ctx.v$.article_no_pl_match_spare_part.$model,
                      "onUpdate:modelValue": _cache[43] || (_cache[43] = $event => ((_ctx.v$.article_no_pl_match_spare_part.$model) = $event)),
                      binary: true,
                      class: "ml-4 mb-1"
                    }, null, 8, ["modelValue"])
                  ])
                ]),
                _createVNode(_component_Divider, {
                  align: "left",
                  type: "dashed"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_51, _toDisplayString(_ctx.$t('labels.productHome.characteristics')), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_52, [
                  _createElementVNode("div", _hoisted_53, [
                    _withDirectives((_openBlock(), _createElementBlock("label", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.weeeClasification')), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.customAttributesDefinition?.article_weee_relevant?.attributeDescription,
                        void 0,
                        { top: true }
                      ]
                    ]),
                    _createVNode(_component_p_dropdown, {
                      modelValue: _ctx.v$.article_weee_relevant.$model,
                      "onUpdate:modelValue": _cache[44] || (_cache[44] = $event => ((_ctx.v$.article_weee_relevant.$model) = $event)),
                      options: _ctx.weeeOptions,
                      optionLabel: "label",
                      optionValue: "value",
                      onChange: _cache[45] || (_cache[45] = $event => (_ctx.setDropdownValue('article_weee_relevant', $event))),
                      class: _normalizeClass({'p-invalid':_ctx.v$.article_weee_relevant.$invalid && _ctx.submitted})
                    }, null, 8, ["modelValue", "options", "class"])
                  ]),
                  _createElementVNode("div", _hoisted_54, [
                    _withDirectives((_openBlock(), _createElementBlock("label", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.gemaClasification')), 1)
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.customAttributesDefinition?.article_gema_relevant?.attributeDescription,
                        void 0,
                        { top: true }
                      ]
                    ]),
                    _createVNode(_component_p_dropdown, {
                      modelValue: _ctx.v$.article_gema_relevant.$model,
                      "onUpdate:modelValue": _cache[46] || (_cache[46] = $event => ((_ctx.v$.article_gema_relevant.$model) = $event)),
                      options: _ctx.gemaOptions,
                      optionLabel: "label",
                      optionValue: "value",
                      onChange: _cache[47] || (_cache[47] = $event => (_ctx.setDropdownValue('article_gema_relevant', $event))),
                      class: _normalizeClass({'p-invalid':_ctx.v$.article_gema_relevant.$invalid && _ctx.submitted})
                    }, null, 8, ["modelValue", "options", "class"])
                  ])
                ]),
                _createVNode(_component_Divider, {
                  align: "left",
                  type: "dashed"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_55, _toDisplayString(_ctx.$t('labels.productHome.webshop')), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_56, [
                  _createElementVNode("div", _hoisted_57, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.metaTagsSEO')), 1),
                    _createElementVNode("div", _hoisted_58, [
                      _createVNode(_component_Chips, {
                        modelValue: _ctx.v$.article_metatags.$model,
                        "onUpdate:modelValue": _cache[48] || (_cache[48] = $event => ((_ctx.v$.article_metatags.$model) = $event)),
                        allowDuplicate: false,
                        placeholder: _ctx.$t('labels.metatagsSeparatorInfo'),
                        separator: ","
                      }, null, 8, ["modelValue", "placeholder"]),
                      _withDirectives(_createElementVNode("i", {
                        class: "pi pi-copy ml-2",
                        style: {"font-size":"2.5rem","cursor":"pointer"},
                        onClick: _cache[49] || (_cache[49] = $event => (_ctx.copyToClipboard(_ctx.v$.article_metatags.$model, $event)))
                      }, null, 512), [
                        [
                          _directive_tooltip,
                          _ctx.$t('labels.copyMetatagsToClipboard'),
                          void 0,
                          { left: true }
                        ]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_59, [
                  _createElementVNode("div", _hoisted_60, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.bulkGoods')), 1),
                    _createVNode(_component_app_checkbox, {
                      modelValue: _ctx.v$.article_sperrgut.$model,
                      "onUpdate:modelValue": _cache[50] || (_cache[50] = $event => ((_ctx.v$.article_sperrgut.$model) = $event)),
                      binary: true,
                      class: "ml-2 mb-1"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_61, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.containsAerosols')), 1),
                    _createVNode(_component_app_checkbox, {
                      modelValue: _ctx.v$.article_flag_aerosole.$model,
                      "onUpdate:modelValue": _cache[51] || (_cache[51] = $event => ((_ctx.v$.article_flag_aerosole.$model) = $event)),
                      binary: true,
                      class: "ml-2 mb-1"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_62, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.dangerousGoods')), 1),
                    _createVNode(_component_app_checkbox, {
                      modelValue: _ctx.v$.article_flag_akku_groesser_100wh.$model,
                      "onUpdate:modelValue": _cache[52] || (_cache[52] = $event => ((_ctx.v$.article_flag_akku_groesser_100wh.$model) = $event)),
                      binary: true,
                      class: "ml-2 mb-1"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_63, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.dangerousGoodsSmall')), 1),
                    _createVNode(_component_app_checkbox, {
                      modelValue: _ctx.v$.article_flag_akku_kleiner_100wh.$model,
                      "onUpdate:modelValue": _cache[53] || (_cache[53] = $event => ((_ctx.v$.article_flag_akku_kleiner_100wh.$model) = $event)),
                      binary: true,
                      class: "ml-2 mb-1"
                    }, null, 8, ["modelValue"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["legend"]),
            _createVNode(_component_p_fieldset, {
              legend: _ctx.$t('labels.salesOrder.disposition'),
              toggleable: true,
              class: "mb-2"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_64, [
                  _createElementVNode("div", _hoisted_65, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.procurementLeadDays')), 1),
                    _createVNode(_component_InputNumber, {
                      locale: _ctx.locale,
                      autocomplete: "off",
                      showButtons: "",
                      min: 0,
                      modelValue: _ctx.v$.procurementLeadDays.$model,
                      "onUpdate:modelValue": _cache[54] || (_cache[54] = $event => ((_ctx.v$.procurementLeadDays.$model) = $event)),
                      class: _normalizeClass({'p-invalid':_ctx.v$.procurementLeadDays.$invalid && _ctx.submitted})
                    }, null, 8, ["locale", "modelValue", "class"]),
                    (_ctx.submitted)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.procurementLeadDays.$silentErrors, (error) => {
                          return (_openBlock(), _createElementBlock("small", {
                            style: {"display":"block"},
                            class: "p-error",
                            key: error
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_66, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.minimumStockQuantity')), 1),
                    _createVNode(_component_InputNumber, {
                      locale: _ctx.locale,
                      modelValue: _ctx.v$.minimumStockQuantity.$model,
                      "onUpdate:modelValue": _cache[55] || (_cache[55] = $event => ((_ctx.v$.minimumStockQuantity.$model) = $event)),
                      autocomplete: "off",
                      showButtons: "",
                      class: "mb-3"
                    }, null, 8, ["locale", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_67, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.targetStockQuantity')), 1),
                    _createVNode(_component_InputNumber, {
                      locale: _ctx.locale,
                      autocomplete: "off",
                      showButtons: "",
                      modelValue: _ctx.v$.targetStockQuantity.$model,
                      "onUpdate:modelValue": _cache[56] || (_cache[56] = $event => ((_ctx.v$.targetStockQuantity.$model) = $event)),
                      class: "mb-3"
                    }, null, 8, ["locale", "modelValue"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["legend"]),
            _createVNode(_component_p_fieldset, {
              legend: _ctx.$t('labels.productHome.preferences'),
              toggleable: true
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_68, [
                  _createElementVNode("div", _hoisted_69, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.productType')), 1),
                    _createVNode(_component_p_dropdown, {
                      modelValue: _ctx.productDetails?.weclapp?.articleType,
                      disabled: true,
                      options: _ctx.productTypeOptions,
                      optionLabel: "label",
                      optionValue: "value",
                      class: "mb-3"
                    }, null, 8, ["modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_70, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.unit')), 1),
                    _createVNode(_component_p_dropdown, {
                      modelValue: _ctx.productDetails?.weclapp?.unitId,
                      disabled: true,
                      options: _ctx.unitOptions,
                      optionLabel: "label",
                      optionValue: "value",
                      class: "mb-3"
                    }, null, 8, ["modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_71, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.serialNumberExtern')), 1),
                    _createVNode(_component_app_checkbox, {
                      modelValue: _ctx.v$.serialNumberRequired.$model,
                      "onUpdate:modelValue": _cache[57] || (_cache[57] = $event => ((_ctx.v$.serialNumberRequired.$model) = $event)),
                      disabled: true,
                      binary: true,
                      class: "ml-2 mb-1"
                    }, null, 8, ["modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_72, [
                  _createElementVNode("div", _hoisted_73, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.currency')), 1),
                    _createVNode(_component_p_dropdown, {
                      modelValue: _ctx.computedCurrency,
                      disabled: true,
                      options: _ctx.currencyOptions,
                      optionLabel: "label",
                      optionValue: "value",
                      class: "mb-3"
                    }, null, 8, ["modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_74, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.taxRateType')), 1),
                    _createVNode(_component_p_dropdown, {
                      modelValue: _ctx.productDetails?.weclapp?.taxRateType,
                      disabled: true,
                      options: _ctx.taxRateTypeOptions,
                      optionLabel: "label",
                      optionValue: "value",
                      class: "mb-3"
                    }, null, 8, ["modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_75, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.creator')), 1),
                    _createVNode(_component_p_dropdown, {
                      modelValue: _ctx.productDetails?.weclapp?.customAttributes?.article_creator,
                      options: _ctx.userOptions,
                      disabled: true,
                      optionLabel: "label",
                      optionValue: "value"
                    }, null, 8, ["modelValue", "options"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["legend"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_76, [
          _createVNode(_component_p_button, {
            severity: "success",
            disabled: _ctx.savingInProgress,
            type: "submit",
            textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.confirm'))
          }, null, 8, ["disabled", "textContent"])
        ])
      ], 32)
    ]),
    _createVNode(_component_p_dialog, {
      header: _ctx.$t('messages.pleaseConfirm'),
      visible: _ctx.showCollidingVersionDialog,
      "onUpdate:visible": _cache[60] || (_cache[60] = $event => ((_ctx.showCollidingVersionDialog) = $event)),
      breakpoints: {'960px': '75vw'},
      style: {width: '50vw'},
      closable: false,
      modal: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createElementVNode("h6", null, _toDisplayString(_ctx.$t('messages.collidingVersionProductEdit')), 1)
          ]),
          _createElementVNode("div", _hoisted_77, [
            _createVNode(_component_p_button, {
              onClick: _ctx.cancelSavingHandler,
              severity: "danger"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_p_button, {
              severity: "success",
              onClick: _cache[59] || (_cache[59] = $event => {_ctx.showCollidingVersionDialog = false;_ctx.executeUpdate()}),
              textContent: _toDisplayString(_ctx.$t('buttons.saveAnyway'))
            }, null, 8, ["textContent"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["header", "visible"])
  ], 64))
}