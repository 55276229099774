import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withKeys as _withKeys, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex align-content-center" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 0,
  class: "flex"
}
const _hoisted_6 = {
  key: 0,
  style: {"cursor":"pointer","display":"flex"}
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 0,
  class: "flex"
}
const _hoisted_11 = {
  key: 0,
  style: {"cursor":"pointer","display":"flex"}
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "text-xs" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  key: 0,
  class: "text-xs"
}
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "button-container" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = ["src", "alt"]
const _hoisted_23 = ["onClick"]
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { class: "w-full mb-3" }
const _hoisted_27 = {
  key: 0,
  class: "pi text-green-600 text-lg pi-check-circle mr-2"
}
const _hoisted_28 = {
  key: 1,
  class: "pi text-lg text-red-600 pi-times-circle mr-2"
}
const _hoisted_29 = { class: "font-semibold" }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = {
  key: 0,
  class: "col"
}
const _hoisted_32 = { style: {"display":"block"} }
const _hoisted_33 = {
  key: 1,
  class: "col"
}
const _hoisted_34 = { class: "col-2" }
const _hoisted_35 = { class: "font-bold" }
const _hoisted_36 = { class: "col-1" }
const _hoisted_37 = { class: "font-bold" }
const _hoisted_38 = { class: "col-1" }
const _hoisted_39 = { class: "font-bold" }
const _hoisted_40 = { class: "col-1" }
const _hoisted_41 = { class: "font-bold" }
const _hoisted_42 = {
  key: 2,
  class: "col-2"
}
const _hoisted_43 = {
  key: 0,
  style: {"display":"block"}
}
const _hoisted_44 = {
  key: 1,
  style: {"display":"block"}
}
const _hoisted_45 = {
  key: 3,
  class: "col-2"
}

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_FilterMenu = _resolveComponent("FilterMenu")
  const _component_TieredMenu = _resolveComponent("TieredMenu")
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_Column = _resolveComponent("Column")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_router_link = _resolveComponent("router-link")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _component_AnnounceShipmentDialog = _resolveComponent("AnnounceShipmentDialog")
  const _component_SendLabelToRemotePrinter = _resolveComponent("SendLabelToRemotePrinter")
  const _component_SendDocumentToRemotePrinter = _resolveComponent("SendDocumentToRemotePrinter")
  const _component_p_button = _resolveComponent("p-button")
  const _component_p_dialog = _resolveComponent("p-dialog")
  const _component_Tag = _resolveComponent("Tag")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.shipments')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          paginator: "",
          rows: 10,
          scrollable: "",
          scrollHeight: "calc(100vh - 23rem)",
          lazy: "",
          ref: "dt",
          dataKey: "id",
          totalRecords: _ctx.totalRecords,
          value: _ctx.shipments,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50],
          "current-page-report-template": "{first} to {last} of {totalRecords}",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[3] || (_cache[3] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          onPage: _cache[4] || (_cache[4] = $event => (_ctx.onPage($event))),
          onFilter: _cache[5] || (_cache[5] = $event => (_ctx.onFilter($event))),
          onSort: _cache[6] || (_cache[6] = $event => (_ctx.onSort($event))),
          onStateRestore: _ctx.onStateRestore,
          stateStorage: "local",
          stateKey: "shipment-list-state-session"
        }, {
          expansion: _withCtx((slotProps) => [
            (slotProps.data.description)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  innerHTML: slotProps.data.description
                }, null, 8, _hoisted_24))
              : (_openBlock(), _createElementBlock("div", _hoisted_25, "No description available."))
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              style: {"min-width":"4rem"},
              class: "tt-filter-menu-th"
            }, {
              filter: _withCtx(() => [
                _createVNode(_component_FilterMenu, {
                  "parent-component-id": "shipment-list",
                  "parent-filters": _ctx.filters,
                  onClearFilters: _ctx.clearFilters,
                  onApplyFilters: _ctx.applyFilters
                }, null, 8, ["parent-filters", "onClearFilters", "onApplyFilters"]),
                (_ctx.groupedSelectionColumnAvailable)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: "pi pi-cog",
                      style: {"padding-left":"7px","cursor":"pointer"},
                      onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.bulkMenuToggle && _ctx.bulkMenuToggle(...args))),
                      "aria-haspopup": "true",
                      "aria-controls": "overlay_tmenu"
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_TieredMenu, {
                  ref: "bulkMenu",
                  id: "overlay_tmenu",
                  model: _ctx.bulkItems,
                  popup: ""
                }, null, 8, ["model"])
              ]),
              body: _withCtx(({data}) => [
                (_ctx.shippingCarrierAnnouncementAvailable(data) && !_ctx.groupedShipmentsSelection.some((item) => item.warehouse?.id !== data.warehouse?.id || item.recipientParty?.id !== data.recipientParty?.id || item.shippingCarrier?.id !== data.shippingCarrier?.id || item.recipientParty?.customerSalesChannel !== data.recipientParty?.customerSalesChannel))
                  ? (_openBlock(), _createBlock(_component_p_checkbox, {
                      key: 0,
                      modelValue: _ctx.groupedShipmentsSelection,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.groupedShipmentsSelection) = $event)),
                      value: data
                    }, null, 8, ["modelValue", "value"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "shipmentNumber",
              sortable: true,
              style: {"min-width":"8rem"},
              header: _ctx.$t('labels.shipment.shipmentNumber')
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("a", {
                    href: data.url + 'webapp/view/shipment/ShipmentDetailOutgoing.page?entityId=' + data.id,
                    target: "_blank"
                  }, _toDisplayString(data.shipmentNumber), 9, _hoisted_2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-teltec': data?.salesChannel === 'NET1', 'sales-channel-videodata': data?.salesChannel === 'NET2'}])
                  }, null, 2)
                ])
              ]),
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: "#"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              style: {"min-width":"9rem"},
              field: "recipient",
              header: _ctx.$t('labels.shipment.recipient')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: "#"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("a", {
                  class: "text-xs",
                  href: data.url + 'webapp/view/party/PartyDetail.page?entityId=' + data.recipientParty?.id,
                  target: "_blank"
                }, _toDisplayString(_ctx.outputRecipientInGrid(data)), 9, _hoisted_3)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "createdDate",
              dataType: "date",
              sortable: true,
              header: _ctx.$t('labels.createdAt'),
              style: {"max-width":"16rem"}
            }, {
              filter: _withCtx(({filterModel}) => [
                _createVNode(_component_Calendar, {
                  class: "p-column-filter text-xs",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  dateFormat: "dd.mm.yy"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              body: _withCtx((data) => [
                _createTextVNode(_toDisplayString(new Date(data.data.createdDate).toLocaleDateString("de-DE")), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('labels.status'),
              style: {"max-width":"10rem"},
              field: "status",
              sortable: true,
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.getStatusCellStyle(data.status))
                }, _toDisplayString(_ctx.$t('labels.shipment.statusOptions.' + data.status)), 3)
              ]),
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_Dropdown, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  placeholder: "Any",
                  options: [{label: _ctx.$t('labels.shipment.statusOptions.NEW'), value: 'NEW'}, {label: _ctx.$t('labels.shipment.statusOptions.DELIVERY_NOTE_PRINTED'), value: 'DELIVERY_NOTE_PRINTED'}, {label: _ctx.$t('labels.shipment.statusOptions.SHIPPED'), value: 'SHIPPED'}, {label: _ctx.$t('labels.shipment.statusOptions.CANCELLED'), value: 'CANCELLED'}],
                  optionLabel: "label",
                  optionValue: "value",
                  class: "p-column-filter text-xs",
                  onChange: $event => (filterCallback()),
                  showClear: true
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('labels.shipment.shipmentType'),
              style: {"max-width":"10rem"},
              field: "shipmentType",
              sortable: true,
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              body: _withCtx(({data}) => [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.getShipmentTypeCellStyle(data.shipmentType))
                }, _toDisplayString(_ctx.$t('labels.shipment.shipmentTypeOptions.' +
                    data.shipmentType)), 3)
              ]),
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_Dropdown, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  options: [{label: _ctx.$t('labels.shipment.shipmentTypeOptions.STANDARD'), value: 'STANDARD'}, {label: _ctx.$t('labels.shipment.shipmentTypeOptions.CONSIGNMENT'), value: 'CONSIGNMENT'}, {label: _ctx.$t('labels.shipment.shipmentTypeOptions.CUSTOMER_COMPENSATION'), value: 'CUSTOMER_COMPENSATION'}, {label: _ctx.$t('labels.shipment.shipmentTypeOptions.SUPPLIER_RETURN'), value: 'SUPPLIER_RETURN'}, {label: _ctx.$t('labels.shipment.shipmentTypeOptions.CONSIGNMENT_RETURN'), value: 'CONSIGNMENT_RETURN'}, {label: _ctx.$t('labels.shipment.shipmentTypeOptions.INTERNAL'), value: 'INTERNAL'}],
                  optionLabel: "label",
                  optionValue: "value",
                  placeholder: "Any",
                  class: "p-column-filter text-xs",
                  onChange: $event => (filterCallback()),
                  showClear: true
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "salesOrder",
              sortable: true,
              style: {"max-width":"10rem"},
              header: _ctx.$t('labels.shipment.orderNumber')
            }, {
              body: _withCtx(({data}) => [
                (data.salesOrder)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: data.url + 'webapp/view/op/order/salesOrderDetail.page?entityId=' + data.salesOrder.id,
                      target: "_blank"
                    }, _toDisplayString(data.salesOrder.orderNumber), 9, _hoisted_4))
                  : _createCommentVNode("", true)
              ]),
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: "#"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "shippingCarrier",
              sortable: true,
              style: {"max-width":"fit-content"},
              header: _ctx.$t('labels.shipment.shippingCarrier')
            }, {
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.shippingCarrier?.name), 1)
              ]),
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: _ctx.$t('labels.shipment.shippingCarrier')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "warehouse",
              sortable: true,
              style: {"max-width":"fit-content"},
              header: _ctx.$t('labels.shipment.warehouse')
            }, {
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.warehouse?.name), 1)
              ]),
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: _ctx.$t('labels.shipment.warehouse')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "packageTrackingNumber",
              header: "Tracking #",
              style: {"min-width":"12rem","max-width":"fit-content"}
            }, {
              body: _withCtx(({data}) => [
                (!data.packageTrackingNumber || data.packageTrackingNumber.indexOf(',') === -1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createTextVNode(_toDisplayString(data.packageTrackingNumber) + " ", 1),
                      (data.packageTrackingNumber && _ctx.upsViewPermissionAvailable && data.shippingCarrierType !== null)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                            (data.shippingCarrierType === 'ups')
                              ? _withDirectives((_openBlock(), _createElementBlock("i", {
                                  key: 0,
                                  onClick: $event => (_ctx.getUpsTrackingDetails(data.packageTrackingNumber)),
                                  class: "pi pi-truck ml-1",
                                  style: {"margin-top":"2px"}
                                }, null, 8, _hoisted_7)), [
                                  [
                                    _directive_tooltip,
                                    _ctx.$t('labels.shipment.shippingCarrierTrackingDetails', {carrierName: _ctx.resolveShipmentCarrierType(data)}),
                                    void 0,
                                    { top: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (data.documents && data.documents.some((item) => {return item.name.startsWith(data.packageTrackingNumber.trim());}))
                              ? _withDirectives((_openBlock(), _createElementBlock("i", {
                                  key: 1,
                                  onClick: $event => {_ctx.showTrackingLabel(data.documents.find((item) => {return item.name.startsWith(data.packageTrackingNumber.trim());}))},
                                  class: "pi pi-instagram ml-1",
                                  style: {"margin-top":"2px"}
                                }, null, 8, _hoisted_8)), [
                                  [
                                    _directive_tooltip,
                                    _ctx.$t('labels.shipment.showShippingCarrierLabel', {carrierName: _ctx.resolveShipmentCarrierType(data)}),
                                    void 0,
                                    { top: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (data.documents && data.documents.some((item) => {return item.name.startsWith(data.packageTrackingNumber.trim());}))
                              ? _withDirectives((_openBlock(), _createElementBlock("i", {
                                  key: 2,
                                  onClick: $event => (_ctx.printUpsLabel(data, data.packageTrackingNumber)),
                                  class: "pi pi-print ml-1",
                                  style: {"margin-top":"2px"}
                                }, null, 8, _hoisted_9)), [
                                  [
                                    _directive_tooltip,
                                    _ctx.$t('labels.printDocumentRemotely'),
                                    void 0,
                                    { top: true }
                                  ]
                                ])
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(data.packageTrackingNumber.split(','), (element) => {
                      return (_openBlock(), _createElementBlock("div", null, [
                        (element.trim() !== '')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createTextVNode(_toDisplayString(element.trim()) + " ", 1),
                              (data.shippingCarrierType !== null)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                                    (data.shippingCarrierType === 'ups')
                                      ? _withDirectives((_openBlock(), _createElementBlock("i", {
                                          key: 0,
                                          onClick: $event => (_ctx.getUpsTrackingDetails(element.trim())),
                                          class: "pi pi-truck ml-1",
                                          style: {"margin-top":"2px"}
                                        }, null, 8, _hoisted_12)), [
                                          [
                                            _directive_tooltip,
                                            _ctx.$t('labels.shipment.shippingCarrierTrackingDetails', {carrierName: _ctx.resolveShipmentCarrierType(data)}),
                                            void 0,
                                            { top: true }
                                          ]
                                        ])
                                      : _createCommentVNode("", true),
                                    (data.documents && data.documents.some((item) => {return item.name.startsWith(element.trim());}))
                                      ? _withDirectives((_openBlock(), _createElementBlock("i", {
                                          key: 1,
                                          onClick: $event => {_ctx.showTrackingLabel(data.documents.find((item) => {return item.name.startsWith(element.trim());}))},
                                          class: "pi pi-instagram ml-1",
                                          style: {"margin-top":"2px"}
                                        }, null, 8, _hoisted_13)), [
                                          [
                                            _directive_tooltip,
                                            _ctx.$t('labels.shipment.showShippingCarrierLabel', {carrierName:  _ctx.resolveShipmentCarrierType(data)}),
                                            void 0,
                                            { top: true }
                                          ]
                                        ])
                                      : _createCommentVNode("", true),
                                    (data.documents && data.documents.some((item) => {return item.name.startsWith(element.trim());}))
                                      ? _withDirectives((_openBlock(), _createElementBlock("i", {
                                          key: 2,
                                          onClick: $event => (_ctx.printUpsLabel(data, element.trim())),
                                          class: "pi pi-print ml-1",
                                          style: {"margin-top":"2px"}
                                        }, null, 8, _hoisted_14)), [
                                          [
                                            _directive_tooltip,
                                            _ctx.$t('labels.printDocumentRemotely'),
                                            void 0,
                                            { top: true }
                                          ]
                                        ])
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 256))
              ]),
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: "#"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { style: {"min-width":"10rem"} }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_15, [
                  _createTextVNode(_toDisplayString(_ctx.$t('labels.shipment.weight')) + " : ", 1),
                  _createElementVNode("b", null, _toDisplayString(data.packageWeight), 1),
                  (data?.customAttributes?.delivery_package_information)
                    ? _withDirectives((_openBlock(), _createElementBlock("i", {
                        key: 0,
                        onClick: $event => {_ctx.selectedShipment = data; _ctx.showPackageInformationDialog = true;},
                        class: "pi pi-box ml-2",
                        style: {"cursor":"pointer"}
                      }, null, 8, _hoisted_16)), [
                        [
                          _directive_tooltip,
                          _ctx.$t('labels.shipment.packageInformation'),
                          void 0,
                          { top: true }
                        ]
                      ])
                    : _createCommentVNode("", true)
                ]),
                (data?.shipmentMethod?.name)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createElementVNode("b", null, _toDisplayString(data.shipmentMethod.name), 1),
                      (data.isPickup)
                        ? _withDirectives((_openBlock(), _createElementBlock("i", {
                            key: 0,
                            onClick: $event => (_ctx.downloadPickupDocument(data)),
                            class: "pi pi-file-pdf ml-1 text-red-600",
                            style: {"cursor":"pointer"}
                          }, null, 8, _hoisted_18)), [
                            [
                              _directive_tooltip,
                              _ctx.$t('labels.downloadFile'),
                              void 0,
                              { top: true }
                            ]
                          ])
                        : _createCommentVNode("", true),
                      (data.isPickup && _ctx.existingPickupDocument(data))
                        ? _withDirectives((_openBlock(), _createElementBlock("i", {
                            key: 1,
                            onClick: $event => (_ctx.printDocument(_ctx.existingPickupDocument(data))),
                            class: "pi pi-print ml-1",
                            style: {"cursor":"pointer"}
                          }, null, 8, _hoisted_19)), [
                            [
                              _directive_tooltip,
                              _ctx.$t('labels.printSpecificDocumentRemotely', {name: _ctx.existingPickupDocument(data).name}),
                              void 0,
                              { top: true }
                            ]
                          ])
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            (_ctx.editPermissionAvailable)
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 0,
                  style: {"min-width":"60px","max-width":"'fit-content'"}
                }, {
                  body: _withCtx(({data}) => [
                    _createElementVNode("div", _hoisted_20, [
                      ((data.status === 'NEW' || data.status === 'DELIVERY_NOTE_PRINTED'))
                        ? _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                            key: 0,
                            to: {
                                    name: 'ShipmentMask',
                                    params: {shipmentNumber: data.shipmentNumber}
                                }
                          }, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createElementVNode("i", { class: "pi pi-pencil text-sm mr-2 text-color" }, null, -1)
                            ])),
                            _: 2
                          }, 1032, ["to"])), [
                            [
                              _directive_tooltip,
                              _ctx.$t('labels.shipment.shipmentMask'),
                              void 0,
                              { left: true }
                            ]
                          ])
                        : _createCommentVNode("", true),
                      (_ctx.shippingCarrierAnnouncementAvailable(data))
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            style: {"cursor":"pointer"},
                            onClick: $event => {_ctx.groupedShipmentsSelection.length = 0;_ctx.selectedShipment = data;_ctx.displayUpsDialog = true}
                          }, [
                            (data.shippingCarrierType !== null)
                              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  class: "rounded",
                                  src: require('@/assets/img/'+(data.shippingCarrierType || _ctx.ups)+'.png'),
                                  alt: _ctx.resolveShipmentCarrierType(data),
                                  style: {"height":"18px","margin-top":"-5px","background-color":"#ffb500"}
                                }, null, 8, _hoisted_22)), [
                                  [
                                    _directive_tooltip,
                                    _ctx.$t('labels.shipment.shippingCarrierAnnouncePackage', {carrierName: _ctx.resolveShipmentCarrierType(data)}),
                                    void 0,
                                    { left: true }
                                  ]
                                ])
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_21))
                        : _createCommentVNode("", true),
                      (_ctx.upsEditPermissionAvailable && data.packageTrackingNumber && data.shippingCarrierType !== null)
                        ? _withDirectives((_openBlock(), _createElementBlock("i", {
                            key: 2,
                            onClick: $event => (_ctx.voidShipment(data.shipmentNumber, _ctx.resolveShipmentCarrierType(data))),
                            class: _normalizeClass(["pi pi-ban text-red-600", {'ml-1': (data.status === 'NEW' || data.status === 'DELIVERY_NOTE_PRINTED') || _ctx.shippingCarrierAnnouncementAvailable(data)}]),
                            style: {"margin-top":"2px","cursor":"pointer"}
                          }, null, 10, _hoisted_23)), [
                            [
                              _directive_tooltip,
                              _ctx.$t('labels.shipment.cancelShippingCarrierShipment', {carrierName: _ctx.resolveShipmentCarrierType(data)}),
                              void 0,
                              { left: true }
                            ]
                          ])
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["totalRecords", "value", "filters", "onStateRestore"])
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_AnnounceShipmentDialog, {
      shipment: _ctx.selectedShipment,
      groupedShipments: _ctx.groupedShipments,
      "display-dialog": _ctx.displayUpsDialog,
      onCloseDialog: _ctx.closeDialogListener
    }, null, 8, ["shipment", "groupedShipments", "display-dialog", "onCloseDialog"]),
    _createVNode(_component_SendLabelToRemotePrinter, {
      "display-dialog": _ctx.displaySendToRemotePrinterDialog,
      onCloseDialog: _ctx.closePrintDialogListener,
      "shipment-number": _ctx.selectedShipment?.shipmentNumber,
      "tracking-number": _ctx.selectedTrackingNumber
    }, null, 8, ["display-dialog", "onCloseDialog", "shipment-number", "tracking-number"]),
    _createVNode(_component_SendDocumentToRemotePrinter, {
      "display-dialog": _ctx.displayPickupRemotePrinterDialog,
      onCloseDialog: _ctx.closePrintDialogListener,
      "selected-document": _ctx.selectedDocument
    }, null, 8, ["display-dialog", "onCloseDialog", "selected-document"]),
    _createVNode(_component_p_dialog, {
      header: _ctx.$t('labels.result'),
      visible: _ctx.showWorkflowExecutionOverviewDialog,
      "onUpdate:visible": _cache[8] || (_cache[8] = $event => ((_ctx.showWorkflowExecutionOverviewDialog) = $event)),
      breakpoints: {'960px': '75vw', '640px': '90vw'},
      style: {width: '600px'},
      modal: "",
      closable: false
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["w-full flex justify-content-end", {'justify-content-between' : _ctx.workflowIdenfier==='full', 'justify-content-end' : _ctx.workflowIdenfier!=='full'}])
        }, [
          false
            ? (_openBlock(), _createBlock(_component_p_button, {
                key: 0,
                severity: "danger",
                onClick: _cache[7] || (_cache[7] = $event => (_ctx.showWorkflowExecutionOverviewDialog = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_p_button, {
            severity: "success",
            onClick: _ctx.handleWorkflowResultsConfirmed
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ], 2)
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workflowExecutionResults, (item) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_26, [
            (!item.error)
              ? (_openBlock(), _createElementBlock("i", _hoisted_27))
              : (_openBlock(), _createElementBlock("i", _hoisted_28)),
            _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$t("labels.shipment.workflow.steps." + item?.step)), 1),
            _createElementVNode("div", null, [
              (item.result && typeof item.result === 'string')
                ? (_openBlock(), _createElementBlock("small", _hoisted_30, _toDisplayString(item.result), 1))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 256))
      ]),
      _: 1
    }, 8, ["header", "visible"]),
    _createVNode(_component_p_dialog, {
      header: _ctx.$t('labels.shipment.packageInformation'),
      visible: _ctx.showPackageInformationDialog,
      "onUpdate:visible": _cache[9] || (_cache[9] = $event => ((_ctx.showPackageInformationDialog) = $event)),
      breakpoints: {'960px': '75vw', '640px': '90vw'},
      style: {width: '900px'},
      modal: "",
      onHide: _cache[10] || (_cache[10] = $event => {_ctx.selectedShipment = null;})
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.packageInformationData, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "grid"
          }, [
            (item.articleNumber)
              ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                  _cache[12] || (_cache[12] = _createElementVNode("label", { class: "font-bold" }, "Karton", -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("small", _hoisted_32, _toDisplayString(item.article?.name), 1)
                  ])
                ]))
              : (item.singlePackageArticleNumber)
                ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                    _createVNode(_component_Tag, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.singlePackage')), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t('labels.productHome.grossWeightShort')), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.formatter.format(item.grossWeight)) + " kg", 1)
            ]),
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("label", _hoisted_37, _toDisplayString(_ctx.$t('labels.productHome.lengthShort')), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.formatter.format(item.length)) + " cm", 1)
            ]),
            _createElementVNode("div", _hoisted_38, [
              _createElementVNode("label", _hoisted_39, _toDisplayString(_ctx.$t('labels.productHome.heightShort')), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.formatter.format(item.height)) + " cm", 1)
            ]),
            _createElementVNode("div", _hoisted_40, [
              _createElementVNode("label", _hoisted_41, _toDisplayString(_ctx.$t('labels.productHome.widthShort')), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.formatter.format(item.width)) + " cm", 1)
            ]),
            (item.dangerousGoods)
              ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_Tag, null, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createTextVNode("Gefahrgut")
                      ])),
                      _: 1
                    }),
                    (item.netWeight)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_43, _toDisplayString(_ctx.$t('labels.productHome.netWeightShort')) + " : " + _toDisplayString(_ctx.formatter.format(item.netWeight)) + " kg ", 1))
                      : _createCommentVNode("", true),
                    (item.chemicalId)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_44, _toDisplayString(_ctx.$t('labels.shipment.upsChemicalId')) + ": " + _toDisplayString(item.chemicalId), 1))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_45, " "))
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["header", "visible"])
  ], 64))
}