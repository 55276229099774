import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mt-2" }
const _hoisted_2 = { class: "flex align-items-center mb-3 mt-3" }
const _hoisted_3 = { class: "flex-none flex mr-5" }
const _hoisted_4 = { class: "flex align-content-center" }
const _hoisted_5 = { class: "p-button-label" }
const _hoisted_6 = { class: "flex flex-grow-1" }
const _hoisted_7 = {
  key: 0,
  class: "text-center w-full"
}
const _hoisted_8 = {
  key: 1,
  class: "text-center w-full"
}
const _hoisted_9 = {
  key: 0,
  class: "flex-none flex ml-5"
}

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_p_button = _resolveComponent("p-button")
  const _component_Images = _resolveComponent("Images")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_TabView = _resolveComponent("TabView")
  const _component_AddDialog = _resolveComponent("AddDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_p_button, {
            class: "text-sm",
            severity: "success",
            onClick: _cache[1] || (_cache[1] = $event => {_ctx.displayAddDialog = true;})
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _cache[3] || (_cache[3] = _createElementVNode("span", {
                  class: "p-button-icon p-button-icon-left pi pi-plus",
                  "data-pc-section": "icon"
                }, null, -1)),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('buttons.addNewGeneric')), 1),
                (_ctx.productDetails?.shopware)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                    }, null, 2))
                  : _createCommentVNode("", true),
                (_ctx.productDetails?.secondShopware)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-1", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                    }, null, 2))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _mergeProps(_ctx.getRootProps(), { class: "import-wizard-container font-medium text-sm flex-grow-1 flex" }), [
            _createElementVNode("input", _normalizeProps(_guardReactiveProps(_ctx.getInputProps())), null, 16),
            (_ctx.acceptedFiles.length > 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.dropZoneText), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t("labels.importWizardInfoText")), 1))
          ], 16)
        ]),
        (_ctx.showUploadConfirmButton)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_p_button, {
                class: "p-button-sm p-button-success",
                label: _ctx.$t('buttons.confirm'),
                onClick: _ctx.handleUpload
              }, null, 8, ["label", "onClick"]),
              _createVNode(_component_p_button, {
                style: {"margin-left":"5px"},
                class: "p-button-sm p-button-danger",
                label: _ctx.$t('buttons.cancel'),
                onClick: _cache[2] || (_cache[2] = $event => (_ctx.acceptedFiles.length = 0))
              }, null, 8, ["label"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_TabView, { class: "tabview-custom text-sm" }, {
      default: _withCtx(() => [
        _createVNode(_component_TabPanel, {
          disabled: !_ctx.productDetails?.shopware
        }, {
          header: _withCtx(() => [
            _cache[4] || (_cache[4] = _createElementVNode("i", { class: "pi pi-images" }, null, -1)),
            _createElementVNode("span", {
              class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
            }, null, 2)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Images, {
              "shopware-details": _ctx.productDetails?.shopware,
              "second-shopware-details": _ctx.productDetails?.secondShopware,
              onReloadProduct: _ctx.loadProductDetails
            }, null, 8, ["shopware-details", "second-shopware-details", "onReloadProduct"])
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(_component_TabPanel, {
          disabled: !_ctx.productDetails?.secondShopware
        }, {
          header: _withCtx(() => [
            _cache[5] || (_cache[5] = _createElementVNode("i", { class: "pi pi-images" }, null, -1)),
            _createElementVNode("span", {
              class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
            }, null, 2)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Images, {
              "shopware-details": _ctx.productDetails?.secondShopware,
              "second-shopware-details": _ctx.productDetails?.shopware,
              onReloadProduct: _ctx.loadProductDetails,
              platform: _ctx.secondPlatform
            }, null, 8, ["shopware-details", "second-shopware-details", "onReloadProduct", "platform"])
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    }),
    _createVNode(_component_AddDialog, {
      displayResponsive: _ctx.displayAddDialog,
      "product-number": _ctx.productDetails?.shopware?.productNumber,
      onCloseDialog: _ctx.closeDialogListener
    }, null, 8, ["displayResponsive", "product-number", "onCloseDialog"])
  ], 64))
}