import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/themes/lara-light-teal.png'
import _imports_1 from '@/assets/img/themes/lara-dark-teal.png'


const _hoisted_1 = { class: "flex align-items-center" }
const _hoisted_2 = { class: "flex gap-2 align-items-center" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "field-radiobutton flex-1" }
const _hoisted_5 = { class: "field-radiobutton flex-1" }
const _hoisted_6 = { class: "flex align-items-center justify-content-between gap-3 mb-3" }
const _hoisted_7 = { class: "flex align-items-center justify-content-between gap-3 mb-3" }

export function render(_ctx, _cache) {
  const _component_p_button = _resolveComponent("p-button")
  const _component_RadioButton = _resolveComponent("RadioButton")
  const _component_Sidebar = _resolveComponent("Sidebar")

  return (_openBlock(), _createBlock(_component_Sidebar, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[12] || (_cache[12] = $event => ((_ctx.visible) = $event)),
    position: "right",
    transitionOptions: '.3s cubic-bezier(0, 0, 0.2, 1)',
    class: "layout-config-sidebar w-20rem",
    onHide: _ctx.onHide
  }, {
    default: _withCtx(() => [
      _cache[24] || (_cache[24] = _createElementVNode("h5", null, "Maßstab", -1)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_p_button, {
          icon: "pi pi-minus",
          type: "button",
          onClick: _cache[0] || (_cache[0] = $event => (_ctx.decrementScale())),
          class: "p-button-text p-button-rounded w-2rem h-2rem mr-2",
          disabled: _ctx.layoutConfig.scale === _ctx.scales[0]
        }, null, 8, ["disabled"]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scales, (s) => {
            return (_openBlock(), _createElementBlock("i", {
              class: _normalizeClass(["pi pi-circle-fill text-300", { 'text-primary-500': s === _ctx.layoutConfig.scale }]),
              key: s
            }, null, 2))
          }), 128))
        ]),
        _createVNode(_component_p_button, {
          icon: "pi pi-plus",
          type: "button",
          pButton: "",
          onClick: _cache[1] || (_cache[1] = $event => (_ctx.incrementScale())),
          class: "p-button-text p-button-rounded w-2rem h-2rem ml-2",
          disabled: _ctx.layoutConfig.scale === _ctx.scales[_ctx.scales.length - 1]
        }, null, 8, ["disabled"])
      ]),
      (!_ctx.simple)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _cache[15] || (_cache[15] = _createElementVNode("h5", null, "Menüart", -1)),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_RadioButton, {
                  name: "menuMode",
                  value: "static",
                  modelValue: _ctx.layoutConfig.menuMode,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.layoutConfig.menuMode) = $event)),
                  inputId: "mode1"
                }, null, 8, ["modelValue"]),
                _cache[13] || (_cache[13] = _createElementVNode("label", { for: "mode1" }, "Static", -1))
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_RadioButton, {
                  name: "menuMode",
                  value: "overlay",
                  modelValue: _ctx.layoutConfig.menuMode,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.layoutConfig.menuMode) = $event)),
                  inputId: "mode2"
                }, null, 8, ["modelValue"]),
                _cache[14] || (_cache[14] = _createElementVNode("label", { for: "mode2" }, "Overlay", -1))
              ])
            ])
          ], 64))
        : _createCommentVNode("", true),
      _cache[25] || (_cache[25] = _createElementVNode("h5", null, "Layout-Skin", -1)),
      _cache[26] || (_cache[26] = _createElementVNode("div", { class: "flex align-items-center gap-2 mb-3" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "Lara Light Teal",
          class: "border-circle",
          style: {"width":"1.5rem"}
        }),
        _createElementVNode("span", { class: "font-medium" }, "Lara")
      ], -1)),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_p_button, {
          class: "bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 border-primary",
          style: {"border-radius":"30px"},
          onClick: _cache[4] || (_cache[4] = $event => (_ctx.onChangeTheme('lara-light-indigo', 'light', _ctx.layoutConfig.theme)))
        }, {
          default: _withCtx(() => _cache[16] || (_cache[16] = [
            _createElementVNode("span", {
              class: "block h-1rem w-full",
              style: {"border-radius":"30px","background":"linear-gradient(\n                        rgb(88, 95, 224) 0%,\n                        rgba(88, 95, 224, 0.5) 100%\n                    )"}
            }, null, -1)
          ])),
          _: 1
        }),
        _createVNode(_component_p_button, {
          class: "bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 border-primary",
          style: {"border-radius":"30px"},
          onClick: _cache[5] || (_cache[5] = $event => (_ctx.onChangeTheme('lara-light-blue', 'light', _ctx.layoutConfig.theme)))
        }, {
          default: _withCtx(() => _cache[17] || (_cache[17] = [
            _createElementVNode("span", {
              class: "block h-1rem w-full",
              style: {"border-radius":"30px","background":"linear-gradient(\n                        rgb(67, 120, 230) 0%,\n                        rgba(67, 120, 230, 0.5) 100%\n                    )"}
            }, null, -1)
          ])),
          _: 1
        }),
        _createVNode(_component_p_button, {
          class: "bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 border-primary",
          style: {"border-radius":"30px"},
          onClick: _cache[6] || (_cache[6] = $event => (_ctx.onChangeTheme('lara-light-purple', 'light', _ctx.layoutConfig.theme)))
        }, {
          default: _withCtx(() => _cache[18] || (_cache[18] = [
            _createElementVNode("span", {
              class: "block h-1rem w-full",
              style: {"border-radius":"30px","background":"linear-gradient(\n                        rgb(119, 88, 228) 0%,\n                        rgba(119, 88, 228, 0.5) 100%\n                    )"}
            }, null, -1)
          ])),
          _: 1
        }),
        _createVNode(_component_p_button, {
          class: "bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 border-primary",
          style: {"border-radius":"30px"},
          onClick: _cache[7] || (_cache[7] = $event => (_ctx.onChangeTheme('lara-light-teal', 'light', _ctx.layoutConfig.theme)))
        }, {
          default: _withCtx(() => _cache[19] || (_cache[19] = [
            _createElementVNode("span", {
              class: "block h-1rem w-full",
              style: {"border-radius":"30px","background":"linear-gradient(\n                        rgb(20, 184, 166) 0%,\n                        rgba(20, 184, 166, 0.5) 100%\n                    )"}
            }, null, -1)
          ])),
          _: 1
        })
      ]),
      _cache[27] || (_cache[27] = _createElementVNode("div", { class: "flex align-items-center gap-2 mb-3" }, [
        _createElementVNode("img", {
          src: _imports_1,
          alt: "Lara Light Teal",
          class: "border-circle",
          style: {"width":"1.5rem"}
        }),
        _createElementVNode("span", { class: "font-medium" }, "Lara Dark")
      ], -1)),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_p_button, {
          class: "bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 border-primary",
          style: {"border-radius":"30px"},
          onClick: _cache[8] || (_cache[8] = $event => (_ctx.onChangeTheme('lara-dark-indigo', 'dark', _ctx.layoutConfig.theme)))
        }, {
          default: _withCtx(() => _cache[20] || (_cache[20] = [
            _createElementVNode("span", {
              class: "block h-1rem w-full",
              style: {"border-radius":"30px","background":"linear-gradient(\n                        rgb(88, 95, 224) 0%,\n                        rgba(88, 95, 224, 0.5) 100%\n                    )"}
            }, null, -1)
          ])),
          _: 1
        }),
        _createVNode(_component_p_button, {
          class: "bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 border-primary",
          style: {"border-radius":"30px"},
          onClick: _cache[9] || (_cache[9] = $event => (_ctx.onChangeTheme('lara-dark-blue', 'dark', _ctx.layoutConfig.theme)))
        }, {
          default: _withCtx(() => _cache[21] || (_cache[21] = [
            _createElementVNode("span", {
              class: "block h-1rem w-full",
              style: {"border-radius":"30px","background":"linear-gradient(\n                        rgb(67, 120, 230) 0%,\n                        rgba(67, 120, 230, 0.5) 100%\n                    )"}
            }, null, -1)
          ])),
          _: 1
        }),
        _createVNode(_component_p_button, {
          class: "bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 border-primary",
          style: {"border-radius":"30px"},
          onClick: _cache[10] || (_cache[10] = $event => (_ctx.onChangeTheme('lara-dark-purple', 'dark', _ctx.layoutConfig.theme)))
        }, {
          default: _withCtx(() => _cache[22] || (_cache[22] = [
            _createElementVNode("span", {
              class: "block h-1rem w-full",
              style: {"border-radius":"30px","background":"linear-gradient(\n                        rgb(119, 88, 228) 0%,\n                        rgba(119, 88, 228, 0.5) 100%\n                    )"}
            }, null, -1)
          ])),
          _: 1
        }),
        _createVNode(_component_p_button, {
          class: "bg-transparent border-1 cursor-pointer p-2 w-3 flex align-items-center justify-content-center transition-all transition-duration-200 border-primary",
          style: {"border-radius":"30px"},
          onClick: _cache[11] || (_cache[11] = $event => (_ctx.onChangeTheme('lara-dark-teal', 'dark', _ctx.layoutConfig.theme)))
        }, {
          default: _withCtx(() => _cache[23] || (_cache[23] = [
            _createElementVNode("span", {
              class: "block h-1rem w-full",
              style: {"border-radius":"30px","background":"linear-gradient(\n                        rgb(20, 184, 166) 0%,\n                        rgba(20, 184, 166, 0.5) 100%\n                    )"}
            }, null, -1)
          ])),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["visible", "onHide"]))
}