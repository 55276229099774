import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "m-2" }
const _hoisted_2 = { class: "formgrid grid p-fluid mt-3 mb-3" }
const _hoisted_3 = { class: "col-2 field" }
const _hoisted_4 = { class: "col-4 field" }
const _hoisted_5 = { class: "flex justify-content-end mt-3" }

export function render(_ctx, _cache) {
  const _component_SelectButton = _resolveComponent("SelectButton")
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col field" }, null, -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_SelectButton, {
            modelValue: _ctx.v$.language.$model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$.language.$model) = $event)),
            options: [
                    {name: 'Deutsch', value: 'de'},
                    {name: 'Englisch', value: 'en'}
                ],
            optionLabel: "name",
            optionValue: "value"
          }, null, 8, ["modelValue"]),
          (_ctx.submitted)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.language.$silentErrors, (error) => {
                return (_openBlock(), _createElementBlock("small", {
                  style: {"display":"block"},
                  class: "p-error",
                  key: error
                }, _toDisplayString(error.$message), 1))
              }), 128))
            : _createCommentVNode("", true)
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col-1 field" }, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_SelectButton, {
            modelValue: _ctx.v$.office.$model,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.office.$model) = $event)),
            options: [
                    {name: 'BPM', value: 'bpm'},
                    {name: 'VD', value: 'vd'},
                    {name: 'Mainz', value: 'mz'},
                    {name: 'Waschow', value: 'lwl'},
                    {name: 'TT-Retoure', value: 'tt-hh'}
                ],
            optionLabel: "name",
            optionValue: "value"
          }, null, 8, ["modelValue"]),
          (_ctx.submitted)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.office.$silentErrors, (error) => {
                return (_openBlock(), _createElementBlock("small", {
                  style: {"display":"block"},
                  class: "p-error",
                  key: error
                }, _toDisplayString(error.$message), 1))
              }), 128))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_p_button, {
          disabled: _ctx.savingInProgress,
          severity: "success",
          textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.confirm')),
          type: "submit"
        }, null, 8, ["disabled", "textContent"])
      ])
    ], 32)
  ]))
}