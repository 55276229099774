import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex justify-content-end mt-5" }
const _hoisted_2 = { class: "w-full flex justify-content-between mt-5" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_SupplierAutoComplete = _resolveComponent("SupplierAutoComplete")
  const _component_p_button = _resolveComponent("p-button")
  const _component_StepperPanel = _resolveComponent("StepperPanel")
  const _component_file_upload_step = _resolveComponent("file-upload-step")
  const _component_price_list_upload_step = _resolveComponent("price-list-upload-step")
  const _component_Stepper = _resolveComponent("Stepper")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.isLoading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.isLoading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.importWizard')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Stepper, {
          linear: "",
          activeStep: _ctx.activeIndex,
          "onUpdate:activeStep": _cache[3] || (_cache[3] = $event => ((_ctx.activeIndex) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_StepperPanel, {
              header: _ctx.$t('labels.productAdd.supplier')
            }, {
              content: _withCtx(({ nextCallback }) => [
                _createVNode(_component_SupplierAutoComplete, {
                  onDataEntered: _cache[1] || (_cache[1] = $event => (_ctx.onSupplierChosen($event)))
                }),
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_p_button, {
                    severity: "success",
                    disabled: !_ctx.supplierConfiguration,
                    onClick: nextCallback,
                    textContent: _toDisplayString(_ctx.$t('buttons.next'))
                  }, null, 8, ["disabled", "onClick", "textContent"])
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_StepperPanel, {
              header: _ctx.$t('labels.yourFile')
            }, {
              content: _withCtx(({ prevCallback, nextCallback }) => [
                _createVNode(_component_file_upload_step, {
                  onSheetChosen: _ctx.onSpreadsheetChosen,
                  "supplier-configuration": _ctx.supplierConfiguration
                }, null, 8, ["onSheetChosen", "supplier-configuration"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_p_button, {
                    severity: "danger",
                    textContent: _toDisplayString(_ctx.$t('buttons.back')),
                    onClick: prevCallback
                  }, null, 8, ["textContent", "onClick"]),
                  _createVNode(_component_p_button, {
                    severity: "success",
                    disabled: !_ctx.showUploadButton,
                    onClick: $event => (_ctx.uploadFile(nextCallback, $event)),
                    textContent: _toDisplayString(_ctx.$t('buttons.next'))
                  }, null, 8, ["disabled", "onClick", "textContent"])
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_StepperPanel, {
              header: _ctx.$t('labels.columnMapping')
            }, {
              content: _withCtx(({ prevCallback, nextCallback }) => [
                _createVNode(_component_price_list_upload_step, {
                  "saved-file-id": _ctx.savedFile?.id,
                  "supplier-configuration": _ctx.supplierConfiguration,
                  "sheet-name": _ctx.chosenSheet,
                  "chosen-manufacturer": _ctx.chosenManufacturer,
                  "cost-price-start-date": _ctx.costPriceStartDate,
                  "list-price-start-date": _ctx.listPriceStartDate,
                  onColumnMappingSaved: _cache[2] || (_cache[2] = $event => (_ctx.validateFile())),
                  onBackButtonClicked: prevCallback
                }, null, 8, ["saved-file-id", "supplier-configuration", "sheet-name", "chosen-manufacturer", "cost-price-start-date", "list-price-start-date", "onBackButtonClicked"])
              ]),
              _: 1
            }, 8, ["header"])
          ]),
          _: 1
        }, 8, ["activeStep"])
      ]),
      _: 1
    }, 8, ["header"])
  ], 64))
}