import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.warehouseStockBPM')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          paginator: "",
          scrollable: "",
          scrollHeight: "calc(100vh - 23rem)",
          rows: 500,
          ref: "dt",
          dataKey: "id",
          value: _ctx.warehouseStockItems,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [20,50,100,200,500],
          "current-page-report-template": "{first} to {last} of {totalRecords}",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[1] || (_cache[1] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "teltecArticleNumber",
              sortable: "",
              style: {"min-width":"7rem"},
              header: 'Teltec ' + _ctx.$t('labels.articleNumber')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: "#"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "bpmName",
              sortable: "",
              style: {"min-width":"7rem"},
              header: 'BPM ' + _ctx.$t('labels.name')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "bpmArticleNumber",
              sortable: "",
              style: {"min-width":"7rem"},
              header: 'BPM ' + _ctx.$t('labels.articleNumber')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: "#"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "manufacturer",
              sortable: "",
              style: {"min-width":"7rem"},
              header: _ctx.$t('labels.manufacturer')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: "#"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "stockQuantity",
              sortable: "",
              style: {"min-width":"7rem"},
              header: 'BPM Lagerbestand'
            }),
            false
              ? (_openBlock(), _createBlock(_component_Column, {
                  key: 0,
                  field: "price",
                  sortable: "",
                  style: {"min-width":"8rem"},
                  header: 'EK Vorschlag'
                }, {
                  body: _withCtx(({data}) => [
                    (data.price)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.formatPrice(data.price)), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["value", "filters"])
      ]),
      _: 1
    }, 8, ["header"])
  ], 64))
}