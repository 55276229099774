import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { style: {"vertical-align":"middle"} }
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  key: 0,
  class: "text-xs"
}
const _hoisted_4 = {
  key: 1,
  class: "text-xs"
}
const _hoisted_5 = { style: {"vertical-align":"middle"} }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "tooltip-text"
}
const _hoisted_8 = {
  key: 1,
  class: "tooltip-text text-xs"
}
const _hoisted_9 = { style: {"vertical-align":"middle"} }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "grid p-fluid" }
const _hoisted_13 = { class: "field col" }
const _hoisted_14 = { class: "w-full mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Badge = _resolveComponent("Badge")
  const _component_BulkActionsMenu = _resolveComponent("BulkActionsMenu")
  const _component_Column = _resolveComponent("Column")
  const _component_InputText = _resolveComponent("InputText")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.deliveryTime')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          paginator: true,
          rows: 10,
          style: {"height":"100%"},
          scrollable: "",
          scrollHeight: "calc(100vh - 23rem)",
          lazy: true,
          editMode: "cell",
          onCellEditComplete: _ctx.onCellEditComplete,
          ref: "dt",
          dataKey: "id",
          totalRecords: _ctx.totalRecords,
          value: _ctx.products,
          "paginator-template": 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
          "rows-per-page-options": [10,20,50,100,200],
          "current-page-report-template": '{first} to {last} of {totalRecords}',
          filters: _ctx.filters,
          "onUpdate:filters": _cache[2] || (_cache[2] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          onPage: _cache[3] || (_cache[3] = $event => (_ctx.onPage($event))),
          onFilter: _cache[4] || (_cache[4] = $event => (_ctx.onFilter($event))),
          onSort: _cache[5] || (_cache[5] = $event => (_ctx.onSort($event))),
          onStateRestore: _ctx.onStateRestore,
          selection: _ctx.selectedProducts,
          "onUpdate:selection": _cache[6] || (_cache[6] = $event => ((_ctx.selectedProducts) = $event)),
          stateStorage: "local",
          stateKey: "products-delivery-time-list-state-session",
          onRowSelect: _ctx.onRowSelect,
          onRowSelectAll: _ctx.onRowSelectAll,
          onRowUnselect: _ctx.onRowUnselect,
          onRowUnselectAll: _ctx.onRowUnselectAll
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              selectionMode: "multiple",
              style: {"min-width":"3rem","max-width":"6rem"}
            }, {
              header: _withCtx(() => [
                _createVNode(_component_Badge, {
                  value: _ctx.mergedSelection.length
                }, null, 8, ["value"]),
                (_ctx.mergedSelection.length > 0)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: "pi pi-times-circle text-red-600",
                      style: {"padding-left":"7px","cursor":"pointer"},
                      onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.clearSelection && _ctx.clearSelection(...args)))
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_BulkActionsMenu, {
                  mergedSelection: _ctx.mergedSelection,
                  totalRecords: _ctx.totalRecords,
                  filters: _ctx.filters,
                  visibleColumns: Object.keys(_ctx.filters),
                  bulkItemsToPrepend: _ctx.bulkOptions,
                  onForceDataRefresh: _ctx.onForceDataRefresh
                }, null, 8, ["mergedSelection", "totalRecords", "filters", "visibleColumns", "bulkItemsToPrepend", "onForceDataRefresh"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "articleNumber",
              sortable: true,
              header: _ctx.$t('labels.articleNumber'),
              style: {"min-width":"13rem"}
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: _ctx.$t('labels.articleNumber')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("div", {
                  class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData && !data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData && !data.shopwareData.active, 'bg-gray-200': !data?.shopwareData?.active}])
                }, null, 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData && !data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData && !data.secondShopwareData.active, 'bg-gray-200': !data?.secondShopwareData?.active}])
                }, null, 2),
                _createElementVNode("span", _hoisted_1, [
                  _createElementVNode("a", {
                    href: data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id,
                    target: "_blank"
                  }, _toDisplayString(data.articleNumber), 9, _hoisted_2)
                ]),
                (_ctx.ekPriceCalculation(data))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createTextVNode(_toDisplayString(_ctx.$t('labels.ekPrice')) + ": ", 1),
                      _createElementVNode("b", null, _toDisplayString(_ctx.ekPriceCalculation(data)), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.uvpPriceCalculation(data))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _cache[11] || (_cache[11] = _createTextVNode(" UVP: ")),
                      _createElementVNode("b", null, _toDisplayString(_ctx.uvpPriceCalculation(data)), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "activeInAnyShop",
              dataType: "boolean",
              bodyClass: "text-center",
              style: {"min-width":"6rem"},
              header: _ctx.$t('labels.productHome.activeInShop')
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_TriStateCheckbox, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onChange: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': (data.customAttributes?.article_active_in_shop || data.customAttributes?.article_active_in_shop2), 'text-red-600 pi-times-circle': !(data.customAttributes?.article_active_in_shop || data.customAttributes?.article_active_in_shop2)}])
                }, null, 2)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "name",
              sortable: true,
              style: {"min-width":"15rem"},
              header: _ctx.$t('labels.name')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: _ctx.$t('labels.name')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("span", _hoisted_5, _toDisplayString(data.name || data.shopwareData?.name), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "sku",
              header: _ctx.$t('labels.productAdd.sku'),
              style: {"min-width":"12rem"}
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: _ctx.$t('labels.productAdd.sku')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx((data) => [
                (data.data.articleSupplySources && data.data.articleSupplySources.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(data.data.articleSupplySources[0].articleNumber), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "supplierNumber",
              header: _ctx.$t('labels.salesOrder.supplierNumber'),
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}],
              style: {"min-width":"12rem"}
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: _ctx.$t('labels.salesOrder.supplierNumber')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx((data) => [
                (data.data.articleSupplySources && data.data.articleSupplySources.length > 0)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass({'product-supply-source-not-primary': _ctx.filters.supplierNumber?.value && _ctx.filters.supplierNumber?.value !== data.data.articleSupplySources[0].supplierNumber})
                    }, _toDisplayString(data.data.articleSupplySources[0].supplierNumber), 3))
                  : _createCommentVNode("", true),
                (_ctx.filters.supplierNumber?.value && _ctx.filters.supplierNumber?.value !== data.data.articleSupplySources[0].supplierNumber)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('messages.nonPrimarySupplier')), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('labels.productAdd.supplier'),
              style: {"min-width":"8rem"}
            }, {
              body: _withCtx((data) => [
                (data.data.articleSupplySources && data.data.articleSupplySources.length > 0)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass([{'product-supply-source-not-primary': _ctx.filters.supplierNumber?.value && _ctx.filters.supplierNumber?.value !== data.data.articleSupplySources[0].supplierNumber}, "text-xs"])
                    }, _toDisplayString(data.data.articleSupplySources[0].supplier.name), 3))
                  : _createCommentVNode("", true),
                (_ctx.filters.supplierNumber?.value && data.data.articleSupplySources && data.data.articleSupplySources.length > 0 && _ctx.filters.supplierNumber?.value !== data.data.articleSupplySources[0].supplierNumber)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('messages.nonPrimarySupplier')), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "manufacturerName",
              sortable: true,
              style: {"min-width":"12rem"},
              header: _ctx.$t('labels.manufacturer')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: _ctx.$t('labels.manufacturer')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("span", _hoisted_9, _toDisplayString(data.manufacturerName || data.shopwareData?.manufacturer), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "blackWeekPromoFlag",
              dataType: "boolean",
              bodyClass: "text-center",
              style: {"max-width":"6rem"},
              header: _ctx.$t('labels.productPromotions.promoFlag')
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_TriStateCheckbox, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onChange: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.customAttributes?.article_promo_flag, 'text-red-600 pi-times-circle': !(data.customAttributes?.article_promo_flag)}])
                }, null, 2)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "procurementLeadDays",
              header: "WBT",
              sortable: true,
              style: {"min-width":"8rem","cursor":"pointer"},
              filterMatchModeOptions: _ctx.matchModesNumeric
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputNumber, {
                  class: "p-column-filter text-xs w-full",
                  autocomplete: "off",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"])
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              editor: _withCtx(({ data, field }) => [
                (_ctx.filters?.supplierNumber?.value && data?.articleSupplySources && data.articleSupplySources.length > 0 && _ctx.filters.supplierNumber.value !== data.articleSupplySources[0].supplierNumber)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(data.procurementLeadDays), 1))
                  : (_openBlock(), _createBlock(_component_InputText, {
                      key: 1,
                      modelValue: data[field],
                      "onUpdate:modelValue": $event => ((data[field]) = $event),
                      autofocus: "",
                      class: "w-full"
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              ]),
              _: 1
            }, 8, ["filterMatchModeOptions"]),
            _createVNode(_component_Column, {
              field: "wbtDate",
              filterMatchModeOptions: _ctx.matchModeWithEmptyFilter,
              sortable: true,
              header: "WBT-Datum",
              style: {"min-width":"10rem"}
            }, {
              filter: _withCtx(({filterModel}) => [
                _createVNode(_component_Calendar, {
                  class: "p-column-filter text-xs",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  dateFormat: "dd.mm.yy"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              body: _withCtx((data) => [
                (data.data.customAttributes?.art_wbt_datum)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(new
                    Date(data.data.customAttributes.art_wbt_datum).toLocaleDateString("de-DE")), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["filterMatchModeOptions"])
          ]),
          _: 1
        }, 8, ["onCellEditComplete", "totalRecords", "value", "filters", "onStateRestore", "selection", "onRowSelect", "onRowSelectAll", "onRowUnselect", "onRowUnselectAll"])
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_PrimeDialog, {
      header: _ctx.$t('messages.pleaseConfirm'),
      visible: _ctx.showWbtUpdateDialog,
      "onUpdate:visible": _cache[10] || (_cache[10] = $event => ((_ctx.showWbtUpdateDialog) = $event)),
      breakpoints: {'960px': '75vw'},
      style: {width: '20vw'},
      closable: false,
      modal: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.procurementLeadDays')), 1),
            _createVNode(_component_InputNumber, {
              autocomplete: "off",
              modelValue: _ctx.bulkWbtUpdateValue,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.bulkWbtUpdateValue) = $event)),
              onInput: _ctx.forceUpdateWbtValue
            }, null, 8, ["modelValue", "onInput"])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_p_button, {
            severity: "danger",
            onClick: _cache[8] || (_cache[8] = $event => (_ctx.closeWbtBulkUpdateDialog(false)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_p_button, {
            severity: "success",
            disabled: !_ctx.bulkWbtUpdateValue,
            onClick: _cache[9] || (_cache[9] = $event => (_ctx.closeWbtBulkUpdateDialog(true)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ]),
      _: 1
    }, 8, ["header", "visible"])
  ], 64))
}