import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 0 }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Card = _resolveComponent("Card")
  const _component_Timeline = _resolveComponent("Timeline")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, null, {
      header: _withCtx(() => [
        (_ctx.productDetails?.weclapp)
          ? (_openBlock(), _createElementBlock("h5", _hoisted_1, [
              _createElementVNode("a", {
                href: _ctx.productDetails.weclapp.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + _ctx.productDetails.weclapp.id,
                target: "_blank"
              }, _toDisplayString(_ctx.productId), 9, _hoisted_2),
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.productDetails.weclapp.name) + " ", 1),
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "spacer-dot" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("labels.changelog")), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_loading, {
          active: _ctx.loading,
          "onUpdate:active": _cache[1] || (_cache[1] = $event => ((_ctx.loading) = $event))
        }, null, 8, ["active"]),
        _createVNode(_component_Timeline, {
          value: _ctx.events,
          align: "alternate",
          class: "customized-timeline"
        }, {
          marker: _withCtx((slotProps) => [
            _createElementVNode("span", {
              class: "custom-marker shadow-2",
              style: _normalizeStyle({backgroundColor: _ctx.getBackgroundColorBasedOnHttpVerb(slotProps.item.action)})
            }, [
              _createElementVNode("i", {
                class: _normalizeClass(_ctx.getIconBasedOnHttpVerb(slotProps.item.childEntityName))
              }, null, 2)
            ], 4)
          ]),
          content: _withCtx((slotProps) => [
            _createVNode(_component_Card, null, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleBasedOnChildEntity(slotProps.item.childEntityName)), 1)
              ]),
              subtitle: _withCtx(() => [
                _createTextVNode(_toDisplayString(slotProps.item.timestamp), 1)
              ]),
              content: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: _ctx.getContentBasedOnEventDetails(slotProps.item)
                }, null, 8, _hoisted_3)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["value"]),
        (!_ctx.loading && _ctx.events.length < 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("messages.noRecordsFound")), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}