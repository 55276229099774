import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mt-2" }
const _hoisted_2 = { class: "flex align-content-center" }
const _hoisted_3 = { class: "p-button-label" }
const _hoisted_4 = { class: "flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_p_button = _resolveComponent("p-button")
  const _component_Accessories = _resolveComponent("Accessories")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_TabView = _resolveComponent("TabView")
  const _component_BulkAddAccessories = _resolveComponent("BulkAddAccessories")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_p_button, {
        class: "text-sm",
        onClick: _cache[0] || (_cache[0] = $event => {_ctx.bulkAddAccessoriesDialogVisible = true; _ctx.bulkAddDialogPlatform = _ctx.secondPlatform;}),
        severity: "success"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _cache[5] || (_cache[5] = _createElementVNode("span", {
              class: "p-button-icon p-button-icon-left pi pi-plus",
              "data-pc-section": "icon"
            }, null, -1)),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('buttons.addNewGeneric')), 1),
            (_ctx.productDetails?.shopware)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                }, null, 2))
              : _createCommentVNode("", true),
            (_ctx.productDetails?.secondShopware)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass(["sales-channel-dot w-1rem h-1rem", {'ml-1': _ctx.productDetails?.shopware, 'ml-2': !_ctx.productDetails?.shopware, 'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                }, null, 2))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_TabView, { class: "tabview-custom text-sm" }, {
      default: _withCtx(() => [
        _createVNode(_component_TabPanel, {
          disabled: !_ctx.productDetails?.shopware
        }, {
          header: _withCtx(() => [
            _cache[6] || (_cache[6] = _createElementVNode("i", { class: "pi pi-sitemap mr-2" }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.articleAccessories")), 1),
            _createElementVNode("span", {
              class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
            }, null, 2)
          ]),
          default: _withCtx(() => [
            (_ctx.productDetails?.shopware?.productNumber)
              ? (_openBlock(), _createBlock(_component_Accessories, {
                  key: 0,
                  "product-number": _ctx.productDetails.shopware.productNumber,
                  reloadRequired: _ctx.gridReloadRequired,
                  onCrossPlatformGridReload: _cache[1] || (_cache[1] = $event => (_ctx.closeAddUsingSelectedMainProducts(true)))
                }, null, 8, ["product-number", "reloadRequired"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(_component_TabPanel, {
          disabled: !_ctx.productDetails?.secondShopware
        }, {
          header: _withCtx(() => [
            _cache[7] || (_cache[7] = _createElementVNode("i", { class: "pi pi-sitemap mr-2" }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("labels.articleAccessories")), 1),
            _createElementVNode("span", {
              class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-2", {'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
            }, null, 2)
          ]),
          default: _withCtx(() => [
            (_ctx.productDetails?.secondShopware?.productNumber)
              ? (_openBlock(), _createBlock(_component_Accessories, {
                  key: 0,
                  "product-number": _ctx.productDetails.secondShopware.productNumber,
                  platform: _ctx.secondPlatform,
                  reloadRequired: _ctx.gridReloadRequired,
                  onCrossPlatformGridReload: _cache[2] || (_cache[2] = $event => (_ctx.closeAddUsingSelectedMainProducts(true)))
                }, null, 8, ["product-number", "platform", "reloadRequired"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    }),
    _createVNode(_component_BulkAddAccessories, {
      products: [{..._ctx.productDetails?.weclapp, shopwareData: _ctx.productDetails?.shopware, secondShopwareData: _ctx.productDetails?.secondShopware}],
      displayResponsive: _ctx.bulkAddAccessoriesDialogVisible,
      onCloseDialog: _ctx.closeAddUsingSelectedMainProducts
    }, null, 8, ["products", "displayResponsive", "onCloseDialog"]),
    _createVNode(_component_PrimeDialog, {
      visible: _ctx.bulkAddResultVisible,
      "onUpdate:visible": _cache[3] || (_cache[3] = $event => ((_ctx.bulkAddResultVisible) = $event)),
      header: _ctx.$t('labels.result'),
      onHide: _cache[4] || (_cache[4] = $event => {_ctx.bulkAddAccessoriesDialogVisible = false;}),
      modal: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TabView, { class: "tabview-custom text-sm" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.bulkAddData), (platform) => {
              return (_openBlock(), _createBlock(_component_TabPanel, null, {
                header: _withCtx(() => [
                  _createElementVNode("span", {
                    class: _normalizeClass(["sales-channel-dot", {'sales-channel-teltec':['teltec', 'teltec-demo'].includes(platform), 'sales-channel-videodata':['videodata', 'videodata-demo'].includes(platform)}])
                  }, null, 2)
                ]),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.bulkAddData[platform]), (productNumber) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_DataTable, {
                        class: "p-datatable-sm text-sm mr-5",
                        stripedRows: "",
                        paginator: "",
                        dataKey: "id",
                        rows: 10,
                        value: _ctx.bulkAddData[platform][productNumber]['oldAccessories'] || [],
                        responsiveLayout: "scroll",
                        "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                        "rows-per-page-options": [10,20,50,100,200],
                        "current-page-report-template": "{first} to {last} of {totalRecords}"
                      }, {
                        header: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('labels.previousArticleAccessories')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_Column, {
                            field: "productNumber",
                            sortable: "",
                            header: _ctx.$t('labels.articleNumber')
                          }, null, 8, ["header"]),
                          _createVNode(_component_Column, {
                            field: "name",
                            sortable: "",
                            header: _ctx.$t('labels.articleName')
                          }, null, 8, ["header"])
                        ]),
                        _: 2
                      }, 1032, ["value"]),
                      _createVNode(_component_DataTable, {
                        class: "p-datatable-sm text-sm",
                        stripedRows: "",
                        paginator: "",
                        dataKey: "id",
                        rows: 10,
                        value: _ctx.bulkAddData[platform][productNumber]['newAccessories'] || [],
                        responsiveLayout: "scroll",
                        "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                        "rows-per-page-options": [10,20,50,100,200],
                        "current-page-report-template": "{first} to {last} of {totalRecords}"
                      }, {
                        header: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('labels.currentArticleAccessories')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_Column, {
                            field: "productNumber",
                            sortable: "",
                            header: _ctx.$t('labels.articleNumber')
                          }, null, 8, ["header"]),
                          _createVNode(_component_Column, {
                            field: "name",
                            sortable: "",
                            header: _ctx.$t('labels.articleName')
                          }, null, 8, ["header"])
                        ]),
                        _: 2
                      }, 1032, ["value"])
                    ]))
                  }), 256))
                ]),
                _: 2
              }, 1024))
            }), 256))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}