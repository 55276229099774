import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "grid p-fluid mt-2" }
const _hoisted_3 = { class: "col field" }
const _hoisted_4 = { class: "w-full flex justify-content-between mt-2" }

export function render(_ctx, _cache) {
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('messages.pleaseConfirm'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[2] || (_cache[2] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw', '640px': '90vw'},
    style: {width: '450px'},
    onShow: _ctx.onShowDialog,
    onHide: _cache[3] || (_cache[3] = $event => (_ctx.confirmationInputValue = '')),
    modal: true
  }, {
    default: _withCtx(() => [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex justify-content-center" }, [
        _createElementVNode("span", {
          style: {"font-size":"8rem"},
          class: "pi pi-exclamation-triangle"
        })
      ], -1)),
      _createElementVNode("div", {
        class: "mt-3",
        innerHTML: _ctx.isMailDialog ? _ctx.$t('messages.manualMailJobRunConfirmation') : _ctx.$t('messages.manualCronJobRunConfirmation')
      }, null, 8, _hoisted_1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.emailRecipient')), 1),
          _createVNode(_component_InputText, {
            autocomplete: "off",
            modelValue: _ctx.confirmationInputValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.confirmationInputValue) = $event)),
            ref: "confirmationInputField"
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_p_button, {
          severity: "danger",
          onClick: _cache[1] || (_cache[1] = $event => (_ctx.showDialog = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_p_button, {
          disabled: !_ctx.confirmationInputValue && _ctx.isMailDialog,
          severity: "success",
          onClick: _ctx.onConfirmClicked
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["header", "visible", "onShow"]))
}