import {computed, ref, watch} from 'vue';
import Dialog from 'primevue/dialog';
import useVuelidate from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';
import {i18n} from '@/utils/i18n';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Fieldset from 'primevue/fieldset';
import InputText from 'primevue/inputtext';
import Panel from 'primevue/panel';
import {ReductionAdditionType, ReductionAdditionValueType} from '@/utils/enums';
import {DateTime} from 'luxon';

export default {
    components: {
        PrimeDialog: Dialog,
        'p-button': Button,
        'p-dropdown': Dropdown,
        InputNumber,
        'p-fieldset': Fieldset,
        InputText,
        Panel
    },
    emits: ['close-dialog'],
    props: {
        displayScaledPriceDialog: {
            type: Boolean,
            default: false
        },
        originalPrice: {
            type: Object,
            default: false
        }
    },
    setup(props: any, context: any) {
        const showDialog = ref(props.displayScaledPriceDialog);
        const originalPrice = ref(props.originalPrice);

        const submitted = ref(false);

        watch(
            () => props.displayScaledPriceDialog,
            (val) => {
                showDialog.value = val;
            }
        );

        watch(
            () => props.originalPrice,
            (val) => {
                originalPrice.value = val;
                state.value = {
                    price: originalPrice.value?.price,
                    priceScaleValue:
                        (originalPrice.value?.priceScaleValue || 0) + 1,
                    reductionAdditions: (
                        originalPrice.value?.reductionAdditions || []
                    ).map((item: any) => {
                        return {
                            ...item,
                            value: parseFloat(item.value),
                            description: Object.values(
                                ReductionAdditionType
                            ).includes(item.name as ReductionAdditionType)
                                ? item.name
                                : ReductionAdditionType.Regular
                        };
                    })
                };
            }
        );

        const closeResponsive = (dialogData: any = null) => {
            showDialog.value = false;
            context.emit(
                'close-dialog',
                dialogData
                    ? {...dialogData, originalPriceId: originalPrice.value?.id}
                    : null
            );
        };

        const state = ref({
            price: null,
            priceScaleValue: null,
            reductionAdditions: []
        });

        const rules = {
            price: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            priceScaleValue: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            reductionAdditions: {
                $each: helpers.forEach({
                    type: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    },
                    value: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    }
                })
            }
        };

        const v$ = useVuelidate(rules, state);

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            closeResponsive(state.value);
        };

        const addReductionAddition = () => {
            state.value.reductionAdditions.push({
                type: null,
                value: null
            });
        };

        const removeReductionAddition = (index: number) => {
            state.value.reductionAdditions.splice(index, 1);
        };

        const currencyName = computed(() => originalPrice.value?.currencyName);

        const priceScaleMinValue = computed(
            () => (originalPrice.value?.priceScaleValue || 0) + 1
        );

        const startDate = computed(() => {
            return originalPrice.value?.startDate
                ? DateTime.fromMillis(originalPrice.value?.startDate)
                      .setLocale(i18n.global.locale)
                      .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                      .toLocaleString({
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                      })
                : null;
        });
        const endDate = computed(() => {
            return originalPrice.value?.endDate
                ? DateTime.fromMillis(originalPrice.value?.endDate)
                      .setLocale(i18n.global.locale)
                      .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                      .toLocaleString({
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                      })
                : null;
        });

        return {
            showDialog,
            closeResponsive,
            state,
            v$,
            handleSubmit,
            submitted,
            reductionAdditionValueOptions: [
                {
                    label: i18n.global.t(
                        'labels.productPrices.reductionAdditionTypeSet.' +
                            ReductionAdditionType.Regular
                    ),
                    value: ReductionAdditionType.Regular
                },
                {
                    label: i18n.global.t(
                        'labels.productPrices.reductionAdditionTypeSet.' +
                            ReductionAdditionType.Transport
                    ),
                    value: ReductionAdditionType.Transport
                },
                {
                    label: i18n.global.t(
                        'labels.productPrices.reductionAdditionTypeSet.' +
                            ReductionAdditionType.Customs
                    ),
                    value: ReductionAdditionType.Customs
                },
                {
                    label: i18n.global.t(
                        'labels.productPrices.reductionAdditionTypeSet.' +
                            ReductionAdditionType.NSR
                    ),
                    value: ReductionAdditionType.NSR
                }
            ],
            reductionAdditionValueTypeOptions: [
                {
                    label: i18n.global.t(
                        'labels.productPrices.reductionAdditionValueTypeSet.' +
                            ReductionAdditionValueType.ReductionPercent
                    ),
                    value: ReductionAdditionValueType.ReductionPercent
                },
                {
                    label: i18n.global.t(
                        'labels.productPrices.reductionAdditionValueTypeSet.' +
                            ReductionAdditionValueType.ReductionAbsolute
                    ),
                    value: ReductionAdditionValueType.ReductionAbsolute
                },
                {
                    label: i18n.global.t(
                        'labels.productPrices.reductionAdditionValueTypeSet.' +
                            ReductionAdditionValueType.AdditionPercent
                    ),
                    value: ReductionAdditionValueType.AdditionPercent
                },
                {
                    label: i18n.global.t(
                        'labels.productPrices.reductionAdditionValueTypeSet.' +
                            ReductionAdditionValueType.AdditionAbsolute
                    ),
                    value: ReductionAdditionValueType.AdditionAbsolute
                }
            ],
            locale: i18n.global.locale,
            addReductionAddition,
            removeReductionAddition,
            currencyName,
            priceScaleMinValue,
            originalPrice,
            startDate,
            endDate
        };
    }
};
