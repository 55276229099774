import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full flex justify-content-start" }
const _hoisted_2 = { class: "flex align-items-center" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { key: 0 }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_p_button = _resolveComponent("p-button")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Column = _resolveComponent("Column")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_Tag = _resolveComponent("Tag")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Crons = _resolveComponent("Crons")
  const _component_Panel = _resolveComponent("Panel")
  const _component_AddSupplierDialog = _resolveComponent("AddSupplierDialog")
  const _component_AddDiscountGroupDialog = _resolveComponent("AddDiscountGroupDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, null, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('labels.supplierConfiguration')), 1),
            _createVNode(_component_p_button, {
              class: "ml-3",
              onClick: _cache[1] || (_cache[1] = $event => (_ctx.displayAddDialog = true)),
              size: "small",
              severity: "success"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("buttons.addNewGeneric")), 1)
              ]),
              _: 1
            })
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          paginator: "",
          rows: 10,
          scrollable: "",
          scrollHeight: "calc(100vh - 25rem)",
          ref: "dt",
          dataKey: "id",
          totalRecords: _ctx.totalRecords,
          value: _ctx.logs,
          editingRows: _ctx.editingRows,
          "onUpdate:editingRows": _cache[2] || (_cache[2] = $event => ((_ctx.editingRows) = $event)),
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50],
          "current-page-report-template": "{first} to {last} of {totalRecords}",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[3] || (_cache[3] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          editMode: "row",
          onRowEditSave: _ctx.onRowEditComplete
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "supplierName",
              style: {"max-width":"10rem"},
              header: _ctx.$t('labels.productAdd.supplier'),
              sortable: ""
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.supplierName) + " ", 1),
                (data.supplierId)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                      _cache[4] || (_cache[4] = _createTextVNode("[")),
                      _createElementVNode("a", {
                        href: data.url + 'webapp/view/party/PartyDetail.page?entityId=' + data.supplierId,
                        target: "_blank"
                      }, _toDisplayString(data.supplierNumber), 9, _hoisted_5),
                      _cache[5] || (_cache[5] = _createTextVNode("]"))
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_6, " [" + _toDisplayString(data.supplierNumber) + "]", 1))
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "costPriceInGross",
              dataType: "boolean",
              sortable: "",
              style: {"max-width":"6rem"},
              header: "EK als Bruttopreis in der PL",
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_TriStateCheckbox, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onChange: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_p_checkbox, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  binary: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.costPriceInGross, 'text-red-600 pi-times-circle': !(data.costPriceInGross)}])
                }, null, 2)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "listPriceInGross",
              dataType: "boolean",
              sortable: "",
              style: {"max-width":"6rem"},
              header: "UVP als Bruttopreis in der PL",
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_TriStateCheckbox, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onChange: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_p_checkbox, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  binary: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.listPriceInGross, 'text-red-600 pi-times-circle': !(data.listPriceInGross)}])
                }, null, 2)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "trailingDiscountPossible",
              dataType: "boolean",
              sortable: "",
              style: {"max-width":"6rem"},
              header: "NSR möglich",
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_TriStateCheckbox, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onChange: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_p_checkbox, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  binary: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.trailingDiscountPossible, 'text-red-600 pi-times-circle': !(data.trailingDiscountPossible)}])
                }, null, 2)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "trailingDiscountPercentage",
              style: {"min-width":"8rem"},
              header: _ctx.$t('labels.priceImport.trailingDiscountPercentage')
            }, {
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputNumber, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  suffix: " %",
                  mode: "decimal",
                  locale: _ctx.locale,
                  maxFractionDigits: 3,
                  class: "p-column-filter text-sm"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "locale"])
              ]),
              body: _withCtx(({data}) => [
                (data.trailingDiscountPercentage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.formatter.format(data.trailingDiscountPercentage)) + " % ", 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "automaticImportActive",
              dataType: "boolean",
              sortable: "",
              style: {"max-width":"6rem"},
              header: "Automatischer Import",
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_TriStateCheckbox, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onChange: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_p_checkbox, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  binary: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.automaticImportActive, 'text-red-600 pi-times-circle': !(data.automaticImportActive)}])
                }, null, 2)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              style: {"max-width":"12rem"},
              header: _ctx.$t('labels.discountGroups')
            }, {
              body: _withCtx(({data}) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.discountGroups, (element) => {
                  return (_openBlock(), _createElementBlock("span", null, [
                    _createVNode(_component_Tag, {
                      class: "ml-1 mb-1",
                      style: {"text-transform":"uppercase"},
                      severity: element?.name ? 'primary': 'warning'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(element?.name ? element?.name + ' : ' :
                        '') + _toDisplayString(element?.percentage) + "% " + _toDisplayString(element.manufacturerName
                        ? ('(' + element.manufacturerName + ')') : '') + " ", 1),
                        _createElementVNode("i", {
                          class: "pi pi-trash text-xs ml-2",
                          onClick: $event => (_ctx.removeDiscountGroup(data.id, element)),
                          style: {"cursor":"pointer"}
                        }, null, 8, _hoisted_8)
                      ]),
                      _: 2
                    }, 1032, ["severity"])
                  ]))
                }), 256)),
                _createElementVNode("div", {
                  style: {"cursor":"pointer"},
                  onClick: $event => {_ctx.selectedConfiguration = data.id.toString();_ctx.displayAddDiscountGroupDialog = true}
                }, _cache[6] || (_cache[6] = [
                  _createElementVNode("i", {
                    class: "pi pi-plus-circle ml-1 text-green-600",
                    style: {"margin-top":"2px"}
                  }, null, -1)
                ]), 8, _hoisted_9)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "importConfirmationPercentageThreshold",
              style: {"max-width":"10rem"},
              header: "Schwellenwert für die manuelle Verarbeitungsbestätigung (EK/UVP Diff > ±15%)",
              sortable: ""
            }, {
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputNumber, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  mode: "decimal",
                  locale: _ctx.locale,
                  maxFractionDigits: 3,
                  class: "p-column-filter text-sm",
                  inputClass: "w-8rem"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "locale"])
              ]),
              body: _withCtx(({data}) => [
                (data.importConfirmationPercentageThreshold)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.formatter.format(data.importConfirmationPercentageThreshold)) + " % ", 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "forceSellPriceToListPrice",
              dataType: "boolean",
              sortable: "",
              style: {"max-width":"6rem"},
              header: "VK = UVP",
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_TriStateCheckbox, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onChange: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_p_checkbox, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  binary: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.forceSellPriceToListPrice, 'text-red-600 pi-times-circle': !(data.forceSellPriceToListPrice)}])
                }, null, 2)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              rowEditor: "",
              style: {"width":"10%","min-width":"5rem"},
              bodyStyle: "text-align:center"
            })
          ]),
          _: 1
        }, 8, ["totalRecords", "value", "editingRows", "filters", "onRowEditSave"]),
        _createVNode(_component_Crons, {
          "cron-type": 'price-import',
          reloadRequired: _ctx.reloadRequired
        }, null, 8, ["reloadRequired"])
      ]),
      _: 1
    }),
    _createVNode(_component_AddSupplierDialog, {
      displayResponsive: _ctx.displayAddDialog,
      onCloseDialog: _ctx.closeDialogListener
    }, null, 8, ["displayResponsive", "onCloseDialog"]),
    _createVNode(_component_AddDiscountGroupDialog, {
      displayResponsive: _ctx.displayAddDiscountGroupDialog,
      "supplier-configuration-id": _ctx.selectedConfiguration,
      onCloseDialog: _ctx.closeDialogListener
    }, null, 8, ["displayResponsive", "supplier-configuration-id", "onCloseDialog"])
  ], 64))
}