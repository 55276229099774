<BulkProductAction
    @close-dialog="closeResponsive"
    @confirm-clicked="confirmClicked"
    :products="products"
    :productTotalRecords="productTotalRecords"
    :displayDialog="showDialog"
    :isFilterRelated="isFilterRelated"
    :summaryTabVisible="summaryTabVisible"
    :loading="loading"
    :dialogHeader="$t('labels.editCustomFields')"
    canPostponeExecution
>
    <template #tab-panel-middle>
        <TabPanel :header="$t('labels.customAttributes')">
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label
                        v-tooltip.bottom="customAttributesDefinition?.article_memo?.attributeDescription"
                        >{{ $t('labels.productHome.memo') }}
                    </label>
                    <div>
                        <InputSwitch
                            v-model="bulkEditSwitches.article_memo"
                        ></InputSwitch>
                    </div>
                    <p-textarea
                        rows="4"
                        autocomplete="off"
                        v-model="v$.article_memo.$model"
                        :disabled="!bulkEditSwitches.article_memo"
                        :class="{'p-invalid':v$.article_memo.$invalid && submitted}"
                    />
                </div>
                <div class="col field">
                    <label
                        v-tooltip.bottom="customAttributesDefinition?.article_comment_delivery_note?.attributeDescription"
                        >{{ $t('labels.productHome.commentDeliveryNote') }}
                    </label>
                    <div>
                        <InputSwitch
                            v-model="bulkEditSwitches.article_comment_delivery_note"
                        ></InputSwitch>
                    </div>
                    <p-textarea
                        rows="4"
                        autocomplete="off"
                        v-model="v$.article_comment_delivery_note.$model"
                        :disabled="!bulkEditSwitches.article_comment_delivery_note"
                        :class="{'p-invalid':v$.article_comment_delivery_note.$invalid && submitted}"
                    />
                </div>
                <div class="col field">
                    <label
                        v-tooltip.bottom="customAttributesDefinition?.art_eol_datum?.attributeDescription"
                        >{{ $t('labels.productHome.eol') }}
                    </label>
                    <div>
                        <InputSwitch
                            v-model="bulkEditSwitches.art_eol_datum"
                        ></InputSwitch>
                    </div>
                    <p-calendar
                        autocomplete="off"
                        dateFormat="dd.mm.yy"
                        v-model="v$.art_eol_datum.$model"
                        :disabled="!bulkEditSwitches.art_eol_datum"
                        :class="{'tt-form-input': true, 'p-invalid':v$.art_eol_datum.$invalid && submitted}"
                    />
                </div>
                <div class="col field">
                    <label
                        v-tooltip.bottom="customAttributesDefinition?.article_garantiezeit?.attributeDescription"
                        >{{ $t('labels.productHome.warrantyTime') }}
                    </label>
                    <div>
                        <InputSwitch
                            v-model="bulkEditSwitches.article_garantiezeit"
                        ></InputSwitch>
                    </div>
                    <p-dropdown
                        v-model="v$.article_garantiezeit.$model"
                        :options="warrantyTimeOptions"
                        :disabled="!bulkEditSwitches.article_garantiezeit"
                        optionLabel="label"
                        optionValue="value"
                        :class="{'p-invalid':v$.article_garantiezeit.$invalid && submitted}"
                    />
                </div>
                <div class="col field">
                    <label
                        >{{ $t('labels.productHome.priceUponRequest') }}
                    </label>
                    <div>
                        <InputSwitch
                            v-model="bulkEditSwitches.priceUponRequest"
                        ></InputSwitch>
                    </div>
                    <p-dropdown
                        v-model="v$.priceUponRequest.$model"
                        :options="priceUponRequestOptions"
                        :disabled="!bulkEditSwitches.priceUponRequest"
                        optionLabel="label"
                        optionValue="value"
                        :class="{'p-invalid':v$.priceUponRequest.$invalid && submitted}"
                    />
                </div>
            </div>
            <Divider align="left" type="dashed"> </Divider>
            <div class="grid">
                <div class="field col">
                    <label
                        >{{ $t('labels.productHome.serialNumberIntern') }}
                    </label>
                    <TriStateCheckbox
                        class="ml-2"
                        v-model="v$.SN_AUTO_GENERATE.$model"
                    ></TriStateCheckbox>
                </div>
                <div class="field col">
                    <label
                        v-tooltip.bottom="customAttributesDefinition?.article_batteriegesetz_relevant?.attributeDescription"
                        >{{ $t('labels.productHome.battg') }}
                    </label>
                    <TriStateCheckbox
                        class="ml-2"
                        v-model="v$.article_batteriegesetz_relevant.$model"
                    ></TriStateCheckbox>
                </div>
                <div class="field col">
                    <label
                        v-tooltip.bottom="customAttributesDefinition?.article_ueberlaenge?.attributeDescription"
                        >{{ $t('labels.productHome.oversized') }}
                    </label>
                    <TriStateCheckbox
                        class="ml-2"
                        v-model="v$.article_ueberlaenge.$model"
                    ></TriStateCheckbox>
                </div>
                <div class="field col">
                    <label
                        v-tooltip.bottom="customAttributesDefinition?.article_sell_out?.attributeDescription"
                        >{{ $t('labels.productHome.sellOut') }}
                    </label>

                    <TriStateCheckbox
                        class="ml-2"
                        v-model="v$.article_sell_out.$model"
                    ></TriStateCheckbox>
                </div>
                <div class="field col">
                    <label
                        v-tooltip.bottom="customAttributesDefinition?.article_markenmehrwert?.attributeDescription"
                        >{{ $t('labels.productHome.brandAddedValue') }}
                    </label>
                    <TriStateCheckbox
                        class="ml-2"
                        v-model="v$.article_markenmehrwert.$model"
                    ></TriStateCheckbox>
                </div>
                <div class="field col">
                    <label
                        v-tooltip.bottom="customAttributesDefinition?.article_no_pl_match_spare_part?.attributeDescription"
                        >{{ $t('labels.productHome.notInPriceListSparePart') }}
                    </label>
                    <TriStateCheckbox
                        class="ml-2"
                        v-model="v$.article_no_pl_match_spare_part.$model"
                    ></TriStateCheckbox>
                </div>
            </div>
            <Divider align="left" type="dashed"> </Divider>
            <div class="formgrid grid p-fluid">
                <div class="field col">
                    <label
                        v-tooltip.bottom="customAttributesDefinition?.article_sperrgut?.attributeDescription"
                        >{{ $t('labels.productHome.bulkGoods') }}
                    </label>
                    <TriStateCheckbox
                        class="ml-2"
                        v-model="v$.article_sperrgut.$model"
                    ></TriStateCheckbox>
                </div>
                <div class="field col">
                    <label
                        v-tooltip.bottom="customAttributesDefinition?.article_flag_aerosole?.attributeDescription"
                        >{{ $t('labels.productHome.containsAerosols') }}
                    </label>
                    <TriStateCheckbox
                        class="ml-2"
                        v-model="v$.article_flag_aerosole.$model"
                    ></TriStateCheckbox>
                </div>
                <div class="field col">
                    <label
                        v-tooltip.bottom="customAttributesDefinition?.article_flag_akku_groesser_100wh?.attributeDescription"
                        >{{ $t('labels.productHome.dangerousGoods') }}
                    </label>
                    <TriStateCheckbox
                        class="ml-2"
                        v-model="v$.article_flag_akku_groesser_100wh.$model"
                    ></TriStateCheckbox>
                </div>
                <div class="field col">
                    <label
                        v-tooltip.bottom="customAttributesDefinition?.article_flag_akku_kleiner_100wh?.attributeDescription"
                        >{{ $t('labels.productHome.dangerousGoodsSmall') }}
                    </label>
                    <TriStateCheckbox
                        class="ml-2"
                        v-model="v$.article_flag_akku_kleiner_100wh.$model"
                    ></TriStateCheckbox>
                </div>
            </div>
        </TabPanel>
    </template>
    <template #summary-panel-header="slotProps">
        <Panel class="mb-3">
            <template #header>
                <div class="font-bold">{{ $t('labels.adjustments') }}</div>
            </template>
            <template v-if="filteredInput">
                <div
                    v-for="item in Object.entries(filteredInput)"
                    class="grid p-fluid"
                >
                    <div class="col field">
                        <label class="mr-3 font-bold"
                            >{{ $t('labels.productHome.' +
                            fieldTranslationMapping[item[0]]) }}</label
                        >

                        <template v-if="item[1] === true || item[1] === false">
                            <TriStateCheckbox
                                v-model="item[1]"
                            ></TriStateCheckbox>
                        </template>
                        <template v-else-if="item[0] ==='priceUponRequest'"
                            >{{ priceUponRequestOptions.find((opt) => item[1]
                            === opt.value)?.label}}</template
                        >
                        <template v-else-if="item[0] ==='article_garantiezeit'"
                            >{{ warrantyTimeOptions.find((opt) => item[1] ===
                            opt.value)?.label}}</template
                        >
                        <template v-else
                            >{{item[1] ||
                            $t('messages.valueWillBeSetToNull').toUpperCase()
                            }}</template
                        >
                    </div>
                </div>
            </template>
        </Panel>
    </template>
    <template #summary-panel-middle>
        <h1 style="text-align: center">
            <font-awesome-icon
                style="margin-left: 30px"
                :icon="['fas', 'angle-double-down']"
            /><font-awesome-icon
                style="margin-left: 30px"
                :icon="['fas', 'angle-double-down']"
            /><font-awesome-icon
                style="margin-left: 30px"
                :icon="['fas', 'angle-double-down']"
            />
        </h1>
    </template>
</BulkProductAction>
